<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="LinkDialog">
  <h3 matDialogTitle data-test="title" gaClickListener="TitleClick">
    {{ 'offer.digital_offer.title' | translate }}
  </h3>

  <mat-dialog-content>
    <div fxLayout="column" [fxLayoutGap]="0.5 | dsSpacing">
      <span gaClickListener="TitleClick" data-test="description">
        {{ 'offer.digital_offer.description' | translate }}
      </span>
      <mat-card>
        <mat-card-content>
          <div fxLayout fxLayoutAlign="start center">
            <div fxFlex="1 1 auto" data-test="link" gaClickListener="LinkClick">
              {{ link }}
            </div>
            <button
              color="accent"
              fxFlex="0 0 auto"
              mat-raised-button
              data-test="copy-button"
              [cdkCopyToClipboard]="link"
              gaClickListener="CopyClick"
            >
              <span>
                {{ 'general.copy' | translate }}
              </span>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
      <ds-text-indicator
        type="warning"
        gaClickListener="WarningClick"
        data-test="warning"
      >
        {{ 'offer.digital_offer.warning' | translate }}
      </ds-text-indicator>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      matDialogClose
      gaClickListener="CloseClick"
      data-test="close-button"
    >
      {{ 'general.close' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
