<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="TechnicalData">
  <h2 id="technicalData" gaClickListener="HeadlineClick">
    {{ 'offer_technical_data_headline' | translate }}
  </h2>

  @if (technicalData?.litfing_cap_diagram1_url) {
    <img
      [src]="technicalData.litfing_cap_diagram1_url"
      alt="lifting diagram"
      gaClickListener="LifitingDiagramClick"
      data-test="lifting-diagram"
    />
  }

  @if (technicalData?.litfing_cap_diagram2_url) {
    <img
      [src]="technicalData.litfing_cap_diagram2_url"
      alt="lifting diagram 2"
      gaClickListener="LifitingDiagram2Click"
      data-test="lifting-diagram-2"
    />
  }

  @if (technicalData?.default_arm_drawing_url) {
    <ds-text-indicator
      type="warning"
      gaClickListener="OutreachLimitationWarningClick"
      data-test="outreach-limitation-warning"
    >
      {{ 'offer_outreach_limitation_warning' | translate }}</ds-text-indicator
    >
    <br />
    <img
      [src]="technicalData.default_arm_drawing_url"
      alt="arm drawing"
      gaClickListener="ArmDrawingClick"
      data-test="arm-drawing"
    />
    <br />
    <ds-text-indicator
      type="warning"
      gaClickListener="TechnicalDataWarning"
      data-test="technical-data-warning"
    >
      {{ 'offer_technical_data_warning' | translate }}
    </ds-text-indicator>
  }

  @if (technicalData?.stabilizer_drawing_url) {
    <img
      [src]="technicalData.stabilizer_drawing_url"
      alt="stabilizer"
      gaClickListener="StabilizerDrawingClick"
      data-test="stabilizer-drawing"
    />
  }

  @if (technicalData?.technical_table) {
    <table gaClickListener="TechnicalTableClick" data-test="technical-table">
      @for (item of technicalTable; track item) {
        <tr>
          <td>
            {{ item.key }}
          </td>
          <td>
            {{ item.value }}
          </td>
        </tr>
      }
    </table>
    <br />
    <ds-text-indicator
      type="warning"
      gaClickListener="TechnicalDataWarningClick"
      data-test="technical-changes-warning"
    >
      {{ 'offer_technical_data_technical_changes_warning' | translate }}
    </ds-text-indicator>
  }
</ng-container>
