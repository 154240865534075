<ng-container matColumnDef="end_date">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="end_date"
    data-test="lost-order-end-date-header"
  >
    {{ 'table_columns.end_date' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'EndDateClick'
    }"
    data-test="lost-order-end-date"
  >
    {{
      lostOrder.end_date
        ? (lostOrder.end_date | date: 'shortDate')
        : ('project.no_end_date' | translate)
    }}
  </td>
</ng-container>
