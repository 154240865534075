/* eslint-disable max-lines */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CalculationsService,
  Cart,
  CartCompare,
  CartItemsService,
  CartsService,
  Currency,
  Project,
  ProjectInput,
  ProjectsService,
} from '@sales-libs/project/data-access';
import { combineLatest, map, take } from 'rxjs';
import { SlProjectCartItemGroupCreationRowFactory } from './classes/factories/cart-item-group-creation-row-factory';
import { SlProjectCustomArticleItemCreationRowFactory } from './classes/factories/custom-article-item-creation-row-factory';
import { SlProjectProductItemCreationRowFactory } from './classes/factories/product-item-creation-row-factory';
import { SlProjectSalesOptionItemCreationRowFactory } from './classes/factories/sales-option-item-creation-row-factory';
import { SlProjectSolutionItemCreationRowFactory } from './classes/factories/solution-cart-item-creation-row-factory';
import { SlProjectWarrantyExtensionItemCreationRowFactory } from './classes/factories/warranty-extension-item-creation-row-factory';
import { SlProjectCalculationCreationRow } from './classes/rows/calculation-creation-row';
import { SlProjectCartCreationRow } from './classes/rows/cart-creation-row';
import { SlProjectCartDependendCreationRow } from './classes/rows/cart-dependend-row';
import { SlProjectComparisonCreationRow } from './classes/rows/comparison-creation-row';
import {
  SlProjectDataCreationRow,
  SlProjectDataCreationRowState,
} from './classes/rows/data-creation-row';
import { SlProjectCreationRow } from './classes/rows/project-creation-row';
import {
  SlProjectDataCreationCustomArticleItem,
  SlProjectDataCreationProductItem,
  SlProjectDataCreationSalesOptionItem,
  SlProjectDataCreationSolutionItem,
  SlProjectDataCreationWarrantyExtensionItem,
} from './classes/types/item-types';
import { SlProjectDataCreationOptionalGroup } from './classes/types/optional-group-type';

export interface SlProjectDataCreationDialogData {
  project?: ProjectInput;
  targetProjectId?: number;
  pricelistDate: Date;
  productLine: string;
  language: string;
  validUntil: Date;
  currency: Currency;
  comparison?: CartCompare;
  isCartWithCalculation?: boolean;
  product_items?: SlProjectDataCreationProductItem[];
  warranty_extension_items?: SlProjectDataCreationWarrantyExtensionItem[];
  custom_article_items?: SlProjectDataCreationCustomArticleItem[];
  solution_items?: SlProjectDataCreationSolutionItem[];
  sales_option_items?: SlProjectDataCreationSalesOptionItem[];
  optionalGroups?: SlProjectDataCreationOptionalGroup[];
  redirectTo?: string;
}

@Component({
  selector: 'sl-project-data-creation-dialog',
  templateUrl: './project-data-creation-dialog.component.html',
  styleUrls: ['./project-data-creation-dialog.component.scss'],
  standalone: false,
})
export class SlProjectDataCreationDialogComponent {
  isProjectCreationIncluded = false;
  isCreationCompleted = false;
  targetProjectId: number;
  createdCartId: number;

  rows: SlProjectDataCreationRow[] = [];
  rowStates = SlProjectDataCreationRowState;

  private _isOpenClicked = false;
  private _productItemCreationRowFactory: SlProjectProductItemCreationRowFactory;
  private _cartItemGroupCreationRowFactory: SlProjectCartItemGroupCreationRowFactory;
  private _salesOptionItemCreationRowFactory: SlProjectSalesOptionItemCreationRowFactory;
  private _customArticleItemCreationRowFactory: SlProjectCustomArticleItemCreationRowFactory;
  private _warrantyExtensionItemCreationRowFactory: SlProjectWarrantyExtensionItemCreationRowFactory;
  private _solutionCartItemCreationRowFactory: SlProjectSolutionItemCreationRowFactory; // Add this line

  constructor(
    private _router: Router,
    private _cartsService: CartsService,
    private _projectsService: ProjectsService,
    private _cartItemsService: CartItemsService,
    private _translateService: TranslateService,
    private _calculationsService: CalculationsService,
    private _selfRef: MatDialogRef<SlProjectDataCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: SlProjectDataCreationDialogData,
  ) {
    this._productItemCreationRowFactory =
      new SlProjectProductItemCreationRowFactory(this._cartItemsService);
    this._cartItemGroupCreationRowFactory =
      new SlProjectCartItemGroupCreationRowFactory(this._cartItemsService);
    this._salesOptionItemCreationRowFactory =
      new SlProjectSalesOptionItemCreationRowFactory(this._cartItemsService);
    this._customArticleItemCreationRowFactory =
      new SlProjectCustomArticleItemCreationRowFactory(this._cartItemsService);
    this._warrantyExtensionItemCreationRowFactory =
      new SlProjectWarrantyExtensionItemCreationRowFactory(
        this._cartItemsService,
      );
    this._solutionCartItemCreationRowFactory =
      new SlProjectSolutionItemCreationRowFactory(this._cartItemsService); // Add this line

    if (this._data.project) {
      this.isProjectCreationIncluded = true;
    } else if (this._data.targetProjectId) {
      this.targetProjectId = this._data.targetProjectId;
    }
    this.rows = this._mapDataToRows(this._data);
    combineLatest(this.rows.map((row) => row.result$))
      .pipe(take(1))
      .subscribe(() => (this.isCreationCompleted = true));
    this._setupCloseHandling();
  }

  onOpenClick() {
    this._isOpenClicked = true;
    this._selfRef.close(this.isCreationCompleted);
  }

  private _mapDataToRows(
    data: SlProjectDataCreationDialogData,
  ): SlProjectDataCreationRow[] {
    const productItemRows =
      data.product_items?.map((item) =>
        this._productItemCreationRowFactory.create(item),
      ) ?? [];
    const warrantyExtensionItemRows =
      data.warranty_extension_items?.map((item) =>
        this._warrantyExtensionItemCreationRowFactory.create(item),
      ) ?? [];
    const customArticleRows =
      data.custom_article_items?.map((item) =>
        this._customArticleItemCreationRowFactory.create(item),
      ) ?? [];
    const salesOptionRows =
      data.sales_option_items?.map((item) =>
        this._salesOptionItemCreationRowFactory.create(item),
      ) ?? [];
    const solutionCartItemRows =
      data.solution_items?.map((item) =>
        this._solutionCartItemCreationRowFactory.create(item),
      ) ?? []; // Add this block
    const optionalGroupRows =
      data.optionalGroups?.map((group) =>
        this._cartItemGroupCreationRowFactory.create(group),
      ) ?? [];

    const cartDependendRows: SlProjectCartDependendCreationRow[] = [
      ...productItemRows,
      ...warrantyExtensionItemRows,
      ...customArticleRows,
      ...optionalGroupRows,
      ...salesOptionRows,
      ...solutionCartItemRows,
    ];

    const flatCartDependendRows = [
      ...cartDependendRows,
      ...optionalGroupRows.reduce(
        (acc, optionalGroup) => [...acc, ...optionalGroup.relatedCreationRows],
        [] as SlProjectDataCreationRow[],
      ),
    ];

    if (data.comparison) {
      const comparisonRow = new SlProjectComparisonCreationRow(
        this._translateService.instant(
          'project.data_creation.saving_comparison',
        ),
        data.comparison,
        this._cartsService,
      );
      cartDependendRows.push(comparisonRow);
    }

    if (this._data.isCartWithCalculation && data.comparison) {
      const predecessesingRows = [
        ...cartDependendRows,
        ...optionalGroupRows.reduce(
          (acc, optionalGroup) => [
            ...acc,
            ...optionalGroup.relatedCreationRows,
          ],
          [] as SlProjectDataCreationRow[],
        ),
      ];
      const calculationRow = new SlProjectCalculationCreationRow(
        this._translateService.instant('project.data_creation.calculation'),
        data.comparison.source_cart_id,
        this._calculationsService,
        predecessesingRows,
      );

      cartDependendRows.push(calculationRow);
      flatCartDependendRows.push(calculationRow);
    }

    const cartCreationRow = new SlProjectCartCreationRow(
      this._translateService.instant('solution.solution'),
      {
        project_id: 0, // will be set after project creation
        price_list_date: this._data.pricelistDate,
        product_line: this._data.productLine,
        language: this._data.language,
        valid_until: this._data.validUntil,
        original_cart_id: this._data.comparison?.source_cart_id,
        currency_settings: this._data.currency,
      },
      this._cartsService,
      cartDependendRows,
    );
    cartCreationRow.result$
      .pipe(
        take(1),
        map((result) => result.data),
      )
      .subscribe((result: Cart) => (this.createdCartId = result.id));

    if (this.isProjectCreationIncluded) {
      const projectCreationRow = new SlProjectCreationRow(
        this._translateService.instant('project.project'),
        this._data.project as ProjectInput,
        this._projectsService,
        cartCreationRow,
      );

      projectCreationRow.result$
        .pipe(
          take(1),
          map((result) => result.data),
        )
        .subscribe((result: Project) => (this.targetProjectId = result.id));
      projectCreationRow.create();
      return [projectCreationRow, cartCreationRow, ...flatCartDependendRows];
    } else {
      cartCreationRow.projectId = this.targetProjectId;
      cartCreationRow.create();
      return [cartCreationRow, ...flatCartDependendRows];
    }
  }

  private _setupCloseHandling() {
    this._selfRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (this.isCreationCompleted && this._isOpenClicked) {
          this._router.navigate([
            'projects',
            this.targetProjectId,
            'carts',
            this.createdCartId,
            this._data.redirectTo ?? 'configuration',
          ]);
        } else if (this.isProjectCreationIncluded) {
          this._projectsService
            .deleteProject(this.targetProjectId)
            .pipe(take(1))
            .subscribe();
        } else if (this.createdCartId) {
          this._cartsService
            .deleteCart(this.createdCartId)
            .pipe(take(1))
            .subscribe();
        }
      });
  }
}
