<ng-container gaCategoryGroup="Upselling" gaLabelGroup="Utilization">
  <h3 matDialogTitle gaClickListener="DialogTitleClick">
    {{ 'project_detail.utilization' | translate }}
  </h3>
  <mat-dialog-content>
    <sl-upselling-best-invest-input-data
      [projectId]="data.projectId"
      [isReadOnly]="data.isReadOnly"
      [cancelSave]="revertParametersClicked"
      [resetExternalWorkingHourCosts]="updatedExternalWorkingHourCosts"
      (formStatusChange)="onFormStatusChange($event)"
    ></sl-upselling-best-invest-input-data>

    <mat-slide-toggle
      [(ngModel)]="showExtendedInputData"
      (click)="scrollToForm()"
      data-test="toggle-extended-data"
      class="extended-data-input-toggle"
      >{{
        'benefits.extended_data_input.show_extended_data_input' | translate
      }}</mat-slide-toggle
    >

    <div
      #extendedDataInput
      *ngIf="showExtendedInputData"
      data-test="extended-data"
    >
      <sl-upselling-best-invest-extended-input-data
        [projectId]="data.projectId"
        [isReadOnly]="data.isReadOnly"
        [cancelSave]="revertParametersClicked"
        (resetToDealerDefault)="resetValueToDealerDefault($event)"
        (extendedFormStatusChange)="onExtendedFormStatusChange($event)"
      >
      </sl-upselling-best-invest-extended-input-data>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      gaClickListener="CloseClick"
      data-test="close-button"
      (click)="revertParameters()"
    >
      {{ 'general.close' | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!(isUpdated || isExtendedFormUpdated) || !isValid"
      data-test="save-button"
      (click)="updateParameters()"
    >
      {{ 'general.save' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
