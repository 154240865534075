<ng-container gaLabelGroup="EditLostOrder" gaCategoryGroup="Projects">
  <h3
    matDialogTitle
    gaClickListener="TitleClick"
    data-test="edit-lost-order-title"
  >
    {{ 'edit_lost_order.lost_order' | translate }} ({{ project.code }})
  </h3>
  <form [formGroup]="form">
    <mat-dialog-content>
      <br />
      <div
        class="form-grid"
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      >
        <div fxFlex fxFlex.sm="100%" fxFlex.gt-sm="50%">
          <mat-form-field
            class="full-width"
            gaClickListener="DeliveryDateClick"
          >
            <mat-label>{{
              'edit_lost_order.delivery_date' | translate
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="closedDatepicker"
              [formControl]="form.controls.closed_date"
              required
              [min]="currentDate"
              autocomplete="off"
              data-test="edit-lost-order-delivery-date"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="closedDatepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #closedDatepicker></mat-datepicker>
            <mat-error *ngIf="form.controls.closed_date.invalid">
              <span *ngIf="form.controls.closed_date.hasError('required')">
                {{ 'common.not_empty_hint' | translate }}
              </span>
              <span *ngIf="form.controls.closed_date.hasError('minDate')">
                {{ 'common.future_date_hint' | translate }}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <mat-form-field class="full-width" gaClickListener="AmountClick">
            <mat-label>{{ 'edit_lost_order.amount' | translate }}</mat-label>
            <input
              matInput
              autocomplete="off"
              type="number"
              min="1"
              [formControl]="form.controls.amount"
              required
              data-test="edit-lost-order-amount"
            />
            <mat-error *ngIf="form.controls.amount.invalid">
              {{ 'common.not_empty_hint' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <mat-form-field class="full-width" gaClickListener="ReasonClick">
            <mat-label>{{ 'edit_lost_order.reason' | translate }}</mat-label>
            <mat-select
              [formControl]="form.controls.reason_id"
              required
              [hidden]="!reasons"
              data-test="edit-lost-order-reason"
            >
              <mat-option
                *ngFor="let reason of reasons"
                [value]="reason.id"
                [gaClickListener]="reason.name + 'ReasonClick'"
                data-test="reason-option"
                >{{ reason | slProjectReasonTranslation }}</mat-option
              >
            </mat-select>
            <mat-progress-spinner
              *ngIf="!reasons"
              mode="indeterminate"
              class="progress-spinner"
              [strokeWidth]="2"
              [diameter]="20"
              [color]="'warn'"
            >
            </mat-progress-spinner>
            <mat-error *ngIf="form.controls.reason_id.invalid">
              {{ 'common.not_empty_hint' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <mat-form-field class="full-width" gaClickListener="CompetitorClick">
            <mat-label>{{
              'edit_lost_order.competitor' | translate
            }}</mat-label>
            <mat-select
              [formControl]="form.controls.competitor_id"
              [required]="reasonRequiresCompetitor()"
              [hidden]="!competitors"
              data-test="edit-lost-order-competitor"
            >
              <mat-option
                *ngFor="let competitor of competitors"
                [value]="competitor.id"
                [gaClickListener]="competitor.name + 'CompetitorClick'"
                data-test="competitor-option"
                >{{ competitor.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                reasonRequiresCompetitor() && !form.controls.competitor_id.value
              "
            >
              {{ 'common.not_empty_hint' | translate }}
            </mat-error>
            <mat-progress-spinner
              *ngIf="!competitors"
              mode="indeterminate"
              class="progress-spinner"
              [strokeWidth]="2"
              [diameter]="20"
              [color]="'warn'"
            >
            </mat-progress-spinner>
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <mat-form-field
            class="full-width"
            gaClickListener="CompetitorTypeClick"
          >
            <mat-label>{{
              'edit_lost_order.competitor_type' | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              [formControl]="form.controls.competitor_type"
              autocomplete="off"
              data-test="edit-lost-order-competitor-type"
            />
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <mat-form-field
            class="full-width"
            gaClickListener="CompetitorPriceClick"
          >
            <mat-label>{{
              'edit_lost_order.competitor_price' | translate
            }}</mat-label>
            <input
              autocomplete="off"
              matInput
              type="number"
              [formControl]="form.controls.competitor_price"
              data-test="edit-lost-order-competitor-price"
            />
            <span matTextSuffix *ngIf="currency$ | async as currency">{{
              currency.symbol || currency.code
            }}</span>
          </mat-form-field>
        </div>
        <div fxFlex="100%">
          <mat-form-field class="full-width" gaClickListener="CommentClick">
            <mat-label>{{ 'edit_lost_order.comment' | translate }}</mat-label>
            <textarea
              matInput
              type="text"
              [formControl]="form.controls.comment"
              data-test="edit-lost-order-comment"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        matDialogClose
        gaClickListener="CancelClick"
        data-test="cancel-button"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="confirmChange()"
        [disabled]="!form.valid"
        gaClickListener="SaveClick"
        data-test="edit-lost-order-save"
      >
        {{ 'general.save' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
