<ng-container gaLabelGroup="TuningCenterDialog" gaCategoryGroup="Projects">
  <h3 matDialogTitle gaClickListener="TitleClick">
    {{ 'tuning_center.title' | translate }}
  </h3>
  <form
    [formGroup]="form"
    (ngSubmit)="confirm(ratio)"
    *ngIf="ratio$ | async as ratio"
  >
    <mat-dialog-content fxLayout="column" [fxLayoutGap]="0.5 | dsSpacing">
      <mat-form-field
        class="full-width"
        gaClickListener="ShortDescriptionClick"
      >
        <mat-label>{{ 'tuning_center.required' | translate }}</mat-label>
        <textarea
          matInput
          [formControl]="form.controls.short_description"
          [maxLength]="5000"
          required
          data-test="short-description-input"
        ></textarea>
        <mat-error *ngIf="form.controls.short_description.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
      <div fxLayout [fxLayoutGap]="0.5 | dsSpacing" fxLayout.xs="column">
        <mat-form-field class="full-width" gaClickListener="RetailPriceClick">
          <mat-label>{{ 'tuning_center.retail_price' | translate }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="form.controls.sales_price"
            [max]="1000000000"
            required
            autocomplete="off"
            data-test="retail-price-input"
          />
          <mat-error *ngIf="form.controls.sales_price.invalid">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          *ngIf="isUserWithPurchasingGpRole"
          class="full-width"
          gaClickListener="PurchasingPriceClick"
        >
          <mat-label>{{
            'tuning_center.purchasing_price' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="form.controls.purchase_price"
            [max]="1000000000"
            required
            autocomplete="off"
            data-test="purchasing-price-input"
          />
          <mat-error *ngIf="form.controls.purchase_price.invalid">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="full-width" gaClickListener="LongDescriptionClick">
        <mat-label>{{ 'tuning_center.application' | translate }}</mat-label>
        <textarea
          matInput
          [formControl]="form.controls.long_description"
          [maxLength]="5000"
          required
          data-test="long-description-input"
        ></textarea>
        <mat-error *ngIf="form.controls.long_description.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
      <ng-container *ngIf="optionCategories$ | async as optionCategories">
        <form
          [formGroup]="selectedOptionsFormGroup"
          *ngIf="optionCategories && selectedOptionsFormGroup; else loading"
        >
          <div fxLayout="column" [fxLayoutGap]="0.5 | dsSpacing">
            <div
              *ngFor="let category of optionCategories"
              fxLayout="column"
              [fxLayoutGap]="0.5 | dsSpacing"
            >
              <strong>{{ category.translation_key || '' | translate }}</strong>
              <div fxLayout="row wrap" [fxLayoutGap]="0.5 | dsSpacing">
                <mat-checkbox
                  *ngFor="let option of category.options"
                  [formControlName]="'' + option.id"
                  [gaClickListener]="option.id + 'OptionClick'"
                  data-test="option-checkbox"
                  >{{ option.translation_key || '' | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <sl-project-checkout-documents
        [isTuningCenter]="true"
        #checkoutDocuments
        gaClickListener="DocumentsClick"
      ></sl-project-checkout-documents>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        (click)="cancel(checkoutDocuments.documentIds)"
        type="button"
        gaClickListener="CancelClick"
        data-test="cancel-button"
      >
        {{ 'general.cancel' | translate }}
      </button>

      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!form.valid"
        [gaClickListener]="tuningCenterItem ? 'SaveClick' : 'AddToCartClick'"
        data-test="confirm-button"
      >
        <ng-container *ngIf="!tuningCenterItem">
          {{ 'project.add_to_cart' | translate }}
        </ng-container>
        <ng-container *ngIf="tuningCenterItem">
          {{ 'general.save' | translate }}
        </ng-container>
      </button>
    </mat-dialog-actions>
  </form>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</ng-container>
