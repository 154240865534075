/* eslint-disable max-lines */
import { Action, createReducer, on } from '@ngrx/store';
import {
  Cart,
  CartDocument,
  CartState as CartTypeState,
  ContractItem,
  Currency,
  CustomArticleItem,
  MccItem,
  ProductItem,
  ProductItemProductType,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem, SlSharedMathUtils } from '@sales-libs/shared/util';
import { LoadingStatus } from '@ui-kit/loading-status';
import { CartItemUtils, WarrantyExtensionUtils } from '../../utils';
import { CartActions } from './cart.actions';

export enum StepperMapping {
  Model = 0,
  Options = 1,
  Pec = 2,
  CustomArticles = 3,
  Result = 4,
}

export interface StepperState {
  selectedIndex: StepperMapping;
}

export interface CartState {
  id: number | null;
  loading: boolean;
  isCompareLoading: boolean;
  current: Cart | null;
  isPricingVisible: boolean;
  stepper: StepperState;
  loadDiagram: UrlResourceState;
  stabilizerDrawing: UrlResourceState;
  documents: CartDocument[];
  isCartDeleted: boolean;
  hasLoadingError: boolean;
}
export interface UrlResourceState {
  loadingStatus: LoadingStatus;
  url: string | null;
}

export const initialCartState: CartState = {
  id: null,
  loading: false,
  isCompareLoading: false,
  current: null,
  isPricingVisible: true,
  stepper: {
    selectedIndex: StepperMapping.Model,
  },
  loadDiagram: {
    loadingStatus: LoadingStatus.NoData,
    url: null,
  },
  stabilizerDrawing: {
    loadingStatus: LoadingStatus.NoData,
    url: null,
  },
  documents: [],
  isCartDeleted: false,
  hasLoadingError: false,
};

const reducer = createReducer(
  initialCartState,
  on(CartActions.Reset, () => initialCartState),
  on(CartActions.TogglePriceVisibility, (state) => ({
    ...state,
    isPricingVisible: !state.isPricingVisible,
  })),
  on(CartActions.SetCartId, (state, { payload }) => ({
    ...state,
    id: payload,
    current:
      state.current && state.current.id === payload
        ? state.current
        : initialCartState.current,
  })),
  on(CartActions.LoadCart, CartActions.UpdateCart, (state) => ({
    ...state,
    loading: true,
    isCartDeleted: false,
    hasLoadingError: false,
  })),
  on(
    CartActions.LoadCartSuccess,
    CartActions.AddOptionAsOptionalCartItemSuccess,
    (state, { payload }) =>
      updateCartState({
        ...state,
        id: payload.id,
        current: payload,
        loading: false,
      }),
  ),
  on(CartActions.UpdateCartSuccess, (state, { payload }) =>
    updateCartState({
      ...state,
      id: payload.id,
      current: state.current
        ? { ...state.current, ...payload, state: CartTypeState[payload.state] }
        : state.current,
      loading: false,
    }),
  ),
  on(CartActions.DeleteCheckoutDocumentSuccess, (state, { docId }) => ({
    ...state,
    documents: state.documents.filter((document) => document.id !== docId),
    loading: false,
  })),
  on(CartActions.CreateItemGroupSuccess, (state, { payload }) => ({
    ...state,
    current: state.current
      ? {
          ...state.current,
          optional_cart_item_groups: [
            ...(state.current.optional_cart_item_groups || []),
            payload,
          ],
        }
      : state.current,
  })),
  on(CartActions.UpdateCartItemGroupSuccess, (state, { update }) => ({
    ...state,
    current: state.current
      ? {
          ...state.current,
          optional_cart_item_groups: state.current.optional_cart_item_groups
            ? state.current.optional_cart_item_groups.map((x) =>
                x.id === update.id ? { ...x, ...update } : x,
              )
            : state.current.optional_cart_item_groups,
        }
      : state.current,
  })),
  on(CartActions.DeleteItemGroupSuccess, (state, { payload }) => ({
    ...state,
    current: state.current
      ? {
          ...state.current,
          ..._removeGroupIdFromAllItems(state.current, payload.id),
          optional_cart_item_groups: (
            state.current.optional_cart_item_groups || []
          ).filter((x) => x.id !== payload.id),
        }
      : state.current,
  })),
  on(CartActions.StepChanged, (state, { payload }) => {
    const stepper = { ...state.stepper, selectedIndex: payload };
    return { ...state, stepper: stepper };
  }),
  on(
    CartActions.GetCheckoutDocumentsSuccess,
    (state, { documentContainer }) => ({
      ...state,
      documents: documentContainer?.items || [],
    }),
  ),
  on(CartActions.CreateCheckoutDocumentSuccess, (state, { payload }) => ({
    ...state,
    documents: [...state.documents, payload],
  })),
  on(CartActions.LoadCartError, (state, { isCartDeleted }) => ({
    ...state,
    isCartDeleted: isCartDeleted,
    hasLoadingError: !isCartDeleted,
  })),
  // CONTRACT ITEMS
  on(CartActions.AddContractItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            contract_items: [...(state.current?.contract_items || []), item],
          }
        : state.current,
    }),
  ),

  on(CartActions.UpdateContractItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            contract_items: state.current?.contract_items
              ? state.current?.contract_items.map((x) =>
                  x.id === update?.id ? { ...x, ...update } : x,
                )
              : state.current?.contract_items,
          }
        : state.current,
    }),
  ),

  on(CartActions.DeleteContractItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            contract_items: state.current?.contract_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // CUSTOM ARTICLE ITEMS
  on(CartActions.AddCustomArticleSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            custom_article_items: [
              ...(state.current?.custom_article_items || []),
              item,
            ],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateCustomArticleSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            custom_article_items: state.current?.custom_article_items
              ? state.current?.custom_article_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.custom_article_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteCustomArticleSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            custom_article_items: state.current?.custom_article_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // MCC ITEMS
  on(CartActions.AddMccItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            mcc_items: [...(state.current?.mcc_items || []), item],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateMccItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            mcc_items: state.current?.mcc_items
              ? state.current?.mcc_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.mcc_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteMccItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            mcc_items: state.current?.mcc_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // PRODUCT ITEMS
  on(CartActions.AddProductItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            product_items: [...(state.current?.product_items || []), item],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateProductItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            product_items: state.current?.product_items
              ? state.current?.product_items.map((x) =>
                  x.id === update?.id ? { ...x, ...update } : x,
                )
              : state.current?.product_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteProductItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            product_items: state.current?.product_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // SALES OPTION ITEMS
  on(CartActions.AddSalesOptionItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            sales_option_items: [
              ...(state.current?.sales_option_items || []),
              item,
            ],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateSalesOptionItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            sales_option_items: state.current?.sales_option_items
              ? state.current?.sales_option_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.sales_option_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteSalesOptionItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            sales_option_items: state.current?.sales_option_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // TUNING CENTER ITEMS
  on(CartActions.AddTuningCenterItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            tuning_center_items: [
              ...(state.current?.tuning_center_items || []),
              item,
            ],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateTuningCenterItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            tuning_center_items: state.current?.tuning_center_items
              ? state.current?.tuning_center_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.tuning_center_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteTuningCenterItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            tuning_center_items: state.current?.tuning_center_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
  // WARRANTY EXTENSION ITEMS
  on(CartActions.AddWarrantyExtensionItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            warranty_extension_items: [
              ...(state.current?.warranty_extension_items || []),
              item,
            ],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateWarrantyExtensionItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            warranty_extension_items: state.current?.warranty_extension_items
              ? state.current?.warranty_extension_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.warranty_extension_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteWarrantyExtensionItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            warranty_extension_items:
              state.current?.warranty_extension_items?.filter(
                (item) => item.id !== id,
              ),
          }
        : state.current,
    }),
  ),
  // SOLUTION ITEMS
  on(CartActions.AddSolutionItemSuccess, (state, { item }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            solution_items: [...(state.current?.solution_items || []), item],
          }
        : state.current,
    }),
  ),
  on(CartActions.UpdateSolutionItemSuccess, (state, { update }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            solution_items: state.current?.solution_items
              ? state.current?.solution_items.map((x) =>
                  x.id === update.id ? { ...x, ...update } : x,
                )
              : state.current?.solution_items,
          }
        : state.current,
    }),
  ),
  on(CartActions.DeleteSolutionItemSuccess, (state, { id }) =>
    updateCartState({
      ...state,
      current: state.current
        ? {
            ...state.current,
            solution_items: state.current?.solution_items?.filter(
              (item) => item.id !== id,
            ),
          }
        : state.current,
    }),
  ),
);

function updateCartState(state: CartState): CartState {
  // update on cart overview, can lead to null state in current cart
  if (!state.current) return state;

  // sum up purchase price
  const productItems: ProductItem[] =
    state.current?.product_items?.map((item) =>
      CartItemUtils.getProductWithSumPrices(
        item,
        (state.current as Cart).currency_settings,
      ),
    ) ?? [];

  // get different item lists
  const customArticleItems = state.current?.custom_article_items;
  const tuningCenterItems = state.current?.tuning_center_items;
  const salesOptionItems = state.current?.sales_option_items;
  const warrantyExtensionItems = WarrantyExtensionUtils.getItemsWithPrices(
    state.current?.warranty_extension_items,
    productItems.find(
      (item) => item.product_type === ProductItemProductType.Product,
    ),
    (state.current as Cart).currency_settings,
  );
  const contractItems = state.current?.contract_items;
  const mccItems = state.current?.mcc_items;
  const solutionItems = state.current?.solution_items;

  // return the state with the new sales_price sum
  return {
    ...state,
    current: state.current
      ? {
          ...state.current,
          product_items: productItems,
          custom_article_items: state.current.custom_article_items?.sort(
            (a, b) => a.sort_key - b.sort_key,
          ),
          solution_items: solutionItems,
          sales_price:
            _getSalesPriceSumFromItems(
              productItems,
              state.current.currency_settings,
            ) +
            _getSalesPriceSumFromItems(
              salesOptionItems,
              state.current.currency_settings,
            ) +
            _getSalesPriceSumFromItems(
              warrantyExtensionItems,
              state.current.currency_settings,
            ) +
            _getSalesPriceSumFromItems(
              contractItems,
              state.current.currency_settings,
            ) +
            _getSalesPriceSumFromItems(mccItems, {
              ...state.current.currency_settings,
              rounding_digits: 2,
            }) +
            _getSalesPriceSumFromItems(customArticleItems, {
              ...state.current.currency_settings,
              rounding_digits: 2,
            }) +
            _getSalesPriceSumFromItems(tuningCenterItems, {
              ...state.current.currency_settings,
              rounding_digits: 2,
            }) +
            _getSalesPriceSumFromItems(solutionItems, {
              ...state.current.currency_settings,
              rounding_digits: 2,
            }),
        }
      : null,
  };
}

function _getSalesPriceSumFromItems(
  items: SlCartItem[] | null | undefined,
  currency: Currency,
) {
  return (
    items
      ?.filter((item) => !item.is_optional)
      .reduce(
        (acc, item) =>
          acc +
          // sum prices up in local currency and so that user can retract the sum price
          // calculate back to euro so that prices pipe can work with it
          (SlSharedMathUtils.roundAwayFromZero(
            (item.sales_price ?? 0) * currency.ratio,
            currency.rounding_digits,
          ) /
            currency.ratio) *
            item.quantity,
        0,
      ) ?? 0
  );
}

function _removeGroupIdFromAllItems(cart: Cart, groupId: number): Cart {
  return {
    ...cart,
    product_items: cart.product_items
      ? (_removeGroupIdFromItem(cart.product_items, groupId) as ProductItem[])
      : cart.product_items,
    custom_article_items: cart.custom_article_items
      ? (_removeGroupIdFromItem(
          cart.custom_article_items,
          groupId,
        ) as CustomArticleItem[])
      : cart.custom_article_items,
    sales_option_items: cart.sales_option_items
      ? (_removeGroupIdFromItem(
          cart.sales_option_items,
          groupId,
        ) as SalesOptionCartItem[])
      : cart.sales_option_items,
    warranty_extension_items: cart.warranty_extension_items
      ? (_removeGroupIdFromItem(
          cart.warranty_extension_items,
          groupId,
        ) as WarrantyExtensionItem[])
      : cart.warranty_extension_items,
    contract_items: cart.contract_items
      ? (_removeGroupIdFromItem(cart.contract_items, groupId) as ContractItem[])
      : cart.contract_items,
    mcc_items: cart.mcc_items
      ? (_removeGroupIdFromItem(cart.mcc_items, groupId) as MccItem[])
      : cart.mcc_items,
    tuning_center_items: cart.tuning_center_items
      ? (_removeGroupIdFromItem(
          cart.tuning_center_items,
          groupId,
        ) as TuningCenterItem[])
      : cart.tuning_center_items,
  };
}

function _removeGroupIdFromItem(items: SlCartItem[], groupId: number) {
  return items.map((item) =>
    item.cart_item_group_id === groupId
      ? { ...item, cart_item_group_id: undefined }
      : item,
  );
}

export function mapItemById(
  item: SlCartItem,
  newItems: SlCartItem[] | undefined,
) {
  const newItem = newItems?.find((x) => x.id === item.id);
  return newItem
    ? {
        ...item,
        ...newItem,
      }
    : item;
}

export function cartReducer(state: CartState | undefined, action: Action) {
  return reducer(state, action);
}
