import {
  Cart,
  ConfigurationOption,
  ConfigurationPackage,
  ContractItem,
  Currency,
  CustomArticleItem,
  MccItem,
  ProductItem,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem, SlSharedMathUtils } from '@sales-libs/shared/util';
import { CartActions } from '../store';

export class CartItemUtils {
  public static isProductItem(item: SlCartItem, cart: Cart): boolean {
    return cart.product_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isContractItem(item: SlCartItem, cart: Cart): boolean {
    return cart.contract_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isCustomArticleItem(item: SlCartItem, cart: Cart): boolean {
    return cart.custom_article_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isMccItem(item: SlCartItem, cart: Cart): boolean {
    return cart.mcc_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isSalesOptionCartItem(item: SlCartItem, cart: Cart): boolean {
    return cart.sales_option_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isTuningCenterItem(item: SlCartItem, cart: Cart): boolean {
    return cart.tuning_center_items?.some((x) => x.id === item.id) ?? false;
  }
  public static isWarrantyExtensionItem(item: SlCartItem, cart: Cart): boolean {
    return (
      cart.warranty_extension_items?.some((x) => x.id === item.id) ?? false
    );
  }

  public static getAllItemsOfSameType(
    item: SlCartItem,
    cart: Cart,
  ): SlCartItem[] {
    if (this.isProductItem(item, cart)) {
      return (cart.product_items || []).filter(
        (productItem) =>
          productItem.product_type === (item as ProductItem).product_type,
      );
    } else if (this.isCustomArticleItem(item, cart)) {
      return cart.custom_article_items || [];
    } else if (this.isMccItem(item, cart)) {
      return cart.mcc_items || [];
    } else if (this.isSalesOptionCartItem(item, cart)) {
      return cart.sales_option_items || [];
    } else if (this.isTuningCenterItem(item, cart)) {
      return cart.tuning_center_items || [];
    } else if (this.isWarrantyExtensionItem(item, cart)) {
      return cart.warranty_extension_items || [];
    } else if (this.isContractItem(item, cart)) {
      return cart.contract_items || [];
    }
    return [];
  }

  public static getUpdateActionAccordingToType(item: SlCartItem, cart: Cart) {
    if (this.isProductItem(item, cart)) {
      return CartActions.UpdateProductItem({
        update: item as ProductItem,
        productType: (item as ProductItem).product_type,
      });
    } else if (this.isCustomArticleItem(item, cart)) {
      return CartActions.UpdateCustomArticle({
        update: item as CustomArticleItem,
      });
    } else if (this.isMccItem(item, cart)) {
      return CartActions.UpdateMccItem({ update: item as MccItem });
    } else if (this.isSalesOptionCartItem(item, cart)) {
      return CartActions.UpdateSalesOptionItem({
        update: item as SalesOptionCartItem,
      });
    } else if (this.isTuningCenterItem(item, cart)) {
      return CartActions.UpdateTuningCenterItem({
        update: item as TuningCenterItem,
      });
    } else if (this.isWarrantyExtensionItem(item, cart)) {
      return CartActions.UpdateWarrantyExtensionItem({
        update: item as WarrantyExtensionItem,
      });
    } else if (this.isContractItem(item, cart)) {
      return CartActions.UpdateContractItem({
        update: item as ContractItem,
      });
    }
    return undefined;
  }

  public static getDeleteActionAccordingToType(item: SlCartItem, cart: Cart) {
    const payload = { itemId: item.id as string, cartId: item.cart_id };
    if (this.isProductItem(item, cart)) {
      return CartActions.DeleteProductItem(payload);
    } else if (this.isCustomArticleItem(item, cart)) {
      return CartActions.DeleteCustomArticle(payload);
    } else if (this.isMccItem(item, cart)) {
      return CartActions.DeleteMccItem(payload);
    } else if (this.isSalesOptionCartItem(item, cart)) {
      return CartActions.DeleteSalesOptionItem(payload);
    } else if (this.isTuningCenterItem(item, cart)) {
      return CartActions.DeleteTuningCenterItem(payload);
    } else if (this.isWarrantyExtensionItem(item, cart)) {
      return CartActions.DeleteWarrantyExtensionItem(payload);
    } else if (this.isContractItem(item, cart)) {
      return CartActions.DeleteContractItem(payload);
    }
    return undefined;
  }

  public static getProductWithSumPrices(
    item: ProductItem,
    currency: Currency,
    includePackages = true,
  ): ProductItem {
    // only do this for configurable items
    if (item.is_configurable) {
      let newItem = { ...item };

      // sum prices up in local currency and so that user can retract the sum price
      // calculate back to euro so that prices pipe can work with it
      const optionSalesPriceSum = this.getSalesPriceSumFromChildItems(
        item.options,
        currency,
      );
      const packageSalesPriceSum = includePackages
        ? this.getSalesPriceSumFromChildItems(item.packages, currency)
        : 0;

      const calculatedSalesPrice =
        (optionSalesPriceSum + packageSalesPriceSum) / currency.ratio;

      // allways update original sales price because this can never be set other than by summing up the children
      newItem = {
        ...newItem,
        original_sales_price: calculatedSalesPrice,
      };

      // only update sales price if it was not edited by the user
      if (!this.isItemSalesPriceEdited(item)) {
        newItem = {
          ...newItem,
          sales_price: calculatedSalesPrice,
        };
      }

      // do the same for purchase price
      const optionPurchasePriceSum = this.getPurchasePriceSumFromChildItems(
        item.options,
        currency,
      );
      const packagePurchasePriceSum = includePackages
        ? this.getPurchasePriceSumFromChildItems(item.packages, currency)
        : 0;

      const calculatedPurchasePrice =
        (optionPurchasePriceSum + packagePurchasePriceSum) / currency.ratio;

      newItem = {
        ...newItem,
        original_purchase_price: calculatedPurchasePrice,
      };

      if (!this.isItemPurchasePriceEdited(item)) {
        newItem = {
          ...newItem,
          purchase_price: calculatedPurchasePrice,
        };
      }
      return newItem;
    } else {
      return item;
    }
  }

  public static getSalesPriceSumFromChildItems(
    children: ConfigurationOption[] | ConfigurationPackage[] | null | undefined,
    currency: Currency,
  ) {
    return (
      children?.reduce(
        (acc, item) =>
          acc +
          SlSharedMathUtils.roundAwayFromZero(
            +(item.sales_price ?? 0) * currency.ratio,
            currency.rounding_digits,
          ),
        0,
      ) ?? 0
    );
  }

  public static getPurchasePriceSumFromChildItems(
    children: ConfigurationOption[] | ConfigurationPackage[] | null | undefined,
    currency: Currency,
  ) {
    return (
      children?.reduce(
        (acc, item) =>
          acc +
          SlSharedMathUtils.roundAwayFromZero(
            +(item.purchase_price ?? 0) * currency.ratio,
            currency.rounding_digits,
          ),
        0,
      ) ?? 0
    );
  }

  public static isItemSalesPriceEdited(productItem: ProductItem): boolean {
    return (
      !!productItem.original_sales_price &&
      productItem.original_sales_price !== productItem.sales_price
    );
  }

  public static isItemPurchasePriceEdited(productItem: ProductItem): boolean {
    return (
      !!productItem.original_purchase_price &&
      productItem.original_purchase_price !== productItem.purchase_price
    );
  }
}
