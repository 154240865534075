import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-project-absolute-discounted-price-column',
  templateUrl: './absolute-discounted-price-column.component.html',
  styleUrls: ['./absolute-discounted-price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectAbsoluteDiscountedSlProjectPriceColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() currencySymbol: string;
  @Input() currencyRatio: number;
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this.formControl.setValue(
      +(value[this.perspective].absolute_discount * this.currencyRatio).toFixed(
        2,
      ),
      {
        emitEvent: false,
      },
    );
  }
  @Output() calculationChange = new EventEmitter<Calculation>();

  readonly triggerBlur = triggerBlur;

  formControl = new FormControl<number>(0, {
    nonNullable: true,
    updateOn: 'blur',
  });
  private _calculation: Calculation;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }
    this.formDirective.form.addControl(
      this.perspective + 'AbsoluteDiscountedPrice',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.calculationChange.emit({
          ...this._calculation,
          [this.perspective]: {
            ...this._calculation[this.perspective],
            absolute_discount: (value ?? 0) / this.currencyRatio,
          },
        });
      });
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
