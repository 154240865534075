import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const projectState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace LostOrderSelectors {
  const lostOrderState = createSelector(
    projectState,
    (state) => state.lostOrder,
  );

  export const lostOrderEntities = createSelector(
    lostOrderState,
    (state) => state.entities,
  );

  export const lostOrderId = createSelector(
    lostOrderState,
    (state) => state.id,
  );
  export const lostOrder = createSelector(
    lostOrderEntities,
    lostOrderId,
    (entities, id) => (id && entities[id]) || null,
  );

  export const lostOrderIds = createSelector(
    lostOrderState,
    (state) => state.ids as number[],
  );
  export const lostOrders = createSelector(
    lostOrderEntities,
    lostOrderIds,
    (entities, ids) =>
      ids && ids.length > 0 ? ids.map((id) => entities[id]) : null,
  );

  export const reasons = createSelector(
    lostOrderState,
    (state) => state.reasons,
  );
  export const competitors = createSelector(
    lostOrderState,
    (state) => state.competitors,
  );

  export const reportData = createSelector(
    lostOrderState,
    (state) => state.reportData,
  );

  export const reportingFilter = createSelector(
    lostOrderState,
    (state) => state.lostOrderReportingFilters,
  );
}
