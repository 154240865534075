import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTableLoadingModule } from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { TranslateModule } from '@ngx-translate/core';
import { SalestoolSharedModule } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { PrintModule } from '@shared-lib/print';
import { SlCalculationComponent } from '.';
import { SlProjectCartValidDependendPageComponent } from '../cart-valid-dependend-page/cart-valid-dependend-page.component';
import { CalculationTableModule } from './calculation-table/calculation-table.module';

@NgModule({
  imports: [
    CommonModule,
    CalculationTableModule,
    TranslateModule,
    GoogleAnalytics4DirectiveModule,
    PdLetDirectiveModule,
    SalestoolSharedModule,
    // Material
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    // Design System
    DsSpacingModule,
    DsSpotlightModule,
    PrintModule,
    DsTableLoadingModule,
    DsTextIndicatorModule,
    DsPageModule,
    DsLoadingModule,
    SlProjectCartValidDependendPageComponent,
  ],
  declarations: [SlCalculationComponent],
  exports: [SlCalculationComponent],
})
export class SlProjectCalculationModule {}
