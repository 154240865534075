<div
  [gaClickListener]="{
    action: 'StateClick',
    category: 'Projects',
    label: 'CartItemList'
  }"
  class="min-width-cell"
  [spotlight]="editedSpotlight"
>
  <span *ngIf="!itemAsEditableItem.is_edited; else editIndicator">{{
    'project.not_edited' | translate
  }}</span>
  <ng-template #editIndicator>
    <span class="info-text">{{ 'project.edited' | translate }}</span>
  </ng-template>
</div>

<ds-spotlight
  #editedSpotlight
  id="sl-project-cart-item-list-edited-spotlight"
  [tags]="['release_2020_07', 'cart-item-list']"
>
  {{ 'spotlight.release_2020_07.optional_items_edited_info' | translate }}
</ds-spotlight>
