import {
  Currency,
  ProductItem,
  ProductItemInput,
  WarrantyExtensionItem,
  WarrantyExtensionItemInput,
} from '@sales-libs/project/data-access';
import { CartItemUtils } from './cart-item.utils';

export class WarrantyExtensionUtils {
  public static getItemsWithPrices = (
    warrantyExtensions: WarrantyExtensionItem[] | null | undefined,
    productItem: ProductItem | null | undefined,
    currency: Currency,
  ): WarrantyExtensionItem[] | null | undefined =>
    warrantyExtensions?.map(
      (item) =>
        this.getItemWithPrices(
          item,
          productItem,
          currency,
        ) as WarrantyExtensionItem,
    );

  public static getItemWithPrices = (
    item: WarrantyExtensionItem | WarrantyExtensionItemInput,
    productItem: ProductItem | ProductItemInput | null | undefined,
    currency: Currency,
  ): WarrantyExtensionItem | WarrantyExtensionItemInput => {
    const getProductWithSumPrices = productItem
      ? CartItemUtils.getProductWithSumPrices(
          productItem as ProductItem,
          currency,
          false,
        )
      : null;
    return {
      ...item,
      sales_price:
        (item.percentage_value / 100) *
        (getProductWithSumPrices?.sales_price ?? 0),
      purchase_price:
        (item.sap_percentage_value / 100) *
        (getProductWithSumPrices?.purchase_price ?? 0),
    };
  };
}
