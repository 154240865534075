/**
 * Simulate blur event
 */
export function triggerBlur(event: Event) {
  const target = event.target || event.srcElement || event.currentTarget;
  if (target) {
    target.dispatchEvent(new Event('blur'));
    target.dispatchEvent(new Event('focus'));
  }
}
