/* tslint:disable:no-access-missing-member */
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { APPS } from '@config';
import { dsConfig } from '@design-system/cdk/config';
import {
  DisabledPositionType,
  TableSettings,
  TableSettingsService,
} from '@design-system/components/table-settings';
import { UserService } from '@features/auth';
import { CrmCustomerDetailsDialogComponent } from '@features/crm-customer';
import { CustomerDetailsComponent } from '@features/sap-customer';
import { Store } from '@ngrx/store';
import {
  Cart,
  Currency,
  Project,
  ProjectInput,
} from '@sales-libs/project/data-access';
import {
  DocumentDownloadDialogComponent,
  FEATURE_FLAG_EARLY_ACCESS,
  UserSelectors,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { ConfirmDeleteDialogComponent } from '@ui-kit/modals';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  SL_PROJECT_EDIT_DIALOG_DEFAULT_WIDTH,
  SlProjectEditComponent,
  SlProjectEditDialogData,
} from '../edit-project/edit-project.component';
import { ProjectFilters } from '../models';
import { ProjectActions, ProjectSelectors } from '../store';

@Component({
  selector: 'sl-project-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  standalone: false,
})
export class SlProjectOverviewComponent implements OnInit, OnDestroy {
  @Input() currency: Currency;

  displayColumns: TableSettings[];
  readonly loading$: Observable<boolean>;
  readonly projects$: Observable<Project[]>;
  readonly selectedProject$: Observable<Project>;
  readonly pagination$: Observable<PageEvent>;
  readonly projectFilters$: Observable<ProjectFilters>;
  readonly cart$: Observable<Cart | null>;
  readonly offerUrl$: Observable<string | undefined>;
  readonly showEarlyAccessBanner$: Observable<boolean>;

  fileName: string;

  private readonly _destroy$ = new Subject<void>();
  private readonly _defaultColumns: TableSettings[] = [
    {
      name: 'code',
      selected: true,
      disabledPosition: DisabledPositionType.Start,
    },
    { name: 'status', selected: true },
    { name: 'created_by', selected: true },
    { name: 'name', selected: true },
    { name: 'model', selected: true },
    { name: 'chance', selected: true },
    { name: 'created_date', selected: true },
    { name: 'end_date', selected: true },
    { name: 'customer', selected: true },
    {
      name: 'actions',
      selected: true,
      disabledPosition: DisabledPositionType.End,
    },
  ];

  private _doNotShowEarlyAccessBanner$ = new BehaviorSubject<boolean>(false);
  private _doNotShowEarlyAccessBannerLocalStorageKey =
    'do_not_show_early_access_banner';

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _dialog: MatDialog,
    private readonly _store: Store<any>,
    private readonly _tableSettingsService: TableSettingsService,
    private readonly _userService: UserService,
    @Inject(FEATURE_FLAG_EARLY_ACCESS)
    private _isEarlyAccessFeatureFlagSet,
  ) {
    this.loading$ = this._store.select(ProjectSelectors.loading);
    this.projects$ = this._store
      .select(ProjectSelectors.currentPage)
      .pipe(filter((value): value is Project[] => !!value));
    this.pagination$ = this._store.select(ProjectSelectors.pagination);
    this.projectFilters$ = this._store.select(ProjectSelectors.projectFilters);

    this._doNotShowEarlyAccessBanner$.next(
      localStorage.getItem(this._doNotShowEarlyAccessBannerLocalStorageKey) ===
        'true',
    );

    this.showEarlyAccessBanner$ = combineLatest([
      this._doNotShowEarlyAccessBanner$.asObservable(),
      this._store.select(UserSelectors.isEarlyAccessRequested),
      this._userService.isAuthorized$.pipe(
        map(() =>
          this._userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_EARLY_ACCESS),
        ),
      ),
    ]).pipe(
      map(
        ([isUnwanted, hasApplied, hasEarlyAccess]) =>
          this._isEarlyAccessFeatureFlagSet &&
          !isUnwanted &&
          !hasEarlyAccess &&
          hasApplied === false,
      ),
    );
  }

  setUserSettings(userSettings: TableSettings[]): void {
    this.displayColumns = userSettings;
  }

  ngOnInit() {
    this._store.dispatch(ProjectActions.SearchProjects());
    this._tableSettingsService
      .get('CPQ.projects.table_settings', this._defaultColumns)
      .pipe(takeUntil(this._destroy$))
      .subscribe((storedUserColumns) => {
        this.setUserSettings(
          storedUserColumns?.length ? storedUserColumns : this._defaultColumns,
        );
      });
  }

  openProjectsDownloadDialog(): void {
    // reset
    this._store.dispatch(ProjectActions.ResetExportState());

    // open dialog and prefill name
    const dialogRef = this._dialog.open(DocumentDownloadDialogComponent, {
      width: 25 * dsConfig.spacing + 'px',
    });
    dialogRef.componentInstance.fileName = 'project-export.xls';

    // handle sucess
    this._store
      .select(ProjectSelectors.exportUrl)
      .pipe(filterTruthy(), take(1))
      .subscribe((url) => {
        dialogRef.componentInstance.documentUrl = url;
      });

    // handle error
    this._store
      .select(ProjectSelectors.hasExportError)
      .pipe(
        filter((x) => x === true),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe(() => {
        dialogRef.componentInstance.hasError = true;
        dialogRef.componentInstance.tryAgainClicked
          .pipe(take(1))
          .subscribe(() => {
            dialogRef.componentInstance.hasError = false;
            this._store.dispatch(ProjectActions.ResetExportState());
            this._store.dispatch(ProjectActions.GetExport());
          });
      });

    // start export
    this._store.dispatch(ProjectActions.GetExport());
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * dispatch filter change and take first page
   * @param filters
   */
  updateFilter(filters: ProjectFilters) {
    this._store.dispatch(ProjectActions.FilterChange({ payload: filters }));
    this._store.dispatch(ProjectActions.SearchProjects());
  }

  /**
   * clear filter and take first page
   */
  clearFilter() {
    this._store.dispatch(ProjectActions.ClearFilter());
    this._store.dispatch(ProjectActions.SearchProjects());
  }

  /**
   * get projects, default first page
   * @param page
   */
  getPage(page: PageEvent) {
    this._store.dispatch(ProjectActions.PageChange({ payload: page }));
    this._store.dispatch(ProjectActions.SearchProjects());
  }

  /**
   * select a project
   * @param project
   */
  selectProject(project: Project) {
    this._store.dispatch(ProjectActions.SelectProject({ payload: project.id }));
  }

  /**
   * save currency on load
   * @param event currency
   */
  onCurrencyLoad(event: Currency) {
    this.currency = event;
  }

  /**
   * new project
   */
  addProject() {
    this.openProjectEdit();
  }

  /**
   * open project for editing
   * @param project
   */
  openProjectEdit(project?: ProjectInput | Project) {
    const editConfig = new MatDialogConfig();
    editConfig.width = SL_PROJECT_EDIT_DIALOG_DEFAULT_WIDTH;
    editConfig.data = {
      project: project ? ({ ...project } as Project) : undefined,
      currency: this.currency,
    };

    const editDialogRef = this._dialog.open<
      SlProjectEditComponent,
      SlProjectEditDialogData
    >(SlProjectEditComponent, editConfig);
    editDialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((p) => !!p),
      )
      .subscribe((p) =>
        this._store.dispatch(
          ProjectActions.CreateOrUpdate({
            id: p.id,
            payload: p,
          }),
        ),
      );
  }

  /**
   * show customer
   * @param customer
   */
  showCustomer(project: Project) {
    if (project.external_customer_number) {
      this._dialog.open(CrmCustomerDetailsDialogComponent, {
        data: { accountId: project.external_customer_number },
      });
    } else {
      // deprecated fallback
      const customerDetailsDialog = this._dialog.open(CustomerDetailsComponent);
      customerDetailsDialog.componentInstance.customerNumber =
        project.customer_number || '';
    }
  }

  /**
   * delte project
   * @param project
   */
  deleteProject(project: Project) {
    const config = new MatDialogConfig();

    const dialogRef = this._dialog.open(ConfirmDeleteDialogComponent, config);
    dialogRef.componentInstance.key = project.name;
    dialogRef.componentInstance.confirm
      .pipe(tap(() => dialogRef.close(), takeUntil(dialogRef.afterClosed())))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this._store.dispatch(
            ProjectActions.DeleteProject({ payload: project.id }),
          );
        }
      });
  }

  sortChange(sort: Sort) {
    this._store.dispatch(ProjectActions.SortChange({ payload: sort }));
    this._store.dispatch(ProjectActions.SearchProjects());
  }

  hideEarlyAccessBanner() {
    this._doNotShowEarlyAccessBanner$.next(true);
    localStorage.setItem(
      this._doNotShowEarlyAccessBannerLocalStorageKey,
      'true',
    );
  }
}
