/* eslint-disable max-lines */
import {
  Calculation,
  CalculationItem,
  CalculationItemItemType,
  CalculationItemPerspective,
  Cart,
  CartItemGroup,
  ConfigurationOption,
  ConfigurationPackage,
  ContractItem,
  Currency,
  CustomArticleItem,
  MccItem,
  ProductItem,
  ProductItemProductType,
  SalesOptionCartItem,
  SolutionItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItemGroupContainer } from '@sales-libs/project/ui';
import { SlCartItem } from '@sales-libs/shared/util';
import { WarrantyExtensionUtils } from '../../utils';
import { CalculationUtils } from './calculation-utils';

export class CalculationMapping {
  static transform(
    calculation: Calculation,
    cart: Cart,
    currency: Currency,
    groups: SlCartItemGroupContainer[] | undefined,
    isUserWithPurchasingRole: boolean,
  ) {
    const itemsAndCalculations = CalculationMapping.provideItemCalculation(
      calculation,
      cart,
      currency,
      isUserWithPurchasingRole,
    );

    const optionalItemsItemsAndGroups = groups
      ? groups
          .map((x) => {
            const items = x.items.map(
              (i) =>
                itemsAndCalculations.find((f) => f.cartItem.id === i.id)
                  .calculation,
            );
            if (x.group) {
              const calculationGroup = CalculationMapping.mapGroup(x.group);
              calculationGroup.items = items;
              return calculationGroup;
            }
            return items;
          })
          .flat()
      : [];

    const calculationItems = itemsAndCalculations
      .filter((x) => !x.cartItem.is_optional)
      .map((x) => x.calculation);
    calculationItems.push(...optionalItemsItemsAndGroups);
    return { ...calculation, items: calculationItems };
  }

  static isKey(calculation: CalculationItem, id: string) {
    return (
      calculation.product_item_id === id ||
      calculation.custom_article_item_id === id ||
      calculation.sales_option_item_id === id ||
      calculation.contract_item_id === id ||
      calculation.mcc_item_id === id ||
      calculation.tuning_center_item_id === id ||
      calculation.warranty_extension_item_id === id
    );
  }

  static provideItemCalculation(
    calculation: Calculation,
    cart: Cart,
    currency: Currency,
    isUserWithPurchasingRole: boolean,
  ): any[] {
    const allMappedItems: any[] = [];

    // solution items
    cart.solution_items?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapSolutionItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    // main product item
    const mainProductItem = cart.product_items?.find(
      (item) => item.product_type === ProductItemProductType.Product,
    );
    if (mainProductItem) {
      allMappedItems.push(
        CalculationMapping.mapItem(
          mainProductItem,
          calculation,
          CalculationMapping.mapProductItem,
          isUserWithPurchasingRole,
        ),
      );
    }

    // then warranty
    WarrantyExtensionUtils.getItemsWithPrices(
      cart.warranty_extension_items ?? [],
      mainProductItem,
      currency,
    )?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapWarranryExtensionItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    // then tuning
    cart.tuning_center_items?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapTuningCenterItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    // then mcc
    cart.mcc_items?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapMccItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    // then accessories, first configureable, then non-configureable
    const accessoryItems = cart.product_items?.filter(
      (item) =>
        (item as ProductItem).product_type === ProductItemProductType.Accessory,
    );

    accessoryItems
      ?.filter((item) => (item as ProductItem).is_configurable)
      .forEach((item) => {
        const mappedItem = CalculationMapping.mapItem(
          item,
          calculation,
          CalculationMapping.mapProductItem,
          isUserWithPurchasingRole,
        );
        allMappedItems.push(mappedItem);
      });

    accessoryItems
      ?.filter((item) => !(item as ProductItem).is_configurable)
      // eslint-disable-next-line sonarjs/no-identical-functions
      .forEach((item) => {
        const mappedItem = CalculationMapping.mapItem(
          item,
          calculation,
          CalculationMapping.mapProductItem,
          isUserWithPurchasingRole,
        );
        allMappedItems.push(mappedItem);
      });

    // then custom articles sorted by key
    cart.custom_article_items
      ?.sort((a, b) => a.sort_key - b.sort_key)
      .forEach((item) => {
        const mappedItem = CalculationMapping.mapItem(
          item,
          calculation,
          CalculationMapping.mapCustomArticleItem,
          isUserWithPurchasingRole,
        );
        allMappedItems.push(mappedItem);
      });

    // then contracts
    cart.contract_items?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapContractItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    // sales options are optional anyways, and get grouped and sorted later
    cart.sales_option_items?.forEach((item) => {
      const mappedItem = CalculationMapping.mapItem(
        item,
        calculation,
        CalculationMapping.mapSalesOptionItem,
        isUserWithPurchasingRole,
      );
      allMappedItems.push(mappedItem);
    });

    return allMappedItems;
  }

  private static get defaultCalculationItem(): CalculationItem {
    return {
      quantity: 0,
      item_type: CalculationItemItemType.Item,
      is_optional: false,
      is_read_only: false,
      is_ppi_relevant: false,
    };
  }

  static createCalculationItemPerspective(): CalculationItemPerspective {
    return {
      purchase_price: 0,
      discount: 0,
      sales_price: 0,
    };
  }
  static createCalculationItem(): CalculationItem {
    return {
      id: '',
      cart_item_id: 0,
      cart_item_group_id: 0,
      name: 'template',
      description: '-',
      quantity: 1,
      item_type: CalculationItemItemType.Item,
      is_optional: false,
      is_read_only: false,
      is_ppi_relevant: true,
      general_agent: CalculationMapping.createCalculationItemPerspective(),
      partner: CalculationMapping.createCalculationItemPerspective(),
      customer: CalculationMapping.createCalculationItemPerspective(),
      items: [],
    };
  }

  static mapAnyItemAndOption(
    item: SlCartItem | ConfigurationOption,
    calculationItem: CalculationItem,
  ): CalculationItem {
    calculationItem.name = item.name;

    calculationItem.customer =
      calculationItem.customer ??
      CalculationMapping.createCalculationItemPerspective();
    calculationItem.partner =
      calculationItem.partner ??
      CalculationMapping.createCalculationItemPerspective();
    calculationItem.general_agent =
      calculationItem.general_agent ??
      CalculationMapping.createCalculationItemPerspective();

    calculationItem.customer.sales_price = item.sales_price;
    calculationItem.customer.purchase_price = item.sales_price;

    calculationItem.partner.sales_price = item.sales_price;
    calculationItem.partner.purchase_price = item.sales_price;

    calculationItem.general_agent.sales_price = item.purchase_price;
    calculationItem.general_agent.purchase_price = item.purchase_price;

    return calculationItem;
  }

  static mapAnyItem(
    item: SlCartItem,
    calculationItem: CalculationItem,
    id: string | null | undefined,
  ): CalculationItem {
    calculationItem.id = `I${id}`;

    calculationItem.item_type =
      item.is_optional && item.cart_item_group_id
        ? CalculationItemItemType.GroupItem
        : CalculationItemItemType.Item;
    calculationItem.is_read_only = !item.is_discountable;
    calculationItem.is_optional = item.is_optional;
    calculationItem.quantity = item.quantity;
    return calculationItem;
  }

  static mapProductItem(
    cartItem: ProductItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);

    mappedItem.is_ppi_relevant = true;
    mappedItem.description = cartItem.short_description;
    mappedItem.product_item_id = cartItem.id;
    mappedItem.name =
      (cartItem.options?.length ?? 0) > 0 ? cartItem.model_id : cartItem.name;
    mappedItem.items = cartItem.options
      ?.filter((o) => o.state === 'SELECTED' || o.state === 'REQUIRED')
      ?.map((o) => CalculationMapping.mapOption(o, mappedItem));
    const calcPackages = cartItem.packages
      ?.filter((x) => CalculationUtils.IsPackageFulfiled(cartItem, x))
      .sort((a, b) => a.sort_key - b.sort_key)
      .map((p) => CalculationMapping.mapPackage(p, mappedItem));

    if (calcPackages)
      mappedItem.items = (mappedItem.items ?? []).concat(calcPackages);
    return mappedItem;
  }

  static mapSolutionItem(
    cartItem: SolutionItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);

    mappedItem.is_ppi_relevant = true;
    mappedItem.description = cartItem.short_description;
    mappedItem.product_item_id = cartItem.id;
    mappedItem.name = cartItem.name;

    // TODO
    /*
    mappedItem.items = cartItem.options
      ?.filter((o) => o.state === 'SELECTED' || o.state === 'REQUIRED')
      ?.map((o) => CalculationMapping.MapOption(o, mappedItem));
      */
    return mappedItem;
  }

  static mapCustomArticleItem(
    cartItem: CustomArticleItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    if (mappedItem.partner) {
      if (!cartItem.is_purchasing_custom_article) {
        mappedItem.partner.purchase_price = cartItem.purchase_price;
        mappedItem.partner.sales_price = cartItem.purchase_price;
      } else {
        mappedItem.partner.purchase_price = cartItem.sales_price;
        mappedItem.partner.sales_price = cartItem.sales_price;
      }
    }
    mappedItem.is_ppi_relevant = cartItem.is_ppi_relevant;
    mappedItem.description = cartItem.short_description;
    mappedItem.custom_article_item_id = cartItem.id;
    return mappedItem;
  }

  static mapSalesOptionItem(
    cartItem: SalesOptionCartItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    mappedItem.is_ppi_relevant = cartItem.is_ppi_relevant;
    mappedItem.description = cartItem.short_description;
    mappedItem.sales_option_item_id = cartItem.id;
    return mappedItem;
  }

  static mapTuningCenterItem(
    cartItem: TuningCenterItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    mappedItem.is_ppi_relevant = true;
    mappedItem.tuning_center_item_id = cartItem.id;
    return mappedItem;
  }

  static mapMccItem(
    cartItem: MccItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    mappedItem.is_ppi_relevant = true;
    mappedItem.mcc_item_id = cartItem.id;
    return mappedItem;
  }

  static mapContractItem(
    cartItem: ContractItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    mappedItem.description = cartItem.description;
    mappedItem.is_ppi_relevant = true;
    mappedItem.contract_item_id = cartItem.id;
    return mappedItem;
  }

  static mapWarranryExtensionItem(
    cartItem: WarrantyExtensionItem,
    calculationItem: CalculationItem | undefined,
  ) {
    const mappedItem =
      calculationItem ?? CalculationMapping.defaultCalculationItem;
    CalculationMapping.mapAnyItem(cartItem, mappedItem, cartItem.id);
    CalculationMapping.mapAnyItemAndOption(cartItem, mappedItem);
    mappedItem.is_ppi_relevant = true;
    mappedItem.description = cartItem.short_description;
    mappedItem.warranty_extension_item_id = cartItem.id;
    return mappedItem;
  }

  static mapOption(option: ConfigurationOption, parent: CalculationItem) {
    const mappedItem: CalculationItem = {
      id: `O${option.id}`,
      item_type: CalculationItemItemType.Option,
      is_read_only: true,
      is_ppi_relevant: false,
      quantity: 1,
      is_optional: parent.is_optional,
    };
    CalculationMapping.mapAnyItemAndOption(option, mappedItem);
    CalculationUtils.perspectives?.forEach((perspective) => {
      mappedItem[perspective].discount = parent[perspective].discount;
    });
    mappedItem.description = option.description;
    mappedItem.item_type = CalculationItemItemType.Option;
    return mappedItem;
  }

  static mapPackage(
    configurationPackage: ConfigurationPackage,
    parent: CalculationItem,
  ) {
    const mappedItem: CalculationItem = {
      id: `P${configurationPackage.package_id}`,
      item_type: CalculationItemItemType.Option,
      is_read_only: true,
      is_ppi_relevant: false,
      quantity: 1,
      is_optional: false,
      name: configurationPackage.description ?? configurationPackage.package_id,
      description: configurationPackage.package_id,
    };
    mappedItem.customer = CalculationMapping.createCalculationItemPerspective();
    mappedItem.partner = CalculationMapping.createCalculationItemPerspective();
    mappedItem.general_agent =
      CalculationMapping.createCalculationItemPerspective();

    mappedItem.customer.sales_price = CalculationMapping.convertToNumber(
      configurationPackage.sales_price,
    );
    mappedItem.customer.purchase_price = CalculationMapping.convertToNumber(
      configurationPackage.sales_price,
    );

    mappedItem.partner.sales_price = CalculationMapping.convertToNumber(
      configurationPackage.sales_price,
    );
    mappedItem.partner.purchase_price = CalculationMapping.convertToNumber(
      configurationPackage.sales_price,
    );

    mappedItem.general_agent.sales_price = CalculationMapping.convertToNumber(
      configurationPackage.purchase_price,
    );
    mappedItem.general_agent.purchase_price =
      CalculationMapping.convertToNumber(configurationPackage.purchase_price);

    CalculationUtils.perspectives?.forEach((perspective) => {
      mappedItem[perspective].discount = parent[perspective].discount;
    });
    return mappedItem;
  }

  static convertToNumber(value) {
    return value === null || value === '' ? 0 : Number(value);
  }

  static mapGroup(group: CartItemGroup) {
    const mappedItem: CalculationItem = {
      id: `G${group.id}`,
      name: group.name,
      item_type: CalculationItemItemType.Group,
      is_ppi_relevant: false,
      quantity: 1,
      is_optional: true,
      customer: CalculationMapping.createCalculationItemPerspective(),
      partner: CalculationMapping.createCalculationItemPerspective(),
      general_agent: CalculationMapping.createCalculationItemPerspective(),
      is_read_only: true,
    };
    mappedItem.cart_item_group_id = group.id;
    return mappedItem;
  }

  static mapItem(
    item: any,
    calculation: Calculation,
    mappingFunction: (
      cartItem: any,
      calculationItem: CalculationItem | undefined,
      isUserWithPurchasingRole?: boolean,
    ) => CalculationItem,
    isUserWithPurchasingRole,
  ) {
    const topLevelItems = calculation?.items ?? [];
    const allItems = [
      ...topLevelItems,
      ...CalculationMapping.getGroupItems(topLevelItems),
    ];
    const calculationItem =
      allItems.find((x) => CalculationMapping.isKey(x, item.id)) ??
      CalculationMapping.createCalculationItem();
    return {
      cartItem: item,
      calculation: mappingFunction(
        item,
        calculationItem,
        isUserWithPurchasingRole,
      ),
    };
  }

  static getGroupItems(calculationItems: CalculationItem[]): CalculationItem[] {
    return calculationItems
      .filter((item) => item.item_type === CalculationItemItemType.Group)
      .reduce(
        (acc, group) => acc.concat(group.items ?? []),
        [] as CalculationItem[],
      );
  }
}
