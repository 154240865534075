import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartItemsService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { catchError, map, switchMap } from 'rxjs';
import { MccActions } from '../store';

@Injectable()
export class MccEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store<any> = inject(Store);

  getMountingOptions$ = createEffect(() =>
    this._actions.pipe(
      ofType(MccActions.GetMountingOptions),
      switchMap(() =>
        this._cartItemsService.getMountingCategoriesAsync().pipe(
          map((data) =>
            MccActions.GetMountingOptionsSuccess({ payload: data }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.mcc.mounting_options_not_loaded',
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  getTruckOptions$ = createEffect(() =>
    this._actions.pipe(
      ofType(MccActions.GetTruckOptions),
      switchMap(() =>
        this._cartItemsService.getTrucksAsync().pipe(
          map((data) => MccActions.GetTruckOptionsSuccess({ payload: data })),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.mcc.truck_options_not_loaded',
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
