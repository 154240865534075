import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const projectState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace CalculationSelectors {
  const calculationState = createSelector(
    projectState,
    (state) => state.calculation,
  );

  export const calculation = createSelector(
    calculationState,
    (state) => state.calculation,
  );
  export const discount_and_costs = createSelector(
    calculationState,
    (state) => state.calculation,
  );
  export const perspectives = createSelector(
    calculationState,
    (state) => state.perspectives,
  );

  export const expandedItemsLookup = createSelector(
    calculationState,
    (state) => state.expandedItemsLookup,
  );

  export const exportUrl = createSelector(
    calculationState,
    (state) => state.exportUrl,
  );
  export const hasExportError = createSelector(
    calculationState,
    (state) => state.hasExportError,
  );

  export const offerUrl = createSelector(
    calculationState,
    (state) => state.offerUrl,
  );

  export const hasOfferUrlError = createSelector(
    calculationState,
    (state) => state.hasOfferUrlError,
  );

  export const isSaving = createSelector(
    calculationState,
    (state) => state.isSaving,
  );
}
