import { Component, Input } from '@angular/core';
import {
  CartItemCompare,
  CartItemCompareCartItemType,
  CartItemCompareCompareDifferenceType,
  ConfigurationOptionCompareCompareDifferenceType,
  Currency,
  PackageCompareCompareDifferenceType,
} from '@sales-libs/project/data-access';

const columnNames = [
  'image_url',
  'name',
  'compare_difference_type',
  'cart_item_type',
  'short_description',
  'long_description',
  'sales_price',
];

@Component({
  selector: 'sl-project-cart-compare-table',
  templateUrl: './cart-compare-table.component.html',
  styleUrls: ['./cart-compare-table.component.scss'],
  standalone: false,
})
export class SlProjectCartCompareTableComponent {
  @Input() items: CartItemCompare[] = [];
  @Input() showTextChanges = true;
  @Input() sourceCurrency: Currency | undefined;

  compareDifferenceType = CartItemCompareCompareDifferenceType;
  cartCompareItemType = CartItemCompareCartItemType;
  private _optionCompareDifferentType =
    ConfigurationOptionCompareCompareDifferenceType;
  private _packageCompareDifferentType = PackageCompareCompareDifferenceType;

  get filteredItems() {
    return this.items.filter(
      (item) =>
        this.showTextChanges ||
        item.compare_difference_type !== this.compareDifferenceType.Changed ||
        item.changes?.sales_price ||
        (item.options &&
          item.options.filter(
            (x) =>
              x.compare_difference_type !==
                this._optionCompareDifferentType.Changed ||
              x.changes?.sales_price,
          ).length > 0) ||
        (item.packages &&
          item.packages.filter(
            (x) =>
              x.compare_difference_type !==
                this._packageCompareDifferentType.Changed ||
              x.changes?.sales_price,
          ).length > 0),
    );
  }

  get columnsToDisplay(): string[] {
    return columnNames.filter(
      (columnName) =>
        this.showTextChanges ||
        (columnName !== 'short_description' &&
          columnName !== 'long_description'),
    );
  }
}
