import { Component, OnInit } from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';
import { Store } from '@ngrx/store';
import { LostOrderView } from '@sales-libs/project/data-access';
import { ChartData } from '@sales-libs/shared/ui';
import { nameofFactory } from '@utils/name-of';
import { map, Observable, take } from 'rxjs';
import { LostOrderActions, LostOrderSelectors } from '../../lost-order/store';
import { LostOrderReportingFilters } from '../../models';

@Component({
  selector: 'sl-project-lost-order-reporting-overview',
  templateUrl: './lost-order-reporting-overview.component.html',
  styleUrls: ['./lost-order-reporting-overview.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderReportingOverviewComponent implements OnInit {
  totalLostOrders$: Observable<number | undefined>;
  mainCompetitor$: Observable<ChartData | undefined>;
  mainReason$: Observable<ChartData | undefined>;
  reasonsData$: Observable<ChartData[] | undefined>;
  competitorsData$: Observable<ChartData[] | undefined>;

  singleValueColors: string[] = [
    dsConfig.colors.primary[600],
    dsConfig.colors.primary[400],
    dsConfig.colors.primary[300],
  ];

  readonly lostOrderReportingFilters$: Observable<any>;
  private _nameOf = nameofFactory<LostOrderView>();

  constructor(private readonly _store: Store) {
    const lostOrders$ = this._store.select(LostOrderSelectors.reportData);
    this.reasonsData$ = lostOrders$.pipe(
      map((data) =>
        data
          ? this._mapDataForProperty(data.items, this._nameOf('reason'))
          : undefined,
      ),
    );
    this.competitorsData$ = lostOrders$.pipe(
      map((data) =>
        data
          ? this._mapDataForProperty(data.items, this._nameOf('competitor'))
          : undefined,
      ),
    );
    this.totalLostOrders$ = lostOrders$.pipe(
      map((data) => data?.items.length ?? undefined),
    );
    this.mainCompetitor$ = this.competitorsData$.pipe(
      map((data) =>
        data ? data.sort((a, b) => b.value - a.value)[0] : undefined,
      ),
    );
    this.mainReason$ = this.reasonsData$.pipe(
      map((data) =>
        data ? data.sort((a, b) => b.value - a.value)[0] : undefined,
      ),
    );
  }

  ngOnInit() {
    this._store
      .select(LostOrderSelectors.reportingFilter)
      .pipe(take(1))
      .subscribe((filter) => {
        this._store.dispatch(
          LostOrderActions.LostOrderReportingFilterChange({ payload: filter }),
        );
      });
  }

  /**
   * dispatch filter change and take first page
   * @param filters
   */
  updateFilter(filters: LostOrderReportingFilters) {
    this._store.dispatch(
      LostOrderActions.LostOrderReportingFilterChange({ payload: filters }),
    );
  }

  updateFilterViaWidget(filters: LostOrderReportingFilters) {
    // toggle function
    this._store
      .select(LostOrderSelectors.reportingFilter)
      .pipe(take(1))
      .subscribe((currentFilter) => {
        Object.keys(filters).forEach((key) => {
          if (
            filters[key]?.sort().join('') ===
            currentFilter[key]?.sort().join('')
          ) {
            filters = { ...filters, [key]: undefined };
          }
        });
        this._store.dispatch(
          LostOrderActions.LostOrderReportingFilterChange({
            payload: {
              ...currentFilter,
              ...filters,
            },
          }),
        );
      });
  }

  private _mapDataForProperty(
    data: LostOrderView[],
    property: string,
  ): ChartData[] {
    const initialAcc: ChartData[] = [];
    return data.reduce((acc, curr) => {
      if (curr[property] !== null && curr[property].name) {
        const index = acc.findIndex(
          (item) => item.name === curr[property].name,
        );
        if (index === -1) {
          acc.push({
            id: curr[property].id,
            name: curr[property].name,
            value: 1,
            barsColorScheme: 'primary',
          });
        } else {
          acc[index].value++;
        }
      }
      return acc;
    }, initialAcc);
  }
}
