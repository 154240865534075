<ng-container matColumnDef="competitor_price">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="competitor_price"
    data-test="lost-order-competitor-price-header"
  >
    {{ 'table_columns.competitor_price' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'CompetitorPriceClick'
    }"
    data-test="lost-order-competitor-price"
  >
    {{
      lostOrder.competitor_price
        ? lostOrder.competitor_price
        : ('error_messages.lost_order.no_competitor_price' | translate)
    }}
  </td>
</ng-container>
