import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DsFilterPaginationValue } from '@design-system/components/filter-input';
import { createAction, props } from '@ngrx/store';
import {
  Cart,
  CartOverviewContainer,
  Currency,
  DocumentDownload,
  Project,
  ProjectInput,
  ProjectUpdate,
  ProjectsContainer,
} from '@sales-libs/project/data-access';
import { ProjectFilters } from '../../models';

export enum ProjectActionTypes {
  CreateOrUpdate = '[Project] CREATE_OR_UPDATE',
  Create = '[Project] CREATE',
  Update = '[Project] UPDATE',
  LoadProjects = '[Project] LOAD_PROJECT_PAGE',
  SearchProjects = '[Project] SEARCH_PROJECTS',
  LoadProject = '[Project] LOAD_PROJECT',
  SelectProject = '[Project] SELECT_PROJECT',
  SaveProject = '[Project] SAVE_PROJECT',
  DeleteProject = '[Project] DELETE_PROJECT',
  FilterChange = '[Project] FILTER_CHANGE',
  PageChange = '[Project] PAGE_CHANGE',
  SortChange = '[Project] SORT_CHANGE',
  ClearFilter = '[Project] CLEAR_FILTER',
  LoadCarts = '[Project] LOAD_CARTS',
  SelectCart = '[Project] SELECT_CART',
  CreateCart = '[Project] CREATE_CART',
  DeleteCart = '[Project] DELETE_CART',
  DuplicateCart = '[Project] DUPLICATE_CART',
  ShowCartActions = '[Project] SHOW_CART_ACTIONS',
  HideCartActions = '[Project] HIDE_CART_ACTIONS',
  LoadProjectsSuccess = '[Project] LOAD_PROJECTS_SUCCESS',
  LoadProjectSuccess = '[Project] LOAD_PROJECT_SUCCESS',
  LoadProjectError = '[Project] LOAD_PROJECT_ERROR',
  SaveProjectSuccess = '[Project] SAVE_PROJECT_SUCCESS',
  DeleteProjectSuccess = '[Project] DELETE_PROJECT_SUCCESS',
  LoadCartsSuccess = '[Project] LOAD_CARTS_SUCCESS',
  CreateCartSuccess = '[Project] CREATE_CART_SUCCESS',
  DeleteCartSuccess = '[Project] DELETE_CART_SUCCESS',
  DuplicateCartSuccess = '[Project] DUPLICATE_CART_SUCCESS',
  DuplicateCartByProjectId = '[Project] DUPLICATE_BY_PROJECT_ID',
  Error = '[Project] ERROR',
  GetExport = '[Project] GET_EXPORT',
  GetExportSuccess = '[Project] GET_EXPORT_SUCCESS',
  GetExportError = '[Project] GET_EXPORT_ERROR',
  ResetExportState = '[Project] RESET_EXPORT_STATE',
  LoadProjectFilter = '[Project] Load-Project-Filter',
  LoadProjectFilterSuccess = '[Project] Load-Project-Filter-Success',
  SetWholeSaleOrder = '[Project] SET_WHOLE_SALE_ORDER',
  SetCartActive = '[Project] SET_CART_ACTIVE',
}

export namespace ProjectActions {
  export const ResetExportState = createAction(
    ProjectActionTypes.ResetExportState,
  );
  export const GetExport = createAction(ProjectActionTypes.GetExport);
  export const GetExportSuccess = createAction(
    ProjectActionTypes.GetExportSuccess,
    props<{ payload: DocumentDownload }>(),
  );
  export const GetExportError = createAction(ProjectActionTypes.GetExportError);
  export const CreateOrUpdate = createAction(
    ProjectActionTypes.CreateOrUpdate,
    props<{ id: number; payload: ProjectInput | ProjectUpdate }>(),
  );
  export const Create = createAction(
    ProjectActionTypes.Create,
    props<{ payload: ProjectInput }>(),
  );
  export const Update = createAction(
    ProjectActionTypes.Update,
    props<{ id: number; payload: ProjectUpdate }>(),
  );
  export const LoadProjects = createAction(
    ProjectActionTypes.LoadProjects,
    props<{ payload: number | null }>(),
  );
  export const SearchProjects = createAction(ProjectActionTypes.SearchProjects);
  /**
   * @param payload project id
   */
  export const LoadProject = createAction(
    ProjectActionTypes.LoadProject,
    props<{ payload: number }>(),
  );
  /**
   * Select project and navigate to project details
   * @param payload project id
   */
  export const SelectProject = createAction(
    ProjectActionTypes.SelectProject,
    props<{ payload: number }>(),
  );

  export const DeleteProject = createAction(
    ProjectActionTypes.DeleteProject,
    props<{ payload: number }>(),
  );
  export const FilterChange = createAction(
    ProjectActionTypes.FilterChange,
    props<{ payload: ProjectFilters }>(),
  );
  export const PageChange = createAction(
    ProjectActionTypes.PageChange,
    props<{ payload: PageEvent }>(),
  );
  export const SortChange = createAction(
    ProjectActionTypes.SortChange,
    props<{ payload: Sort }>(),
  );
  export const ClearFilter = createAction(ProjectActionTypes.ClearFilter);
  export const LoadCarts = createAction(
    ProjectActionTypes.LoadCarts,
    props<{ payload: number }>(),
  );
  export const SelectCart = createAction(ProjectActionTypes.SelectCart);

  export const CreateCart = createAction(
    ProjectActionTypes.CreateCart,
    props<{
      productLine: string;
      priceListDate: Date;
      language: string;
      currency: Currency;
      validUntil: Date;
    }>(),
  );
  export const DeleteCart = createAction(
    ProjectActionTypes.DeleteCart,
    props<{ payload: number }>(),
  );
  export const DuplicateCart = createAction(
    ProjectActionTypes.DuplicateCart,
    props<{
      cartId: number;
      project: ProjectInput;
      language: string;
      priceListDate: Date;
    }>(),
  );
  export const DuplicateCartByProjectId = createAction(
    ProjectActionTypes.DuplicateCartByProjectId,
    props<{
      cartId: number;
      project: Project;
      language: string;
      priceListDate: Date;
    }>(),
  );
  // Results Actions
  export const LoadProjectsSuccess = createAction(
    ProjectActionTypes.LoadProjectsSuccess,
    props<{ payload: ProjectsContainer }>(),
  );
  export const LoadProjectSuccess = createAction(
    ProjectActionTypes.LoadProjectSuccess,
    props<{ payload: Project }>(),
  );
  export const LoadProjectError = createAction(
    ProjectActionTypes.LoadProjectError,
    props<{ isProjectDeleted: boolean }>(),
  );
  export const SaveProjectSuccess = createAction(
    ProjectActionTypes.SaveProjectSuccess,
    props<{ payload: Project }>(),
  );
  export const DeleteProjectSuccess = createAction(
    ProjectActionTypes.DeleteProjectSuccess,
    props<{ payload: number }>(),
  );
  export const LoadCartsSuccess = createAction(
    ProjectActionTypes.LoadCartsSuccess,
    props<{ payload: CartOverviewContainer }>(),
  );
  export const CreateCartSuccess = createAction(
    ProjectActionTypes.CreateCartSuccess,
    props<{ payload?: Cart }>(),
  );
  export const DeleteCartSuccess = createAction(
    ProjectActionTypes.DeleteCartSuccess,
    props<{ payload: number }>(),
  );
  export const DuplicateCartSuccess = createAction(
    ProjectActionTypes.DuplicateCartSuccess,
  );

  export const LoadProjectFilter = createAction(
    ProjectActionTypes.LoadProjectFilter,
    props<{ payload: DsFilterPaginationValue }>(),
  );
  export const LoadProjectFilterSuccess = createAction(
    ProjectActionTypes.LoadProjectFilterSuccess,
    props<{ payload: ProjectsContainer }>(),
  );

  export const Error = createAction(ProjectActionTypes.Error);

  export const SetWholeSaleOrder = createAction(
    ProjectActionTypes.SetWholeSaleOrder,
    props<{ payload: boolean }>(),
  );

  export const SetCartActive = createAction(
    ProjectActionTypes.SetCartActive,
    props<{ cartId: number }>(),
  );
}
