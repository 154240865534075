import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ContractItem,
  CustomArticleItem,
  ProductItem,
  SalesOptionCartItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem, SlIsContractItemPipe } from '@sales-libs/shared/util';
import { ContractUtils } from '../../../utils';

@Component({
  selector: 'sl-project-description-column',
  templateUrl: './description-column.component.html',
  styleUrls: ['./description-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectDescriptionColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.description = this.getItemDescription(value);
  }

  description: string | null | undefined;

  constructor(private _translate: TranslateService) {}

  // done here to use in toolitp as well
  getItemDescription(item: SlCartItem) {
    if (new SlIsContractItemPipe().transform(item)) {
      return ContractUtils.getContractDescription(
        item as ContractItem,
        this._translate,
      );
    } else {
      const castedItem = item as
        | ProductItem
        | CustomArticleItem
        | SalesOptionCartItem
        | WarrantyExtensionItem;

      if (castedItem.short_description) {
        return castedItem.short_description;
      }

      if (castedItem.long_description) {
        return castedItem.long_description;
      }

      return '';
    }
  }
}
