import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace TuningCenterSelectors {
  const tuningCenterState = createSelector(
    moduleState,
    (state) => state.tuningCenter,
  );

  export const optionCategories = createSelector(
    tuningCenterState,
    (state) => state.optionCategories,
  );
}
