/* eslint-disable max-lines */
import { formatCurrency } from '@angular/common';
import {
  Calculation,
  CalculationItem,
  CalculationItemItemType,
  CalculationItemPerspective,
  ConfigurationPackage,
  Currency,
  ProductItem,
} from '@sales-libs/project/data-access';
import { SlSharedMathUtils } from '@sales-libs/shared/util';

export class CalculationUtils {
  static readonly perspectives = ['customer', 'partner', 'general_agent'];

  static getSalesPrice(
    perspective: CalculationItemPerspective,
    quantity: number,
    ratio: number = 1,
    roundingDigits = 2,
  ) {
    const totalPurchasePrice =
      +SlSharedMathUtils.roundAwayFromZero(
        perspective.purchase_price * ratio,
        roundingDigits,
      ).toFixed(2) * (quantity === 0 ? 1 : quantity);

    // to deal with javascript floating point precision problem we work with wholenumbers
    const decimalCount =
      perspective.discount.toString().split('.')[1]?.length || 0;
    const wholeNumberFactor = Math.pow(10, decimalCount);
    const discountFactor =
      wholeNumberFactor - perspective.discount * wholeNumberFactor;

    // we do not round discounted price here, to keep the precision on top level prices.
    // since we show the price only with 2 digits, this can lead to differences when user trys to check calculation
    // by summing up the prices himself. But this is a tradeoff to keep the precision on top level prices.
    // decision made on the 02.12.2024 with PO and PM
    return (totalPurchasePrice * discountFactor) / wholeNumberFactor;
  }

  // TODO: turn this logic around, so that get Sales Price handles child items
  static getItemsTotal(
    items: CalculationItem[],
    perspective: string,
    isAlternative: boolean,
    getSalesPrice = true,
    includeTypeOption = false,
    ratio = 1,
    roundingDigits = 2,
  ): number {
    return (
      items
        .filter(
          (x) =>
            includeTypeOption || x.item_type !== CalculationItemItemType.Option,
        )
        // eslint-disable-next-line sonarjs/cognitive-complexity
        .reduce((sum, x) => {
          if (x.is_optional === isAlternative && x[perspective]) {
            if (x.items && x.items.length > 0) {
              return (
                sum +
                CalculationUtils.getItemsTotal(
                  x.items,
                  perspective,
                  isAlternative,
                  getSalesPrice,
                  true,
                  ratio,
                  roundingDigits,
                )
              );
            } else {
              return (
                sum +
                (getSalesPrice
                  ? CalculationUtils.getSalesPrice(
                      x[perspective],
                      x.quantity,
                      ratio,
                      this.isUserPriceInputItem(x) ? 2 : roundingDigits,
                    )
                  : SlSharedMathUtils.roundAwayFromZero(
                      x[perspective].purchase_price * ratio,
                      this.isUserPriceInputItem(x) ? 2 : roundingDigits,
                    ) * (x.quantity === 0 ? 1 : x.quantity))
              );
            }
          } else {
            return sum;
          }
        }, 0)
    );
  }
  static getTotalPurchasePrice(
    items: CalculationItem[],
    perspective: string,
    isAlternative: boolean,
    isAll: boolean,
    ratio = 1,
    roundingDigits = 2,
  ) {
    return items.reduce(
      (sum, x) =>
        (x.is_read_only || isAll) &&
        x.is_optional === isAlternative &&
        x[perspective]
          ? sum +
            SlSharedMathUtils.roundAwayFromZero(
              x[perspective].purchase_price * ratio,
              this.isUserPriceInputItem(x) ? 2 : roundingDigits,
            ) *
              (x.quantity === 0 ? 1 : x.quantity)
          : sum,
      0,
    );
  }
  static getRelativeDiscountedPrice(
    calculation: Calculation,
    perspective: string,
    isAlternative: boolean,
    ratio = 1,
    roundingDigits = 2,
  ) {
    const subTotal = this.getItemsTotal(
      calculation.items,
      perspective,
      isAlternative,
      true,
      false,
      ratio,
      roundingDigits,
    );
    return (
      subTotal -
      SlSharedMathUtils.roundAwayFromZero(
        subTotal * calculation[perspective].relative_discount,
        2,
      )
    );
  }

  static getFinalPrice(
    calculation: Calculation,
    perspective: string,
    isAlternative: boolean,
    ratio = 1,
    roundingDigits = 2,
  ) {
    return (
      this.getRelativeDiscountedPrice(
        calculation,
        perspective,
        isAlternative,
        ratio,
        roundingDigits,
      ) +
      (calculation[perspective].transport_costs ?? 0) * ratio -
      calculation[perspective].absolute_discount * ratio
    );
  }

  static overwriteItemDiscount(
    id: string,
    item: CalculationItem,
    discount: number,
    perspective: string,
  ) {
    if (item.id === id) {
      return {
        ...item,
        [perspective]: item[perspective]
          ? {
              ...item[perspective],
              discount: discount / 100, // as percentage,
              discounted_price: null, // reset, cause backend does prioritzes this value
            }
          : item[perspective],
        items: item.items
          ? item.items.map((child) => ({
              ...child,
              [perspective]: child[perspective]
                ? {
                    ...child[perspective],
                    discount: discount / 100, // as percentage,
                    discounted_price: null, // reset, cause backend does prioritzes this value
                  }
                : child[perspective],
            }))
          : item.items,
      };
    } else if (item.items) {
      return {
        ...item,
        items: item.items
          ? item.items.map((x) =>
              this.overwriteItemDiscount(id, x, discount, perspective),
            )
          : item.items,
      };
    } else {
      return item;
    }
  }
  static localizePrice(price: number, ratio: number) {
    return SlSharedMathUtils.roundAwayFromZero(price * ratio, 2);
  }
  static calculate(
    calculation: Calculation,
    ratio: number,
    roundingDigits: number,
  ): Calculation {
    CalculationUtils.perspectives.forEach((perspective, index) => {
      if (calculation[perspective]) {
        CalculationUtils.calculate_perspective(
          calculation,
          perspective,
          index,
          false,
          ratio,
          roundingDigits,
        );
      }
    });

    // eslint-disable-next-line sonarjs/cognitive-complexity
    calculation.items?.forEach((item) => {
      if (
        item.items &&
        item.items.length > 0 &&
        item.item_type !== CalculationItemItemType.GroupItem
      ) {
        CalculationUtils.perspectives?.forEach((perspective) => {
          item[perspective].sales_price = CalculationUtils.getItemsTotal(
            item.items as CalculationItem[],
            perspective,
            false,
            true,
            true,
            ratio,
            this.isUserPriceInputItem(item) ? 2 : roundingDigits,
          );
          item[perspective].purchase_price = CalculationUtils.getItemsTotal(
            item.items as CalculationItem[],
            perspective,
            false,
            false,
            true,
            ratio,
            this.isUserPriceInputItem(item) ? 2 : roundingDigits,
          );
        });
        item.items.forEach((child) => {
          CalculationUtils.perspectives?.forEach((perspective) => {
            if (child[perspective] != null) {
              child[perspective].sales_price = +CalculationUtils.getSalesPrice(
                child[perspective],
                child.quantity,
                ratio,
                this.isUserPriceInputItem(child) ? 2 : roundingDigits,
              );
            }
            child[perspective].purchase_price =
              +SlSharedMathUtils.roundAwayFromZero(
                child[perspective].purchase_price * ratio,
                roundingDigits,
              ).toFixed(2) * (child.quantity === 0 ? 1 : child.quantity);
          });
        });
      } else {
        CalculationUtils.perspectives?.forEach((perspective) => {
          if (item[perspective] != null) {
            item[perspective].sales_price = +CalculationUtils.getSalesPrice(
              item[perspective],
              item.quantity,
              ratio,
              this.isUserPriceInputItem(item) ? 2 : roundingDigits,
            );

            item[perspective].purchase_price =
              +SlSharedMathUtils.roundAwayFromZero(
                item[perspective].purchase_price * ratio,
                roundingDigits,
              ).toFixed(2) * (item.quantity === 0 ? 1 : item.quantity);
          }
        });
      }
    });

    return calculation;
  }

  static calculate_perspective(
    calculation: Calculation,
    perspective: string,
    index: number,
    isAlternative: boolean,
    ratio: number,
    roundingDigits: number,
  ) {
    const perspectiveValue = calculation[perspective];
    const transportCost = perspectiveValue.transport_costs * ratio;
    const subTotalPurchaseWithTransportCost =
      CalculationUtils.getItemsTotal(
        calculation.items,
        perspective,
        isAlternative,
        false,
        false,
        ratio,
        roundingDigits,
      ) + transportCost;

    perspectiveValue.final_price = CalculationUtils.getFinalPrice(
      calculation,
      perspective,
      isAlternative,
      ratio,
      roundingDigits,
    );

    perspectiveValue.purchase_price = CalculationUtils.getItemsTotal(
      calculation.items,
      perspective,
      isAlternative,
      false,
      false,
      ratio,
      roundingDigits,
    );
    perspectiveValue.sales_price = CalculationUtils.getItemsTotal(
      calculation.items,
      perspective,
      isAlternative,
      true,
      false,
      ratio,
      roundingDigits,
    );
    perspectiveValue.ppi_price = SlSharedMathUtils.roundAwayFromZero(
      CalculationUtils.getIndexPrice(calculation, perspective, isAlternative),
      2,
    );

    const compareToPerspective =
      index > 0 ? CalculationUtils.perspectives[index - 1] : null;

    if (compareToPerspective) {
      const finalPriceComparePerspective = CalculationUtils.getFinalPrice(
        calculation,
        compareToPerspective,
        isAlternative,
        ratio,
        roundingDigits,
      );

      const absoluteMargin = SlSharedMathUtils.roundAwayFromZero(
        finalPriceComparePerspective - perspectiveValue.final_price,
        2,
      );

      // Four digits because percentage values 20.01% is 0.2001
      const relativeMargin = SlSharedMathUtils.roundAwayFromZero(
        absoluteMargin / finalPriceComparePerspective,
        4,
      );

      // TODO change this to current perspective and fix excel creation
      calculation[compareToPerspective].absolute_margin = absoluteMargin;
      calculation[compareToPerspective].relative_margin = relativeMargin;
    }

    const purchasePrice = CalculationUtils.getTotalPurchasePrice(
      calculation.items,
      perspective,
      isAlternative,
      true,
      ratio,
      roundingDigits,
    );
    const netPositions = CalculationUtils.getTotalPurchasePrice(
      calculation.items,
      perspective,
      isAlternative,
      false,
      ratio,
      roundingDigits,
    );

    // Four digits because percentage values 20.01% is 0.2001
    perspectiveValue.average_discount =
      subTotalPurchaseWithTransportCost === 0
        ? 0
        : SlSharedMathUtils.roundAwayFromZero(
            (subTotalPurchaseWithTransportCost - perspectiveValue.final_price) /
              subTotalPurchaseWithTransportCost,
            4,
          );
    perspectiveValue.average_discount_excluding_net =
      SlSharedMathUtils.roundAwayFromZero(
        purchasePrice === netPositions
          ? 0
          : 1 -
              (perspectiveValue.final_price - transportCost - netPositions) /
                (purchasePrice - netPositions),
        4,
      );
  }

  static getIndexPrice(
    calculation: Calculation,
    perspective: string,
    isAlternative: boolean,
  ) {
    const actualItems = calculation.items.filter(
      (item) =>
        item.item_type !== CalculationItemItemType.Option &&
        item.is_optional === isAlternative,
    );

    const relativeSum = actualItems
      .map(
        (item) =>
          (item.items && item.items.length > 0
            ? CalculationUtils.getItemsTotal(
                item.items,
                perspective,
                isAlternative,
                true,
                false,
              )
            : CalculationUtils.getSalesPrice(
                item[perspective],
                item.quantity,
              )) * (calculation[perspective].relative_discount || 1),
      )
      .reduce((sum, x) => (sum += x), 0);
    if (relativeSum === 0) return 0;
    const finalPriceWithoutTransport =
      CalculationUtils.getFinalPrice(calculation, perspective, isAlternative) -
      (calculation[perspective].transport_costs || 0);
    const absoluteDiscountFactor = finalPriceWithoutTransport / relativeSum;

    return actualItems
      .filter((x) => x.is_ppi_relevant)
      .map(
        (item) =>
          (item.items && item.items.length > 0
            ? CalculationUtils.getItemsTotal(
                item.items,
                perspective,
                isAlternative,
                true,
                false,
              )
            : CalculationUtils.getSalesPrice(
                item[perspective],
                item.quantity,
              )) *
          (calculation[perspective].relative_discount || 1) *
          absoluteDiscountFactor,
      )
      .reduce(
        (ppi, nettoPrice) =>
          (ppi = ppi + nettoPrice * calculation[perspective].ppi_factor),
        0,
      );
  }

  static IsPackageFulfiled(
    item: ProductItem,
    configurationPackage: ConfigurationPackage,
  ): boolean {
    return (
      configurationPackage?.options?.every((x) =>
        ['REQUIRED', 'SELECTED'].includes(
          item.options?.find((o) => o.name === x.sales_option_id)?.state || '',
        ),
      ) ?? false
    );
  }
  static tableItems(
    calc: Calculation | null,
    expandedItemsLookup: { [id: string]: boolean },
  ): CalculationItem[] {
    if (!calc) {
      return [];
    }

    return calc.items
      .filter(
        (item) =>
          item.item_type !== CalculationItemItemType.Option &&
          item.item_type !== CalculationItemItemType.GroupItem,
      )
      .reduce((acc, item) => {
        if (item.item_type === CalculationItemItemType.Group && item.items) {
          acc.push(CalculationUtils.calculateGroup(item));
        } else {
          acc.push(item);
        }
        if (expandedItemsLookup[item.id ?? ''] && item.items) {
          acc.push(
            ...item.items.map((x) =>
              x.item_type === CalculationItemItemType.Option
                ? {
                    ...x,
                    quantity: item.quantity,
                    is_optional: item.is_optional,
                  }
                : x,
            ),
          );
        }
        return acc;
      }, [] as CalculationItem[]);
  }

  static calculateGroup = (group: CalculationItem) => {
    if (group.items) {
      return {
        ...group,
        customer: CalculationUtils.calculatePerspective(group, 'customer'),
        general_agent: CalculationUtils.calculatePerspective(
          group,
          'general_agent',
        ),
        partner: CalculationUtils.calculatePerspective(group, 'partner'),
      };
    } else {
      return group;
    }
  };

  static calculatePerspective = (
    item: CalculationItem,
    perspective: string,
  ) => {
    const calcPerspective =
      item.items && item[perspective]
        ? {
            ...item[perspective],
            purchase_price: CalculationUtils.getItemsTotal(
              item.items,
              perspective,
              true,
              false,
              true,
            ),
            sales_price: CalculationUtils.getItemsTotal(
              item.items,
              perspective,
              true,
              true,
              true,
            ),
          }
        : item[perspective];

    if (calcPerspective) {
      calcPerspective.discount =
        (calcPerspective.purchase_price - calcPerspective.sales_price) /
        calcPerspective.purchase_price;
    }
    return calcPerspective;
  };

  static priceToLocalString(
    value: number | null,
    currencySettings: Currency,
    locale: string,
  ) {
    return formatCurrency(
      value ?? 0,
      currencySettings.locale ?? locale,
      currencySettings.code ?? 'EUR',
      currencySettings.code ?? 'EUR',
      '1.2-2',
    );
  }

  static isUserPriceInputItem(item: CalculationItem): boolean {
    return (
      !!item.custom_article_item_id ||
      !!item.mcc_item_id ||
      !!item.tuning_center_item_id
    );
  }
}
