import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';
import { pageAdapter } from '../projects';
import { lostOrderAdapter } from './lost-order-list.reducer';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');
export namespace LostOrderListSelectors {
  const lostOrderSelectors = lostOrderAdapter.getSelectors();
  const pageSelectors = pageAdapter.getSelectors();

  export const lostOrderState = createSelector(
    moduleState,
    (state) => state?.lostOrderList,
  );

  export const loading = createSelector(
    lostOrderState,
    (state) => state.loading,
  );

  const lostOrderEntitiesState = createSelector(
    lostOrderState,
    lostOrderSelectors.selectEntities,
  );

  export const pagination = createSelector(
    lostOrderState,
    (state) => state.pagination,
  );

  export const pageEntitesState = createSelector(
    pagination,
    pageSelectors.selectEntities,
  );

  export const currentPage = createSelector(
    pagination,
    pageEntitesState,
    lostOrderEntitiesState,
    (pagination1, pageEntities, lostOrderEntities) => {
      const page = pageEntities[pagination1.pageIndex];
      return (
        page && page.ids.map((lostOrderId) => lostOrderEntities[lostOrderId])
      );
    },
  );

  export const lostOrderFilters = createSelector(
    lostOrderState,
    (state) => state.filters,
  );

  export const sort = createSelector(lostOrderState, (state) => state?.sort);

  export const exportUrl = createSelector(
    lostOrderState,
    (state) => state?.exportUrl,
  );

  export const hasExportError = createSelector(
    lostOrderState,
    (state) => state.hasExportError,
  );
}
