import { Observable, Subject } from 'rxjs';
import { SlProjectDataCreationResult } from '../types/result';

export interface SlProjectDataCreationResponse {
  state: SlProjectDataCreationRowState;
  result?: SlProjectDataCreationResult;
}

export enum SlProjectDataCreationRowState {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export class SlProjectDataCreationRow {
  private _isFirstCreateCall = true;
  constructor(public text: string) {}

  protected _creationFunction: () => Observable<SlProjectDataCreationResponse>;

  result$ = new Subject<SlProjectDataCreationResult>();
  state = SlProjectDataCreationRowState.Loading;
  create() {
    this.state = SlProjectDataCreationRowState.Loading;
    this._creationFunction().subscribe((response) => {
      this.state = response.state;
      if (
        this.state === SlProjectDataCreationRowState.Success &&
        response.result
      ) {
        this.result$.next(response.result);
      }
    });

    this._isFirstCreateCall = false;
  }
}
