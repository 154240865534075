<ng-container matColumnDef="amount">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="amount"
    data-test="lost-order-amount-header"
  >
    {{ 'table_columns.amount' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'AmountClick'
    }"
    data-test="lost-order-amount"
  >
    {{ lostOrder.amount }}
  </td>
</ng-container>
