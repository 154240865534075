import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { TableSettings } from '@design-system/components/table-settings';
import {
  Project,
  ProjectActions,
  ProjectState,
} from '@sales-libs/project/data-access';

@Component({
  selector: 'sl-project-project-list-actions-column',
  templateUrl: './project-list-actions-column.component.html',
  styleUrls: ['./project-list-actions-column.component.scss'],
  standalone: false,
})
export class SlProjectListSlProjectActionsColumnComponent extends DsColumnComponent {
  @Input() displayColumns: TableSettings[];
  @Output()
  readonly selectClick = new EventEmitter<Project>();
  @Output()
  readonly deleteClick = new EventEmitter<Project>();
  @Output()
  readonly editClick = new EventEmitter<Project>();
  @Output() readonly setUserSettings = new EventEmitter<TableSettings[]>();

  projectActions = ProjectActions;
  projectState = ProjectState;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
