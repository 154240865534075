<ds-table
  [formGroup]="form"
  [disableResponsive]="true"
  *ngIf="currencyRatio$ | async as currencyRatio"
>
  <table mat-table [dataSource]="dataSource">
    <caption hidden data-test="title">
      {{
        'common.calculation' | translate
      }}
    </caption>
    <ng-container matColumnDef="topLeftCorner" sticky>
      <th mat-header-cell *matHeaderCellDef id="top_left_corner"></th>
    </ng-container>

    <sl-project-name-column
      (toggleClick)="onToggle($event)"
      [expandedItemsLookup]="expandedItemsLookup$ | async"
    ></sl-project-name-column>

    <ng-container *ngFor="let perspective of perspectives; let i = index">
      <sl-project-empty-column
        [perspective]="perspective"
      ></sl-project-empty-column>

      <sl-project-bulk-discount-header
        [perspective]="perspective"
        [label]="
          perspective === 'customer'
            ? ('calculation.retail' | translate)
            : ((perspective === 'partner'
                ? 'calculation.purchasing'
                : 'calculation.total_purchasing'
              ) | translate)
        "
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
        [percentSymbol]="percentSymbol$ | async"
        (calculationChange)="onChange($event)"
        (invalidChange)="onInvalidDiscount()"
      ></sl-project-bulk-discount-header>

      <sl-project-purchase-price-column
        [perspective]="perspective"
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [calculation]="calculation"
        [isAlternativeView]="showAlternatives"
      ></sl-project-purchase-price-column>

      <sl-project-transport-cost-input-column
        [currencySymbol]="currencySymbol$ | async"
        [currencyRatio]="currencyRatio"
        [perspective]="perspective"
        [calculation]="calculation"
        (calculationChange)="onChange($event)"
      ></sl-project-transport-cost-input-column>

      <sl-project-discount-column
        [perspective]="perspective"
        [calculation]="calculation"
        [isAlternativeView]="showAlternatives"
        (calculationChange)="onChange($event)"
        [percentSymbol]="percentSymbol$ | async"
        [locale]="locale"
      ></sl-project-discount-column>

      <sl-project-average-discount-column
        [perspective]="perspective"
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
        [locale]="locale"
      >
      </sl-project-average-discount-column>
      <sl-project-average-discount-excluding-net-column
        [perspective]="perspective"
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
        [locale]="locale"
      >
      </sl-project-average-discount-excluding-net-column>
      <sl-project-relative-discount-input-column
        [perspective]="perspective"
        [calculation]="calculation"
        [percentSymbol]="percentSymbol$ | async"
        [isAlternativeView]="showAlternatives"
        (calculationChange)="onChange($event)"
        (invalidChange)="onInvalidDiscount()"
        [locale]="locale"
      >
      </sl-project-relative-discount-input-column>

      <sl-project-sales-price-column
        [currencySymbol]="currencySymbol$ | async"
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [perspective]="perspective"
        [calculation]="calculation"
        [isAlternativeView]="showAlternatives"
        (calculationChange)="onChange($event)"
      >
      </sl-project-sales-price-column>

      <sl-project-transport-costs-column
        [perspective]="perspective"
        [transportCost]="calculation[perspective].transport_costs"
      ></sl-project-transport-costs-column>

      <sl-project-relative-discount-column
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [perspective]="perspective"
        [calculation]="calculation"
        [isAlternativeView]="showAlternatives"
      ></sl-project-relative-discount-column>

      <sl-project-absolute-discounted-price-column
        [perspective]="perspective"
        [currencySymbol]="currencySymbol$ | async"
        [currencyRatio]="currencyRatio"
        [calculation]="calculation"
        (calculationChange)="onChange($event)"
      >
      </sl-project-absolute-discounted-price-column>

      <sl-project-final-price-input-column
        [currencySymbol]="currencySymbol$ | async"
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [perspective]="perspective"
        [calculation]="calculation"
        [isAlternativeView]="showAlternatives"
        (calculationChange)="onChange($event)"
      ></sl-project-final-price-input-column>

      <sl-project-final-price-column
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [perspective]="perspective"
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
      ></sl-project-final-price-column>

      <sl-project-index-price-column
        [perspective]="perspective"
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
        ><sl-project-index-price-factor-column
          [perspective]="perspective"
          [isAlternativeView]="showAlternatives"
          [calculation]="calculation"
        ></sl-project-index-price-factor-column
      ></sl-project-index-price-column>

      <sl-project-margin-column
        [currencyRatio]="currencyRatio"
        [currencyRoundingDigits]="currencyRoundingDigits$ | async"
        [perspective]="perspective"
        [compareToPerspective]="i > 0 ? perspectives[i - 1] : null"
        [isAlternativeView]="showAlternatives"
        [calculation]="calculation"
        [locale]="locale"
      >
      </sl-project-margin-column>
    </ng-container>

    <sl-project-shipping-costs-column
      [currencyRatio]="currencyRatio"
      [shippingCosts]="shippingCosts"
      [calculation]="calculation"
      (calculationChange)="onChange($event)"
    ></sl-project-shipping-costs-column>

    <ng-container matColumnDef="relativeDiscount" sticky>
      <td mat-footer-cell *matFooterCellDef id="special_discount">
        {{ 'calculation.special_discount' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="absoluteDiscount" sticky>
      <td mat-footer-cell *matFooterCellDef id="special_decline">
        {{ 'calculation.special_decline' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="finalPrice1" sticky>
      <td mat-footer-cell *matFooterCellDef id="discounted_sum">
        {{ 'calculation.overwrite_discounted_sum' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="finalPrice" sticky>
      <td mat-footer-cell *matFooterCellDef id="total">
        {{ 'calculation.total' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="indexPrice" sticky>
      <td mat-footer-cell *matFooterCellDef id="index_price">
        {{ 'calculation.index_price' | translate }}
        <button
          class="print-hidden"
          data-test="index-price-info-Button"
          mat-icon-button
          (click)="openSlProjectIndexPriceDescriptionDialogComponent()"
        >
          <mat-icon>info</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="margin" sticky>
      <td mat-footer-cell *matFooterCellDef id="margin">
        {{ 'calculation.margin' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="averageDiscount" sticky>
      <td mat-footer-cell *matFooterCellDef id="average_discount">
        {{ 'calculation.average_discount' | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="averageDiscountExclNet" sticky>
      <td mat-footer-cell *matFooterCellDef id="average_discount_excluding_net">
        {{ 'calculation.average_discount_excl_net' | translate }}
      </td>
    </ng-container>
    <!-- table header -->
    <tr
      mat-header-row
      *matHeaderRowDef="displayColumns(['topLeftCorner', 'perspective'])"
    ></tr>
    <tr
      mat-header-row
      *matHeaderRowDef="
        displayColumns([
          'name',
          'perspectivePurchasePrice',
          'perspectiveDiscount',
          'perspectiveSalesPrice'
        ])
      "
    ></tr>
    <!-- table body -->
    <tr
      mat-row
      *matRowDef="
        let item;
        columns: displayColumns([
          'name',
          'perspectivePurchasePrice',
          'perspectiveDiscount',
          'perspectiveSalesPrice'
        ])
      "
      [class.item]="item.type === 'item'"
      [class.option]="item.type === 'option'"
      class="default data-test-{{ item.name | slCssClass }}"
    ></tr>
    <!-- table footer -->
    <ng-container *ngIf="!showAlternatives">
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'name',
            'perspectivePurchasePrice',
            'perspectiveDiscount',
            'perspectiveSalesPrice'
          ])
        "
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'transportCosts',
            'perspectiveTransportCostsInput',
            'perspectiveEmpty',
            'perspectiveTransportCosts'
          ])
        "
      ></tr>
      <tr
        mat-footer-row
        data-test="relative-discount-row"
        *matFooterRowDef="
          displayColumns([
            'relativeDiscount',
            'perspectiveEmpty',
            'perspectiveRelativeDiscountInput',
            'perspectiveRelativeDiscount'
          ])
        "
      ></tr>
      <tr
        mat-footer-row
        data-test="absolute-discount-row"
        *matFooterRowDef="
          displayColumns([
            'absoluteDiscount',
            'perspectiveEmpty',
            'perspectiveEmpty',
            'perspectiveAbsoluteDiscount'
          ])
        "
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'finalPrice1',
            'perspectiveEmpty',
            'perspectiveEmpty',
            'perspectiveFinalPriceInput'
          ])
        "
        class="print-hidden"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'finalPrice',
            'perspectiveEmpty',
            'perspectiveEmpty',
            'perspectiveFinalPrice'
          ])
        "
        class="text-bold"
      ></tr>
      <ng-container *ngIf="isFeatureFlagPpiSet">
        <tr
          mat-footer-row
          *matFooterRowDef="
            displayColumns([
              'indexPrice',
              'perspectiveEmpty',
              'perspectiveIndexPriceFactor',
              'perspectiveIndexPrice'
            ])
          "
        ></tr>
      </ng-container>

      <tr
        mat-footer-row
        *matFooterRowDef="displayColumns(['margin', 'perspectiveMargin'])"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'averageDiscount',
            'perspectiveEmpty',
            'perspectiveAverageDiscount',
            'perspectiveEmpty'
          ])
        "
        class="default"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="
          displayColumns([
            'averageDiscountExclNet',
            'perspectiveEmpty',
            'perspectiveAverageDiscountExclNet',
            'perspectiveEmpty'
          ])
        "
        class="default"
      ></tr>
    </ng-container>
  </table>
</ds-table>
