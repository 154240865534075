import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { LostOrderView } from '@sales-libs/project/data-access';

import { TableSettings } from '@design-system/components/table-settings';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LostOrderListSelectors } from '../../store';

@Component({
  selector: 'sl-project-lost-order-list',
  templateUrl: './lost-order-list.component.html',
  styleUrls: ['./lost-order-list.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderListComponent {
  @Input()
  pagination: PageEvent;
  @Input() lostOrders: LostOrderView[];
  @Input() displayColumns: TableSettings[];
  @Output() readonly getPage = new EventEmitter<PageEvent>();
  @Output() readonly sortChange = new EventEmitter<Sort>();
  @Output()
  readonly setUserSettings = new EventEmitter<TableSettings[]>();

  sort$: Observable<Sort | undefined>;

  constructor(private store: Store) {
    this.sort$ = this.store.select(LostOrderListSelectors.sort);
  }
}
