import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-project-relative-discount-input-column',
  templateUrl: './relative-discount-input-column.component.html',
  styleUrls: ['./relative-discount-input-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectRelativeDiscountInputColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() percentSymbol: string;
  @Input() locale: string;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this.formControl.setValue(
      +((value[this.perspective].relative_discount || 0) * 100).toFixed(2),
      {
        emitEvent: false,
        onlySelf: true,
      },
    );
  }

  @Output() calculationChange = new EventEmitter<Calculation>();
  @Output() invalidChange = new EventEmitter();

  readonly triggerBlur = triggerBlur;
  formControl = new FormControl<number>(0, {
    nonNullable: true,
    validators: Validators.max(100),
    updateOn: 'blur',
  });
  private _calculation: Calculation;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }

    this.formDirective.form.addControl(
      this.perspective + 'RelativeDiscount',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        if (this.formControl.valid) {
          this.calculationChange.emit({
            ...this._calculation,
            [this.perspective]: this._calculation[this.perspective]
              ? {
                  ...this._calculation[this.perspective],
                  relative_discount: value / 100, // as percentage
                  absolute_discount: 0,
                }
              : this._calculation[this.perspective],
          });
        }
      });

    this.formControl.statusChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (this.formControl.invalid) {
          this.invalidChange.emit();
        }
      });
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
