<ds-full-page [disableAnimation]="true">
  <ds-full-page-body>
    <div class="offer">
      <div class="offer-section no-margin-top">
        <sl-project-digital-offer-info
          [baseData]="offerData?.base_info"
          [customerData]="offerData?.customer"
          [dealerData]="offerData?.dealer"
        ></sl-project-digital-offer-info>
      </div>

      @if (offerData?.scope) {
        <mat-card class="offer-section">
          <sl-project-digital-offer-specifications
            [specifications]="offerData?.scope"
          ></sl-project-digital-offer-specifications>
        </mat-card>
      }

      @if (offerData?.options?.include_price_chapter) {
        <mat-card class="offer-section">
          <sl-project-digital-offer-prices
            *ngIf="offerData?.prices"
            [prices]="offerData?.prices"
            [displayItemPrices]="offerData?.options?.display_item_prices"
            [displayIndexPrices]="offerData?.options?.display_index_prices_ppi"
            [includeDiscounts]="offerData?.options?.include_discounts"
          ></sl-project-digital-offer-prices>
        </mat-card>
      }

      @if (offerData?.your_benefits) {
        <mat-card class="offer-section">
          <sl-project-digital-offer-benefits
            [benefits]="offerData?.your_benefits"
          ></sl-project-digital-offer-benefits>
        </mat-card>
      }

      <!--- technical data-->
      @if (
        offerData?.options?.include_technical_data_chapter &&
        offerData?.technical_data &&
        (offerData?.technical_data?.litfing_cap_diagram1_url ||
          offerData?.technical_data?.litfing_cap_diagram2_url ||
          offerData?.technical_data?.default_arm_drawing_url ||
          offerData?.technical_data?.stabilizer_drawing_url ||
          offerData?.technical_data?.technical_table)
      ) {
        <mat-card class="offer-section">
          <sl-project-digital-offer-technical-data
            [technicalData]="offerData?.technical_data"
          ></sl-project-digital-offer-technical-data>
        </mat-card>
      }

      @if (
        offerData?.terms_and_conditions &&
        offerData?.options?.include_offer_and_delivery_terms
      ) {
        <mat-card class="offer-section">
          <sl-project-digital-offer-terms-and-conditions
            [termsAndConditions]="offerData?.terms_and_conditions"
          ></sl-project-digital-offer-terms-and-conditions>
        </mat-card>
      }
    </div>
    <br />
    <br />
  </ds-full-page-body>
  <ds-full-page-footer gaCategoryGroup="DigitalOffer" gaLabelGroup="Footer">
    <div fxLayout fxLayoutAlign="end">
      <button
        mat-raised-button
        color="accent"
        [matMenuTriggerFor]="menu"
        gaClickListener="TableOfContentButtonClick"
      >
        {{ 'offer.digital_offer.table_of_contents' | translate }}
      </button>
    </div>
  </ds-full-page-footer>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="offerData?.scope">
      <button mat-menu-item (click)="scrollToElement('yourSpecification')">
        {{ 'offer_your_specification_subheadline' | translate }}
      </button>
      <div class="sub-items">
        <button
          mat-menu-item
          (click)="scrollToElement('equipmentSpecification')"
          *ngIf="
            offerData?.scope?.equipments && offerData?.scope?.equipments?.length
          "
        >
          <small>{{ 'offer_equipment' | translate }}</small>
        </button>
        <button
          mat-menu-item
          (click)="scrollToElement('customArticlesSpecification')"
          *ngIf="
            offerData?.scope?.custom_articles &&
            offerData?.scope?.custom_articles?.length
          "
        >
          <small>{{ 'offer_custom_articles' | translate }}</small>
        </button>
        <button
          mat-menu-item
          (click)="scrollToElement('optionalItemsSpecification')"
          *ngIf="
            offerData?.scope?.optional && offerData?.scope?.optional?.length
          "
        >
          <small>{{ 'offer_optional_items_and_services' | translate }}</small>
        </button>
      </div>
    </ng-container>
    <button
      mat-menu-item
      (click)="scrollToElement('prices')"
      *ngIf="offerData?.prices"
    >
      {{ 'offer_prices_headline' | translate }}
    </button>
    <button
      mat-menu-item
      (click)="scrollToElement('yourBenefits')"
      *ngIf="offerData?.your_benefits"
    >
      {{ 'offer_your_benefits_headline' | translate }}
    </button>
    <div
      class="sub-items"
      *ngIf="
        offerData?.your_benefits?.optional &&
        offerData?.your_benefits?.optional?.length
      "
    >
      <button mat-menu-item (click)="scrollToElement('optionalItemsBenefits')">
        <small>{{ 'offer_optional_items_and_services' | translate }}</small>
      </button>
    </div>
    <button
      mat-menu-item
      (click)="scrollToElement('technicalData')"
      *ngIf="
        offerData?.options?.include_technical_data_chapter &&
        offerData?.technical_data &&
        (offerData?.technical_data?.litfing_cap_diagram1_url ||
          offerData?.technical_data?.litfing_cap_diagram2_url ||
          offerData?.technical_data?.default_arm_drawing_url ||
          offerData?.technical_data?.stabilizer_drawing_url ||
          offerData?.technical_data?.technical_table)
      "
    >
      {{ 'offer_technical_data_headline' | translate }}
    </button>
    <button
      mat-menu-item
      (click)="scrollToElement('termsAndConditions')"
      *ngIf="
        offerData?.terms_and_conditions &&
        offerData?.options?.include_offer_and_delivery_terms
      "
    >
      {{ 'offer_terms_and_conditions_headline' | translate }}
    </button>
  </mat-menu>
</ds-full-page>
