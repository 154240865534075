import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Reason } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectReasonTranslation',
  standalone: false,
})
export class SlProjectReasonTranslationPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}

  transform(reason: Reason): string {
    const key = 'edit_lost_order.reasons.' + reason.id;
    const translateValue = this._translateService.instant(key);

    if (translateValue === key) {
      return reason.name;
    }

    return translateValue;
  }
}
