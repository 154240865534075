import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlCartItem } from '@sales-libs/shared/util';

export interface RelatedRemovalDialogData {
  name: string;
  relatedItems: SlCartItem[];
}

@Component({
  selector: 'sl-project-related-removal-dialog',
  templateUrl: './related-removal-dialog.component.html',
  styleUrls: ['./related-removal-dialog.component.scss'],
  standalone: false,
})
export class SlProjectRelatedRemovalDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: RelatedRemovalDialogData,
  ) {}
}
