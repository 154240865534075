import { Pipe, PipeTransform } from '@angular/core';
import { CartItemCompareCompareDifferenceType } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectCartCompareDifferenceTypeTranslationKey',
  standalone: false,
})
export class SlProjectCartCompareDifferenceTypePipe implements PipeTransform {
  readonly cartDifferenceItemTranslationKeyMapping = {
    [CartItemCompareCompareDifferenceType.Unchanged]:
      'cart_compare.compare_difference_type.unchanged',
    [CartItemCompareCompareDifferenceType.Added]:
      'cart_compare.compare_difference_type.added',
    [CartItemCompareCompareDifferenceType.Changed]:
      'cart_compare.compare_difference_type.changed',
    [CartItemCompareCompareDifferenceType.Removed]:
      'cart_compare.compare_difference_type.removed',
  };

  transform(state: any /* CartItemCompareCompareDifferenceType */): string {
    return this.cartDifferenceItemTranslationKeyMapping[state];
  }
}
