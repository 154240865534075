import { HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SharedContractActions } from '@sales-libs/shared/feature';
import {
  BestInvestDealerParameters,
  BestInvestFeatures,
  BestInvestProjectParameters,
  BestInvestService,
} from '@sales-libs/upselling/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SlUpsellingActions } from './upselling.actions';

@Injectable()
export class SlUpsellingEffects {
  private readonly actions$ = inject(Actions);

  createBestInvestProjectParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedContractActions.createProjectParameters),
      switchMap((projectId) =>
        this.bestInvestService
          .createDefaultBestInvestProjectParameters(projectId.payload)
          .pipe(
            map((data: BestInvestProjectParameters) =>
              SharedContractActions.createProjectParametersSuccess({
                payload: data,
              }),
            ),
            catchError((err) =>
              of(
                SharedContractActions.createProjectParametersError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getBestInvestProjectParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getBestInvestProjectParameters),
      switchMap((payload) =>
        this.bestInvestService
          .getBestInvestProjectParameters(payload.projectId)
          .pipe(
            map((parameters: BestInvestProjectParameters) =>
              SlUpsellingActions.getBestInvestProjectParametersSuccess({
                parameters: parameters,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.getBestInvestProjectParametersError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updateBestInvestProjectParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.updateBestInvestProjectParameters),
      switchMap((payload) =>
        this.bestInvestService
          .updateBestInvestProjectParameters(
            payload.projectId,
            payload.bestInvestProjectParametersInput,
          )
          .pipe(
            map((parameters: BestInvestProjectParameters) =>
              SlUpsellingActions.updateBestInvestProjectParametersSuccess({
                parameters: parameters,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.updateBestInvestProjectParametersError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getBestInvestFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getBestInvestFeatures),
      switchMap((payload) =>
        this.bestInvestService
          .getBestInvestFeatures(
            payload.projectId,
            payload.cartId,
            payload.bestInvestFeaturesInput,
          )
          .pipe(
            map((data: BestInvestFeatures) =>
              SlUpsellingActions.getBestInvestFeaturesSuccess({
                payload: data,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.getBestInvestFeaturesError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createDefaultBestInvestDealerParameters$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SlUpsellingActions.createDefaultBestInvestDealerParameters),
        switchMap(() =>
          this.bestInvestService.createDefaultBestInvestDealerParameters().pipe(
            map((data: BestInvestDealerParameters) =>
              SlUpsellingActions.createDefaultBestInvestDealerParametersSuccess(
                {
                  dealerParameters: data,
                },
              ),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.createDefaultBestInvestDealerParametersError(
                  {
                    payload: err,
                  },
                ),
              ),
            ),
          ),
        ),
      ),
  );

  getBestInvestDealerParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getBestInvestDealerParameters),
      switchMap(() =>
        this.bestInvestService.getBestInvestDealerParameters().pipe(
          map((dealerParameters: BestInvestDealerParameters) =>
            SlUpsellingActions.getBestInvestDealerParametersSuccess({
              dealerParameters: dealerParameters,
            }),
          ),
          catchError((err) => {
            if (err.status === HttpStatusCode.NotFound) {
              return of(
                SlUpsellingActions.createDefaultBestInvestDealerParameters(),
              );
            }
            return of(
              SlUpsellingActions.getBestInvestDealerParametersError({
                payload: err,
              }),
            );
          }),
        ),
      ),
    ),
  );

  updateBestInvestDealerParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.updateBestInvestDealerParameters),
      switchMap((payload) =>
        this.bestInvestService
          .updateBestInvestDealerParameters(
            payload.bestInvestDealerParametersInput,
          )
          .pipe(
            map((dealerParameters: BestInvestDealerParameters) =>
              SlUpsellingActions.updateBestInvestDealerParametersSuccess({
                dealerParameters: dealerParameters,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.updateBestInvestDealerParametersError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  setProjectParametersToDealerDefaults$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.setProjectParametersToDealerDefaults),
      switchMap((payload) =>
        this.bestInvestService
          .setProjectParametersToDealerDefaults(payload.projectId)
          .pipe(
            map((parameters: BestInvestProjectParameters) =>
              SlUpsellingActions.setProjectParametersToDealerDefaultsSuccess({
                parameters: parameters,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.setProjectParametersToDealerDefaultsError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  resetToBestInvestGlobalDealerParameters$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SlUpsellingActions.resetToBestInvestGlobalDealerParameters),
        switchMap(() =>
          this.bestInvestService.getDefaultBestInvestDealerParameters().pipe(
            map((dealerParameters: BestInvestDealerParameters) =>
              SlUpsellingActions.resetToBestInvestGlobalDealerParametersSuccess(
                {
                  dealerParameters: dealerParameters,
                },
              ),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.resetToBestInvestGlobalDealerParametersError(
                  {
                    payload: err,
                  },
                ),
              ),
            ),
          ),
        ),
      ),
  );

  constructor(
    @Inject(BestInvestService)
    private readonly bestInvestService: BestInvestService,
  ) {}
}
