import { testDataCreationProductItem } from './dataCreationProductItem';
import { testDataCreationWarrantyExtensionItem } from './dataCreationWarrantyExtensionItem';
import { testDataCreationCustomArticleItemInput } from './dataCreationCustomArticleItem';
import { testDataCreationSalesOptionItemInput } from './dataCreationSalesOptionItem';
import { testCartItemGroupInput } from '@sales-libs/project/data-access';
import { SlProjectDataCreationOptionalGroup } from '../project-data-creation-dialog';

export const testDataCreationOptionalGroups: SlProjectDataCreationOptionalGroup =
  {
    ...testCartItemGroupInput,
    product_items: [testDataCreationProductItem],
    warranty_extension_items: [testDataCreationWarrantyExtensionItem],
    custom_article_items: [testDataCreationCustomArticleItemInput],
    sales_option_items: [testDataCreationSalesOptionItemInput],
  };
