import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  Cart,
  ProductItem,
  ProductItemProductType,
  ProductItemUpdate,
} from '@sales-libs/project/data-access';
import {
  SlCartItem,
  SlSharedConfigurationOptionsComponent,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, map, take } from 'rxjs';
import { CartActions, CartSelectors } from '../../store';
import {
  RelatedRemovalDialogData,
  SlProjectRelatedRemovalDialogComponent,
} from '../related-removal-dialog/related-removal-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectProductCartItems]',
  templateUrl: './product-cart-items.component.html',
  styleUrls: ['./product-cart-items.component.scss'],
  standalone: false,
})
export class SlProjectProductCartItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;
  @Input() productType: ProductItemProductType;
  @Output() configureItem = new EventEmitter<ProductItem>();

  readonly items$: Observable<ProductItem[] | null | undefined>;

  constructor(
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
  ) {
    this.items$ = this._store.select(CartSelectors.productItems).pipe(
      map((items) => {
        const filteredItems =
          items?.filter((item) => item.product_type === this.productType) ?? [];
        const configureableItems = filteredItems.filter(
          (item) => item.is_configurable,
        );
        const nonConfigureableItems = filteredItems.filter(
          (item) => !item.is_configurable,
        );
        return [
          ...configureableItems.sort((a, b) => a.sort_key - b.sort_key),
          ...nonConfigureableItems.sort((a, b) => a.sort_key - b.sort_key),
        ];
      }),
    );
  }

  onToOptional(item: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateProductItem({
        update: { ...item, is_optional: true } as ProductItemUpdate,
        productType: (item as ProductItem).product_type,
      }),
    );
  }

  onConfigure(item: SlCartItem) {
    this.configureItem.emit(item as ProductItem);
  }

  onQuantityChange(item: SlCartItem): void {
    this._store.dispatch(
      CartActions.UpdateProductItem({
        update: item as ProductItemUpdate,
        productType: (item as ProductItem).product_type,
      }),
    );
  }

  onShowConfiguration(item: SlCartItem, isPricingVisible: boolean): void {
    this._dialog.open(SlSharedConfigurationOptionsComponent, {
      data: {
        name: item.name,
        options: (item as ProductItem).options,
        isPricingVisible: isPricingVisible,
      },
    });
  }

  deleteItem(itemToDelete: SlCartItem): void {
    if (
      (itemToDelete as ProductItem).product_type ===
      ProductItemProductType.Product
    ) {
      this._checkRelatedRemoval(itemToDelete);
    } else {
      this._dispatchDelete(itemToDelete);
    }
  }

  private _checkRelatedRemoval(itemToDelete: SlCartItem) {
    this._store
      .select(CartSelectors.current)
      .pipe(filterTruthy(), take(1))
      .subscribe((cart) => {
        // all these item types could have a dependency to a configurable product item
        // therefore we would need to remove them first
        const relatedItems = [
          ...(cart.contract_items ?? []),
          ...(cart.product_items ?? []).filter(
            (item) => item.is_configurable && item.id !== itemToDelete.id,
          ),
          ...(cart.mcc_items ?? []),
          ...(cart.sales_option_items ?? []),
          ...(cart.tuning_center_items ?? []),
          ...(cart.warranty_extension_items ?? []),
        ];

        if (relatedItems.length > 0) {
          const ref = this._dialog.open(
            SlProjectRelatedRemovalDialogComponent,
            {
              width: '99%', // max width to not cut description
              data: {
                name: itemToDelete.name,
                relatedItems: relatedItems,
              } as RelatedRemovalDialogData,
            },
          );
          ref
            .afterClosed()
            .pipe(take(1))
            .subscribe((isConfirmed) => {
              if (isConfirmed) {
                this._dispatchDeleteForAllRelatedItems(cart, itemToDelete);
                this._dispatchDelete(itemToDelete);
              }
            });
        } else {
          this._dispatchDelete(itemToDelete);
        }
      });
  }

  private _dispatchDelete(itemToDelete: SlCartItem) {
    this._store.dispatch(
      CartActions.DeleteProductItem({
        itemId: itemToDelete.id as string,
        cartId: itemToDelete.cart_id,
      }),
    );
  }

  private _dispatchDeleteForAllRelatedItems(
    cart: Cart,
    itemToDelete: SlCartItem,
  ) {
    (cart.product_items ?? [])
      .filter((item) => item.is_configurable && item.id !== itemToDelete.id)
      .forEach((item) => {
        this._dispatchDelete(item);
      });
    (cart.contract_items ?? []).forEach((item) => {
      this._store.dispatch(
        CartActions.DeleteContractItem({
          itemId: item.id as string,
          cartId: item.cart_id,
        }),
      );
    });
    (cart.mcc_items ?? []).forEach((item) => {
      this._store.dispatch(
        CartActions.DeleteMccItem({
          itemId: item.id as string,
          cartId: item.cart_id,
        }),
      );
    });
    (cart.sales_option_items ?? []).forEach((item) => {
      this._store.dispatch(
        CartActions.DeleteSalesOptionItem({
          itemId: item.id as string,
          cartId: item.cart_id,
        }),
      );
    });
    (cart.tuning_center_items ?? []).forEach((item) => {
      this._store.dispatch(
        CartActions.DeleteTuningCenterItem({
          itemId: item.id as string,
          cartId: item.cart_id,
        }),
      );
    });
    (cart.warranty_extension_items ?? []).forEach((item) => {
      this._store.dispatch(
        CartActions.DeleteWarrantyExtensionItem({
          itemId: item.id as string,
          cartId: item.cart_id,
        }),
      );
    });
  }
}
