<ng-container matColumnDef="customer">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="customer">
    {{ 'table_columns.customer' | translate }}
  </th>
  <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let project">
    <a
      *ngIf="
        project.customer_name ||
          project.customer_number ||
          project.external_customer_number;
        else noCustomer
      "
      (click)="showCustomer.emit(project)"
      [gaClickListener]="{
        category: 'ProjectOverview',
        label: 'ProjectList',
        action: 'CustomerNameClick'
      }"
      >{{
        project.customer_name ||
          project.customer_number ||
          project.external_customer_number
      }}</a
    >
    <ng-template #noCustomer>
      {{ 'general.not_available' | translate }}
    </ng-template>
  </td>
</ng-container>
