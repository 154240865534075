import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-configuration-state-column',
  templateUrl: './configuration-state-column.component.html',
  styleUrls: ['./configuration-state-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectConfigurationStateColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.itemAsProductItem = value as ProductItem;
  }

  itemAsProductItem: ProductItem;
}
