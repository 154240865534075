import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CartCompare, CartsService } from '@sales-libs/project/data-access';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'sl-project-cart-compare',
  templateUrl: './cart-compare.component.html',
  styleUrls: ['./cart-compare.component.scss'],
  standalone: false,
})
export class SlProjectCartCompareComponent implements OnInit {
  data: CartCompare;
  isLoading = true;

  @ViewChild(MatSlideToggle, { static: false })
  showTextChangeToggle: MatSlideToggle | undefined;

  constructor(
    private readonly _cartsService: CartsService,
    @Inject(MAT_DIALOG_DATA) public cartId: number,
  ) {}

  ngOnInit() {
    this._getComparison();
  }

  onTryAgainClick() {
    this._getComparison();
  }

  private _getComparison() {
    this.isLoading = true;
    this._cartsService
      .getCartComparison(this.cartId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.data = data));
  }
}
