import {
  CartItemsService,
  WarrantyExtensionItemInput,
} from '@sales-libs/project/data-access';
import { SlProjectWarrantyExtensionItemCreationRow } from '../rows/warranty-extension-item-creation-row';

export class SlProjectWarrantyExtensionItemCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: WarrantyExtensionItemInput) {
    return new SlProjectWarrantyExtensionItemCreationRow(
      item,
      this._cartItemsService,
    );
  }
}
