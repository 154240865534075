<ds-filter
  [formGroup]="filtersForm"
  (resetFilter)="filtersForm.reset()"
  gaLabelGroup="LostOrderFilters"
  gaCategoryGroup="LostOrderOverview"
>
  <mat-form-field
    gaClickListener="TermClick"
    *dsFilterItem="filtersForm.controls.term"
  >
    <mat-label>{{ 'common.search' | translate }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="filtersForm.controls.term"
      autocomplete="off"
      data-test="lost-order-filter-term"
    />
    <button
      mat-icon-button
      matIconSuffix
      type="button"
      [attr.aria-label]="'common.clear' | translate"
      *ngIf="filtersForm.controls.term.value !== ''"
      (click)="filtersForm.controls.term.reset('')"
      data-test="filter-reset-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field
    gaClickListener="ReasonClick"
    *dsFilterItem="filtersForm.controls.reason"
  >
    <mat-label> {{ 'table_columns.reason' | translate }} </mat-label>
    <input
      matInput
      autocomplete="off"
      [formControl]="filtersForm.controls.reason"
      data-test="reason-input"
    />
  </mat-form-field>
  <mat-form-field
    gaClickListener="CompetitorClick"
    *dsFilterItem="filtersForm.controls.competitor"
  >
    <mat-label> {{ 'table_columns.competitor' | translate }} </mat-label>
    <input
      matInput
      autocomplete="off"
      [formControl]="filtersForm.controls.competitor"
      data-test="competitor-input"
    />
  </mat-form-field>

  <mat-form-field
    gaClickListener="CreatedDateClick"
    *dsFilterItem="filtersForm.controls.createdDate"
  >
    <mat-label>{{ 'project_filters.from' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="startDatepicker"
      autocomplete="off"
      [formControl]="filtersForm.controls.createdDate"
      data-test="created-date-input"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="startDatepicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #startDatepicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    gaClickListener="TillDateClick"
    *dsFilterItem="filtersForm.controls.endDate"
  >
    <mat-label>{{ 'project_filters.till' | translate }}</mat-label>
    <input
      matInput
      autocomplete="off"
      [matDatepicker]="endDatepicker"
      [formControl]="filtersForm.controls.endDate"
      data-test="end-date-input"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="endDatepicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #endDatepicker></mat-datepicker>
  </mat-form-field>
</ds-filter>
