<ds-placeholder [type]="'remove_shopping_cart'" *ngIf="project">
  {{ 'checkout.checkout_only_possible_with_main_product' | translate }}
  <br />
  <ds-text-indicator
    *ngIf="project.state === projectStates.InProgress"
    data-test="project-done-hint"
    >{{ 'checkout.project_done_hint' | translate }}</ds-text-indicator
  >
  <ng-container *ngIf="project.state !== projectStates.InProgress">
    <br />
    <ds-text-indicator
      type="success"
      *ngIf="
        project.state === projectStates.Won ||
        project.state === projectStates.Closed
      "
      data-test="project-won-or-closed-hint"
      >{{ 'checkout.project_was_won' | translate }}</ds-text-indicator
    >
    <ds-text-indicator
      type="error"
      *ngIf="project.state === projectStates.Lost"
      data-test="project-lost-hint"
      >{{ 'checkout.project_was_lost' | translate }}</ds-text-indicator
    >
  </ng-container>

  <br />

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [gaClickListener]="{
      category: 'ProjectCart',
      action: 'ConfirmClick',
      label: 'CheckoutWithoutOrder'
    }"
    *ngIf="project.state === projectStates.InProgress"
    (click)="setProjectToDone.emit()"
    data-test="project-done-button"
  >
    {{ 'checkout.project_done' | translate }}
  </button>
</ds-placeholder>
