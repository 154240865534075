import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  LostOrderView,
  LostOrderViewContainer,
} from '@sales-libs/project/data-access';
import { LostOrderFilters } from '../../models';
import { Page } from '../projects';
import { LostOrderListActions } from './lost-order-list.action';

export interface LostOrderListState extends EntityState<LostOrderView> {
  id: number | null;
  loading: boolean;
  filters: LostOrderFilters;
  pagination: LostOrderListPaginationState;
  sort?: Sort;
  currentLostOrder?: LostOrderView;
  hasLoadingError: boolean;
  exportUrl?: string;
  hasExportError: boolean;
  isFiltersLoading: boolean;
  filterLostOrders?: LostOrderViewContainer;
}

export interface LostOrderListPaginationState
  extends EntityState<Page>,
    PageEvent {}

export const lostOrderAdapter: EntityAdapter<LostOrderView> =
  createEntityAdapter<LostOrderView>({
    selectId: (entity) => entity.project_id,
  });
export const pageAdapterLostOrder: EntityAdapter<Page> =
  createEntityAdapter<Page>({
    selectId: (entity) => entity.index,
  });

export const initialLostOrderListState: LostOrderListState =
  lostOrderAdapter.getInitialState({
    id: null,
    loading: false,
    filters: {
      term: '',
      reason: undefined,
      competitor: undefined,
      createdDate: undefined,
      endDate: undefined,
    },
    pagination: pageAdapterLostOrder.getInitialState({
      pageIndex: 0,
      pageSize: 10,
      length: 0,
    }),
    sort: {
      active: 'created_date',
      direction: 'desc',
    },
    currentLostOrder: undefined,

    hasLoadingError: false,
    hasExportError: false,
    isFiltersLoading: false,
  });

const reducer = createReducer(
  initialLostOrderListState,
  on(
    LostOrderListActions.LoadLostOrders,
    LostOrderListActions.SearchLostOrders,
    (state) => ({
      ...state,
      loading: true,
      hasLoadingError: false,
    }),
  ),

  on(LostOrderListActions.LoadLostOrdersSuccess, (state, { payload }) => ({
    ...lostOrderAdapter.upsertMany(payload.items, state),
    loading: false,
    pagination: {
      ...state.pagination,
      ...pageAdapterLostOrder.upsertOne(
        {
          index: state.pagination.pageIndex,
          ids: payload.items.map((lostOrder) => lostOrder.project_id),
        },
        state.pagination,
      ),
      length: payload.count,
    },
  })),

  on(LostOrderListActions.PageChange, (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...payload,
    },
  })),

  on(LostOrderListActions.SortChange, (state, { payload }) => ({
    ...state,
    sort: payload,
    pagination: {
      ...state.pagination,
      pageIndex: 0,
    },
  })),
  on(LostOrderListActions.FilterChange, (state, { payload }) => ({
    ...state,
    filters: payload,
    pagination: {
      ...state.pagination,
      pageIndex: 0,
    },
  })),
  on(LostOrderListActions.ClearFilter, (state) => ({
    ...state,
    filters: initialLostOrderListState.filters,
    sort: undefined,
  })),
  on(LostOrderListActions.GetExportSuccess, (state, { payload }) => ({
    ...state,
    exportUrl: payload.url || '',
  })),
  on(LostOrderListActions.GetExportError, (state) => ({
    ...state,
    hasExportError: true,
  })),
  on(LostOrderListActions.ResetExportState, (state) => ({
    ...state,
    exportUrl: undefined,
    hasExportError: false,
  })),
);

export function lostOrderListReducer(
  state: LostOrderListState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
