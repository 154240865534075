import { Pipe, PipeTransform } from '@angular/core';
import { ContractItemConnectedType } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectContractTypeTranslationKey',
  standalone: false,
})
export class SlProjectConnectedTypePipe implements PipeTransform {
  readonly ContractTranslationKeyMapping = {
    [ContractItemConnectedType.Plus]: 'contract.connected_type.plus',
    [ContractItemConnectedType.Data]: 'contract.connected_type.data',
    [ContractItemConnectedType.Premium]: 'contract.connected_type.premium',
  };

  transform(type: ContractItemConnectedType): string {
    return this.ContractTranslationKeyMapping[type];
  }
}
