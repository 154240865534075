import {
  CartItemsService,
  CustomArticleItemInput,
} from '@sales-libs/project/data-access';
import { SlProjectCustomArticleItemCreationRow } from '../rows/custom-article-item-creation-row';

export class SlProjectCustomArticleItemCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: CustomArticleItemInput) {
    return new SlProjectCustomArticleItemCreationRow(
      item,
      this._cartItemsService,
    );
  }
}
