import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { dsConfig } from '@design-system/cdk/config';
import { TranslateModule } from '@ngx-translate/core';
import { SlUpsellingUtilizationDialogComponent } from '../utilization-dialog/utilization-dialog.component';

@Component({
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
  selector: 'sl-upselling-utilization-button',
  templateUrl: './utilization-button.component.html',
  styleUrls: ['./utilization-button.component.scss'],
})
export class SlUpsellingUtilizationButtonComponent {
  @Input()
  projectId: number;
  @Input()
  isReadOnly: boolean;

  constructor(private _dialog: MatDialog) {}

  openUtilizationDialog() {
    this._dialog.open(SlUpsellingUtilizationDialogComponent, {
      width: 30 * dsConfig.spacing + 'px',
      data: { projectId: this.projectId, isReadOnly: this.isReadOnly },
    });
  }
}
