import { Action, createReducer, on } from '@ngrx/store';
import { ProjectDocument } from '@sales-libs/project/data-access';
import { DocumentsActions } from './documents.actions';

export interface DocumentsState {
  filter: DocumentsFilterState;
  loading: boolean;
  projectId?: number;
  list?: ProjectDocument[];
  authorization: string;
}
export interface DocumentsFilterState {
  term: string;
}
export const initialDocumentsState: DocumentsState = {
  filter: {
    term: '',
  },
  loading: false,
  projectId: undefined,
  list: undefined,
  authorization: '',
};

const reducer = createReducer(
  initialDocumentsState,
  on(DocumentsActions.LoadDocuments, (state, { payload }) => ({
    ...state,
    loading: true,
    projectId: payload,
    list: state.projectId === payload ? state.list : initialDocumentsState.list,
  })),
  on(DocumentsActions.RefreshDocuments, (state) => ({
    ...state,
    loading: true,
  })),
  on(DocumentsActions.LoadDocumentsSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    list: payload.items,
  })),
  on(DocumentsActions.ChangeFilter, (state, { payload }) => ({
    ...state,
    filter: payload,
  })),
);

export function documentsReducer(
  state: DocumentsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
