<ng-container gaLabelGroup="CheckoutDocuments" gaCategoryGroup="Project">
  <form [formGroup]="form">
    <h6 gaClickListener="TitleClick" data-test="title">
      {{ 'documents.documents' | translate }}
    </h6>
    <ds-table>
      <ng-container *ngIf="documents$ | async as documents; else loading">
        <table
          mat-table
          [dataSource]="documents"
          *ngIf="documents?.length; else noData"
        >
          <caption hidden>
            {{
              'documents.documents' | translate
            }}
          </caption>
          <!-- file name -->
          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef id="name">
              {{ 'documents.name' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              gaClickListener="NameClick"
              data-test="file-name"
            >
              {{ document.file_name }}
            </td>
          </ng-container>

          <!-- actions -->
          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef id="actions">
              <button
                mat-icon-button
                [matTooltip]="'documents.add_document' | translate"
                gaClickListener="UploadDocumentClick"
                data-test="upload-document-button"
                class="sl-file-select-button"
              >
                <mat-icon>cloud_upload</mat-icon>
                <sl-shared-file-select
                  type="msOffice"
                  (fileSelect)="onFileSelect($event)"
                ></sl-shared-file-select>
              </button>
            </th>
            <td mat-cell *matCellDef="let document" class="mat-action-cell">
              <div fxLayout>
                <a
                  mat-icon-button
                  *ngIf="document.file_url"
                  [href]="document.file_url"
                  target="_blank"
                  download
                  data-test="download-button"
                  [matTooltip]="'documents.download' | translate"
                  gaClickListener="DownloadDocumentClick"
                >
                  <mat-icon>cloud_download</mat-icon>
                </a>
                <button
                  mat-icon-button
                  (click)="deleteDocument(document.id)"
                  [matTooltip]="'common.delete' | translate"
                  data-test="delete-button"
                  gaClickListener="DeleteDocumentClick"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let document; columns: displayedColumns"
            data-test="checkout-document"
          ></tr>
        </table>
      </ng-container>
    </ds-table>
  </form>

  <ng-template #loading>
    <ds-table-loading
      [numberOfHeaders]="4"
      [numberOfRows]="3"
      gaClickListener="LoadingClick"
    >
    </ds-table-loading>
  </ng-template>

  <ng-template #noData>
    <mat-card class="text-center">
      <mat-card-content>
        <ds-placeholder
          type="no_data"
          [customHeadline]="'documents.no_documents' | translate"
          gaClickListener="PlaceholderClick"
        >
        </ds-placeholder>
        <button
          mat-button
          gaClickListener="AddClick"
          data-test="upload-document-button"
          class="sl-file-select-button"
        >
          <mat-icon>add</mat-icon>
          {{ 'documents.add_document' | translate }}
          <sl-shared-file-select
            data-test="add-document"
            type="msOffice"
            (fileSelect)="onFileSelect($event)"
          ></sl-shared-file-select>
        </button>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
