import {
  CartItemsService,
  ProductItemInput,
} from '@sales-libs/project/data-access';
import { SlProjectProductItemCreationRow } from '../rows/product-item-creation-row';

export class SlProjectProductItemCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: ProductItemInput) {
    return new SlProjectProductItemCreationRow(item, this._cartItemsService);
  }
}
