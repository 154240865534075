import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { filterTruthy } from '@shared-lib/rxjs';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-final-price-input-column',
  templateUrl: './final-price-input-column.component.html',
  styleUrls: ['./final-price-input-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectFinalPriceInputColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() currencySymbol: string;
  @Input() currencyRatio: number;
  @Input() currencyRoundingDigits: number;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this.formControl.setValue(null, { emitEvent: false });
  }
  @Output() calculationChange = new EventEmitter<Calculation>();

  readonly triggerBlur = triggerBlur;

  formControl = new FormControl<number | null>(null, { updateOn: 'blur' });
  private _calculation: Calculation;
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }

    this.formDirective.form.addControl(
      this.perspective + 'FinalPrice',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(filterTruthy(), takeUntil(this.destroy$))
      .subscribe((value) =>
        this.calculationChange.emit({
          ...this._calculation,
          [this.perspective]: {
            ...this._calculation[this.perspective],
            absolute_discount:
              (CalculationUtils.getFinalPrice(
                this._calculation,
                this.perspective,
                this.isAlternativeView,
                this.currencyRatio,
                this.currencyRoundingDigits,
              ) -
                value +
                this._calculation[this.perspective].absolute_discount *
                  this.currencyRatio) /
              this.currencyRatio,
          },
        }),
      );
  }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
