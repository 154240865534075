<ng-container
  gaLabelGroup="MccDialog"
  gaCategoryGroup="Projects"
  *ngIf="cartId$ | async as cartId"
>
  <h3 matDialogTitle gaClickListener="TitleClick" data-test="mcc-headline">
    {{ 'mcc.title' | translate }}
  </h3>
  <form
    [formGroup]="form"
    (ngSubmit)="confirm(cartId, ratio)"
    *ngIf="ratio$ | async as ratio"
  >
    <mat-dialog-content fxLayout="column">
      <h5>{{ 'mcc.mounting' | translate }}</h5>

      <mat-form-field
        class="full-width"
        gaClickListener="MccMountingOptionsClick"
      >
        <mat-label>{{ 'mcc.cit' | translate }}</mat-label>
        <mat-select
          data-test="mounting-option-select"
          class="full-width"
          [formControl]="form.controls.cit_material"
          required
        >
          <ng-container
            *ngIf="
              mountingCatergory$ | async as mountingCatergories;
              selectLoading
            "
          >
            <mat-option
              *ngFor="let catergory of mountingCatergories.items"
              [value]="catergory.material"
              >{{ catergory.name }}</mat-option
            >
          </ng-container>
        </mat-select>
        <mat-error *ngIf="form.controls.cit_material.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" gaClickListener="MccNumberClick">
        <mat-label>{{ 'mcc.mcc_number' | translate }}</mat-label>
        <textarea
          required
          matInput
          [formControl]="form.controls.short_description"
          data-test="short-description-input"
        ></textarea>
        <mat-error *ngIf="form.controls.short_description.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>

      <h5>{{ 'mcc.trucks' | translate }}</h5>

      <mat-form-field class="full-width" gaClickListener="MccTruckOptionsClick">
        <mat-label>{{ 'mcc.trucks' | translate }}</mat-label>
        <mat-select
          class="full-width"
          [formControl]="form.controls.truck_material"
          data-test="truck-select"
        >
          <ng-container *ngIf="truck$ | async as trucks; selectLoading">
            <mat-option [value]="null">-</mat-option>
            <mat-option
              *ngFor="let truck of trucks.items"
              [value]="truck.material"
              >{{ truck.name }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="form.controls.truck_material.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
      <div
        *ngIf="form.controls.truck_material.value"
        [@transitionMessages]="
          !!form.controls.truck_material.value ? 'enter' : 'void'
        "
      >
        <mat-form-field
          data-test="order-number-form-field"
          class="full-width"
          gaClickListener="OrderNumberClick"
        >
          <mat-label>{{ 'mcc.order_number' | translate }}</mat-label>
          <textarea
            data-test="long-description-input"
            matInput
            [formControl]="form.controls.long_description"
          ></textarea>
        </mat-form-field>
      </div>
      <div fxLayout fxLayoutGap="1rem" fxLayout.xs="column">
        <mat-form-field class="full-width" gaClickListener="RetailPriceClick">
          <mat-label>{{ 'mcc.retail_price' | translate }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="form.controls.sales_price"
            [max]="1000000000"
            required
            autocomplete="off"
            data-test="retail-price-input"
          />
          <mat-error *ngIf="form.controls.sales_price.invalid">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          *ngIf="isUserWithPurchasingGpRole"
          class="full-width"
          gaClickListener="RetailPriceClick"
        >
          <mat-label>{{ 'mcc.purchasing_price' | translate }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="form.controls.purchase_price"
            [max]="1000000000"
            required
            autocomplete="off"
            data-test="purchase-price-input"
          />
          <mat-error *ngIf="form.controls.purchase_price.invalid">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <sl-project-checkout-documents
        [isMcc]="true"
        #checkoutDocuments
        gaClickListener="DocumentsClick"
      ></sl-project-checkout-documents>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        type="button"
        (click)="cancel(checkoutDocuments.documentIds)"
        gaClickListener="CancelClick"
      >
        {{ 'general.cancel' | translate }}
      </button>

      <button
        data-test="confirm-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!form.valid"
        [gaClickListener]="mccItem ? 'SaveClick' : 'AddToCartClick'"
      >
        <ng-container *ngIf="!mccItem">
          {{ 'project.add_to_cart' | translate }}
        </ng-container>
        <ng-container *ngIf="mccItem">
          {{ 'general.save' | translate }}
        </ng-container>
      </button>
    </mat-dialog-actions>
  </form>
  <ng-template #selectLoading>
    <ds-loading-placeholder
      [height]="2.5 | dsSpacing"
      [width]="11.75 | dsSpacing"
    >
    </ds-loading-placeholder>
  </ng-template>
</ng-container>
