<h3 mat-dialog-title data-test="title">
  {{ 'price_difference.headline' | translate }}
  {{ data.item | slCartItemName }}
</h3>
<mat-dialog-content *ngIf="data.selectableItems?.length; else noData">
  <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
    <ds-text-indicator type="warning" *ngIf="data.isOption">{{
      'price_difference.options_disclaimer' | translate
    }}</ds-text-indicator>
    <sl-project-cart-item-list
      *ngIf="data.selectableItems"
      [items]="data.selectableItems"
      [isSelectOnly]="true"
      (selectItem)="selected = $event"
    ></sl-project-cart-item-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    class="full-width"
    [fxLayoutGap]="1 | dsSpacing"
  >
    <div
      fxLayout
      [fxLayoutGap]="1 | dsSpacing"
      fxLayoutAlign="space-between center"
    >
      <span *ngIf="!selected; else priceCalculation">{{
        'price_difference.info' | translate
      }}</span>
      <ng-template #priceCalculation>
        <div pair>
          <span title>{{ data.item | slCartItemName }}</span
          ><span>
            {{
              data.item.sales_price
                | slMoneyAmount$
                  : {
                      display: 'code',
                      nullValue: ('configuration.zero-price' | translate)
                    }
                | async
            }}
          </span>
        </div>
        <div pair>
          <span title>{{ selected | slCartItemName }}</span
          ><span>
            {{
              selected.sales_price
                | slMoneyAmount$
                  : {
                      display: 'code',
                      nullValue: ('configuration.zero-price' | translate)
                    }
                | async
            }}
          </span>
        </div>
        <div pair>
          <span title>{{ 'price_difference.new_price' | translate }}</span
          ><span>
            {{
              data.item.sales_price - selected.sales_price
                | slMoneyAmount$
                  : {
                      display: 'code',
                      nullValue: ('configuration.zero-price' | translate)
                    }
                | async
            }}
          </span>
        </div>
      </ng-template>
    </div>
    <div gaLabelGroup="PriceDifferenceDialogActions">
      <button
        mat-button
        type="button"
        mat-dialog-close
        gaClickListener="CancelClick"
        data-test="cancel-button"
      >
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!selected"
        (click)="confirm.emit(selected)"
        gaClickListener="ConfirmClick"
        date-test="confirm-button"
      >
        {{ 'common.confirm' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>

<ng-template #noData>
  <ds-placeholder
    type="no_data"
    [customHeadline]="'price_difference.no_data' | translate"
  ></ds-placeholder>
</ng-template>
