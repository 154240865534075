import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  Competitor,
  Currency,
  LostOrder,
  Project,
  Reason,
} from '@sales-libs/project/data-access';
import { PalfingerValidators } from '@utils/palfinger-validators';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartSelectors } from '../store';

export interface SlProjectEditLostOrderDialogData {
  lostOrder$: Observable<LostOrder | null>;
  project: Project;
  competitors$: Observable<Competitor[] | null>;
  reasons$: Observable<Reason[] | null>;
}

@Component({
  selector: 'sl-project-edit-lost-order',
  templateUrl: './edit-lost-order.component.html',
  styleUrls: ['./edit-lost-order.component.scss'],
  standalone: false,
})
export class SlProjectEditLostOrderComponent implements OnInit, OnDestroy {
  @Output()
  readonly confirm = new EventEmitter<LostOrder>();

  project: Project;
  lostOrder: LostOrder;
  competitors: Competitor[] | null;
  reasons: Reason[] | null;
  currency$: Observable<Currency | undefined>;

  readonly form: FormGroup<LostOrderForm>;
  readonly currentDate = new Date();
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _store: Store,
    @Inject(MAT_DIALOG_DATA) readonly data: SlProjectEditLostOrderDialogData,
  ) {
    this.form = new FormGroup<LostOrderForm>(
      {
        closed_date: new FormControl<Date | null>(this.currentDate, {
          validators: [
            Validators.required,
            PalfingerValidators.minDate(this.currentDate),
          ],
        }),
        amount: new FormControl<number | null>(null, [Validators.required]),
        reason_id: new FormControl<number | undefined | null>(
          undefined,
          Validators.required,
        ),
        competitor_id: new FormControl<number | undefined | null>(undefined),
        competitor_type: new FormControl<string | undefined | null>(undefined),
        competitor_price: new FormControl<number | undefined | null>(undefined),
        comment: new FormControl<string | undefined | null>(undefined),
      },
      this._validateCompetitor,
    );
    this.project = data.project;

    this.currency$ = this._store.select(CartSelectors.currency);
  }

  ngOnInit() {
    this.data.lostOrder$
      .pipe(takeUntil(this._destroy$))
      .subscribe((lostOrder) => {
        if (lostOrder) {
          this.lostOrder = {
            ...lostOrder,
            project_id: this.project.id,
          };

          this.form.patchValue(lostOrder);
        }
      });
    this.data.competitors$
      .pipe(takeUntil(this._destroy$))
      .subscribe((competitors) => (this.competitors = competitors));
    this.data.reasons$
      .pipe(takeUntil(this._destroy$))
      .subscribe((reasons) => (this.reasons = reasons));
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  reasonRequiresCompetitor() {
    const reasonId = this.form.controls.reason_id.value;
    if (this.reasons && reasonId) {
      const reason = this.reasons.find((x) => x.id === +reasonId);
      if (reason && reason.competitor_required) {
        return true;
      }
    }
    return false;
  }

  confirmChange() {
    this.confirm.emit({ ...this.lostOrder, ...this.form.value } as LostOrder);
  }

  private _validateCompetitor = (value: AbstractControl) => {
    const form = value as FormGroup<LostOrderForm>;
    const reasonIdForm = form.controls.reason_id;
    const reasonId = reasonIdForm.value && +reasonIdForm.value;
    if (reasonId && this.reasons) {
      const reason = this.reasons.find((x) => x.id === reasonId);
      // if it is not required or we have a value
      if (
        (reason && !reason.competitor_required) ||
        form.controls.competitor_id.value
      ) {
        // return no error = 'valid'
        return null;
      }
      // return not valid if we haven't return by now
      return {
        validateCompetitor: {
          valid: false,
        },
      };
    }
    // if no reason selected this is not required yet
    return null;
  };
}

interface LostOrderForm {
  closed_date: FormControl<Date | null>;
  amount: FormControl<number | null>;
  reason_id: FormControl<number | undefined | null>;
  competitor_id: FormControl<number | undefined | null>;
  competitor_type: FormControl<string | undefined | null>;
  competitor_price: FormControl<number | undefined | null>;
  comment: FormControl<string | undefined | null>;
}
