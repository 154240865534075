<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef id="state">
    {{ 'project.status' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'StateClick'
    }"
    data-test="project-list-state"
  >
    <span
      [ngClass]="{
        'error-text': project.state === states.Lost,
        'success-text':
          project.state === states.Won || project.state === states.Closed
      }"
      >{{ project.state | slProjectStateTranslationKey | translate }}</span
    >
  </td>
</ng-container>
