<ng-container gaLabelGroup="GroupSelection" gaCategoryGroup="Project">
  <h3 matDialogTitle>
    {{ data?.item?.name }} {{ 'project_detail.group_selection' | translate }}
  </h3>
  <mat-dialog-content>
    <mat-form-field
      class="full-width"
      gaClickListener="SelectClick"
      *ngIf="data?.groups?.length; else noGroupsInfo"
    >
      <mat-select [formControl]="formControl">
        <mat-option *ngFor="let item of data.groups || []" [value]="item">
          {{ item.name || '-' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button matDialogClose gaClickListener="CancelClick">
      {{ 'general.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onConfirm()"
      gaClickListener="ConfirmClick"
    >
      {{ 'general.confirm' | translate }}
    </button>
  </mat-dialog-actions>

  <ng-template #noGroupsInfo>
    <ds-placeholder
      type="no_data"
      [customHeadline]="'project_detail.no_groups' | translate"
    >
      <p>{{ 'project_detail.no_groups_hint' | translate }}</p>
    </ds-placeholder>
  </ng-template>
</ng-container>
