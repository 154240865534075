import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { LostOrderFilters } from '../../models';

@Component({
  selector: 'sl-project-lost-order-filter',
  templateUrl: './lost-order-filter.component.html',
  styleUrls: ['./lost-order-filter.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderFilterComponent implements OnInit, OnDestroy {
  @Input()
  set filterValues(value: LostOrderFilters) {
    this.filtersForm.patchValue(value, { emitEvent: false });
  }

  @Output()
  readonly filter = new EventEmitter<LostOrderFilters>();
  @Output()
  readonly clearFilter = new EventEmitter();

  readonly filtersForm = new FormGroup<LostOrderFiltersForm>({
    term: new FormControl<string>('', { nonNullable: true }),
    reason: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    competitor: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    createdDate: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
    }),
    endDate: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
    }),
  });

  private readonly _destroy$ = new Subject<void>();

  ngOnInit() {
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe((value) =>
        this.filter.emit({
          ...value,
          startDate: value.createdDate,
          endDate: value.endDate,
        } as LostOrderFilters),
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

interface LostOrderFiltersForm {
  term: FormControl<string>;
  reason: FormControl<string | undefined>;
  competitor: FormControl<string | undefined>;
  createdDate: FormControl<Date | undefined>;
  endDate: FormControl<Date | undefined>;
}
