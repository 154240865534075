import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTableModule } from '@design-system/components/table';
import { TranslateModule } from '@ngx-translate/core';
import { Model, Option } from '../models/modelsComparison';

@Component({
  selector: 'sl-project-model-comparison-table',
  imports: [
    CommonModule,
    FormsModule,
    DsTableModule,
    DsSpacingModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    TranslateModule,
  ],
  templateUrl: './model-comparison-table.component.html',
  styleUrl: './model-comparison-table.component.scss',
})
export class SlProjectModelComparisonTableComponent {
  @Input() heading1 = '';
  @Input() heading2 = '';
  @Input() models: Model[] = [];
  @Input() options: Option[] = [];

  @Output() configureModel = new EventEmitter<Model>();
  @Output() removeModel = new EventEmitter<Model>();

  showOnlyDifferences = false;
  value: string | number;

  getFormattedOptionValue(model: Model, option: Option): string {
    const value = model.options?.[option.label] ?? '';
    return value ? `${value}${option.unit ?? ''}` : '-';
  }

  hasModelDifferences(option: Option): boolean {
    if (!this.showOnlyDifferences || this.models.length <= 1) {
      return true;
    }

    const values = this.models.map((model) => model.options?.[option.label]);
    const firstValue = values[0];
    return values.some((value) => value !== firstValue);
  }
}
