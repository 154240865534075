<ds-table
  class="no-min-height"
  *ngIf="
    filteredItems && filteredItems.length > 0;
    else onlyTextChangesPlaceholder
  "
>
  <table mat-table [dataSource]="filteredItems">
    <!-----Position Column-->
    <ng-container matColumnDef="image_url">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cart_compare.column_headline_postion' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'error-ribbon':
            element.compare_difference_type === compareDifferenceType.Removed,
          'success-ribbon':
            element.compare_difference_type === compareDifferenceType.Added,
          'warning-ribbon':
            element.compare_difference_type === compareDifferenceType.Changed
        }"
        class="text-center"
        data-test="position-cell"
      >
        <img [src]="element.image_url" *ngIf="element.image_url" />
        <div *ngIf="!element.image_url">
          {{ 'general.not_available' | translate }}
        </div>
      </td>
    </ng-container>

    <!-----Name Column------>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cart_compare.column_headline_name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" data-test="cell-name">
        <div
          [ngClass]="{
            'text-line-through':
              element.compare_difference_type ===
                compareDifferenceType.Removed || element.changes?.name,
            'error-text':
              element.compare_difference_type === compareDifferenceType.Removed,
            'success-text':
              element.compare_difference_type === compareDifferenceType.Added
          }"
          data-test="original-name"
        >
          {{ element.name }}
        </div>
        {{ element.changes?.name }}
      </td>
    </ng-container>

    <!-----Change Column----->
    <ng-container matColumnDef="compare_difference_type">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cart_compare.column_headline_change' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'error-text':
            element.compare_difference_type === compareDifferenceType.Removed,
          'success-text':
            element.compare_difference_type === compareDifferenceType.Added,
          'warning-text':
            element.compare_difference_type === compareDifferenceType.Changed
        }"
        data-test="change-cell"
      >
        {{
          element.compare_difference_type
            | slProjectCartCompareDifferenceTypeTranslationKey
            | translate
        }}
      </td>
    </ng-container>

    <!------Type Column------>
    <ng-container matColumnDef="cart_item_type">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cart_compare.column_headline_type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.cart_item_type
            | slProjectCartItemTypeTranslationKey
            | translate
        }}
        <ng-container *ngIf="element.is_optional">
          <span data-test="optional-info" [spotlight]="optionalItemSpotlight">
            ({{ 'configuration.optional' | translate
            }}<ng-container *ngIf="element.is_edited">
              |
              <span class="info-text" data-test="edited-info">
                {{ 'project.edited' | translate }}</span
              > </ng-container
            ><span>)</span>
          </span>
          <ds-spotlight
            #optionalItemSpotlight
            id="cpq-copy-compare-table-optional-items-spotlight"
          >
            {{
              'spotlight.release_2022_06_01.optionalItemSpotlight' | translate
            }}
          </ds-spotlight>
        </ng-container>
      </td>
    </ng-container>

    <!------Short Description Column----->
    <ng-container matColumnDef="short_description">
      <th
        mat-header-cell
        *matHeaderCellDef
        data-test="short-description-header"
      >
        {{ 'cart_compare.column_headline_short_description' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" data-test="cell_short_description">
        <div
          [ngClass]="{
            'text-line-through':
              element.compare_difference_type ===
                compareDifferenceType.Removed ||
              element.changes?.short_description,
            'error-text':
              element.compare_difference_type === compareDifferenceType.Removed,
            'success-text':
              element.compare_difference_type === compareDifferenceType.Added,
            'warning-text': element.changes?.short_description
          }"
          data-test="short-description-cell"
        >
          {{ element.short_description }}
        </div>
        {{ element.changes?.short_description }}
      </td>
    </ng-container>

    <!------Long Description Column----->
    <ng-container matColumnDef="long_description">
      <th mat-header-cell *matHeaderCellDef data-test="long-description-header">
        {{ 'cart_compare.column_headline_long_description' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div
          [ngClass]="{
            'text-line-through':
              element.compare_difference_type ===
                compareDifferenceType.Removed ||
              element.changes?.long_description,
            'error-text':
              element.compare_difference_type === compareDifferenceType.Removed,
            'success-text':
              element.compare_difference_type === compareDifferenceType.Added,
            'warning-text': element.changes?.long_description
          }"
          data-test="original-long-description"
          [innerHTML]="element.long_description"
        ></div>
        <div
          data-test="cell-long-description"
          [innerHTML]="element.changes?.long_description"
        ></div>
      </td>
    </ng-container>

    <!-------Sales Price Column--------->
    <ng-container matColumnDef="sales_price" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cart_compare.column_headline_sales_price' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="text-right"
        data-test="cell-sales-price"
      >
        <div
          [ngClass]="{
            'text-line-through':
              element.compare_difference_type ===
                compareDifferenceType.Removed ||
              !!element.changes?.sales_price ||
              element.changes?.sales_price === 0,
            'error-text':
              element.compare_difference_type === compareDifferenceType.Removed,
            'success-text':
              element.compare_difference_type === compareDifferenceType.Added,
            'warning-text': element.changes?.sales_price
          }"
          data-test="original-sales-price"
        >
          {{
            element.sales_price
              | slMoneyAmount$: { currency: sourceCurrency }
              | async
          }}
        </div>
        <span data-test="new-sales-price">
          {{ element.changes?.sales_price | slMoneyAmount$ | async }}
        </span>
        <ng-container *ngIf="element.comparer_name">
          <br />
          <small data-test="compared-to-info">
            {{
              'project_detail.compared_to'
                | translate: { name: element.comparer_name }
            }}
          </small>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      data-test="compare-row"
      *matRowDef="let row; columns: columnsToDisplay"
      [class.option-row]="
        (row.cart_item_type === cartCompareItemType.Option &&
          !row.is_optional) ||
        row.cart_item_type === cartCompareItemType['Package']
      "
    ></tr>
  </table>
</ds-table>

<ng-template #onlyTextChangesPlaceholder>
  <ds-placeholder
    data-test="info-placeholder"
    class="no-min-height"
    [type]="'info'"
    [customHeadline]="'cart_compare.placeholder_info_headline' | translate"
  >
    {{ 'cart_compare.placeholder_info_text' | translate }}
  </ds-placeholder>
</ng-template>
