import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlUpsellingState } from './upselling.store';

export namespace SlUpsellingSelectors {
  export const slUpsellingState =
    createFeatureSelector<SlUpsellingState>('SlUpsellingState');

  export const projectParameters = createSelector(
    slUpsellingState,
    (state) => state?.parameters,
  );

  export const bestInvestFeatures = createSelector(
    slUpsellingState,
    (state) => state?.bestInvestFeatures,
  );

  export const dealerParameters = createSelector(
    slUpsellingState,
    (state) => state?.parameters?.dealer_parameters,
  );
}
