import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace MccSelectors {
  const mccState = createSelector(moduleState, (state) => state.mcc);

  export const trucks = createSelector(mccState, (state) => state.trucks);
  export const mountingOptionCategories = createSelector(
    mccState,
    (state) => state.mountingOptionCategories,
  );
}
