import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Competitor,
  LostOrder,
  LostOrderViewContainer,
  Reason,
} from '@sales-libs/project/data-access';
import { LostOrderReportingFilters } from '../../models';
import { LostOrderActions } from './lost-order.actions';

export interface LostOrderState extends EntityState<LostOrder> {
  id?: number | null;
  reasons: Reason[] | null;
  competitors: Competitor[] | null;
  lostOrderReportingFilters: LostOrderReportingFilters;
  reportData: LostOrderViewContainer | undefined;
}

const adapter: EntityAdapter<LostOrder> = createEntityAdapter<LostOrder>();

export const initialLostOrderState: LostOrderState = adapter.getInitialState({
  id: null,
  reasons: null,
  competitors: null,
  lostOrderReportingFilters: {
    startDate: undefined,
    endDate: undefined,
    competitors: undefined,
    productLine: undefined,
    products: undefined,
    causeOfLoss: undefined,
    createdBy: undefined,
  },
  reportData: undefined,
});

const reducer = createReducer(
  initialLostOrderState,
  on(LostOrderActions.LoadLostOrder, (state) => ({
    ...state,
    id: undefined,
  })),
  on(LostOrderActions.LoadLostOrderSuccess, (state, { payload }) => ({
    ...adapter.upsertOne(payload, state),
    id: payload.id,
  })),
  on(LostOrderActions.DeleteLostOrderSuccess, (state, { payload }) => ({
    ...adapter.removeOne(payload, state),
  })),
  on(LostOrderActions.LoadReasonsSuccess, (state, { payload }) => ({
    ...state,
    reasons: payload.items,
  })),
  on(LostOrderActions.LoadCompetitorsSuccess, (state, { payload }) => ({
    ...state,
    competitors: payload.items,
  })),
  on(LostOrderActions.LostOrderReportingFilterChange, (state, { payload }) => ({
    ...state,
    reportData: undefined,
    lostOrderReportingFilters: payload,
  })),
  on(LostOrderActions.LoadLostOrderReportingSuccess, (state, { payload }) => ({
    ...state,
    reportData: payload,
  })),
);

export function lostOrderReducer(
  state: LostOrderState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
