import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  Cart,
  CartOverview,
  CartState,
  CartUpdate,
  CartUpdateState,
  Currency,
  Project,
  ProjectState,
} from '@sales-libs/project/data-access';
import { slAnimations } from '@sales-libs/shared/util';
import { ConfirmDeleteDialogComponent } from '@ui-kit/modals';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { CartActions, ProjectActions, ProjectSelectors } from '../store';

@Component({
  selector: 'sl-project-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
  animations: [slAnimations.list],
  standalone: false,
})
export class SlProjectCartListComponent implements OnInit, OnDestroy {
  @Output() add = new EventEmitter();
  @Output() duplicateCart = new EventEmitter<Cart | CartOverview>();
  @Output() duplicateCartToProject = new EventEmitter<Cart | CartOverview>();

  readonly projectStates = ProjectState;
  readonly cartStates = CartState;
  readonly cartUpdate: CartUpdate;
  readonly project$: Observable<Project | undefined>;
  readonly isLoaded$: Observable<boolean>;
  readonly cartsWithExpiration$: Observable<CartWithExpiration[]>;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
  ) {
    this.cartsWithExpiration$ = this._store.select(ProjectSelectors.carts).pipe(
      map((carts) =>
        carts
          ? carts.map((cart) => ({
              cart: cart,
              expirationInfo: this._checkExpiration(cart.valid_until),
            }))
          : [],
      ),
    );
    this.project$ = this._store.select(ProjectSelectors.currentProject);
    this.isLoaded$ = this._store
      .select(ProjectSelectors.cartsLoading)
      .pipe(map((loading) => !loading));
  }

  ngOnInit() {
    this._store
      .select(ProjectSelectors.id)
      .pipe(
        filter((id): id is number => id != null),
        takeUntil(this._destroy$),
      )
      .subscribe((projectId) =>
        this._store.dispatch(ProjectActions.LoadCarts({ payload: projectId })),
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setCartActive(cart: Cart | CartOverview) {
    this._store.dispatch(
      CartActions.UpdateCart({
        update: {
          ...cart,
          state: CartUpdateState.Active,
        },
        isActiveUpdate: true,
      }),
    );
  }

  deleteCart(cart: Cart | CartOverview) {
    if (
      !cart.locked &&
      cart.state !== this.cartStates.Active &&
      cart.state !== this.cartStates.Closed &&
      cart.state !== this.cartStates.Ordered &&
      cart.state !== this.cartStates.Done
    ) {
      const config = new MatDialogConfig();
      config.viewContainerRef = this._viewContainerRef;

      const dialogRef = this._dialog.open(ConfirmDeleteDialogComponent, config);
      dialogRef.componentInstance.key = cart.version.toString();
      dialogRef.componentInstance.confirm
        .pipe(tap(() => dialogRef.close(), takeUntil(dialogRef.afterClosed())))
        .subscribe((confirmed) => {
          if (confirmed) {
            this._store.dispatch(
              ProjectActions.DeleteCart({ payload: cart.id }),
            );
          }
        });
    }
  }

  private _checkExpiration(validUntil: Date): {
    expireSoon: number | null;
    isExpireSoon: boolean;
  } {
    const expirationDate = new Date(validUntil);
    const currentDate = new Date();
    const diff = expirationDate.getTime() - currentDate.getTime();
    const daysUntilExpire = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (daysUntilExpire <= 8 && daysUntilExpire > 0) {
      return {
        expireSoon: daysUntilExpire,
        isExpireSoon: true,
      };
    } else {
      return {
        expireSoon: null,
        isExpireSoon: false,
      };
    }
  }
}

export interface CartWithExpiration {
  cart: Cart | CartOverview;
  expirationInfo: { expireSoon: number | null; isExpireSoon: boolean };
}
