import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-project-transport-cost-input-column',
  templateUrl: './transport-cost-input-column.component.html',
  styleUrls: ['./transport-cost-input-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectTransportCostInputColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() currencySymbol: string;
  @Input() set currencyRatio(value: number) {
    this._currencyRatio = value;
    this._setFormValue();
  }
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this._setFormValue();
  }

  @Output() calculationChange = new EventEmitter<Calculation>();

  readonly triggerBlur = triggerBlur;

  formControl = new FormControl<number>(0, {
    nonNullable: true,
    updateOn: 'blur',
  });

  private _calculation: Calculation;
  private _currencyRatio: number;
  private readonly _destroy$ = new Subject<void>();

  private _setFormValue() {
    if (this._calculation && this._currencyRatio !== undefined) {
      this.formControl.setValue(
        +(
          this._calculation[this.perspective].transport_costs *
          this._currencyRatio
        ).toFixed(2),
        {
          emitEvent: false,
        },
      );
    }
  }

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }

    this.formDirective.form.addControl(
      this.perspective + 'TransportCost',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) =>
        this.calculationChange.emit({
          ...this._calculation,
          [this.perspective]: {
            ...this._calculation[this.perspective],
            transport_costs: (value ?? 0) / this._currencyRatio,
          },
        }),
      );
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
