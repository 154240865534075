import { CartItemsService } from '@sales-libs/project/data-access';
import { SlProjectCartItemGroupCreationRow } from '../rows/cart-item-group-creation-row';
import { SlProjectDataCreationOptionalGroup } from '../types/optional-group-type';

export class SlProjectCartItemGroupCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: SlProjectDataCreationOptionalGroup) {
    return new SlProjectCartItemGroupCreationRow(item, this._cartItemsService);
  }
}
