import { ProjectInput, ProjectsService } from '@sales-libs/project/data-access';
import { catchError, map, tap } from 'rxjs';
import { SlProjectCartCreationRow } from './cart-creation-row';
import {
  SlProjectDataCreationRow,
  SlProjectDataCreationRowState,
} from './data-creation-row';

export class SlProjectCreationRow extends SlProjectDataCreationRow {
  constructor(
    private _projectText: string,
    private _projectInput: ProjectInput,
    private _projectsService: ProjectsService,
    private _cartCreationRow: SlProjectCartCreationRow,
  ) {
    super(_projectText);
  }

  protected _creationFunction = () =>
    this._projectsService.createProject(this._projectInput).pipe(
      tap((data) => {
        this._cartCreationRow.projectId = data.id;
        this._cartCreationRow.create();
      }),
      map((data) => ({
        result: {
          data,
        },
        state: SlProjectDataCreationRowState.Success,
      })),
      catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
    );
}
