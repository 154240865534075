import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-average-discount-column',
  templateUrl: './average-discount-column.component.html',
  styleUrls: ['./average-discount-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectAverageSlProjectDiscountColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() locale: string;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    const subTotalPurchaseWithTransportCost =
      CalculationUtils.getItemsTotal(
        value.items,
        this.perspective,
        this.isAlternativeView,
        false,
      ) + value[this.perspective].transport_costs;

    const finalPrice = CalculationUtils.getFinalPrice(
      value,
      this.perspective,
      this.isAlternativeView,
    );

    this.averageDiscount =
      (subTotalPurchaseWithTransportCost - finalPrice) /
      subTotalPurchaseWithTransportCost;
  }

  averageDiscount: number;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
