<ng-container matColumnDef="end_date">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="end_date">
    {{ 'table_columns.end_date' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      label: 'ProjectListClosedDateColumn',
      category: 'ProjectOverview',
      action: 'ClosedDateClick'
    }"
  >
    {{
      project.closed_date
        ? (project.closed_date | date: 'shortDate')
        : ('project.no_end_date' | translate)
    }}
  </td>
</ng-container>
