<ng-container [matColumnDef]="perspective + 'RelativeDiscount'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right"
    [gaClickListener]="{
      action: perspective + 'RelativeDiscountClick',
      category: 'Cart',
      label: 'RelativeDiscountColumn'
    }"
  >
    {{
      formatNegativeValue(
        relativeDiscount
          | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
          | async
      )
    }}
  </td>
</ng-container>
