import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Cart, Project } from '@sales-libs/project/data-access';
import { Observable, filter, map } from 'rxjs';
import { CartSelectors, ProjectSelectors } from '../store';

@Component({
  selector: 'sl-project-cart-valid-dependend-page',
  templateUrl: './cart-valid-dependend-page.component.html',
  styleUrls: ['./cart-valid-dependend-page.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    DsPlaceholderModule,
    RouterModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
  ],
})
export class SlProjectCartValidDependendPageComponent {
  @Input() placeholderHeadline: string;

  readonly cart$: Observable<Cart | null>;
  readonly project$: Observable<Project | undefined>;
  readonly isCartValid$: Observable<boolean>;

  constructor(private _store: Store) {
    this.project$ = this._store.select(ProjectSelectors.currentProject);
    this.cart$ = this._store.select(CartSelectors.current);
    this.isCartValid$ = this.cart$.pipe(
      filter((cart): cart is Cart => cart != null),
      map((cart) =>
        cart ? !cart?.product_items?.some((item) => !item.is_valid) : false,
      ),
    );
  }
}
