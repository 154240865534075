<tr
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  [gaClickListener]="{
    category: 'Cart',
    action: 'ContractItemListClick',
    label: 'CartItemList'
  }"
  class="mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  (toOptional)="onToOptional($event)"
  (delete)="onDelete($event)"
></tr>
