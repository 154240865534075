<ng-container matColumnDef="comment">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="comment"
    data-test="lost-order-comment-header"
  >
    {{ 'table_columns.comment' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'CommentClick'
    }"
    data-test="lost-order-comment"
  >
    {{
      lostOrder.comment
        ? lostOrder.comment
        : ('error_messages.lost_order.no_comment' | translate)
    }}
  </td>
</ng-container>
