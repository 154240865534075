/* eslint-disable max-lines */
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { RootActions } from '@features/root-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Cart,
  CartOverviewState,
  CartsService,
  Project,
  ProjectInput,
  ProjectState,
  ProjectUpdate,
  ProjectsService,
} from '@sales-libs/project/data-access';
import {
  CurrencyActions,
  FEATURE_FLAG_BEST_INVEST,
  FeatureFlagPipe,
  SnackbarActions,
} from '@sales-libs/shared/util';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CartActions } from '../cart/cart.actions';
import { ProjectActions } from './project.actions';
import { ProjectSelectors } from './project.selectors';
import { concat, Observable, of } from 'rxjs';
import { SharedContractActions } from '@sales-libs/shared/feature';

@Injectable()
export class ProjectEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _store: Store = inject(Store);
  private readonly featureFlagPipe = new FeatureFlagPipe();
  isBestInvestFeatureFlagSet$: Observable<boolean>;

  projectId$ = createEffect(() =>
    this._actions.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATED),
      map((action) => action.payload),
      map((payload) => payload.routerState['params']),
      map((params) => coerceNumberProperty(params['projectId'], null)),
      filter((value): value is number => value !== null),
      distinctUntilChanged(),
      map((id) => ProjectActions.LoadProject({ payload: id })),
    ),
  );

  createOrUpdateProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.CreateOrUpdate),
      map((action) =>
        action.id > 0
          ? ProjectActions.Update({
              id: action.id,
              payload: action.payload as ProjectUpdate,
            })
          : ProjectActions.Create({ payload: action.payload as ProjectInput }),
      ),
    ),
  );

  createProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.Create),
      tap(() => this._store.dispatch(RootActions.ShowLoadingSpinner())),
      map((action) => action.payload),
      mergeMap((project) =>
        this._projectsService
          .createProject(
            project,
            undefined,
            project.external_id || undefined,
            project.external_log_id || undefined,
          )
          .pipe(
            withLatestFrom(this.isBestInvestFeatureFlagSet$),
            mergeMap(([data, isFlagSet]) => {
              if (isFlagSet) {
                return concat(
                  this._createProjectSuccessActions(data),
                  of(
                    SharedContractActions.createProjectParameters({
                      payload: data.id,
                    }),
                  ),
                );
              } else {
                return this._createProjectSuccessActions(data);
              }
            }),
            catchError((err) =>
              this._errorActions(err, 'error_messages.project.not_created'),
            ),
          ),
      ),
      tap(() => this._store.dispatch(RootActions.HideLoadingSpinner())),
    ),
  );

  updateProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.Update),
      mergeMap((action) =>
        this._projectsService
          .updateProject(action.id, action.payload, 'response')
          .pipe(
            mergeMap(() => [
              ProjectActions.SaveProjectSuccess({
                payload: action.payload as Project,
              }),
              ProjectActions.LoadProjectSuccess({
                payload: action.payload as Project,
              }),
            ]),
            catchError((err) =>
              this._errorActions(err, 'error_messages.project.not_updated'),
            ),
          ),
      ),
    ),
  );

  loadProjects$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.LoadProjects),
      map((action) => action.payload),
      withLatestFrom(this._store.select(ProjectSelectors.pagination)),
      switchMap(([payload, _pagination]) =>
        this._projectsService
          .searchProjects(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            _pagination.pageSize,
            payload || _pagination.pageIndex,
          )
          .pipe(
            map((data) =>
              ProjectActions.LoadProjectsSuccess({ payload: data }),
            ),
            catchError((err) =>
              this._errorActions(err, 'error_messages.project.not_loaded'),
            ),
          ),
      ),
    ),
  );

  searchProjectPage$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.SearchProjects),
      withLatestFrom(
        this._store.select(ProjectSelectors.projectFilters),
        this._store.select(ProjectSelectors.pagination),
        this._store.select(ProjectSelectors.sort),
      ),
      switchMap(([, filters, pagination, sort]) =>
        this._projectsService
          .searchProjects(
            filters.chance ?? undefined,
            filters.term,
            // parse date adapter type to native date so that query parsing works
            filters.startDate ? new Date(filters.startDate) : undefined,
            filters.endDate ? new Date(filters.endDate) : undefined,
            filters.state,
            sort?.active,
            sort?.direction,
            pagination.pageSize,
            pagination.pageIndex,
          )
          .pipe(
            map((data) =>
              ProjectActions.LoadProjectsSuccess({ payload: data }),
            ),
            catchError((err) =>
              this._errorActions(
                err,
                'error_messages.project.projects_not_loaded',
              ),
            ),
          ),
      ),
    ),
  );

  loadProjectFilter$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.LoadProjectFilter),

      switchMap(({ payload }) =>
        this._projectsService
          .searchProjects(
            undefined,
            payload.searchValue,
            undefined,
            undefined,
            ProjectState.InProgress,
            undefined,
            undefined,
            10,
            payload.pageIndex,
          )
          .pipe(
            map((data) =>
              ProjectActions.LoadProjectFilterSuccess({ payload: data }),
            ),
            // eslint-disable-next-line sonarjs/no-identical-functions
            catchError((err) =>
              this._errorActions(
                err,
                'error_messages.project.projects_not_loaded',
              ),
            ),
          ),
      ),
    ),
  );

  getExport$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.GetExport),
      withLatestFrom(
        this._store.select(ProjectSelectors.projectFilters),
        this._store.select(ProjectSelectors.sort),
      ),
      switchMap(([, filters, sort]) =>
        this._projectsService
          .exportProjectsTable(
            filters.chance || undefined,
            filters.term,
            // parse date adapter type to native date so that query parsing works
            filters.startDate ? new Date(filters.startDate) : undefined,
            filters.endDate ? new Date(filters.endDate) : undefined,
            filters.state,
            sort?.active,
            sort?.direction,
          )
          .pipe(
            map((data) => ProjectActions.GetExportSuccess({ payload: data })),
            catchError(() => [ProjectActions.GetExportError()]),
          ),
      ),
    ),
  );

  loadProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.LoadProject),
      map((action) => action.payload),
      switchMap((payload) =>
        this._projectsService.getProjectById(payload).pipe(
          map((project) =>
            ProjectActions.LoadProjectSuccess({ payload: project }),
          ),
          catchError((response) => [
            ProjectActions.LoadProjectError({
              isProjectDeleted:
                response.status === 404 && !!response.error?.log_id,
            }),
          ]),
        ),
      ),
    ),
  );

  navigate$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(ProjectActions.SelectProject),
        map((action) => action.payload),
        switchMap((payload) =>
          this._store.pipe(
            select(ProjectSelectors.project(payload)),
            map((project) => project?.id),
            take(1),
          ),
        ),
        tap((projectId) => this._router.navigate(['projects', projectId])),
      ),
    { dispatch: false },
  );

  deleteProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.DeleteProject),
      map((action) => action.payload),
      mergeMap((payload) =>
        this._projectsService.deleteProject(payload).pipe(
          map(() => ProjectActions.DeleteProjectSuccess({ payload: payload })),
          catchError((err) =>
            this._errorActions(err, 'error_messages.project.not_deleted'),
          ),
        ),
      ),
    ),
  );

  deleteProjectSuccess$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.DeleteProjectSuccess),
      mergeMap(() => [
        SnackbarActions.ShowSuccess({
          message: this._translateService.instant('project.project_deleted'),
        }),
        ProjectActions.LoadProjects({ payload: null }),
      ]),
    ),
  );

  loadCarts$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.LoadCarts),
      map((action) => action.payload),
      switchMap((projectId) =>
        this._cartsService.getCartOverview(projectId).pipe(
          mergeMap((data) => {
            const primaryCart = data.items.find(
              (cart) =>
                cart.state === CartOverviewState.Active ||
                cart.state === CartOverviewState.Ordered ||
                cart.state === CartOverviewState.Done,
            );
            return [
              ProjectActions.LoadCartsSuccess({ payload: data }),
              ...(primaryCart
                ? [
                    CurrencyActions.SetContextSpecificCurrency({
                      payload: primaryCart.currency_settings,
                    }),
                  ]
                : []),
            ];
          }),
          catchError((err) =>
            this._errorActions(err, 'error_messages.project.carts_not_loaded'),
          ),
        ),
      ),
    ),
  );

  createCart$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.CreateCart),
      withLatestFrom(this._store.select(ProjectSelectors.id)),
      mergeMap(([action, projectId]) =>
        this._cartsService
          .createCartAsync({
            project_id: projectId ?? 0,
            comment: '',
            price_list_date: action.priceListDate,
            product_line: action.productLine,
            language: action.language,
            currency_settings: action.currency,
            valid_until: action.validUntil,
          })
          .pipe(
            tap((cart: Cart) =>
              this._router.navigate([
                'projects',
                cart.project_id,
                'carts',
                cart.id,
              ]),
            ),
            map((cart: Cart) =>
              CartActions.LoadCartSuccess({
                payload: cart,
              }),
            ),
            catchError((err) =>
              this._errorActions(
                err,
                'error_messages.project.cart_not_created',
              ),
            ),
          ),
      ),
    ),
  );

  deleteCart$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProjectActions.DeleteCart),
      map((action) => action.payload),
      mergeMap((cartId) =>
        this._cartsService.deleteCart(cartId).pipe(
          map(() => ProjectActions.DeleteCartSuccess({ payload: cartId })),
          catchError((err) =>
            this._errorActions(err, 'error_messages.project.cart_not_deleted'),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly _router: Router,
    private readonly _projectsService: ProjectsService,
    private readonly _cartsService: CartsService,
    private readonly _translateService: TranslateService,
    @Inject(FEATURE_FLAG_BEST_INVEST)
    public bestInvestFeatureFlag,
  ) {
    this.isBestInvestFeatureFlagSet$ = this.featureFlagPipe.transform(
      this.bestInvestFeatureFlag,
    );
  }

  private _createProjectSuccessActions = (data?: Project) => {
    if (data) {
      return [
        ProjectActions.SaveProjectSuccess({ payload: data }),
        ProjectActions.LoadProjectSuccess({ payload: data }),
        ProjectActions.SelectProject({ payload: data.id }),
      ];
    } else {
      return [];
    }
  };

  private _errorActions = (err: any, message: string) => [
    SnackbarActions.ShowError({
      error: err,
      message: this._translateService.instant(message),
    }),
    ProjectActions.Error(),
  ];
}
