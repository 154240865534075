import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import {
  OfferPriceOverview,
  OfferPricePosition,
} from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlProjectDigitalOfferPriceTableComponent } from '../digital-offer-price-table/digital-offer-price-table.component';

@Component({
  selector: 'sl-project-digital-offer-prices',
  imports: [
    CommonModule,
    GoogleAnalytics4DirectiveModule,
    TranslateModule,
    SlProjectDigitalOfferPriceTableComponent,
    FlexLayoutModule,
    DsLoadingModule,
    DsSpacingModule,
    MatCardModule,
    MatIconModule,
    DsTextIndicatorModule,
  ],
  templateUrl: './digital-offer-prices.component.html',
  styleUrl: './digital-offer-prices.component.scss',
})
export class SlProjectDigitalOfferPricesComponent {
  @Input() set prices(value: OfferPriceOverview) {
    this._prices = value;
    this.equipments = this._flattenEquipments(value?.equipments ?? []);
  }
  get prices(): OfferPriceOverview {
    return this._prices;
  }
  @Input() displayItemPrices: boolean;
  @Input() displayIndexPrices: boolean;
  @Input() includeDiscounts: boolean;

  equipments: OfferPricePosition[] = [];
  private _prices: OfferPriceOverview;

  private _flattenEquipments(
    equipments: OfferPricePosition[],
  ): OfferPricePosition[] {
    const flattenedEquipments: OfferPricePosition[] = [];

    equipments?.forEach((equipment) => {
      if (equipment.options && equipment.options.length > 0) {
        flattenedEquipments.push(...equipment.options);
      } else {
        flattenedEquipments.push(equipment);
      }
    });

    return flattenedEquipments;
  }
}
