import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectCartItemListHeader]',
  templateUrl: './cart-item-list-header.component.html',
  styleUrls: ['./cart-item-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectCartItemListHeaderComponent {
  @Input() isTypeVisible = false;
  @Input() isPriceVisible = false;
  @Input() isReadOnly = false;
}
