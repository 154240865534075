/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

@Component({
  selector: 'sl-project-digital-offer-terms-and-conditions',
  imports: [CommonModule, TranslateModule, GoogleAnalytics4DirectiveModule],
  templateUrl: './digital-offer-terms-and-conditions.component.html',
  styleUrls: ['./digital-offer-terms-and-conditions.component.scss'],
})
export class SlProjectDigitalOfferTermsAndConditionsComponent {
  @Input() termsAndConditions: string;
}
