import { Action, createReducer, on } from '@ngrx/store';
import { TuningCenterOptionCategory } from '@sales-libs/project/data-access';
import { TuningCenterActions } from './tuning-center.actions';

export interface TuningCenterState {
  optionCategories: TuningCenterOptionCategory[];
}

export const initialTuningCenterState: TuningCenterState = {
  optionCategories: [],
};

const reducer = createReducer(
  initialTuningCenterState,
  on(TuningCenterActions.GetOptionsSuccess, (state, { payload }) => ({
    ...state,
    optionCategories: payload.items,
  })),
);

export function tuningCenterReducer(
  state: TuningCenterState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
