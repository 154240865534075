<ng-container gaLabelGroup="IndexPriceDialog" gaCategoryGroup="Calculation">
  <h3 matDialogTitle gaClickListener="TitleClick">
    {{ 'calculation.producer_price_index' | translate }}
  </h3>
  <mat-dialog-content>
    <ul>
      <li>
        {{ 'calculation.producer_price_index_description_1' | translate }}
      </li>
      <li>
        {{ 'calculation.producer_price_index_description_2' | translate }}
      </li>
      <li>
        {{ 'calculation.producer_price_index_description_3' | translate }}
      </li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      type="button"
      mat-dialog-close
      gaClickListener="CancelClick"
    >
      {{ 'common.cancel' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
