<ng-container gaCategoryGroup="Cart" gaLabelGroup="CartItems">
  <ng-container *ngIf="cart$ | async as cart">
    <h3 gaClickListener="CartItemsTitleClick">
      {{ 'project_detail.cart_items' | translate }}
    </h3>
    <ds-table [disableResponsive]="true">
      <ng-container *ngIf="allItems$ | async as allItems; else loading">
        <table
          class="mat-mdc-table"
          *ngIf="allItems.length > 0; else placeholder"
        >
          <thead
            slProjectCartItemListHeader
            [isReadOnly]="isReadOnly || cart.locked"
            [isPriceVisible]="isPricingVisible$ | async"
          ></thead>
          <tbody
            slProjectSolutionItems
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
            (configureItem)="configureSolution.emit($event)"
            data-test="solution-cart-items"
          ></tbody>
          <tbody
            slProjectProductCartItems
            [productType]="productTypes.Product"
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
            (configureItem)="configureItem.emit($event)"
            data-test="product-cart-items"
          ></tbody>
          <tbody
            slProjectWarrantyExtensionCartItems
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
          ></tbody>
          <tbody
            slProjectTuningCenterCartItems
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
          ></tbody>
          <tbody
            slProjectMccCartItems
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
          ></tbody>
          <tbody
            slProjectProductCartItems
            [productType]="productTypes.Accessory"
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
            (configureItem)="configureItem.emit($event)"
          ></tbody>
          <tbody
            slProjectCustomArticleCartItems
            class="drag-drop-boundary"
            cdkDropList
            [cdkDropListData]="customArticleItems$ | async"
            (cdkDropListDropped)="drop($event)"
            [customArticleSortingUpdateCount]="customArticleSortingUpdateCount"
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
          ></tbody>
          <tbody
            slProjectContractCartItems
            [isReadOnly]="isReadOnly || cart.locked"
            [isPricingVisible]="isPricingVisible$ | async"
          ></tbody>
        </table>
      </ng-container>

      <ds-table-actions>
        <ng-content></ng-content>
      </ds-table-actions>
    </ds-table>

    <ng-container
      *ngIf="
        optionalItemGroupContainers$ | async as optionalItemGroupContainers
      "
    >
      <ng-container *ngIf="optionalItemGroupContainers.length > 0">
        <h3
          fxLayout
          fxLayoutAlign="space-between center"
          gaClickListener="OptionalItemsTitleClick"
        >
          {{ 'project_detail.optional_items' | translate }}
          <button
            mat-button
            page-action
            (click)="openCartItemGroupDialog(cart.id)"
            [spotlight]="addGroupSpotlight"
          >
            <mat-icon>create_new_folder</mat-icon>
            {{ 'project.add_group' | translate }}
          </button>
        </h3>
        <ds-text-indicator
          type="warning"
          gaClickListener="OptionalItemsDisclaimerClick"
        >
          {{ 'project.optional_items_disclaimer' | translate }}
        </ds-text-indicator>
        <br />
        <sl-project-optional-cart-item-list
          *ngIf="allItems$ | async as allItems"
          [cart]="cart"
          [isReadOnly]="isReadOnly || cart.locked"
          [groups]="optionalItemGroupContainers"
          [allItems]="allItems"
          [isPricingVisible]="isPricingVisible$ | async"
          (configure)="onConfigureOptionalItem($event)"
          (editGroup)="openCartItemGroupDialog(cart.id, $event)"
          gaClickListener="OptionalItemsClick"
        >
        </sl-project-optional-cart-item-list>

        <ds-spotlight
          id="cpq-spotlight-add-group"
          #addGroupSpotlight
          [tags]="['release_2021_04_01', 'cart-item-list']"
        >
          {{
            'spotlight.release_2021_04_01.grouping_sorting.add_group'
              | translate
          }}
        </ds-spotlight>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <mat-card>
      <mat-card-content>
        <ds-table-loading
          [numberOfHeaders]="4"
          [numberOfRows]="3"
          gaClickListener="LoadingClick"
        >
        </ds-table-loading>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #placeholder>
    <mat-card>
      <mat-card-content>
        <ds-placeholder
          [type]="'no_data'"
          [customHeadline]="'solution.no_items_yet' | translate"
          data-test="no-data-placehoder"
        >
          <p>{{ 'solution.add_item_hint' | translate }}</p>
        </ds-placeholder>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
