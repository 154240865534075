<ng-container gaLabelGroup="ProjectList" gaCategoryGroup="ProjectOverview">
  <ds-table
    [class.min-height]="projects && projects.length > 3"
    *pdLet="sort$ | async as sort"
  >
    <ng-container *ngIf="projects && displayColumns; else loading">
      <table
        mat-table
        matSort
        [matSortActive]="sort?.active"
        [matSortDirection]="sort?.direction"
        matSortDisableClear
        (matSortChange)="sortChange.emit($event)"
        [dataSource]="projects"
        *ngIf="projects?.length; else noData"
        role="presentation"
      >
        <sl-project-project-list-code-column></sl-project-project-list-code-column>
        <sl-project-project-list-state-column></sl-project-project-list-state-column>
        <sl-project-project-list-created-by-column></sl-project-project-list-created-by-column>
        <sl-project-project-list-name-column></sl-project-project-list-name-column>
        <sl-project-project-list-model-column></sl-project-project-list-model-column>
        <sl-project-project-list-chance-column></sl-project-project-list-chance-column>
        <sl-project-project-list-created-date-column></sl-project-project-list-created-date-column>
        <sl-project-project-list-closed-date-column></sl-project-project-list-closed-date-column>
        <sl-project-project-list-customer-column
          (showCustomer)="showCustomer.emit($event)"
        ></sl-project-project-list-customer-column>
        <sl-project-project-list-actions-column
          [displayColumns]="displayColumns"
          (setUserSettings)="setUserSettings.emit($event)"
          (selectClick)="selected.emit($event)"
          (deleteClick)="deleteProject.emit($event)"
          (editClick)="editProject.emit($event)"
        ></sl-project-project-list-actions-column>

        <tr
          [spotlight]="projectsSortSpotlight"
          mat-header-row
          *matHeaderRowDef="displayColumns | displayColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          pointer
          data-test="project-row"
          class="highlight {{ 'data-test-' + row.name | slCssClass }}"
          *matRowDef="let row; columns: displayColumns | displayColumns"
        ></tr>
      </table>

      <ds-spotlight
        #projectsSortSpotlight
        id="sl-project-projects-sort-spotlight"
        [tags]="['release_2021_08', 'project-overview']"
      >
        {{ 'spotlight.release_2021_08.projects_sortable' | translate }}
      </ds-spotlight>
    </ng-container>
    <ds-table-footer>
      <mat-paginator
        *ngIf="pagination"
        [length]="pagination.length"
        [pageSize]="pagination.pageSize"
        [pageIndex]="pagination.pageIndex"
        [pageSizeOptions]="[10, 20, 50]"
        [showFirstLastButtons]="true"
        (page)="getPage.emit($event)"
        gaClickListener="PaginationClick"
        [spotlight]="paginatorSpotlight"
      >
      </mat-paginator>
    </ds-table-footer>

    <ds-spotlight
      #paginatorSpotlight
      id="sl-project-paginator-first-and-last-button"
      [tags]="['release_2021_04_01', 'pagniator']"
    >
      {{ 'spotlight.release_2021_04_01.pagination' | translate }}
    </ds-spotlight>

    <ds-table-actions>
      <button
        mat-button
        data-test="add-project"
        (click)="addProject.emit()"
        [spotlight]="projectAddSpotlight"
        gaClickListener="AddProjectClick"
      >
        <mat-icon>add</mat-icon>
        {{ 'project.add_project' | translate }}
      </button>
    </ds-table-actions>
  </ds-table>

  <ng-template #loading>
    <ds-table-loading
      [numberOfHeaders]="9"
      [numberOfRows]="10"
      gaClickListener="LoadingClick"
    ></ds-table-loading>
  </ng-template>

  <ng-template #noData>
    <ds-placeholder type="no_data" gaClickListener="PlaceholderClick">
      {{ 'project.no_projects' | translate }}
    </ds-placeholder>
  </ng-template>

  <ds-spotlight
    id="sl-project-spotlight-project-overview-add"
    #projectAddSpotlight
    position="above"
  >
    {{ 'spotlight.release_2020_05.project_overview.add' | translate }}
  </ds-spotlight>
</ng-container>
