import { createAction, props } from '@ngrx/store';
import {
  Cart,
  TuningCenterItemInput,
  TuningCenterOptionCategoriesContainer,
} from '@sales-libs/project/data-access';

export namespace TuningCenterActions {
  // options
  export const GetOptions = createAction('[Tuning Center] GET_OPTIONS');
  export const GetOptionsSuccess = createAction(
    '[Tuning Center] GET_OPTIONS_SUCCESS',
    props<{ payload: TuningCenterOptionCategoriesContainer }>(),
  );

  // move to cart actions
  // add
  export const AddPositionCartItem = createAction(
    '[Tuning Center] ADD_POSITION_CART_ITEM',
    props<{ payload: TuningCenterItemInput }>(),
  );
  export const AddPositionCartItemSuccess = createAction(
    '[Tuning Center] ADD_POSITION_CART_ITEM_SUCCESS',
    props<{ payload: Cart }>(),
  );
}
