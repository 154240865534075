<ng-container gaLabelGroup="ProductPreview" gaCategoryGroup="Cart">
  <div [spotlight]="pricesSpotlight">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header
          gaClickListener="SolutionPriceClick"
          *ngIf="solutionCartItem"
        >
          <div
            fxLayout
            fxLayoutAlign="space-between"
            class="full-width"
            data-test="solution-total-price"
          >
            <span>{{ 'project_detail.solution' | translate }}</span>
            <strong data-test="product-preview-solution-price">
              {{
                solutionCartItem.sales_price
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </strong>
          </div>
        </mat-expansion-panel-header>
        <table list role="presentation">
          <tr
            *ngFor="let option of solutionCartItem?.options"
            [gaClickListener]="option.code + 'CranePriceClick'"
            class="data-test-{{ option.code | slCssClass }}"
            data-test="solution-option"
          >
            <td data-test="solution-option-code">
              {{ option.code }}
            </td>
            <td class="text-right" data-test="solution-option-price">
              {{ option.price | slMoneyAmount$ | async }}
            </td>
          </tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel [spotlight]="packageSidebarSpotlight">
        <mat-expansion-panel-header
          gaClickListener="CranePriceClick"
          *ngIf="!solutionCartItem"
        >
          <div
            fxLayout
            fxLayoutAlign="space-between"
            class="full-width"
            data-test="crane-total-price"
          >
            <span>{{ 'project_detail.product' | translate }}</span>
            <strong data-test="product-preview-crane-price">
              {{
                craneCartItemTotalPrice
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </strong>
          </div>
        </mat-expansion-panel-header>
        <ds-spotlight
          id="cpq-spotlight-package-sidebar"
          #packageSidebarSpotlight
        >
          {{ 'spotlight.release_2022_03_01.package_sidebar' | translate }}
        </ds-spotlight>
        <table list role="presentation">
          <tr
            *ngFor="let option of craneCartItem?.options"
            [gaClickListener]="option.name + 'CranePriceClick'"
            class="data-test-{{ option.name | slCssClass }}"
            data-test="crane-option-price"
          >
            <td data-test="sale-options-name">
              {{ option.name }}
            </td>
            <td class="text-right" data-test="sale-options-price">
              {{ option.sales_price | slMoneyAmount$ | async }}
            </td>
          </tr>
          <ng-container *ngIf="craneCartItem?.packages">
            <tr
              *ngFor="let package of craneCartItem?.packages"
              [gaClickListener]="package.model_id + 'PackagePriceClick'"
              data-test="product-preview-package-price"
            >
              <td>
                {{ package.description || package.model_id }}
              </td>
              <td class="text-right">
                {{ +(package.sales_price || 0) | slMoneyAmount$ | async }}
              </td>
            </tr>
          </ng-container>
          <tr
            *ngIf="tuningCenterItem"
            gaClickListener="WarranryTuningCenterPriceClick"
            class="data-test-{{ tuningCenterItem.name | slCssClass }}"
            data-test="crane-tuningcenter-price"
          >
            <td>
              {{ tuningCenterItem.name }}
            </td>
            <td class="text-right">
              {{
                tuningCenterItem.sales_price
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </td>
          </tr>
          <tr
            *ngIf="mccItem"
            gaClickListener="WarranryMccClick"
            data-test="crane-mcc-price"
          >
            <td>
              {{ mccItem.name }}
            </td>
            <td class="text-right">
              {{
                mccItem.sales_price
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </td>
          </tr>
          <tr
            *ngIf="warrantyExtensionItem"
            gaClickListener="WarranryExtensionCranePriceClick"
            data-test="crane-warranty-extension-price"
            class="data-test-{{ warrantyExtensionItem.name | slCssClass }}"
          >
            <td>
              {{ warrantyExtensionItem.name }}
            </td>
            <td class="text-right">
              {{ warrantyExtensionItem.sales_price | slMoneyAmount$ | async }}
            </td>
          </tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header gaClickListener="EquipmentPriceClick">
          <div
            fxLayout
            fxLayoutAlign="space-between"
            class="full-width"
            data-test="accessory-total-price"
          >
            <span>{{ 'project_detail.equipment' | translate }}</span>
            <strong class="text-right" data-test="equipment-price">
              {{ equipmentsTotalPrice | slMoneyAmount$: { ratio: 1 } | async }}
            </strong>
          </div>
        </mat-expansion-panel-header>
        <table
          list
          role="presentation"
          *ngIf="equipmentItems?.length; else noEquipment"
        >
          <tr
            *ngFor="let item of equipmentItems"
            [gaClickListener]="item.name || '' + 'EquipmentPriceClick'"
            data-test="accessory-item-price"
          >
            <td data-test="accessory-item">
              {{ item.quantity }}x {{ item.name }}
            </td>
            <td class="text-right">
              {{ item.sales_price | slMoneyAmount$ | async }}
            </td>
          </tr>
        </table>
        <ng-template #noEquipment>
          <ds-placeholder
            type="no_data"
            gaClickListener="NoEquipmentPlaceholderClick"
            [customHeadline]="
              'project_detail.no_equipment_selected' | translate
            "
          >
          </ds-placeholder>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div
            fxLayout
            fxLayoutAlign="space-between"
            class="full-width"
            data-test="custom-articles-total-price"
            gaClickListener="CustomArticlePriceClick"
          >
            <span>
              {{ 'project_detail.custom_articles' | translate }}
            </span>
            <strong class="text-right">
              {{
                customArticlesTotalPrice
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </strong>
          </div>
        </mat-expansion-panel-header>
        <table
          list
          role="presentation"
          *ngIf="customArticleItems?.length; else noCustomArticles"
        >
          <tr
            *ngFor="let item of customArticleItems"
            gaClickListener="
              item.name || '' + 'CustomArticlePriceClick'"
            data-test="custom-articles-item-price"
          >
            <td data-test="custom-articles-item">
              {{ item.quantity }}x {{ item.name }}
            </td>
            <td class="text-right">
              {{
                item.sales_price
                  | slMoneyAmount$
                    : {
                        roundingDigits: 2
                      }
                  | async
              }}
            </td>
          </tr>
        </table>
        <ng-template #noCustomArticles>
          <ds-placeholder
            type="no_data"
            gaClickListener="NoCustomArticlesPlaceholderClick"
            [customHeadline]="
              'project_detail.no_custom_articles_selected' | translate
            "
          >
          </ds-placeholder>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header gaClickListener="ContractPriceClick">
          <div fxLayout fxLayoutAlign="space-between" class="full-width">
            <span>{{ 'project_detail.contracts' | translate }}</span>
            <strong class="text-right" data-test="contracts-total-price">
              {{ contractsTotalPrice | slMoneyAmount$ | async }}
            </strong>
          </div>
        </mat-expansion-panel-header>
        <table
          list
          role="presentation"
          *ngIf="contractItems?.length; else noContract"
        >
          <tr
            *ngFor="let item of contractItems"
            [gaClickListener]="item.name || '' + 'ContractPriceClick'"
            data-test="contract-item-price"
          >
            <td data-test="contract-item-name">
              <div *ngIf="item.name === contractNames.SERVICE">
                {{ 'project_detail.service_contract' | translate }}
              </div>

              <div *ngIf="item.name === contractNames.CONNECTED">
                {{ 'project_detail.connected_contract' | translate }}
              </div>
            </td>
            <td class="text-right">
              {{ item.sales_price | slMoneyAmount$ | async }}
            </td>
          </tr>
        </table>
        <ng-template #noContract>
          <ds-placeholder
            type="no_data"
            gaClickListener="NoContractPlaceholderClick"
            [customHeadline]="
              'project_detail.no_contracts_selected' | translate
            "
          >
          </ds-placeholder>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ds-spotlight #pricesSpotlight id="cpq-spotlight-cart-sidebar-prices">
    {{ 'spotlight.release_2020_06.unfold_sidebar_prices' | translate }}
  </ds-spotlight>

  <div class="padding">
    <table list role="presentation">
      <tr
        class="sum-divider"
        data-test="sales-price"
        gaClickListener="SalesPriceClick"
      >
        <td>
          <strong>{{ 'project_detail.list_price' | translate }}</strong>
        </td>
        <td class="text-right" data-test="cart-item-total-price">
          <strong>{{ cart.sales_price | slMoneyAmount$ | async }}</strong>
        </td>
      </tr>
      <ng-container *ngIf="cart.final_price">
        <tr>
          <td colspan="2">
            <hr />
          </td>
        </tr>
        <tr data-test="final-price" gaClickListener="FinalPriceClick">
          <td>{{ 'project_detail.offer_price' | translate }}:</td>
          <td class="text-right">
            {{ cart.final_price | slMoneyAmount$ | async }}
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-container>
