<ds-table gaCategoryGroup="DigitalOffer" gaLabelGroup="Prices">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="fix-column--small">
        {{ 'offer_position' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let option"
        gaClickListener="NameClick"
        data-test="name-cell"
      >
        <span> {{ option?.name }} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'offer_pieces' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let option"
        gaClickListener="QuantityClick"
        data-test="quantity-cell"
      >
        {{ option?.quantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="fix-column--big">
        {{ 'offer_description' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let option"
        gaClickListener="DescriptionClick"
        data-test="description-cell"
      >
        {{ option?.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="discount">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'offer_discount' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let option"
        gaClickListener="DiscountClick"
        data-test="discount-cell"
      >
        {{ option?.discount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'offer_price' | translate }} ({{ currency }})
      </th>
      <td
        mat-cell
        *matCellDef="let option"
        gaClickListener="PriceClick"
        data-test="price-cell"
      >
        {{ option?.price }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
</ds-table>
