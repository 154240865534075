import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APPS, PARTNERTYPES } from '@config';

import { Location } from '@angular/common';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { UserService } from '@features/auth';
import { RootSelectors } from '@features/root-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@sales-libs/order/data-access';
import { Cart } from '@sales-libs/project/data-access';
import { CartSelectors, ProjectSelectors } from '@sales-libs/project/feature';
import {
  SlSharedStoreActions,
  SlSharedStoreSelectors,
} from '@sales-libs/shared/feature';
import {
  FEATURE_FLAG_BEST_INVEST,
  FEATURE_FLAG_EARLY_ACCESS,
  FEATURE_FLAG_REPORTING,
  FEATURE_FLAG_SERVICE_CONTRACTS,
  FEATURE_FLAG_SOLUTIONS_INTEGRATION,
  SlSharedProductLineToEquipmentPipe,
  UserActions,
} from '@sales-libs/shared/util';
import { GaService } from '@shared-lib/google-analytics';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { appMenu, solutionMenu } from './shared/menu';
import { FilterableMenuItem } from './shared/menu/model';

@Component({
  selector: 'cpq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  @ViewChild('routerContainer') routerContainer: ElementRef;

  totalAmount$: Observable<number>;

  offerPage: boolean;

  readonly showLoadingSpinner$: Observable<boolean>;
  readonly loadingSpinnerValue$: Observable<number>;
  menu: Menu;
  cartMenu?: Menu;

  get isUserGeneralAgent(): boolean {
    return (
      this.userService?.userContext?.partnertype === PARTNERTYPES.GENERAL_AGENT
    );
  }

  private _productLineToEqupimentPipe =
    new SlSharedProductLineToEquipmentPipe();
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    public userService: UserService,
    private _ordersService: OrderService,
    private _translateService: TranslateService,
    private _store: Store,
    private _gaService: GaService,
    private _router: Router,
    private _acRoute: ActivatedRoute,
    private location: Location,
    @Inject(FEATURE_FLAG_EARLY_ACCESS)
    private _isEarlyAccessFeatureFlagSet,
    @Inject(FEATURE_FLAG_SERVICE_CONTRACTS)
    public isServiceContractFeatureFlagSet,
    @Inject(FEATURE_FLAG_SOLUTIONS_INTEGRATION)
    public isSolutionIntegrationFeatureFlagSet,
    @Inject(FEATURE_FLAG_REPORTING)
    private _isReportingFeatureFlagSet,
    @Inject(FEATURE_FLAG_BEST_INVEST)
    private _isBestInvestFeatureFlagSet,
  ) {
    this._matIconRegistry.addSvgIcon(
      `calculator`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/icons/calculator.svg',
      ),
    );
    this._gaService.create();

    // show/hide loadingSpinner
    this.showLoadingSpinner$ = this._store.select(
      RootSelectors.showLoadingSpinner,
    );

    this.loadingSpinnerValue$ = this._store
      .select(RootSelectors.loadingSpinnerValue)
      .pipe(map((value) => value || 0));

    // router reset
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.routerContainer) {
          this.routerContainer.nativeElement.scrollTop = 0;
        }
      });

    // setup dynamic menu
    combineLatest([
      this._store
        .select(ProjectSelectors.currentProject)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
      this._store
        .select(CartSelectors.current)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
      this._store
        .select(CartSelectors.mainProduct)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
    ]).subscribe(([project, cart, product]) => {
      if (project && cart && cart.project_id === project.id) {
        const baseLink = '/projects/' + project.id + '/carts/' + cart.id;
        this.cartMenu = {
          ...solutionMenu,
          title: project.name + ' (' + cart.version + ')',
          equipmentIcon: this._productLineToEqupimentPipe.transform(
            cart?.product_line || 'A1',
          ),
          backButtonUrl: '/projects/' + project.id,
          navigation:
            solutionMenu.navigation
              ?.filter(
                (child) =>
                  !child.filterPredicate ||
                  (child.data?.id === 'best-invest' &&
                    child.filterPredicate(cart.product_line) &&
                    this._isBestInvestFeatureFlagSet),
              )
              .map((child) =>
                this._mapSolutionNavItems(child, baseLink, cart, product?.name),
              ) || [],
        };
      } else {
        this.cartMenu = undefined;
      }
    });
  }

  ngOnInit() {
    const currentPath = this.location.path();
    // Check if the URL matches the pattern "/offer/{number}-{number}" and it's in the correct order
    this.offerPage = /\/offer\/\d+-\d+$/.test(currentPath);

    this.userService.isAuthorized$
      .pipe(
        filter((isAuthorized) => isAuthorized),
        take(1),
      )
      .subscribe(() => {
        if (this.userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_USER)) {
          this._store.dispatch(UserActions.GetEarlyAccessRequested());
        }
        this._createMenu();
      });
  }

  private _mapSolutionNavItems(
    item: FilterableMenuItem,
    baseLink: string,
    cart: Cart,
    productName?: string | null,
  ) {
    const solutionItem = cart?.solution_items?.[0];
    const hasSolution = !!solutionItem;
    const hasProduct = !!productName;
    return {
      ...item,
      routerLink: baseLink + item.routerLink,
      children: item.children
        ?.map((child) =>
          this._mapSolutionNavItems(child, baseLink, cart, productName),
        )
        // filter out sub items of configuration if cart is locked
        .filter(() => !cart.locked || item.data?.id !== 'configuration')
        .filter(
          (child) =>
            !child.filterPredicate ||
            (child.data?.id === 'configuration-product' &&
              child.filterPredicate(hasProduct)) ||
            (child.data?.id === 'product-selection' &&
              child.filterPredicate(hasProduct, hasSolution)) ||
            (child.data?.id === 'solution' &&
              child.filterPredicate(hasProduct)),
        )
        .map((child) => {
          if (child.data?.id === 'configuration-product') {
            return { ...child, title: productName };
          } else if (child.data?.id === 'solution') {
            return {
              ...child,
              routerLink: child.routerLink + '/' + solutionItem?.solution_id,
            };
          }
          return child;
        }),
    };
  }

  private _createMenu() {
    this._store.dispatch(SlSharedStoreActions.GetBadges());
    this.menu = {
      ...appMenu,
      navigation: appMenu.navigation
        .filter(
          (item) =>
            !item.filterPredicate || item.filterPredicate(this.userService),
        )
        .filter(
          (item) =>
            item.data?.id !== 'earlyAccess' ||
            this._isEarlyAccessFeatureFlagSet,
        )
        .filter(
          (item) =>
            item.data?.id !== 'reporting' || this._isReportingFeatureFlagSet,
        )
        .map((item) => ({
          ...item,
          ...(item.data?.id === 'order' &&
          !this.userService.hasRole(
            APPS.PALDESK_CPQ.ROLES.CPQ_SALES_REPRESENTATIVE,
          )
            ? {
                asyncBadge$: this._store
                  .select(SlSharedStoreSelectors.badges)
                  .pipe(
                    map((badges) =>
                      badges?.new_current_orders ? '!' : undefined,
                    ),
                  ),
                isBadgeIcon: true,
              }
            : {}),
          children: this._getChildren(item.children),
        })),
    };
  }

  private _getChildren(
    children: FilterableMenuItem[] | undefined,
  ): FilterableMenuItem[] | undefined {
    if (!children) return undefined;
    return children
      .filter(
        (child) =>
          (!child.filterPredicate || child.filterPredicate(this.userService)) &&
          (child.data?.id !== 'inquiries' ||
            this.isSolutionIntegrationFeatureFlagSet),
      )
      .map((childItem) => ({
        ...childItem,
        children: childItem.children
          ? childItem.children.filter(
              (child) =>
                !child.filterPredicate ||
                child.filterPredicate(this.userService),
            )
          : childItem.children,
      }))
      .map((childItem) =>
        this.userService.hasRole(
          APPS.PALDESK_CPQ.ROLES.CPQ_SALES_REPRESENTATIVE,
        )
          ? childItem
          : this._addBadgesToOrderItemsAndChildren(childItem),
      );
  }

  private _addBadgesToOrderItemsAndChildren(
    item: FilterableMenuItem,
  ): FilterableMenuItem {
    const badgedItem = this._addBadgesToOrderItems(item);
    return {
      ...badgedItem,
      children: badgedItem.children?.map((child) =>
        this._addBadgesToOrderItems(child),
      ),
    };
  }

  private _addBadgesToOrderItems(item: FilterableMenuItem): FilterableMenuItem {
    switch (item.data?.id) {
      case 'currentOrders': {
        return {
          ...item,
          asyncBadge$: this._store
            .select(SlSharedStoreSelectors.badges)
            .pipe(map((badges) => badges?.new_current_orders || undefined)),
        };
      }
      case 'ordersFailedToSend': {
        return {
          ...item,
          asyncBadge$: this._store
            .select(SlSharedStoreSelectors.badges)
            .pipe(map((badges) => badges?.failed_to_send_orders || undefined)),
        };
      }
      case 'orderHistoryNewDelayed': {
        return {
          ...item,
          asyncBadge$: this._store
            .select(SlSharedStoreSelectors.badges)
            .pipe(map((badges) => badges?.new_delay_unseen || undefined)),
        };
      }
      case 'orderHistory': {
        return {
          ...item,
          asyncBadge$: this._store
            .select(SlSharedStoreSelectors.badges)
            .pipe(
              map((badges) => (badges?.new_delay_unseen ? '!' : undefined)),
            ),
          isBadgeIcon: true,
        };
      }
      default: {
        return item;
      }
    }
  }
}
