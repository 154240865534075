<ng-container
  gaLabelGroup="InvalidConfigurationPage"
  gaCategoryGroup="CartDetail"
>
  <ng-container *ngIf="project$ | async as project; else loading">
    <ng-container *ngIf="cart$ | async as cart; else loading">
      <ng-container *ngIf="isCartValid$ | async; else invalidConfigPlaceholder">
        <ng-content></ng-content>
      </ng-container>

      <ng-template #invalidConfigPlaceholder>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="full-height full-width"
        >
          <ds-placeholder
            type="report"
            [customHeadline]="placeholderHeadline"
            gaClickListener="PlaceholderClick"
          >
            {{ 'project_detail.invalid_configuration.hint' | translate }}
            <br />
            <br />
            <a
              mat-raised-button
              color="primary"
              type="submit"
              gaClickListener="GoToConfigurationClick"
              data-test="invalid-configuration-button"
              [routerLink]="[
                '/projects',
                project.id,
                'carts',
                cart.id,
                'configuration'
              ]"
            >
              {{ 'project_detail.invalid_configuration.button' | translate }}
            </a>
          </ds-placeholder>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div fxLayout fxLayoutAlign="center center" class="full-width full-height">
      <mat-progress-spinner
        [mode]="'indeterminate'"
        color="accent"
        [diameter]="100"
        gaClickListener="LoadingClick"
      >
      </mat-progress-spinner>
    </div>
  </ng-template>
</ng-container>
