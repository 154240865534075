<ng-container gaLabelGroup="DocumentList" gaCategoryGroup="ProjectDetail">
  <ng-container *pdLet="documents$ | async as documents">
    <ng-container *ngIf="(isLoaded$ | async) || documents; else loading">
      <ds-expansion-panel
        *ngIf="documents?.length > 0; else noData"
        class="full-width"
        [zIndex]="1"
      >
        <table mat-table [dataSource]="documents$ | async">
          <caption hidden>
            {{
              'common.documents' | translate
            }}
          </caption>
          <!-- file name -->
          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef id="name">
              {{ 'documents.name' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              gaClickListener="FileNameClick"
            >
              <div class="cut-text file-name" [matTooltip]="document.file_name">
                {{ document.file_name }}
              </div>
            </td>
          </ng-container>

          <!-- file size -->
          <ng-container matColumnDef="file_size">
            <th mat-header-cell *matHeaderCellDef id="size">
              {{ 'documents.size' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              gaClickListener="FileSizeClick"
            >
              {{ document.file_size | pdFileSize }}
            </td>
          </ng-container>

          <!-- created by -->
          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef id="created_by">
              {{ 'documents.created_by' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              gaClickListener="CreatedByClick"
            >
              {{ document.created_by }}
            </td>
          </ng-container>

          <!-- created on -->
          <ng-container matColumnDef="created_on">
            <th mat-header-cell *matHeaderCellDef id="created_on">
              {{ 'documents.created_on' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              gaClickListener="CreatedOnClick"
            >
              {{ document.created_on | date }}
            </td>
          </ng-container>

          <!-- actions -->
          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef id="actions">
              <button
                mat-icon-button
                noToggle
                class="sl-file-select-button mat-accent"
                [matTooltip]="'documents.upload' | translate"
                matTooltipPosition="left"
                data-test="upload-button"
                gaClickListener="UploadClick"
              >
                <mat-icon>cloud_upload</mat-icon>
                <sl-shared-file-select
                  type="msOffice"
                  (fileSelect)="onFileSelect($event)"
                ></sl-shared-file-select>
              </button>
            </th>
            <td
              mat-cell
              *matCellDef="let document"
              class="mat-action-cell"
              (click)="$event.stopPropagation()"
            >
              <a
                mat-icon-button
                (click)="deleteDocument(document)"
                [matTooltip]="'common.delete' | translate"
                data-test="delete-button"
                gaClickListener="DeleteClick"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <a
                mat-icon-button
                [href]="document.file_url"
                target="_blank"
                download
                [matTooltip]="'documents.download' | translate"
                data-test="download-button"
                gaClickListener="DownloadClick"
              >
                <mat-icon>cloud_download</mat-icon>
              </a>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let document; columns: displayedColumns"
            data-test="project-document"
          ></tr>
        </table>
      </ds-expansion-panel>
    </ng-container>
  </ng-container>

  <ng-template #noData>
    <div class="upload-holder">
      <button
        mat-raised-button
        class="sl-file-select-button full-width no-data-button"
        gaClickListener="AddDocumentClick"
        data-test="add-button"
      >
        <mat-icon>add </mat-icon>
        {{ 'documents.add_document' | translate }}
      </button>
      <sl-shared-file-select
        type="msOffice"
        (fileSelect)="onFileSelect($event)"
      ></sl-shared-file-select>
    </div>
  </ng-template>

  <ng-template #loading>
    <mat-card>
      <mat-card-content>
        <ds-table-loading
          [numberOfHeaders]="4"
          [numberOfRows]="3"
          gaClickListener="LoadingClick"
        ></ds-table-loading>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
