import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CartDocument } from '@sales-libs/project/data-access';
import { nameofFactory } from '@utils/name-of';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartActions, CartSelectors } from '../store';

const nameofDocument = nameofFactory<CartDocument>();

@Component({
  selector: 'sl-project-checkout-documents',
  templateUrl: './checkout-documents.component.html',
  styleUrls: ['./checkout-documents.component.scss'],
  standalone: false,
})
export class SlProjectCheckoutDocumentsComponent implements OnInit {
  @Input()
  isTuningCenter: boolean;
  @Input()
  isMcc: boolean;

  form = new FormGroup({});
  documents$: Observable<CartDocument[]>;
  documentIds: number[];

  readonly displayedColumns = [nameofDocument('file_name'), 'actions'];

  constructor(private readonly _store: Store<any>) {
    this.documents$ = this._store.select(CartSelectors.documents).pipe(
      map((value) =>
        value.filter(
          (doc) =>
            (!!this.isTuningCenter && doc.is_tuning_center) ||
            (!!this.isMcc && doc.is_mcc) ||
            (!this.isTuningCenter && !this.isMcc),
        ),
      ),
      tap((value) => {
        this.documentIds = value.map((x) => x.id);
      }),
    );
  }

  ngOnInit() {
    this._store.dispatch(CartActions.GetCheckoutDocuments());
  }

  onFileSelect(event: File): void {
    this._store.dispatch(
      CartActions.CreateCheckoutDocument({
        file: event,
        isTuningCenter: this.isTuningCenter,
        isMcc: this.isMcc,
      }),
    );
  }

  deleteDocument(id: number) {
    this._store.dispatch(CartActions.DeleteCheckoutDocument({ docId: id }));
  }
}
