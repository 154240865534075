<ng-container matColumnDef="code" sticky>
  <th mat-header-cell *matHeaderCellDef id="code">
    {{ 'table_columns.code' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [ngClass]="{
      'error-ribbon': project.state === states.Lost,
      'success-ribbon':
        project.state === states.Won || project.state === states.Closed
    }"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'CodeClick'
    }"
  >
    {{ project.code }}
  </td>
</ng-container>
