<div
  matDialogTitle
  [gaClickListener]="{
    category: 'SolutionConfiguration',
    label: 'Options',
    action: 'NameClick'
  }"
  data-test="title"
>
  {{ data.name }}
</div>
<mat-dialog-content data-test="options-table">
  @if (data.options.length > 0) {
    <ds-table
      gaLabelGroup="OptionsTable"
      gaCategoryGroup="SolutionConfiguration"
      data-test="table"
    >
      <table mat-table [dataSource]="data.options">
        <!-- code -->
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let option"
            gaClickListener="CodeClick"
            data-test="code-cell"
          >
            <span> {{ option.code }} </span>
          </td>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let option"
            gaClickListener="NameClick"
            data-test="name-cell"
          >
            <span> {{ option.name }} </span>
          </td>
        </ng-container>

        <!-- price -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="price"></th>
          <td
            mat-cell
            *matCellDef="let option"
            class="price text-right"
            gaClickListener="PriceClick"
            data-test="price-cell"
          >
            {{ option.price | slMoneyAmount$ | async }}
          </td>
        </ng-container>
        <tr
          mat-row
          *matRowDef="let option; columns: displayColumns"
          data-test="configuration-options-row"
          class="data-test-{{ option.name | slCssClass }}"
        ></tr>
      </table>
    </ds-table>
  } @else {
    <ds-placeholder type="no_data" data-test="no-data-placeholder">
    </ds-placeholder>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close gaClickListener="CloseClick">
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
