import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { FilterValue } from '@design-system/components/filter-input';
import { Store } from '@ngrx/store';
import {
  Competitor,
  LostOrdersService,
  Reason,
} from '@sales-libs/project/data-access';
import { filterTruthy } from '@shared-lib/rxjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { LostOrderSelectors } from '../../../lost-order/store';
import { LostOrderReportingFilters } from '../../../models';

interface LostOrderReportingFiltersForm {
  startDate: FormControl<Dayjs | undefined>;
  endDate: FormControl<Dayjs | undefined>;
  competitors: FormControl<number[] | undefined>;
  productLines: FormControl<string[] | undefined>;
  products: FormControl<string[] | undefined>;
  reasons: FormControl<number[] | undefined>;
  createdBy: FormControl<string[] | undefined>;
}

@Component({
  selector: 'sl-project-lost-order-reporting-filters',
  templateUrl: './lost-order-reporting-filters.component.html',
  styleUrls: ['./lost-order-reporting-filters.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderReportingFiltersComponent
  implements OnInit, OnDestroy
{
  @Output()
  readonly filter = new EventEmitter<LostOrderReportingFilters>();

  competitors: Competitor[];
  reasons: Reason[];
  products: FilterValue[] = [];
  productLines: string[];
  createdBy: string[];

  isCompetitorsLoading = true;
  isReasonsLoading = true;
  isProductLinesLoading = true;
  isProductsLoading = true;
  isCreatedByLoading = true;

  dsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  private _isStoreUpdate = false;
  private readonly _destroy$ = new Subject<void>();

  readonly filtersForm = new FormGroup<LostOrderReportingFiltersForm>({
    startDate: new FormControl<Dayjs | undefined>(undefined, {
      nonNullable: true,
    }),
    endDate: new FormControl<Dayjs | undefined>(undefined, {
      nonNullable: true,
    }),
    competitors: new FormControl<number[] | undefined>([], {
      nonNullable: true,
    }),
    productLines: new FormControl<string[] | undefined>([], {
      nonNullable: true,
    }),
    products: new FormControl<string[] | undefined>([], {
      nonNullable: true,
    }),
    reasons: new FormControl<number[] | undefined>([], {
      nonNullable: true,
    }),
    createdBy: new FormControl<string[] | undefined>([], {
      nonNullable: true,
    }),
  });

  constructor(
    private _store: Store,
    private _lostOrdersService: LostOrdersService,
  ) {
    // set filter values from store but do not emit
    this._store
      .select(LostOrderSelectors.reportingFilter)
      .pipe(filterTruthy(), takeUntil(this._destroy$))
      .subscribe((value) => {
        this._isStoreUpdate = true;
        this.filtersForm.patchValue(
          {
            ...value,
            startDate: value.startDate ? dayjs(value.startDate) : undefined,
            endDate: value.endDate ? dayjs(value.endDate) : undefined,
            reasons: value.reasons ?? [],
            competitors: value.competitors ?? [],
            productLines: value.productLines ?? [],
            products: value.products ?? [],
            createdBy: value.createdBy ?? [],
          },
          { emitEvent: false, onlySelf: true },
        );
        this._isStoreUpdate = false;
      });
  }

  ngOnInit() {
    this._getFilterValues();

    this.filtersForm.valueChanges
      .pipe(
        filter(() => !this._isStoreUpdate),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        const value = this.filtersForm.getRawValue();
        this.filter.emit({
          ...value,
          startDate: value.startDate
            ? new Date(value.startDate.format('YYYY-MM-DD'))
            : undefined,
          endDate: value.endDate
            ? new Date(value.endDate.format('YYYY-MM-DD'))
            : undefined,
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getFilterValues() {
    this.getCompetitors();
    this.getReasons();
    this.getProductLines();
    this.getProducts();
    this.getCreatedBy();
  }

  getCompetitors() {
    this.isCompetitorsLoading = true;
    this.filtersForm.controls.competitors.disable({ emitEvent: false });
    this._lostOrdersService.getCompetitorFilterData().subscribe({
      next: (data) => {
        this.competitors = data.items.filter((item) => !!item);
        this.isCompetitorsLoading = false;
        this.filtersForm.controls.competitors.enable({ emitEvent: false });
      },
      error: () => {
        this.isCompetitorsLoading = false;
      },
    });
  }

  getReasons() {
    this.isReasonsLoading = true;
    this.filtersForm.controls.reasons.disable({ emitEvent: false });
    this._lostOrdersService.getReasonFilterData().subscribe({
      next: (data) => {
        this.reasons = data.items.filter((item) => !!item);
        this.isReasonsLoading = false;
        this.filtersForm.controls.reasons.enable({ emitEvent: false });
      },
      error: () => {
        this.isReasonsLoading = false;
      },
    });
  }

  getProductLines() {
    this.isProductLinesLoading = true;
    this.filtersForm.controls.productLines.disable({ emitEvent: false });
    this._lostOrdersService.getProductLineFilterData().subscribe({
      next: (data) => {
        this.productLines = data.filter((item) => !!item);
        this.isProductLinesLoading = false;
        this.filtersForm.controls.productLines.enable({ emitEvent: false });
      },
      error: () => {
        this.isProductLinesLoading = false;
      },
    });
  }

  getProducts() {
    this.isProductsLoading = true;
    this.filtersForm.controls.products.disable({ emitEvent: false });
    this._lostOrdersService.getProductFilterData().subscribe({
      next: (data) => {
        this.products = data
          .filter((item) => !!item)
          .sort()
          .map((item) => ({ value: item, viewValue: item }));
        this.isProductsLoading = false;
        this.filtersForm.controls.products.enable({ emitEvent: false });
      },
      error: () => {
        this.isProductsLoading = false;
      },
    });
  }

  getCreatedBy() {
    this.isCreatedByLoading = true;
    this.filtersForm.controls.createdBy.disable({ emitEvent: false });
    this._lostOrdersService.getCreatedByFilterData().subscribe({
      next: (data) => {
        this.createdBy = data.filter((item) => !!item);
        this.isCreatedByLoading = false;
        this.filtersForm.controls.createdBy.enable({ emitEvent: false });
      },
      error: () => {
        this.isCreatedByLoading = false;
      },
    });
  }
}
