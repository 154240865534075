<tr class="mat-header-row">
  <th class="mat-header-cell mat-table-sticky sort-cell" id="image">
    {{ 'order_details.positions' | translate }}
  </th>
  <th class="mat-header-cell" id="name">
    {{ 'order_details.name' | translate }}
  </th>
  <th class="mat-header-cell" id="configuration">
    {{ 'project.configuration' | translate }}
  </th>
  <th class="mat-header-cell" id="optional_indicator" *ngIf="isTypeVisible">
    {{ 'order_details.item_type' | translate }}
  </th>
  <th class="mat-header-cell" id="description">
    {{ 'order_details.description' | translate }}
  </th>
  <th class="mat-header-cell" id="price" *ngIf="isPriceVisible">
    {{ 'order_details.price' | translate }}
  </th>
  <th class="mat-header-cell" id="quantity">
    {{ 'order_details.quantity' | translate }}
  </th>
  <th
    class="mat-header-cell mat-table-sticky"
    id="actions"
    *ngIf="!isReadOnly"
  ></th>
</tr>
