import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { dsConfig } from '@design-system/cdk/config';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  BestInvestCartParameters,
  BestInvestFeature,
  BestInvestFeatures,
  BestInvestFeaturesInput,
} from '@sales-libs/upselling/data-access';
import { SlDetailsDialogComponent } from '@sales-libs/upselling/ui';
import {
  Color,
  LegendPosition,
  NgxChartsModule,
  ScaleType,
} from '@swimlane/ngx-charts';
import { isEqual } from 'lodash';
import { take } from 'rxjs';
import { SlUpsellingActions } from '../store';

@Component({
  selector: 'sl-upselling-invest-preview',
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule,
    DsTableModule,
    DsTableLoadingModule,
    DsPlaceholderModule,
    DsSpacingModule,
    DsLoadingModule,
    NgxChartsModule,
  ],
  providers: [CurrencyPipe],
  templateUrl: './invest-preview.component.html',
  styleUrl: './invest-preview.component.scss',
})
export class SlUpsellingInvestPreviewComponent {
  @Input() set cart(value: BestInvestCartParameters) {
    if (!isEqual(this._cart, value)) {
      this._cart = value;
      this.getBestInvestFeatures();
    }
  }

  get cart() {
    return this._cart;
  }
  private _cart: BestInvestCartParameters;

  englishLanguageCode = 'en';
  currencyCode: string;
  data: BestInvestFeatures | undefined;
  sliderValue = 1;
  totalAmountWithSlider = 0;
  featuresInput: BestInvestFeaturesInput;
  totalAmount: number;

  bestInvestFeaturesAvailable: boolean;
  softFactsData: BestInvestFeature[];
  monetaryBenefitsData: BestInvestFeature[];

  totalAmounts: number[] = [];
  view: [number, number] = [270, 300];
  colorScheme: Color = {
    name: 'bestInvestColorScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [dsConfig.colors.primary[500], dsConfig.colors.info['A700']],
  };
  legendPosition = LegendPosition.Below;
  outdatedCraneLabel: string;
  modelChartData: number[] = [];
  chartData: any[] = [];
  outdatedCraneData: any;
  modelId: string;

  constructor(
    public readonly store: Store,
    public readonly currencyPipe: CurrencyPipe,
    public readonly actions: Actions,
    private readonly destroyRef: DestroyRef,
    private readonly snackbar: DsSnackbarService,
    private readonly translateService: TranslateService,
    private readonly _dialog: MatDialog,
  ) {
    this.setOutdatedCraneInfo();
  }

  formatLabel(value: number): string {
    this.sliderValue = value;
    this.totalAmountWithSlider = this.totalAmount * value;
    if (value === 1) {
      return `${value} ${this.translateService.instant('benefits.year')}`;
    }
    return this.translateService.instant('benefits.years', {
      years: value,
    });
  }

  formatYAxisTick(value: number): string {
    return value.toLocaleString('de-DE');
  }

  getBestInvestFeatures(): void {
    this.currencyCode = this.cart?.currency_settings?.code ?? '';
    const productItems = this.cart?.product_items;
    if (!productItems?.length) {
      this.bestInvestFeaturesAvailable = false;
      return;
    }
    this.modelId = productItems[0]?.model_id;

    const filteredSalesOptions = productItems
      ?.find((item) => item.model_id === this.modelId)
      ?.options?.map((salesOption) => salesOption.name);

    this.featuresInput = {
      language: this.cart?.language ?? this.englishLanguageCode,
      feature_filtering: {
        product: this.modelId,
        product_sales_options: filteredSalesOptions ?? [],
      },
    };

    this.store.dispatch(
      SlUpsellingActions.getBestInvestFeatures({
        projectId: this.cart?.project_id,
        cartId: this.cart?.id,
        bestInvestFeaturesInput: this.featuresInput,
      }),
    );

    this.actions
      .pipe(
        ofType(SlUpsellingActions.getBestInvestFeaturesSuccess),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => {
        if (
          (!data.payload.monetary_benefits ||
            data.payload.monetary_benefits.length === 0) &&
          (!data.payload.soft_facts || data.payload.soft_facts.length === 0)
        ) {
          this.bestInvestFeaturesAvailable = false;
          this.snackbar.queue(
            this.translateService.instant(
              'error_messages.best_invest.features_not_available',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
          return;
        }

        this.monetaryBenefitsData = data.payload.monetary_benefits ?? [];
        this.softFactsData = data.payload.soft_facts ?? [];
        this.totalAmount = this._calculateTotalSum(data.payload);
        this.totalAmounts = [...Array(10)].map(
          (_, index) => +(this.totalAmount * (index + 1)).toFixed(2),
        );
        this.modelChartData = this._mapChartData(this.totalAmounts);
        if (this.modelChartData?.length > 0) {
          this.chartData = [
            {
              name: this.modelId ?? '',
              series: this.modelChartData,
            },
            this.outdatedCraneData,
          ];
        }
        this.bestInvestFeaturesAvailable = true;
      });

    this.actions
      .pipe(
        ofType(SlUpsellingActions.getBestInvestFeaturesError),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.bestInvestFeaturesAvailable = false;
        this.snackbar.queue(
          this.translateService.instant(
            'error_messages.best_invest.features_not_available',
          ),
          {
            type: DsSnackbarType.Error,
          },
        );
      });
  }

  setOutdatedCraneInfo() {
    this.outdatedCraneLabel = this.translateService.instant(
      'benefits.outdated_crane',
    );
    this.outdatedCraneData = {
      name: this.outdatedCraneLabel,
      series: [...Array(10)].map((_, i) => ({
        name: (i + 1).toString(),
        value: 0,
      })),
    };
  }

  openMoreDetailsDialog(item: any): void {
    this._dialog.open(SlDetailsDialogComponent, {
      width: String(dsConfig.spacing * 25) + 'px',
      data: {
        feature: item,
      },
    });
  }

  private _calculateTotalSum(data: any): number {
    const sumValues = (arr: any[] = []) =>
      arr.reduce((sum, item) => sum + (item?.value || 0), 0);

    return sumValues(data?.monetary_benefits) + sumValues(data?.soft_facts);
  }

  private _mapChartData(yearlyAmounts: number[]): any {
    return [...Array(10)].map((_, index) => ({
      name: (index + 1).toString(),
      value: yearlyAmounts[index],
    }));
  }
}
