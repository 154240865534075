import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ContractItem,
  CustomArticleItem,
  MccItem,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

type EditAbleItem =
  | ContractItem
  | CustomArticleItem
  | MccItem
  | SalesOptionCartItem
  | TuningCenterItem
  | WarrantyExtensionItem;

@Component({
  selector: 'sl-project-state-column',
  templateUrl: './state-column.component.html',
  styleUrls: ['./state-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectStateColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.itemAsEditableItem = value as EditAbleItem;
  }

  itemAsEditableItem: EditAbleItem;
}
