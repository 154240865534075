<h1>
  {{ 'reporting.lost_order_reporting' | translate }}
</h1>
<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="container">
  <sl-project-lost-order-reporting-filters
    (filter)="updateFilter($event)"
  ></sl-project-lost-order-reporting-filters>
  @if ((totalLostOrders$ | async) === 0) {
    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
      <ds-placeholder
        type="no_data"
        [customHeadline]="'reporting.no_data_for_filter' | translate"
      >
      </ds-placeholder>
    </div>
  } @else {
    <div>
      <div fxLayout="row grid" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
        <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
          <sl-shared-reporting-single-value-widget
            [value]="totalLostOrders$ | async"
            [text]="'reporting.total_lost_orders' | translate"
            [color]="singleValueColors[0]"
            [isClickable]="false"
          ></sl-shared-reporting-single-value-widget>
        </div>
        <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
          <sl-shared-reporting-single-value-widget
            *pdLet="mainCompetitor$ | async as mainCompetitor"
            [value]="mainCompetitor?.name"
            [text]="'reporting.main_competitor' | translate"
            [color]="singleValueColors[1]"
            (click)="
              updateFilterViaWidget({ competitors: [mainCompetitor.id] })
            "
          ></sl-shared-reporting-single-value-widget>
        </div>
        <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
          <sl-shared-reporting-single-value-widget
            *pdLet="mainReason$ | async as mainReason"
            [value]="mainReason?.name"
            [text]="'reporting.main_reason' | translate"
            [color]="singleValueColors[2]"
            (click)="updateFilterViaWidget({ reasons: [mainReason.id] })"
          ></sl-shared-reporting-single-value-widget>
        </div>
      </div>
    </div>
    <div>
      <div fxLayout="row grid" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <sl-shared-reporting-bar-chart
            *pdLet="reasonsData$ | async as reasonsData"
            [headline]="'reporting.reasons' | translate"
            [data]="reasonsData"
            (barClick)="updateFilterViaWidget({ reasons: [$event] })"
          ></sl-shared-reporting-bar-chart>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%">
          <sl-shared-reporting-bar-chart
            *pdLet="competitorsData$ | async as competitorsData"
            [headline]="'reporting.competitors' | translate"
            [data]="competitorsData"
            (barClick)="updateFilterViaWidget({ competitors: [$event] })"
          ></sl-shared-reporting-bar-chart>
        </div>
      </div>
    </div>
  }
</div>
