<ng-container gaLabelGroup="EditProject" gaCategoryGroup="Projects">
  <h3
    matDialogTitle
    gaClickListener="TitleClick"
    data-test="edit-project-dialog-title"
  >
    {{ 'project.project' | translate }}
    <ng-container *ngIf="project && project.id">
      {{ project.code }}
      <ng-container *ngIf="project.state">
        (
        {{ 'project.state' | translate }}:
        <span
          [ngClass]="{
            'error-text': project.state === states.Lost,
            'success-text':
              project.state === states.Won || project.state === states.Closed
          }"
        >
          {{ project.state | slProjectStateTranslationKey | translate }}</span
        >
        )
      </ng-container>
    </ng-container>
  </h3>
  <form [formGroup]="form">
    <mat-dialog-content data-test="edit-project-content">
      <div fxLayout="column">
        <mat-form-field class="full-width" gaClickListener="NameClick">
          <mat-label>{{ 'project.name' | translate }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="form.controls.name"
            required
            autocomplete="off"
            data-test="edit-project-name"
            [maxLength]="maxNameLength"
          />
          <mat-error *ngIf="form.controls.name.invalid">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
          <mat-hint align="end">
            {{ form.controls.name.value?.length || 0 }} /
            {{ maxNameLength }}
          </mat-hint>
        </mat-form-field>
        <label
          >{{ 'project.chance' | translate }}
          <ng-container *ngIf="form.controls.chance.value as chance">
            : {{ chance }}%
          </ng-container></label
        >
        <mat-slider
          min="0"
          max="100"
          [discrete]="true"
          [step]="25"
          [color]="'accent'"
          class="full-width"
          gaClickListener="ChanceClick"
          data-test="edit-project-chance"
        >
          <input matSliderThumb [formControl]="form.controls.chance" />
        </mat-slider>
        <mat-form-field class="full-width" gaClickListener="EndDateClick">
          <mat-label>{{ 'project.end_date' | translate }}</mat-label>
          <input
            matInput
            [min]="project?.created_on"
            [formControl]="form.controls.closed_date"
            [matDatepicker]="pickerEndDate"
            autocomplete="off"
            data-test="edit-project-end-date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
        </mat-form-field>

        <div
          *ngIf="!form.controls.is_whole_sale_order.value"
          [@transitionMessages]="
            form.controls.is_whole_sale_order.value ? 'void' : 'enter'
          "
        >
          <pd-crm-customer-select
            [label]="'project.customer' | translate"
            [control]="form.controls.customer"
            [accountId]="project?.external_customer_number"
            gaClickListener="EditCustomerClick"
            data-test="edit-project-customer-select"
          >
          </pd-crm-customer-select>
        </div>
        <mat-checkbox
          [formControl]="form.controls.is_whole_sale_order"
          [spotlight]="wholeSaleOrderSpotlight"
          gaClickListener="WholeSaleOrderClick"
          data-test="edit-project-wholesale-order"
          >{{ 'project.wholesale_order' | translate }}
        </mat-checkbox>
        <mat-form-field class="full-width" gaClickListener="RequirementClick">
          <mat-label>{{ 'project.requirement' | translate }}</mat-label>
          <textarea
            matInput
            type="text"
            rows="1"
            [formControl]="form.controls.comment"
            data-test="edit-project-comment"
            [maxlength]="maxRequirementLength"
          ></textarea>
          <mat-hint align="end">
            {{ form.controls.comment.value?.length || 0 }} /
            {{ maxRequirementLength }}
          </mat-hint>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div
        fxLayout="row wrap"
        fxLayout.xs="column"
        [fxLayoutGap]="1 | dsSpacing"
        [fxLayoutGap.xs]="0.5 | dsSpacing"
        fxLayoutAlign="end stretch"
        fxLayoutAlign.xs="start stretch"
        class="full-width"
      >
        <button
          mat-button
          type="button"
          mat-dialog-close
          gaClickListener="CancelClick"
        >
          {{ 'general.cancel' | translate }}
        </button>
        <!-- not on project creation -->
        <ng-container *ngIf="project && project.id">
          <button
            mat-stroked-button
            type="button"
            (click)="createLostOrder(project)"
            *ngIf="project.state === states.InProgress"
            gaClickListener="LostOrderClick"
            data-test="set-to-Lost-button"
          >
            {{ 'project.lost_order' | translate }}
          </button>
          <ng-container *ngIf="project.state === states.Lost">
            <div>
              <button
                type="button"
                mat-stroked-button
                gaClickListener="EditLostOrderClick"
                (click)="editOrder(project)"
                [spotlight]="editLostOrderButtonSpotlight"
              >
                {{ 'project.edit_lost_order' | translate }}
              </button>

              <ds-spotlight
                #editLostOrderButtonSpotlight
                id="sl-project-edit-lost-order-button-spotlight"
                [tags]="['release_2021_10', 'edit-project', 'project']"
              >
                {{ 'spotlight.release_2021_10.edit_project_lost' | translate }}
              </ds-spotlight>
            </div>
            <button
              mat-stroked-button
              type="button"
              (click)="deleteLostOrder(project)"
              mat-dialog-close
              gaClickListener="SetToInProgressClick"
              data-test="set-to-InProgress-button"
            >
              {{ 'project.set_to_in_progress' | translate }}
            </button>
          </ng-container>

          <div *ngIf="project.state === states.Won">
            <button
              mat-stroked-button
              type="button"
              gaClickListener="OpenOrderClick"
              (click)="editOrder(project)"
              [spotlight]="openOrderSpotlight"
            >
              {{ 'project.open_order' | translate }}
            </button>
            <ds-spotlight
              #openOrderSpotlight
              id="cpq-open-order-button-spotlight"
              [tags]="['release_2021_10', 'edit-project', 'project']"
            >
              {{ 'spotlight.release_2021_10.edit_project_won' | translate }}
            </ds-spotlight>
          </div>
        </ng-container>

        <!-- mat-dialog-close does not work on form submit buttons, see https://github.com/angular/material2/issues/2599 -->
        <button
          mat-raised-button
          type="submit"
          color="primary"
          (click)="confirmChange()"
          [disabled]="!form.valid"
          gaClickListener="SaveClick"
          data-test="edit-project-save"
          cdkFocusInitial
        >
          {{ 'common.save' | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  </form>

  <ds-spotlight
    id="cpq-spotlight-project-detail-wholesale-order"
    #wholeSaleOrderSpotlight
    position="left"
  >
    {{ 'spotlight.release_2020_05.project_detail.wholesale_order' | translate }}
  </ds-spotlight>
</ng-container>
