import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  CustomArticleItem,
  CustomArticleItemUpdate,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable, filter, map, takeUntil, tap } from 'rxjs';
import { SlProjectEditCustomCartItemComponent } from '../../edit-custom-cart-item/edit-custom-cart-item.component';
import { CartActions, CartSelectors } from '../../store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectCustomArticleCartItems]',
  templateUrl: './custom-article-cart-items.component.html',
  styleUrls: ['./custom-article-cart-items.component.scss'],
  standalone: false,
})
export class SlProjectCustomArticleCartItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;
  @Input() customArticleSortingUpdateCount = 0;

  readonly items$: Observable<CustomArticleItem[] | undefined>;

  constructor(
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
  ) {
    this.items$ = this._store.select(CartSelectors.customArticleItems).pipe(
      tap(() => this.customArticleSortingUpdateCount--),
      filter(() => this.customArticleSortingUpdateCount <= 0),
      map((items) => items?.sort((a, b) => a.sort_key - b.sort_key) ?? []),
    );
  }

  onQuantityChange(item: SlCartItem) {
    this._dispatchUpdate(item);
  }

  onToOptional(item: SlCartItem) {
    this._dispatchUpdate({ ...item, is_optional: true });
  }

  onDelete(itemToDelete: SlCartItem) {
    this._store.dispatch(
      CartActions.DeleteCustomArticle({
        itemId: itemToDelete.id as string,
        cartId: itemToDelete.cart_id,
      }),
    );
  }

  onEdit(item: SlCartItem) {
    const dialogRef = this._dialog.open(SlProjectEditCustomCartItemComponent);
    dialogRef.componentInstance.item = item as CustomArticleItem;
    dialogRef.componentInstance.editConfirmed
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe((event) => {
        this._dispatchUpdate(event);
        dialogRef.close();
      });
  }

  private _dispatchUpdate(update: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateCustomArticle({
        update: update as CustomArticleItemUpdate,
      }),
    );
  }
}
