import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import {
  Calculation,
  CalculationItem,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';

import { DsColumnComponent } from '@design-system/cdk/column';
import { filterTruthy } from '@shared-lib/rxjs';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-project-bulk-discount-header',
  templateUrl: './bulk-discount-header.component.html',
  styleUrls: ['./bulk-discount-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectBulkDiscountHeaderComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() label: string;
  @Input() percentSymbol: string;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this.formControl.setValue(null, { emitEvent: false });
  }
  @Output() calculationChange = new EventEmitter<Calculation>();
  @Output() invalidChange = new EventEmitter();

  readonly triggerBlur = triggerBlur;

  formControl = new FormControl<number | null>(null, {
    validators: Validators.max(100),
    updateOn: 'blur',
  });
  private _calculation: Calculation;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }

    this.formDirective.form.addControl(
      this.perspective + 'BulkDiscount',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(filterTruthy(), takeUntil(this._destroy$))
      .subscribe((value) => {
        if (this.formControl.valid) {
          this.calculationChange.emit(this._applyBulkDiscount(value));
        }
      });

    this.formControl.statusChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (this.formControl.invalid) {
          this.invalidChange.emit();
        }
      });
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setItemsDiscount(
    items: CalculationItem[],
    discount: number,
    isParentOptional = false,
  ): CalculationItem[] {
    return items.map((item) =>
      'is_read_only' in item &&
      (!item.is_read_only ||
        item.item_type === CalculationItemItemType.Group) &&
      (item.is_optional || isParentOptional) === this.isAlternativeView
        ? {
            ...item,
            [this.perspective]: item[this.perspective]
              ? {
                  ...item[this.perspective],
                  discount: discount / 100, // as percentage
                  discounted_price: null, // reset, cause backend does prioritzes this value
                }
              : item[this.perspective],
            items: item.items
              ? this._setItemsDiscount(item.items, discount, item.is_optional)
              : item.items,
          }
        : item,
    );
  }

  private _applyBulkDiscount(value: number): Calculation {
    return {
      ...this._calculation,
      items: this._setItemsDiscount(this._calculation.items, value).reduce(
        (acc, item) => {
          acc.push(item);
          if (item.items) {
            acc.push(...item.items);
          }
          return acc;
        },
        [] as CalculationItem[],
      ),
      [this.perspective]:
        this._calculation[this.perspective] && !this.isAlternativeView // don't reset absolute discount if alternative item changes
          ? {
              ...this._calculation[this.perspective],
              absolute_discount: 0,
            }
          : this._calculation[this.perspective],
    };
  }
}
