import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RootActions } from '@features/root-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CartItemsService,
  ProductItemInputProductType,
  ProductItemProductType,
} from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ProjectSelectors } from '../../projects';
import { CartActions } from '../cart.actions';
import { CartSelectors } from '../cart.selectors';

@Injectable()
export class ProductItemEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _store: Store = inject(Store);

  get$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.GetProductItem),
      map((action) => action.id),
      withLatestFrom(
        this._store.select(CartSelectors.current).pipe(filterTruthy()),
      ),
      switchMap(([id, cart]) =>
        this._cartItemsService.getProductItemAsync(cart.id, id).pipe(
          map((data) =>
            CartActions.GetProductItemSuccess({
              item: data,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.product_not_loaded',
              ),
            }),
            CartActions.GetProductItemError(),
          ]),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.AddProductItem),
      tap(() => this._store.dispatch(RootActions.ShowLoadingSpinner())),
      map((action) => action.item),
      withLatestFrom(this._store.select(ProjectSelectors.id)),
      mergeMap(([item, projectId]) =>
        this._cartItemsService.addProductItemAsync(item).pipe(
          tap(() => {
            if (item.product_type === ProductItemInputProductType.Product) {
              this._router.navigate([
                'projects',
                projectId,
                'carts',
                item.cart_id,
                'configuration',
                'options',
              ]);
            }
          }),
          mergeMap((data) => [
            CartActions.AddProductItemSuccess({
              item: data,
            }),
            SnackbarActions.ShowSuccess({
              message: this._translateService.instant(
                'configuration.added_to_cart',
                { item: item.name },
              ),
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.product_not_added',
              ),
            }),
            CartActions.AddProductItemError(),
          ]),
        ),
      ),
      tap(() => this._store.dispatch(RootActions.HideLoadingSpinner())),
    ),
  );

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateProductItem),
      mergeMap((payload) =>
        this._cartItemsService.updateProductItemAsync(payload.update).pipe(
          mergeMap(() => [
            CartActions.UpdateProductItemSuccess({
              update: payload.update,
              productType: payload.productType,
            }),
            ...(payload.showSuccessSnackbar
              ? [
                  SnackbarActions.ShowSuccess({
                    message: this._translateService.instant(
                      'configuration.updated',
                      {
                        item: payload.update.name,
                      },
                    ),
                  }),
                ]
              : []),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.product_not_updated',
              ),
            }),
            CartActions.UpdateProductItemError(),
          ]),
        ),
      ),
    ),
  );

  removeOptionalItemOnProductItemUpdateSuccess$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateProductItemSuccess),
      filter((action) => action.productType === ProductItemProductType.Product),
      map((action) => action.update),
      withLatestFrom(
        this._store.select(CartSelectors.current).pipe(filterTruthy()),
      ),
      mergeMap(([update, cart]) => {
        const itemsToDelete =
          cart.sales_option_items?.filter((item) =>
            update.options?.some(
              (option) =>
                option.name === item.name || option.name === item.original_name,
            ),
          ) ?? [];
        return itemsToDelete.map((item) =>
          CartActions.DeleteSalesOptionItem({
            itemId: item.id as string,
            cartId: cart.id,
          }),
        );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.DeleteProductItem),
      mergeMap((payload) =>
        this._cartItemsService
          .deleteProductItemAsync(payload.cartId, payload.itemId)
          .pipe(
            map(() =>
              CartActions.DeleteProductItemSuccess({
                id: payload.itemId,
              }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.cart.product_not_removed',
                ),
              }),
              CartActions.DeleteProductItemError(),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private readonly _router: Router,
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
