<div
  fxLayout="column"
  fxFlex="100%"
  fxLayoutAlign="center"
  gaCategoryGroup="DigitalOffer"
  gaLabelGroup="Info"
>
  <div fxLayout="row" fxLayoutAlign="flex-start center">
    <a
      href="https://www.palfinger.com"
      target="_blank"
      rel="noopener"
      class="offer-logo"
      gaClickListener="LogoClick"
      data-test="palfinger-logo"
    >
      <img
        src="/assets/img/logo.svg"
        alt="PALFINGER logo"
        priority
        height="40"
        width="auto"
      />
    </a>
  </div>

  <mat-card>
    <mat-card-content class="offer-info">
      <div
        fxLayout="row wrap"
        [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
        fxLayoutAlign="space-between center"
      >
        <div *ngIf="baseData; else infoLoading">
          <h3
            [style.margin-top]="1 | dsSpacing"
            gaClickListener="ProductLineClick"
            data-test="product-line"
          >
            {{ baseData?.product_line }}
          </h3>
          <h4
            class="no-margin-top"
            gaClickListener="CraneNameClick"
            data-test="crane-name"
          >
            {{ baseData?.crane_name }}
          </h4>
        </div>
        <div
          *ngIf="baseData; else infoLoading"
          gaClickListener="OfferNumberClick"
        >
          <h5 class="strong">
            {{ 'offer_your_offer' | translate | uppercase }}
          </h5>
          <p data-test="offer-number">
            {{ 'offer.digital_offer.number' | translate }}:
            {{ baseData?.offer_number }}
          </p>
        </div>

        <div
          *ngIf="baseData; else infoLoading"
          gaClickListener="CreationDateClick"
        >
          <h5 class="strong">
            {{ 'offer_offer_created_on' | translate | uppercase }}
          </h5>
          <p data-test="creation-date">
            {{ baseData?.creation_date }}
          </p>
        </div>

        <div
          *ngIf="baseData; else infoLoading"
          gaClickListener="ValidUntilClick"
        >
          <h5 class="strong">
            {{ 'offer_valid_until' | translate | uppercase }}
          </h5>
          <p data-test="valid-until">
            {{ baseData?.valid_until }}
          </p>
        </div>
      </div>
      <div
        *ngIf="baseData?.reference1 || baseData?.reference2"
        data-test="reference"
      >
        {{ 'offer_reference' | translate }}: {{ baseData?.reference1 }}
        <span *ngIf="baseData?.reference1 && baseData?.reference2">/</span>
        {{ baseData?.reference2 }}
      </div>
    </mat-card-content>

    @if (mainImage) {
      <img
        class="offer-model-image"
        priority
        [src]="mainImage"
        alt="Model"
        gaClickListener="MainImageClick"
        data-test="main-image"
      />
    } @else {
      <ds-loading-placeholder
        [height]="18 | dsSpacing"
        class="full-width"
      ></ds-loading-placeholder>
    }

    <mat-card-content class="offer-contact">
      <div
        fxLayout="row wrap"
        [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
        fxLayoutAlign="space-between start"
      >
        <div *ngIf="customerData; else infoLoading" fxFlex="1 0 auto">
          <h5 class="strong">
            {{ 'offer_offer_to' | translate | uppercase }}
          </h5>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="CustomerCompanyNameClick"
          >
            <mat-icon>apartment_outline</mat-icon>
            <p data-test="customer-company-name">
              {{ customerData?.company_name }}
            </p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="CustomerStreetClick"
          >
            <mat-icon>home_outline</mat-icon>
            <p data-test="customer-company-street">
              {{ customerData?.address?.street }}
            </p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="CustomerCityClick"
          >
            <mat-icon>location_city_outline</mat-icon>
            <p data-test="customer-company-city">
              {{ customerData?.address?.zip }}
              {{ customerData?.address?.city }}
            </p>
          </div>
        </div>

        <div *ngIf="dealerData; else infoLoading" fxFlex="1 0 0">
          <h5 class="strong">
            {{ 'offer_your_contact_person' | translate | uppercase }}
          </h5>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonNameClick"
          >
            <mat-icon>person_outline</mat-icon>
            <p data-test="dealer-person-name">
              {{ dealerData?.contact_person_name }}
            </p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonPhoneNumberClick"
          >
            <mat-icon>phone_outline</mat-icon>
            <p data-test="dealer-phone">
              {{ dealerData.phone ?? dealerData.mobile }}
            </p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonMailClick"
          >
            <mat-icon>mail_outline</mat-icon>
            <p data-test="dealer-mail">{{ dealerData.email }}</p>
          </div>
        </div>
        <div *ngIf="dealerData; else infoLoading" fxFlex="1 0 0">
          <h5>&nbsp;</h5>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonCompanyNameClick"
          >
            <mat-icon>person_outline</mat-icon>
            <p data-test="dealer-company-name">
              {{ dealerData.company_name }}
            </p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonStreetClick"
          >
            <mat-icon>mail_outline</mat-icon>
            <p data-test="dealer-street">{{ dealerData.address?.street }}</p>
          </div>
          <div
            fxLayoutAlign="baseline center"
            gaClickListener="ContactPersonCityClick"
          >
            <mat-icon>phone_outline</mat-icon>
            <p data-test="dealer-city">
              {{ dealerData?.address?.zip }} {{ dealerData?.address?.city }}
            </p>
          </div>
          <div>
            <img
              [src]="dealerData.logo_url"
              class="full-width"
              data-test="dealer-logo"
            />
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #infoLoading>
  <ds-loading-placeholder
    [width]="10 | dsSpacing"
    [height]="2 | dsSpacing"
  ></ds-loading-placeholder>
</ng-template>
