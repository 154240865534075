/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { OfferTechnicalData } from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

@Component({
  selector: 'sl-project-digital-offer-technical-data',
  imports: [
    CommonModule,
    TranslateModule,
    GoogleAnalytics4DirectiveModule,
    DsTextIndicatorModule,
  ],
  templateUrl: './digital-offer-technical-data.component.html',
  styleUrls: ['./digital-offer-technical-data.component.scss'],
})
export class SlProjectDigitalOfferTechnicalDataComponent {
  @Input() set technicalData(value: OfferTechnicalData) {
    this._technicalData = value;
    if (this._technicalData.technical_table) {
      this.technicalTable = Object.keys(
        this._technicalData.technical_table,
      ).map((key) => ({
        key,
        value: this._technicalData.technical_table
          ? this._technicalData.technical_table[key]
          : '',
      }));
    } else {
      this.technicalTable = undefined;
    }
  }
  get technicalData(): OfferTechnicalData {
    return this._technicalData;
  }

  technicalTable: { key: string; value: string | undefined }[] | undefined;
  private _technicalData: OfferTechnicalData;
}
