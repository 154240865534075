import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartItemsService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { CartActions } from '../cart.actions';
import { CartSelectors } from '../cart.selectors';

@Injectable()
export class CustomArticleItemEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _store: Store = inject(Store);

  get$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.GetCustomArticle),
      map((action) => action.id),
      withLatestFrom(
        this._store.select(CartSelectors.current).pipe(filterTruthy()),
      ),
      switchMap(([id, cart]) =>
        this._cartItemsService.getCustomArticleItemAsync(cart.id, id).pipe(
          switchMap((data) => [
            CartActions.GetCustomArticleSuccess({
              item: data,
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.custom_article_not_loaded',
              ),
            }),
            CartActions.GetCustomArticleError(),
          ]),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.AddCustomArticle),
      map((action) => action.item),
      mergeMap((item) =>
        this._cartItemsService.addCustomArticleItemAsync(item).pipe(
          mergeMap((data) => [
            CartActions.AddCustomArticleSuccess({
              item: data,
            }),
            SnackbarActions.ShowSuccess({
              message: this._translateService.instant(
                'configuration.added_to_cart',
                { item: data.name },
              ),
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.custom_article_not_added',
              ),
            }),
            CartActions.AddCustomArticleError(),
          ]),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateCustomArticle),
      map((action) => action.update),
      mergeMap((update) =>
        this._cartItemsService
          .updateCustomArticleItemAsync({
            ...update,
            picture_type: update.picture_name
              ? update.picture_type
                ? update.picture_type
                : '_'
              : null, // needs to be null if no picture is set for older projects
          })
          .pipe(
            map(() =>
              CartActions.UpdateCustomArticleSuccess({
                update,
              }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.cart.custom_article_not_updated',
                ),
              }),
              CartActions.UpdateCustomArticleError(),
            ]),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.DeleteCustomArticle),
      mergeMap((payload) =>
        this._cartItemsService
          .deleteCustomArticleItemAsync(payload.cartId, payload.itemId)
          .pipe(
            map(() =>
              CartActions.DeleteCustomArticleSuccess({
                id: payload.itemId,
              }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.cart.custom_article_not_removed',
                ),
              }),
              CartActions.DeleteCustomArticleError(),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
