<ng-container gaLabelGroup="CartItemList" gaCategoryGroup="Projects">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    data-test="secondary-actions"
    gaClickListener="MoreClick"
  >
    <mat-icon [spotlight]="groupMenuSpotlight">more_vert</mat-icon>

    <ds-spotlight
      #groupMenuSpotlight
      id="sl-project-cart-item-list-group_menu"
      [tags]="['release_2020_07', 'cart-item-list']"
    >
      {{
        'spotlight.release_2021_04_01.grouping_sorting.group_menu' | translate
      }}
    </ds-spotlight>
  </button>
  <mat-menu #menu="matMenu" data-test="menu">
    <div gaLabelGroup="CartItemListMatMenu" gaCategoryGroup="Projects">
      <button
        mat-menu-item
        *ngIf="itemAsProductItem.is_configurable"
        (click)="showConfigurationClick.emit(cartItem)"
        data-test="configuration"
        gaClickListener="ConfigurationClick"
      >
        <mat-icon>info</mat-icon>
        <span>{{ 'orders.show_configuration' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="itemAsProductItem.is_configurable"
        (click)="configureClick.emit(cartItem)"
        data-test="configure"
        gaClickListener="ConfigureClick"
        [disabled]="isReadOnly"
      >
        <mat-icon>build</mat-icon>
        <span>{{ 'common.configure' | translate }}</span>
      </button>
      <button
        *ngIf="cartItem | isTuningCenterItem; else mccEditButton"
        mat-menu-item
        (click)="editTuningClick.emit()"
        data-test="edit"
        gaClickListener="EditTuningClick"
        [disabled]="isReadOnly"
      >
        <mat-icon>edit</mat-icon>
        <span>{{ 'common.edit' | translate }}</span>
      </button>
      <ng-template #mccEditButton>
        <button
          *ngIf="cartItem | isMccItem; else defaultEditButton"
          mat-menu-item
          (click)="editMccClick.emit()"
          data-test="edit"
          gaClickListener="EditMccClick"
          [disabled]="isReadOnly"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'common.edit' | translate }}</span>
        </button>
      </ng-template>
      <ng-template #defaultEditButton>
        <button
          *ngIf="!(cartItem | isContractItem)"
          mat-menu-item
          (click)="editArticleClick.emit(cartItem)"
          data-test="edit"
          gaClickListener="EditClick"
          [disabled]="isReadOnly"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'common.edit' | translate }}</span>
        </button>
      </ng-template>
      <button
        mat-menu-item
        (click)="changeGroupClick.emit(cartItem)"
        data-test="change-group"
        [gaClickListener]="
          cartItem.cart_item_group_id ? 'ChangeGroupClick' : 'AddToGroupClick'
        "
        [disabled]="isReadOnly"
      >
        <mat-icon>{{
          cartItem.cart_item_group_id ? 'low_priority' : 'code'
        }}</mat-icon>
        <span>{{
          (cartItem.cart_item_group_id
            ? 'project.change_group'
            : 'project.add_to_group'
          ) | translate
        }}</span>
      </button>
      <button
        *ngIf="cartItem.cart_item_group_id"
        mat-menu-item
        (click)="removeFromGroupClick.emit(cartItem)"
        data-test="remove-from-group"
        gaClickListener="RemoveFromGroupClick"
        [disabled]="isReadOnly"
      >
        <mat-icon>code_off</mat-icon>
        <span>{{ 'project.remove_from_group' | translate }}</span>
      </button>
      <button
        mat-menu-item
        *ngIf="
          (cartItem | isProductItem) ||
          (cartItem | isContractItem) ||
          (cartItem | isCustomArticleItem) ||
          (cartItem | isMccItem) ||
          (cartItem | isTuningCenterItem) ||
          (cartItem | isWarrantyExtensionItem)
        "
        (click)="backToCartClick.emit(cartItem)"
        data-test="add-to-cart"
        gaClickListener="BackToCartClick"
        [disabled]="isReadOnly"
      >
        <mat-icon>add_shopping_cart</mat-icon>
        <span>{{ 'project.move_back_to_cart' | translate }}</span>
      </button>
      <ng-container
        *ngIf="!(cartItem | isTuningCenterItem) && !(cartItem | isMccItem)"
      >
        <button
          mat-menu-item
          *ngIf="
            itemAsCustomPriceItem.original_sales_price === null ||
            itemAsCustomPriceItem.original_sales_price === cartItem.sales_price
          "
          [disabled]="isReadOnly"
          (click)="priceDifferenceToClick.emit(cartItem)"
          data-test="price-difference-button"
          gaClickListener="PriceDifferenceToClick"
        >
          <mat-icon>link</mat-icon>
          <span>{{ 'project.price_difference_to' | translate }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="
            itemAsCustomPriceItem.original_sales_price &&
            itemAsCustomPriceItem.original_sales_price !== cartItem.sales_price
          "
          (click)="restoreOriginalPriceClick.emit(cartItem)"
          [disabled]="isReadOnly"
          data-test="restore-original-price-button"
          gaClickListener="RestoreOriginalPriceClick"
        >
          <mat-icon>link_off</mat-icon>
          <span>{{ 'project.restore_original_price' | translate }}</span>
        </button>
      </ng-container>
      <button
        mat-menu-item
        [disabled]="isReadOnly"
        (click)="deleteClick.emit(cartItem)"
        data-test="remove"
        gaClickListener="DeleteClick"
      >
        <mat-icon>remove_shopping_cart</mat-icon>
        <span>{{ 'project.remove_from_cart' | translate }}</span>
      </button>
    </div>
  </mat-menu>
</ng-container>
