<button mat-icon-button [spotlight]="dragItemSpotlight">
  <mat-icon>drag_handle</mat-icon>
</button>

<ds-spotlight
  id="sl-project-spotlight-drag_item"
  #dragItemSpotlight
  [tags]="['release_2021_04_01', 'cart-item-list']"
>
  {{ 'spotlight.release_2021_04_01.grouping_sorting.drag_item' | translate }}
</ds-spotlight>
