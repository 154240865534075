<div
  [gaClickListener]="{
    action: 'NameClick',
    category: 'Projects',
    label: 'CartItemList'
  }"
>
  <ng-container *ngIf="!(cartItem | isContractItem)">
    {{ cartItem.name || itemAsProductItem.material_id }}
  </ng-container>

  <ng-container
    *ngIf="
      (cartItem | isContractItem) &&
      itemAsContractItem.name === contractName.SERVICE
    "
  >
    {{ 'project_detail.service_contract' | translate }}
  </ng-container>

  <ng-container
    *ngIf="
      (cartItem | isContractItem) &&
      itemAsContractItem.name === contractName.CONNECTED
    "
  >
    {{ 'project_detail.connected_contract' | translate }}
  </ng-container>
</div>
