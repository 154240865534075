<ng-container [matColumnDef]="perspective + 'TransportCostsInput'">
  <td mat-footer-cell *matFooterCellDef class="text-right">
    <ng-container *ngIf="formControl">
      <mat-form-field
        [gaClickListener]="{
          action: perspective + 'TransportCostInputClick',
          category: 'Cart',
          label: 'TransportCostsInputColumn'
        }"
        class="print-hidden"
      >
        <input
          (keyup.enter)="triggerBlur($event)"
          matInput
          [formControl]="formControl"
          type="number"
          min="0"
          autocomplete="off"
        />
        <span matTextSuffix>&nbsp;{{ currencySymbol }}</span>
      </mat-form-field>
      <span class="print-only">
        {{
          formControl.value
            | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
            | async
        }}
      </span>
    </ng-container>
  </td>
</ng-container>
