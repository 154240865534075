import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import {
  MatColumnDef as MatColumnDef,
  MatTable,
} from '@angular/material/table';

@Component({
  template: '',
  standalone: false,
})
export class DsColumnComponent implements OnDestroy, OnInit {
  @ViewChild(MatColumnDef) columnDef: MatColumnDef;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.table) {
      this.cdRef.detectChanges();
      if (this.columnDef) {
        this.table.addColumnDef(this.columnDef);
      }
    }
    this.onInit();
  }

  ngOnDestroy() {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
    this.onDestroy();
  }

  onInit() {
    /* used by child component instead of overwriting ngOnInit */
  }
  onDestroy() {
    /* used by child component instead of overwriting ngOnDestroy */
  }
}
