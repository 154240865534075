import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-price-difference-dialog',
  templateUrl: './price-difference-dialog.component.html',
  styleUrls: ['./price-difference-dialog.component.scss'],
  standalone: false,
})
export class SlProjectPriceDifferenceDialogComponent {
  @Output()
  readonly confirm = new EventEmitter<SlCartItem | undefined>();

  selected: SlCartItem;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SlProjectPriceDifferenceDialogData,
  ) {}
}

export interface SlProjectPriceDifferenceDialogData {
  item: SlCartItem;
  isOption?: boolean;
  selectableItems?: SlCartItem[];
}
