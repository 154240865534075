import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Calculation, CalculationItem } from '@sales-libs/project/data-access';
import { CurrencySelectors, FEATURE_FLAG_PPI } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShippingCost } from '../shared/models';
import { CalculationActions, CalculationSelectors } from '../store';
import { SlProjectIndexPriceDescriptionDialogComponent } from './index-price-description-dialog/index-price-description-dialog.component';

@Component({
  selector: 'sl-project-calculation-table',
  templateUrl: './calculation-table.component.html',
  styleUrls: ['./calculation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectCalculationTableComponent {
  @Input() shippingCosts: ShippingCost[];
  @Input()
  showAlternatives = false;

  @Input()
  set items(items: CalculationItem[]) {
    this.dataSource = items
      ? items.filter(
          (item) =>
            !('is_optional' in item) ||
            item.is_optional === this.showAlternatives,
        )
      : [];
  }

  @Input()
  set calculation(value: Calculation) {
    this._calculation = value;
    if (value.is_read_only) {
      this.form.disable({ emitEvent: false });
    }
  }
  get calculation(): Calculation {
    return this._calculation;
  }

  @Input() set perspectives(value: string[]) {
    this._perspectives = value;
  }
  get perspectives() {
    return this._perspectives.filter(
      (x) => this.calculation.general_agent || x !== 'general_agent',
    );
  }

  @Output()
  readonly updated = new EventEmitter<Calculation>();

  dataSource: CalculationItem[] = [];

  readonly form = new FormGroup({}, { updateOn: 'blur' });
  readonly locale$: Observable<string>;
  readonly percentSymbol$: Observable<string>;
  readonly expandedItemsLookup$: Observable<{ [id: number]: boolean }>;
  readonly currencySymbol$: Observable<string | null>;
  readonly currencyRatio$: Observable<number>;
  readonly currencyRoundingDigits$: Observable<number>;

  private _perspectives: string[] = [];
  private _calculation: Calculation;
  private _errorMessageKey: string;
  constructor(
    private readonly _store: Store<any>,
    readonly snackbar: DsSnackbarService,
    private readonly _translateService: TranslateService,
    private readonly _dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(FEATURE_FLAG_PPI) public isFeatureFlagPpiSet: boolean,
  ) {
    this.locale$ = this._store
      .select(CurrencySelectors.currency)
      .pipe(map((state) => (state && state.locale) ?? locale));
    this.currencySymbol$ = this._store.select(CurrencySelectors.currency).pipe(
      filterTruthy(),
      map((value) => value.symbol ?? null),
    );
    this.currencyRatio$ = this._store.select(CurrencySelectors.currency).pipe(
      filterTruthy(),
      map((value) => value.ratio),
    );
    this.currencyRoundingDigits$ = this._store
      .select(CurrencySelectors.currency)
      .pipe(
        filterTruthy(),
        map((value) => value.rounding_digits),
      );

    this.percentSymbol$ = this.locale$.pipe(
      map((value) => getLocaleNumberSymbol(value, NumberSymbol.PercentSign)),
    );

    this.expandedItemsLookup$ = this._store.select(
      CalculationSelectors.expandedItemsLookup,
    );
  }
  /*
   * Replaces all columns except the first one replacing 'perspective' by one of 'customer', 'partner', or 'general_agent'
   */
  displayColumns(displayColumns: string[]): string[] {
    return [
      ...displayColumns.slice(0, 1),
      ...this.perspectives
        .map((p) =>
          displayColumns.slice(1).map((c) => c.replace('perspective', p)),
        )
        .reduce((a, b) => a.concat(b), []),
    ];
  }

  /**
   * Implements {@link TrackByFunction<T>}
   */
  trackByItem(index: number, item: CalculationItem): any {
    return item.id;
  }

  onChange(calculation: Calculation) {
    this.updated.emit(calculation);
  }

  onToggle(item: CalculationItem): void {
    this._store.dispatch(
      CalculationActions.ToggleItemExpanded({ payload: item.id ?? '' }),
    );
  }

  onInvalidDiscount() {
    this._errorMessageKey = 'calculation.max_discount_hin';
    this._showErrorMessage();
  }

  private _showErrorMessage() {
    this.snackbar.queue(this._translateService.instant(this._errorMessageKey), {
      type: DsSnackbarType.Warning,
    });
  }

  openSlProjectIndexPriceDescriptionDialogComponent() {
    this._dialog.open(SlProjectIndexPriceDescriptionDialogComponent, {
      width: '99%', // max width to not cut description
    });
  }
}
