<ng-container matColumnDef="delivery_date">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="delivery_date"
    data-test="lost-order-delivery-date-header"
  >
    {{ 'table_columns.delivery_date' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'DeliveryDateClick'
    }"
    data-test="lost-order-delivery-date"
  >
    {{ lostOrder?.delivery_date | date: 'shortDate' }}
  </td>
</ng-container>
