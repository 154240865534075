import { Pipe, PipeTransform } from '@angular/core';
import { CalculationItem } from '@sales-libs/project/data-access';
import { CalculationUtils } from './calculation-utils';

/**
 * checks if item is with customer price input
 */
@Pipe({
  name: 'isUserPriceInputItem',
  standalone: false,
})
export class SlProjectIsUserPriceInputItemPipe implements PipeTransform {
  transform(item: CalculationItem): boolean {
    return CalculationUtils.isUserPriceInputItem(item);
  }
}
