import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProductItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-quantity-column',
  templateUrl: './quantity-column.component.html',
  styleUrls: ['./quantity-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectQuantityColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsProductItem = value as ProductItem;
  }
  @Input() isReadOnly = false;
  @Output() quantityChange = new EventEmitter<SlCartItem>();

  cartItem: SlCartItem;
  itemAsProductItem: ProductItem;
}
