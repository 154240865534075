/* eslint-disable max-lines */
import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DsSpacingPipe } from '@design-system/cdk/spacing';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Cart,
  CartItemGroup,
  ContractItem,
  CustomArticleItem,
  MccItem,
  ProductItem,
  ProductItemProductType,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItemGroupContainer } from '@sales-libs/project/ui';
import {
  SlCartItem,
  SlSharedCartItemMappingUtils,
  SlSharedConfigurationOptionsComponent,
  SnackbarActions,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { ConfirmDeleteDialogComponent } from '@ui-kit/modals';
import { nameofFactory } from '@utils/name-of';
import { takeUntil, tap } from 'rxjs/operators';
import { SlProjectEditCustomCartItemComponent } from '../../edit-custom-cart-item/edit-custom-cart-item.component';
import { SlProjectMccDialogComponent } from '../../mcc-dialog/mcc-dialog.component';
import {
  SlProjectPriceDifferenceDialogComponent,
  SlProjectPriceDifferenceDialogData,
} from '../../price-difference-dialog/price-difference-dialog.component';
import { CartActions } from '../../store';
import { SlProjectTuningCenterDialogComponent } from '../../tuning-center-dialog/tuning-center-dialog.component';
import { CartItemUtils } from '../../utils';
import {
  GroupSelectionDialogData,
  SlProjectGroupSelectionDialogComponent,
} from '../group-selection-dialog/group-selection-dialog.component';
import { SlProjectMissingOriginalDataInfoDialogComponent } from './missing-originial-data-info-dialog/missing-original-data-info-dialog.component';

type SalesPriceEditableItem =
  | ContractItem
  | CustomArticleItem
  | MccItem
  | SalesOptionCartItem
  | TuningCenterItem
  | WarrantyExtensionItem;

export interface SlDragCartItemGroupContainer extends SlCartItemGroupContainer {
  index: number;
}

@Component({
  selector: 'sl-project-optional-cart-item-list',
  templateUrl: './optional-cart-item-list.component.html',
  styleUrls: ['./optional-cart-item-list.component.scss'],
  standalone: false,
})
export class SlProjectOptionalCartItemListComponent {
  @Input() set groups(value: SlCartItemGroupContainer[]) {
    if (this.updateCount === 0) {
      // THIS IS DONE FOR NESTED DRAG AND DROP
      // add empty groups, to create cdkDropLists between the groups with items.
      // this way angular drag and drop can create drop-previews between groups.
      // items can be dragged in the "empty space" between the groups, to the user
      // it looks like he removes a item from grouping, but in reality he drops it
      // in an empty group, on drop we treat empty groups like "remove from group"
      this.dragContainers = [
        // add an empty group at the beginning
        [
          {
            items: [] as SlCartItem[],
            index: 0,
          } as SlDragCartItemGroupContainer,
        ],
        // add an empty group after each real group
        // by adding them in sets of two
        // we can wrap them in a drag-container
        // this way they only get dragged together
        // "empty" space remain between real groups
        ...value.map((x, i) => [
          { ...x, items: x.items ? [...x.items] : x.items, index: i * 2 + 1 },
          {
            items: [] as SlCartItem[],
            index: i * 2 + 2,
          } as SlDragCartItemGroupContainer,
        ]),
      ];
    } else {
      this.updateCount--;
    }
  }
  @Input() cart: Cart;
  @Input() allItems: SlCartItem[];
  @Input() isPricingVisible = true;
  @Input() isReadOnly = false;

  @Output() configure = new EventEmitter<SlCartItem>();
  @Output() editGroup = new EventEmitter<CartItemGroup>();

  nameof = nameofFactory<ProductItem>();
  dragContainers: SlDragCartItemGroupContainer[][];

  readonly columns = {
    sort: 'sort',
    image: 'image',
    name: 'name',
    state: 'state',
    description: 'description',
    price: 'price',
    quantity: 'quantity',
    actions: 'actions',
  };

  get displayedColumns(): string[] {
    return Object.values(this.columns).filter(
      (x) =>
        (this.isPricingVisible || x !== this.columns.price) &&
        (this.isReadOnly || x !== this.columns.actions),
    );
  }

  updateCount = 0;

  constructor(
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
    private readonly _translateService: TranslateService,
  ) {}

  onDelete(item: SlCartItem) {
    const action = CartItemUtils.getDeleteActionAccordingToType(
      item,
      this.cart,
    );
    const errorAction = SnackbarActions.ShowError({
      message: this._translateService.instant(
        'error_messages.cart.item_not_removed',
      ),
    });
    this._store.dispatch(action ? action : errorAction);
  }

  onEditTuning() {
    this._dialog.open(SlProjectTuningCenterDialogComponent);
  }

  onEditMcc() {
    this._dialog.open(SlProjectMccDialogComponent);
  }

  onEditArticle(item: SlCartItem) {
    const itemAsCustomArticleItem = item as CustomArticleItem;
    const dialogRef = this._dialog.open(SlProjectEditCustomCartItemComponent);
    dialogRef.componentInstance.item = itemAsCustomArticleItem;
    dialogRef.componentInstance.editConfirmed
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe((event) => {
        this._updateItemAccordingToType(event);
        dialogRef.close();
      });
  }

  onPriceDifferenceTo(item: SlCartItem, cart: Cart) {
    const dialogData: SlProjectPriceDifferenceDialogData = {
      item,
    };
    if (CartItemUtils.isSalesOptionCartItem(item, cart)) {
      dialogData.selectableItems = cart.product_items
        ?.find((x) => x.product_type === ProductItemProductType.Product)
        ?.options?.map(
          (option) =>
            SlSharedCartItemMappingUtils.mapSalesOptionToItemInput(
              option,
              item.cart_id,
            ) as SalesOptionCartItem,
        );
    } else {
      dialogData.selectableItems = CartItemUtils.getAllItemsOfSameType(
        item,
        cart,
      ).filter((x) => x.id !== item.id && !x.is_optional);
    }
    const dialogRef = this._dialog.open(
      SlProjectPriceDifferenceDialogComponent,
      {
        width: '99%', // max width to not cut description
        data: dialogData,
      },
    );
    dialogRef.componentInstance.confirm
      .pipe(filterTruthy(), takeUntil(dialogRef.afterClosed()))
      .subscribe((selected: SlCartItem) => {
        this._updateItemAccordingToType({
          ...item,
          sales_price: item.sales_price - (selected?.sales_price || 0),
          original_sales_price: item.sales_price,
          cart_item_price_comparer_id: selected?.id,
        });
        dialogRef.close();
      });
  }

  onShowconfiguration(item: SlCartItem) {
    this._dialog.open(SlSharedConfigurationOptionsComponent, {
      data: {
        name: item.name,
        options: (item as ProductItem).options,
        isPricingVisible: this.isPricingVisible,
      },
    });
  }

  onRestoreOriginalPrice(item: SlCartItem) {
    this._updateItemAccordingToType({
      ...item,
      sales_price: (item as SalesPriceEditableItem).original_sales_price ?? 0,
      cart_item_price_comparer_id: undefined,
    });
  }

  onRemoveFromGroup(item: SlCartItem) {
    this._updateItemAccordingToType({
      ...item,
      cart_item_group_id: undefined,
    });
  }

  /**
   * Predicate function that stops groups to be dropped
   * at index 0 (which always should be a empty fake group for item dropping)
   */
  sortPredicate(index: number) {
    return index !== 0;
  }

  /**
   * Predicate function that allows items to be dropped
   */
  dropPredicate(targetContainer: SlDragCartItemGroupContainer) {
    return (dragContainer: CdkDrag<SlDragCartItemGroupContainer>) =>
      // only into real groups (with id) and nested-helper-groups (item.length = 0)
      (!!targetContainer.group?.id ||
        (!!targetContainer.items && targetContainer.items.length === 0)) &&
      // and if item comes from a fake group, the index difference should be at least 2
      // so it doesn't get dropped in the spaces sorounding its orignal position
      (!!dragContainer.data?.group?.id ||
        dragContainer.data?.index > targetContainer.index + 1 ||
        dragContainer.data?.index < targetContainer.index - 1);
  }

  groupDrop(
    event: CdkDragDrop<SlDragCartItemGroupContainer[][], any>,
    containers: SlDragCartItemGroupContainer[][],
  ) {
    moveItemInArray(containers, event.previousIndex, event.currentIndex);
    this._updateSorting(containers);
  }

  itemDrop(
    event: CdkDragDrop<SlDragCartItemGroupContainer>,
    containers: SlDragCartItemGroupContainer[][],
  ) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data.items || [],
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data.items || [],
        event.container.data.items || [],
        event.previousIndex,
        event.currentIndex,
      );
    }

    this._updateSorting(containers);
  }

  openConfirmDeleteDialogForGroup(group: CartItemGroup | undefined) {
    if (group) {
      const dialogRef = this._dialog.open(ConfirmDeleteDialogComponent);
      dialogRef.componentInstance.key = group.name || '';
      dialogRef.componentInstance.confirm
        .pipe(tap(() => dialogRef.close(), takeUntil(dialogRef.afterClosed())))
        .subscribe((confirmed) => {
          if (confirmed) {
            this._store.dispatch(
              CartActions.DeleteItemGroup({ payload: group }),
            );
          }
        });
    }
  }

  onChangeGroup(
    currentContainer: SlCartItemGroupContainer,
    item: SlCartItem,
    containers: SlDragCartItemGroupContainer[][],
  ) {
    const containerWithGroups = containers
      .map((x) => x.find((y) => y.group) || ({} as SlCartItemGroupContainer))
      .filter((x) => x.group);
    const dialogData: GroupSelectionDialogData = {
      groups: containerWithGroups.map((x) => x.group as CartItemGroup),
      currentGroup: currentContainer.group as CartItemGroup,
      item: item,
    };
    const dialogRef = this._dialog.open(
      SlProjectGroupSelectionDialogComponent,
      {
        data: dialogData,
      },
    );
    dialogRef.afterClosed().subscribe((newGroup) => {
      // only emit events if group changed
      if (newGroup?.id && newGroup?.id !== currentContainer.group?.id) {
        const newContainer = containerWithGroups.find(
          (container) => container.group?.id === newGroup.id,
        );
        transferArrayItem(
          currentContainer.items || [],
          newContainer?.items || [],
          (currentContainer.items || []).findIndex((x) => x.id === item.id),
          0, // always move to top when added via dialogto make it easy for user to find item again
        );

        this._updateSorting(containers);
      }
      dialogRef.close();
    });
  }

  removeItemFromGroup(
    currentContainer: SlCartItemGroupContainer,
    item: SlCartItem,
    containers: SlDragCartItemGroupContainer[][],
  ) {
    transferArrayItem(
      currentContainer.items || [],
      containers[0][0].items || [], // move to top to make it easy for user to find item again
      (currentContainer.items || []).findIndex((x) => x.id === item.id),
      0, // always move to top to make it easy for user to find item again
    );

    this._updateSorting(containers);
  }

  onBackToCart(item: SlCartItem) {
    const itemAsProductItem = item as ProductItem;
    if (
      itemAsProductItem.is_edited &&
      !this._isItemWithOriginalData(itemAsProductItem)
    ) {
      this._dialog.open(SlProjectMissingOriginalDataInfoDialogComponent, {
        width: new DsSpacingPipe().transform(15),
      });
    } else {
      this._updateItemAccordingToType({
        ...item,
        name: itemAsProductItem.original_name ?? itemAsProductItem.name,
        short_description:
          itemAsProductItem.original_short_description ??
          itemAsProductItem.short_description,
        long_description:
          itemAsProductItem.original_long_description ??
          itemAsProductItem.long_description,
        sales_price:
          itemAsProductItem.original_sales_price ??
          itemAsProductItem.sales_price,
        purchase_price:
          itemAsProductItem.original_purchase_price ??
          itemAsProductItem.purchase_price,
        is_discountable:
          itemAsProductItem.original_is_discountable ??
          itemAsProductItem.is_discountable,
        is_ppi_relevant:
          itemAsProductItem.original_is_ppi_relevant ??
          itemAsProductItem.is_ppi_relevant,
        is_edited: false,
        is_optional: false,
        cart_item_group_id: null,
      });
    }
  }

  onQuantityChange(item: SlCartItem) {
    this._updateItemAccordingToType(item);
  }

  private _updateSorting(containers: SlDragCartItemGroupContainer[][]) {
    // reset, cause previous update might have failed
    this.updateCount = 0;

    // for Each index not possible here because of nested array with helpers
    let topLevelIndex = 0;

    // create original format with new sorting from itemDragGroups
    let items: SlCartItem[] = [];
    const groups: CartItemGroup[] = [];

    containers.forEach((containerGroup) =>
      containerGroup.forEach((container) => {
        // real group
        if (container.group) {
          groups.push({
            ...container.group,
            sort_key_ui: topLevelIndex,
          });
          items = [
            ...items,
            ...(container.items || []).map((x, i) => ({
              ...x,
              cart_item_group_id: container.group?.id,
              sort_key_optional: i,
            })),
          ];
          topLevelIndex++;
        } else if (container.items && container.items.length > 0) {
          // item sorting
          container.items.forEach((item) => {
            items.push({
              ...item,
              cart_item_group_id: undefined,
              sort_key_optional: topLevelIndex,
            });
          });
          topLevelIndex++;
        }
      }),
    );

    this.groups = containers
      .reduce(
        (acc, item) => [...acc, ...item],
        [] as SlDragCartItemGroupContainer[],
      )
      .filter((group) => group.group || group.items.length > 0);
    this.updateCount = items.length + groups.length - 1;
    items.forEach((item) => this._updateItemAccordingToType(item));
    groups.forEach((group) =>
      this._store.dispatch(CartActions.UpdateCartItemGroup({ payload: group })),
    );
  }

  private _updateItemAccordingToType(item: SlCartItem) {
    const action = CartItemUtils.getUpdateActionAccordingToType(
      item,
      this.cart,
    );
    const errorAction = SnackbarActions.ShowError({
      message: this._translateService.instant(
        'error_messages.cart.item_not_updated',
      ),
    });
    this._store.dispatch(action ? action : errorAction);
  }

  // since saving of original data was introduced in release 2023.12.1
  // we need to handle the case that old carts don't have this data
  // this function checks every parameter except for sales price
  // since original sales price was also saved before release
  private _isItemWithOriginalData(item: ProductItem): boolean {
    return (
      item.original_name !== undefined ||
      item.original_short_description !== undefined ||
      item.original_long_description !== undefined ||
      item.original_purchase_price !== undefined ||
      item.original_quantity !== undefined ||
      item.original_is_discountable !== undefined ||
      item.original_is_ppi_relevant !== undefined
    );
  }
}
