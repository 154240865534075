<ng-container gaLabelGroup="EditCartItemGroup" gaCategoryGroup="Cart">
  <h3 matDialogTitle>{{ 'project_detail.cart_item_group' | translate }}</h3>
  <form [formGroup]="form" (ngSubmit)="onConfirm()" *ngIf="form">
    <mat-dialog-content>
      <mat-form-field class="full-width" gaClickListener="NameClick">
        <mat-label>{{ 'project.name' | translate }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="form.controls.name"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls.name.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose gaClickListener="CancelClick">
        {{ 'general.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!form.valid"
        gaClickListener="ConfirmClick"
      >
        {{ 'general.confirm' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
