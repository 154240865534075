export const testBestInvestDealerDefaultParameters = {
  costs_external_working_hour: 70,
  price_diesel: 1.4,
  price_hydraulic_oil: 3,
  price_lubricant: 18,
  price_co2_per_ton: 55,
};

export const testBestInvestDealerDefaultParametersPayload = {
  ...testBestInvestDealerDefaultParameters,
  id: 1,
  company_bpm_id: 1,
};
