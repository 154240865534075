import { Pipe, PipeTransform } from '@angular/core';
import { ContractItemPaymentPeriodType } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectPaymentPeriodTypeTranslationKey',
  standalone: false,
})
export class SlProjectPaymentPeriodTypePipe implements PipeTransform {
  readonly ContractTranslationKeyMapping = {
    [ContractItemPaymentPeriodType.Monthly]:
      'contract.payment_period_type.monthly',
    [ContractItemPaymentPeriodType.Quarterly]:
      'contract.payment_period_type.quarterly',
    [ContractItemPaymentPeriodType.Yearly]:
      'contract.payment_period_type.yearly',
    [ContractItemPaymentPeriodType.OneOffPayment]:
      'contract.payment_period_type.one_off_payment',
  };

  transform(type: ContractItemPaymentPeriodType | null | undefined): string {
    return type ? this.ContractTranslationKeyMapping[type] : '';
  }
}
