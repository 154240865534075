<td
  class="min-width-cell mat-mdc-cell mat-mdc-table-sticky"
  [ngClass]="
    item?.changes
      ? {
          'error-ribbon': !isLoading && !isComparisonSuccessfull,
          'warning-ribbon':
            item?.compare_difference_type === compareDifferenceType.Removed,
          'success-ribbon':
            item?.compare_difference_type === compareDifferenceType.Added,
          'info-ribbon':
            item?.compare_difference_type === compareDifferenceType.Changed
        }
      : {}
  "
  data-test="position-cell"
>
  <img
    [src]="item?.image_url | slSharedImageSize: 'xs'"
    *ngIf="item?.image_url"
  />
  <div *ngIf="!item?.image_url" data-test="warning-message">
    {{ 'general.not_available' | translate }}
  </div>
</td>
<td class="min-width-cell mat-mdc-cell" data-test="name-cell">
  <div
    [ngClass]="getClass(item, !!item?.changes?.name)"
    data-test="original-name"
  >
    {{ item?.name || '-' }}
  </div>

  <ds-loading-placeholder
    [height]="1 | dsSpacing"
    *ngIf="isLoading"
  ></ds-loading-placeholder>
  <span data-test="changed-name">{{ item?.changes?.name }}</span>
</td>
<td
  class="min-width-cell mat-mdc-cell"
  [ngClass]="getClass(item, !!item?.changes, false)"
  data-test="compare-type-cell"
>
  <ng-container *ngIf="isLoading">
    <span>-</span>
    <ds-loading-placeholder [height]="1 | dsSpacing"></ds-loading-placeholder>
  </ng-container>

  <span *ngIf="!isLoading && isComparisonSuccessfull"
    >{{
      item?.compare_difference_type
        | slProjectCartCompareDifferenceTypeTranslationKey
        | translate
    }}
  </span>
  <span
    *ngIf="!isLoading && !isComparisonSuccessfull"
    data-test="compare-type-error-message"
  >
    {{ 'general.error_code.error' | translate }}
    {{ 'cart_compare.placeholder_error_text' | translate }}
  </span>
</td>
<td class="min-width-cell mat-mdc-cell" data-test="item-type-cell">
  {{ item?.cart_item_type | slProjectCartItemTypeTranslationKey | translate }}
  <ng-container *ngIf="item?.is_optional">
    <span data-test="optional-info" [spotlight]="optionalItemSpotlight">
      ({{ 'configuration.optional' | translate
      }}<ng-container *ngIf="item?.is_edited">
        |
        <span class="info-text" data-test="edited-info">
          {{ 'project.edited' | translate }}</span
        > </ng-container
      ><span>)</span>
    </span>
    <ds-spotlight
      #optionalItemSpotlight
      id="cpq-copy-compare-table-optional-items-spotlight"
    >
      {{ 'spotlight.release_2022_06_01.optionalItemSpotlight' | translate }}
    </ds-spotlight>
  </ng-container>

  <ds-loading-placeholder
    [height]="1 | dsSpacing"
    *ngIf="isLoading"
  ></ds-loading-placeholder>
</td>
<td
  class="min-width-cell mat-mdc-cell"
  data-test="short-description-cell"
  *ngIf="isTextChangeVisible"
>
  <div
    [ngClass]="getClass(item, !!item?.changes?.short_description)"
    data-test="original-short-description"
  >
    {{ item?.short_description || '-' }}
  </div>
  <span data-test="changed-short-description">
    {{ item?.changes?.short_description }}
  </span>

  <ds-loading-placeholder
    [height]="1 | dsSpacing"
    *ngIf="isLoading"
  ></ds-loading-placeholder>
</td>
<td
  class="min-width-cell mat-mdc-cell"
  *ngIf="isTextChangeVisible"
  data-test="long-description-cell"
>
  <div
    [ngClass]="getClass(item, !!item?.changes?.long_description)"
    data-test="original-long-description"
    [innerHTML]="item?.long_description || '-'"
  ></div>
  <div
    data-test="changed-long-description"
    [innerHTML]="item?.changes?.long_description"
  ></div>

  <ds-loading-placeholder
    [height]="1 | dsSpacing"
    *ngIf="isLoading"
  ></ds-loading-placeholder>
</td>
<td class="min-width-cell mat-mdc-cell text-right" data-test="sales-price-cell">
  <div
    [ngClass]="getClass(item, !!item?.changes?.sales_price)"
    data-test="original-sales-price"
  >
    {{
      item?.sales_price
        ? (item?.sales_price
          | slMoneyAmount$: { currency: sourceCurrency }
          | async)
        : '-'
    }}
  </div>
  <span data-test="new-sales-price">
    {{
      item?.changes?.sales_price
        | slMoneyAmount$: { currency: newCurrency }
        | async
    }}
  </span>

  <ds-loading-placeholder
    [height]="1 | dsSpacing"
    *ngIf="isLoading"
  ></ds-loading-placeholder>
  <ng-container *ngIf="item?.comparer_name">
    <br />
    <small data-test="compared-to-info">
      {{
        'project_detail.compared_to' | translate: { name: item?.comparer_name }
      }}
    </small>
  </ng-container>
</td>
<td
  class="text-center min-width-cell mat-mdc-cell mat-mdc-table-sticky"
  *ngIf="isActionColumnVisible"
  data-test="action-cell"
>
  <mat-spinner
    *ngIf="isLoading"
    color="accent"
    [diameter]="24"
    data-test="loading-spinner"
  ></mat-spinner>
  <button
    *ngIf="!isLoading && !isComparisonSuccessfull"
    mat-stroked-button
    color="primary"
    (click)="tryAgainClick.emit()"
    data-test="try-again-button"
  >
    {{ 'common.try_again' | translate }}
  </button>
  <button
    *ngIf="!isLoading && !isComparisonSuccessfull"
    mat-stroked-button
    color="primary"
    (click)="removeClick.emit()"
    data-test="remove-button"
  >
    {{ 'common.delete' | translate }}
  </button>
</td>
