import {
  CartItemsService,
  SalesOptionCartItemInput,
} from '@sales-libs/project/data-access';
import { SlProjectSalesOptionItemCreationRow } from '../rows/sales-option-item-creation-row';

export class SlProjectSalesOptionItemCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: SalesOptionCartItemInput) {
    return new SlProjectSalesOptionItemCreationRow(
      item,
      this._cartItemsService,
    );
  }
}
