<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div fxFlex="0 0 auto">
    <ds-page-sticky-header>
      <h1 fxLayout="row" fxLayoutAlign="space-between center">
        {{ 'project.lost_order' | translate }}
        <ds-page-actions>
          <button
            mat-button
            page-action
            *dsPageAction
            (click)="openProjectsDownloadDialog()"
            [gaClickListener]="{
              category: 'LostOrderOverview',
              action: 'ExportClick',
              label: 'LostOrder'
            }"
            data-test="export-button"
          >
            <mat-icon>cloud_download</mat-icon>
            {{ 'common.export' | translate }}
          </button>
        </ds-page-actions>
      </h1>
    </ds-page-sticky-header>
    <sl-project-lost-order-filter
      (filter)="updateFilter($event)"
      (clearFilter)="clearFilter()"
      [filterValues]="lostOrderFilters$ | async"
    ></sl-project-lost-order-filter>
  </div>
  <sl-project-lost-order-list
    fxFlex="1 1 auto"
    fxLayout="column"
    class="no-min-height"
    [pagination]="pagination$ | async"
    [lostOrders]="lostOrders$ | async"
    [displayColumns]="displayColumns"
    (setUserSettings)="setUserSettings($event)"
    (getPage)="getPage($event)"
    (sortChange)="sortChange($event)"
  ></sl-project-lost-order-list>
</div>
