<ng-container gaLabelGroup="LostOrderList" gaCategoryGroup="LostOrderOverview">
  <ds-table
    [class.min-height]="lostOrders && lostOrders.length > 3"
    *pdLet="sort$ | async as sort"
  >
    <ng-container *ngIf="lostOrders && displayColumns; else loading">
      <table
        mat-table
        matSort
        [matSortActive]="sort?.active"
        [matSortDirection]="sort?.direction"
        matSortDisableClear
        (matSortChange)="sortChange.emit($event)"
        [dataSource]="lostOrders"
        *ngIf="lostOrders?.length; else noData"
        role="presentation"
      >
        <sl-project-lost-order-list-code-column></sl-project-lost-order-list-code-column>
        <sl-project-lost-order-list-created-date-column></sl-project-lost-order-list-created-date-column>
        <sl-project-lost-order-list-name-column></sl-project-lost-order-list-name-column>
        <sl-project-lost-order-list-customer-column></sl-project-lost-order-list-customer-column>
        <sl-project-lost-order-list-model-column></sl-project-lost-order-list-model-column>
        <sl-project-lost-order-list-created-by-column></sl-project-lost-order-list-created-by-column>
        <sl-project-lost-order-list-end-date-column></sl-project-lost-order-list-end-date-column>
        <sl-project-lost-order-list-delivery-date-column></sl-project-lost-order-list-delivery-date-column>
        <sl-project-lost-order-list-amount-column></sl-project-lost-order-list-amount-column>
        <sl-project-lost-order-list-reason-column></sl-project-lost-order-list-reason-column>
        <sl-project-lost-order-list-competitor-column></sl-project-lost-order-list-competitor-column>
        <sl-project-lost-order-list-competitor-type-column></sl-project-lost-order-list-competitor-type-column>
        <sl-project-lost-order-list-competitor-price-column></sl-project-lost-order-list-competitor-price-column>
        <sl-project-lost-order-list-comment-column></sl-project-lost-order-list-comment-column>
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <ds-table-settings
              [defaultSettings]="displayColumns"
              [settingsKey]="'CPQ.lostOrder.table_settings'"
              (userSettings)="setUserSettings.emit($event)"
            ></ds-table-settings>
          </th>
          <td mat-cell class="mat-action-cell" *matCellDef="let element"></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayColumns | displayColumns; sticky: true"
        ></tr>

        <tr
          mat-row
          pointer
          class="highlight"
          *matRowDef="let row; columns: displayColumns | displayColumns"
        ></tr>
      </table>
    </ng-container>
    <ds-table-footer>
      <mat-paginator
        *ngIf="pagination"
        [length]="pagination.length"
        [pageSize]="pagination.pageSize"
        [pageIndex]="pagination.pageIndex"
        [pageSizeOptions]="[10, 20, 50]"
        [showFirstLastButtons]="true"
        (page)="getPage.emit($event)"
        gaClickListener="PaginationClick"
      >
      </mat-paginator>
    </ds-table-footer>
  </ds-table>

  <ng-template #loading>
    <ds-table-loading
      [numberOfHeaders]="9"
      [numberOfRows]="10"
      gaClickListener="LoadingClick"
    ></ds-table-loading>
  </ng-template>

  <ng-template #noData>
    <ds-placeholder type="no_data" gaClickListener="PlaceholderClick">
    </ds-placeholder>
  </ng-template>
</ng-container>
