import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContractItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable, map } from 'rxjs';
import { CartActions, CartSelectors } from '../../store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectContractCartItems]',
  templateUrl: './contract-cart-items.component.html',
  styleUrls: ['./contract-cart-items.component.scss'],
  standalone: false,
})
export class SlProjectContractCartItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;

  readonly items$: Observable<ContractItem[] | undefined>;

  constructor(private readonly _store: Store) {
    this.items$ = this._store
      .select(CartSelectors.contractItems)
      .pipe(map((items) => items ?? []));
  }

  onDelete(itemToDelete: SlCartItem) {
    this._store.dispatch(
      CartActions.DeleteContractItem({
        itemId: itemToDelete.id as string,
        cartId: itemToDelete.cart_id,
      }),
    );
  }

  onToOptional(item: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateContractItem({
        update: { ...item, is_optional: true } as ContractItem,
      }),
    );
  }
}
