import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractItem, ProductItem } from '@sales-libs/project/data-access';
import { ContractName, SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-name-column',
  templateUrl: './name-column.component.html',
  styleUrls: ['./name-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectNameColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsProductItem = value as ProductItem;
    this.itemAsContractItem = value as ContractItem;
  }

  contractName = ContractName;
  cartItem: SlCartItem;
  itemAsProductItem: ProductItem;
  itemAsContractItem: ContractItem;
}
