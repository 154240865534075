<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="isPricingVisible$ | async as isPricingVisible">
    <table class="mat-mdc-table" *ngIf="items && items.length > 0">
      <thead
        slProjectCartItemListHeader
        [isReadOnly]="isReadOnly"
        [isPriceVisible]="isPricingVisible"
        [isTypeVisible]="isTypeVisible"
      ></thead>
      <tr
        *ngFor="let item of items"
        slProjectCartItemListRow
        [gaClickListener]="{
          category: 'Cart',
          action: 'RowClick',
          label: 'CartItemList'
        }"
        class="mat-row data-test-{{ item.name | slCssClass }}"
        [item]="item"
        [isReadOnly]="isReadOnly || cart.locked"
        [isPricingVisible]="isPricingVisible"
        [isSelectOnly]="isSelectOnly"
        (selectItem)="selectItem.emit($event)"
      ></tr>
    </table>
  </ng-container>
</ng-container>
