import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';

@Component({
  selector: 'sl-project-project-list-closed-date-column',
  templateUrl: './project-list-closed-date-column.component.html',
  styleUrls: ['./project-list-closed-date-column.component.scss'],
  standalone: false,
})
export class SlProjectListClosedDateColumnComponent extends DsColumnComponent {
  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
