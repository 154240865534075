import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CartItemGroup,
  CartItemGroupInput,
} from '@sales-libs/project/data-access';

@Component({
  selector: 'sl-project-edit-cart-item-group-dialog',
  templateUrl: './edit-cart-item-group-dialog.component.html',
  styleUrls: ['./edit-cart-item-group-dialog.component.scss'],
  standalone: false,
})
export class SlProjectEditCartItemGroupDialogComponent {
  readonly form: FormGroup<EditCartItemGroupForm>;

  constructor(
    private readonly _dialogRef: MatDialogRef<SlProjectEditCartItemGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly _data?: CartItemGroup | CartItemGroupInput,
  ) {
    this.form = new FormGroup<EditCartItemGroupForm>({
      name: new FormControl<string | null | undefined>(
        this._data?.name,
        Validators.required,
      ),
    });
  }

  onConfirm() {
    this._dialogRef.close({
      ...this._data,
      name: this.form.controls.name.value,
    });
  }
}

interface EditCartItemGroupForm {
  name: FormControl<string | null | undefined>;
}
