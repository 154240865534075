import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectState } from '@sales-libs/project/data-access';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ProjectFilters } from '../../models';

@Component({
  selector: 'sl-project-projects-filters',
  templateUrl: './projects-filters.component.html',
  styleUrls: ['./projects-filters.component.scss'],
  standalone: false,
})
export class SlProjectOverviewFiltersComponent implements OnInit, OnDestroy {
  @Input()
  set filterValues(value: ProjectFilters) {
    this.filtersForm.patchValue(value, { emitEvent: false });
  }

  @Output()
  readonly filter = new EventEmitter<ProjectFilters>();
  @Output()
  readonly clearFilter = new EventEmitter<never>();

  readonly states = ProjectState;
  readonly filtersForm = new FormGroup<ProjectFiltersForm>({
    term: new FormControl<string>('', { nonNullable: true }),
    state: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    chance: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
    startDate: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
    }),
    endDate: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
    }),
  });

  private readonly _destroy$ = new Subject<void>();

  ngOnInit() {
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe((value) =>
        this.filter.emit({
          ...value,
          startDate: value.startDate,
          endDate: value.endDate,
        } as ProjectFilters),
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

interface ProjectFiltersForm {
  term: FormControl<string>;
  state: FormControl<string | undefined>;
  chance: FormControl<number | undefined>;
  startDate: FormControl<Date | undefined>;
  endDate: FormControl<Date | undefined>;
}
