import { CartCompare, CartsService } from '@sales-libs/project/data-access';
import { catchError, map } from 'rxjs';
import { SlProjectCartDependendCreationRow } from './cart-dependend-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectComparisonCreationRow extends SlProjectCartDependendCreationRow {
  constructor(
    private _comparisonText: string,
    private _comparison: CartCompare,
    private _cartService: CartsService,
  ) {
    super(_comparisonText);
  }

  protected _creationFunction = () =>
    this._cartService
      .createCartComparison({
        ...this._comparison,
        target_cart_id: this.cartId,
      })
      .pipe(
        map((data) => ({
          result: {
            data,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
