<h1 mat-dialog-title>
  {{ 'project.original_data_missing_dialog.headline' | translate }}
</h1>
<div mat-dialog-content>
  {{ 'project.original_data_missing_dialog.message' | translate
  }}<strong>{{
    'project.original_data_missing_dialog.solution' | translate
  }}</strong>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'common.close' | translate }}
  </button>
</div>
