<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div class="info banner" *ngIf="showEarlyAccessBanner$ | async">
    <div
      fxLayout
      fxLayout.lt-lg="column"
      [fxLayoutGap]="0.5 | dsSpacing"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-lg="start start"
    >
      <div
        fxFlex="1 1 auto"
        fxLayout
        [fxLayoutGap]="0.5 | dsSpacing"
        fxLayoutAlign="start center"
      >
        <mat-icon>flag</mat-icon>
        <span fxFlex="1 1 auto"
          >{{ 'early_access.banner.text' | translate }}
        </span>
      </div>
      <div
        fxFlex="0 0 auto"
        fxLayout
        fxLayout.xs="column"
        [fxLayoutGap]="0.5 | dsSpacing"
        fxLayoutAlign="start center"
        class="banner-buttons"
      >
        <a mat-stroked-button [routerLink]="['/early-access']">
          {{ 'early_access.banner.more_button' | translate }}
        </a>
        <button mat-button (click)="hideEarlyAccessBanner()">
          {{ 'early_access.banner.do_not_show_again' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div fxFlex="0 0 auto" *pdLet="cart$ | async as cart">
    <ds-page-sticky-header>
      <h1
        fxLayout="row"
        fxLayoutAlign="space-between center"
        data-test="title"
        [class.no-margin-top]="showEarlyAccessBanner$ | async"
      >
        {{ 'common.projects' | translate }}
        <ds-page-actions>
          <button
            mat-button
            page-action
            *dsPageAction
            (click)="openProjectsDownloadDialog()"
            [spotlight]="exportDocumentProject"
            [gaClickListener]="{
              category: 'ProjectOverview',
              action: 'ExportClick',
              label: 'Project'
            }"
            data-test="download-button"
          >
            <mat-icon>cloud_download</mat-icon>
            {{ 'common.export' | translate }}
          </button>
        </ds-page-actions>
      </h1>
    </ds-page-sticky-header>
    <ds-spotlight
      id="sl-project-spotlight-project-overview-exportDocumentProject"
      #exportDocumentProject
      position="left"
    >
      {{ 'spotlight.release_2022_05_01.exportDocumentProject' | translate }}
    </ds-spotlight>

    <div [spotlight]="filterSpotlight">
      <sl-project-projects-filters
        (filter)="updateFilter($event)"
        (clearFilter)="clearFilter()"
        [filterValues]="projectFilters$ | async"
      ></sl-project-projects-filters>
    </div>
  </div>
  <sl-project-project-list
    fxFlex="1 1 auto"
    fxLayout="column"
    class="no-min-height"
    [projects]="projects$ | async"
    (getPage)="getPage($event)"
    (selected)="selectProject($event)"
    (deleteProject)="deleteProject($event)"
    (editProject)="openProjectEdit($event)"
    (showCustomer)="showCustomer($event)"
    (addProject)="addProject()"
    (setUserSettings)="setUserSettings($event)"
    [pagination]="pagination$ | async"
    [displayColumns]="displayColumns"
    (sortChange)="sortChange($event)"
  >
  </sl-project-project-list>
</div>
<ds-spotlight
  id="sl-project-spotlight-new-filter"
  #filterSpotlight
  position="below"
>
  {{ 'spotlight.release_2022_12_01.filter' | translate }}
</ds-spotlight>
