<ng-container
  [matColumnDef]="perspective + 'SalesPrice'"
  gaLabelGroup="SalesPriceColumn"
  gaCategoryGroup="Calculation"
>
  <th mat-header-cell *matHeaderCellDef id="discounted_price">
    {{ 'calculation.discounted' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let item"
    class="text-right"
    [gaClickListener]="perspective + 'SalesPriceClick'"
  >
    <sl-project-sales-price-input
      *ngIf="
        item.item_type === itemTypes.Item ||
        item.item_type === itemTypes.GroupItem
      "
      [item]="item"
      [parentForm]="parentForm"
      [perspective]="perspective"
      [currencySymbol]="currencySymbol"
      [currencyRatio]="currencyRatio"
      [currencyRoundingDigits]="currencyRoundingDigits"
      [isAlternativeView]="isAlternativeView"
      [calculation]="calculation"
      (calculationChange)="calculationChange.emit($event)"
    ></sl-project-sales-price-input>
    <span *ngIf="item.item_type === itemTypes.Group">
      {{
        item[perspective]
          | cpqSalesPrice
            : item.quantity
            : currencyRatio
            : currencyRoundingDigits
          | slMoneyAmount$
            : {
                display: 'symbol',
                ratio: 1,
                roundingDigits: 2
              }
          | async
      }}
    </span>
    <small *ngIf="item.item_type === itemTypes.Option">
      {{
        item[perspective]
          | cpqSalesPrice
            : item.quantity
            : currencyRatio
            : currencyRoundingDigits
          | slMoneyAmount$
            : {
                display: 'symbol',
                ratio: 1,
                roundingDigits: 2
              }
          | async
      }}
    </small>
  </td>
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right sub-total {{ perspective }}-sub-total"
    [gaClickListener]="perspective + 'TotalSalesPriceClick'"
  >
    {{
      subTotal
        | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
        | async
    }}
  </td>
</ng-container>
