import { FilterableMenuItem } from './model';

export const productConfigurationItem = {
  data: { id: 'configuration-product' },
  title: '', // dynamic from product
  routerLink: '/configuration/options',
  filterPredicate: (hasProduct: boolean) => hasProduct,
};

export const productSelectionItem = {
  data: { id: 'product-selection' },
  title: 'project_detail.product',
  routerLink: '/configuration/models',
  filterPredicate: (hasProduct: boolean, hasSolution: boolean) =>
    !hasProduct && !hasSolution,
};

export const solutionItem = {
  data: { id: 'solution' },
  title: 'project_detail.solution',
  routerLink: '/configuration/solution',
  filterPredicate: (hasProduct: boolean) => !hasProduct,
};

export const configurationMenu: FilterableMenuItem = {
  title: 'project.configuration',
  icon: 'settings',
  routerLink: '/configuration',
  data: { id: 'configuration' },
  children: [
    {
      title: 'project_detail.overview',
      routerLink: '/configuration',
    },
    solutionItem,
    productSelectionItem,
    productConfigurationItem,
    {
      title: 'project_detail.equipment',
      routerLink: '/configuration/equipment',
    },
    {
      title: 'project_detail.custom_articles',
      routerLink: '/configuration/custom-articles',
    },
  ],
};
