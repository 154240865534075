<ng-container gaLabelGroup="DependendRemovalDialog" gaCategoryGroup="Cart">
  <h1
    mat-dialog-title
    gaClickListener="TitleClick"
    data-test="product-dependend-removal-title"
  >
    {{ 'project_detail.product_dependend_removal_title' | translate }}
  </h1>

  <mat-dialog-content>
    <span
      [innerHTML]="'project_detail.product_dependend_removal_text' | translate"
    ></span>
    <br /><br />
    <sl-project-cart-item-list
      [items]="data.items"
      [isReadOnly]="true"
      gaClickListener="CartItemListClick"
    ></sl-project-cart-item-list>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close data-test="cancel-button">
      {{ 'common.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      [mat-dialog-close]="true"
      data-test="confirm-button"
    >
      {{ 'common.confirm' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
