import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { CustomArticleItem } from '@sales-libs/project/data-access';
import { FEATURE_FLAG_PPI, TINYMCECONFIG } from '@sales-libs/shared/util';
import { Observable, Subject, combineLatest } from 'rxjs';
import { CartSelectors } from '../store';

@Component({
  selector: 'sl-project-edit-custom-cart-item',
  templateUrl: './edit-custom-cart-item.component.html',
  styleUrls: ['./edit-custom-cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectEditCustomCartItemComponent implements OnInit {
  get item(): CustomArticleItem {
    return this._item;
  }

  @Input()
  set item(value: CustomArticleItem) {
    this._itemStream$.next(value);
  }

  @Output()
  readonly editConfirmed = new EventEmitter<CustomArticleItem>();

  longDescription: LongDescription;
  ratio$: Observable<number>;
  private _itemStream$ = new Subject<CustomArticleItem>();
  readonly maxPrice = 9999999999.99;

  readonly form: FormGroup<CustomCartItemForm>;
  readonly tinymceConfig = TINYMCECONFIG.default();

  private _item: CustomArticleItem;
  private _hasBrWithSpace: boolean;

  constructor(
    private readonly _store: Store,
    private readonly _userService: UserService,
    @Inject(FEATURE_FLAG_PPI) public isFeatureFlagPpiSet,
  ) {
    this.ratio$ = this._store.select(CartSelectors.currencyRatio);

    this.form = new FormGroup<CustomCartItemForm>({
      short_description: new FormControl<string | undefined | null>(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      name: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      is_discountable: new FormControl<boolean>(true, { nonNullable: true }),
      is_ppi_relevant: new FormControl<boolean>(true, { nonNullable: true }),
      sales_price: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      purchase_price: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      quantity: new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });

    combineLatest([this._itemStream$, this.ratio$]).subscribe(
      ([item, ratio]) => {
        // we persit the original values so we can compare them later
        item = {
          ...item,
          original_name: item.original_name ?? item.name,
          original_short_description:
            item.original_short_description ?? item.short_description,
          original_long_description:
            item.original_long_description ?? item.long_description,
          original_sales_price: item.original_sales_price ?? item.sales_price,
          original_purchase_price:
            item.original_purchase_price ?? item.purchase_price,
          original_quantity: item.original_quantity ?? item.quantity,
          original_is_discountable:
            item.original_is_discountable ?? item.is_discountable,
          original_is_ppi_relevant:
            item.original_is_ppi_relevant ?? item.is_ppi_relevant,

          is_edited: true,
        };

        this._item = item;

        this.form.patchValue(
          {
            ...item,
            // we convert the prices to the users currency to fill form properly
            sales_price: this._toRoundedCurrency(item.sales_price, ratio),
            purchase_price: this._toRoundedCurrency(item.purchase_price, ratio),
          },
          { emitEvent: false },
        );

        this.longDescription = {
          originalValue: item.long_description,
          editorValue: item.long_description,
        };

        if (
          item.long_description &&
          item.long_description.indexOf('<br />') !== -1
        ) {
          this._hasBrWithSpace = true;
        }
      },
    );
  }
  ngOnInit() {
    this.tinymceConfig['language'] =
      this._userService.userContext.lang.toLowerCase();
  }
  setInitalEditorValue() {
    this.longDescription.originalEditorFormatedValue =
      this.longDescription?.editorValue;
  }
  private _getLongDescription() {
    if (
      this.longDescription.originalEditorFormatedValue ===
      this.longDescription.editorValue
    ) {
      return this.longDescription.originalValue;
    } else {
      // tinymce formats br like this "<br />"
      // if the original item had brs like this "<br/>" (no space)
      // then we format this here back again
      return this._hasBrWithSpace
        ? this.longDescription.editorValue
        : this.longDescription.editorValue &&
            this.longDescription.editorValue.replace(
              new RegExp('<br />', 'g'),
              '<br/>',
            );
      // we do this so if the user later undos his changes
      // the backend can compare original and current
      // and set the isEdited flag correctly
    }
  }

  onSubmit(ratio: number) {
    let itemUpdate: CustomArticleItem = {
      ...this.item,
      ...this.form.value,
      long_description: this._getLongDescription(),
    };

    // since form value is a partial, we do this step after the merging with the original item
    itemUpdate = {
      ...itemUpdate,
      // user will enter values in his currency so we need to convert it back
      sales_price: itemUpdate.sales_price / ratio,
      purchase_price: itemUpdate.purchase_price / ratio,
    };

    // if the item is new, we set the original values to the first values entered by the user
    if (!itemUpdate.id) {
      itemUpdate = {
        ...itemUpdate,
        original_name: itemUpdate.name,
        original_short_description: itemUpdate.short_description,
        original_long_description: itemUpdate.long_description,
        original_sales_price: itemUpdate.sales_price,
        original_purchase_price: itemUpdate.purchase_price,
        original_is_discountable: itemUpdate.is_discountable,
        original_is_ppi_relevant: itemUpdate.is_ppi_relevant,
      };
    }

    // if the edited values match the original values, we set is_edited flag to false
    if (
      itemUpdate.name === itemUpdate.original_name &&
      itemUpdate.short_description === itemUpdate.original_short_description &&
      itemUpdate.is_discountable === itemUpdate.original_is_discountable &&
      itemUpdate.is_ppi_relevant === itemUpdate.original_is_ppi_relevant &&
      this._comparePrices(
        itemUpdate.sales_price,
        itemUpdate.original_sales_price,
        ratio,
      ) &&
      this._comparePrices(
        itemUpdate.purchase_price,
        itemUpdate.original_purchase_price,
        ratio,
      ) &&
      itemUpdate.long_description === itemUpdate.original_long_description
    ) {
      itemUpdate.is_edited = false;
    }

    this.editConfirmed.emit(itemUpdate);
  }

  private _toRoundedCurrency(value: number, ratio: number) {
    return Math.round(value * ratio * 100) / 100;
  }

  // we compare prices in user currency to make sure rounding errors are not causing the isEdited flag to be set
  private _comparePrices(
    value1: number | null | undefined,
    value2: number | null | undefined,
    ratio: number,
  ) {
    // backend saves prices with 6 digits precision
    return (
      this._toRoundedCurrency(this._roundTo6Digits(value1), ratio) ===
      this._toRoundedCurrency(this._roundTo6Digits(value2), ratio)
    );
  }

  private _roundTo6Digits(value: number | null | undefined) {
    const tenTo6 = 10 ** 6;
    return Math.round((value ?? 0) * tenTo6) / tenTo6;
  }
}

interface CustomCartItemForm {
  short_description: FormControl<string | undefined | null>;
  name: FormControl<string | null>;
  is_discountable: FormControl<boolean>;
  is_ppi_relevant: FormControl<boolean>;
  sales_price: FormControl<number>;
  purchase_price: FormControl<number>;
  quantity: FormControl<number>;
}

interface LongDescription {
  editorValue?: string | null;
  originalValue?: string | null;
  originalEditorFormatedValue?: string | null;
}
