/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DsImagesModule } from '@design-system/components/images';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { OfferScopeOfDelivery } from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlProjectDigitalOfferSpecificationsSectionComponent } from './digital-offer-specifications-section/digital-offer-specifications-section.component';

@Component({
  selector: 'sl-project-digital-offer-specifications',
  imports: [
    CommonModule,
    GoogleAnalytics4DirectiveModule,
    DsImagesModule,
    TranslateModule,
    DsTextIndicatorModule,
    SlProjectDigitalOfferSpecificationsSectionComponent,
  ],
  templateUrl: './digital-offer-specifications.component.html',
  styleUrls: ['./digital-offer-specifications.component.scss'],
})
export class SlProjectDigitalOfferSpecificationsComponent {
  @Input() specifications: OfferScopeOfDelivery;
}
