import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LostOrder, LostOrdersService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ProjectActions, ProjectSelectors } from '../../store';
import { LostOrderActions } from '../store';

@Injectable()
export class LostOrderEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store<any> = inject(Store);

  addLostOrder$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.AddLostOrder),
      map((action) => action.payload),
      mergeMap((payload) =>
        this._lostOrdersService.createLostOrder(payload).pipe(
          withLatestFrom(this._store.select(ProjectSelectors.id)),
          mergeMap(([data, projectId]) => {
            const actions: Array<Action> = [
              LostOrderActions.LoadLostOrderSuccess({ payload: data }),
              ProjectActions.LoadProjects({ payload: null }),
            ];
            if (projectId) {
              actions.push(ProjectActions.LoadProject({ payload: projectId }));
              actions.push(ProjectActions.LoadCarts({ payload: projectId }));
            }
            return actions;
          }),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.not_created',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  loadLostOrderByProject$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.LoadLostOrder),
      map((action) => action.projectId),
      switchMap((projectId) =>
        this._lostOrdersService.getLostOrderByProjectId(projectId).pipe(
          map((data) =>
            LostOrderActions.LoadLostOrderSuccess({
              payload: data,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.not_loaded',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  deleteLostOrders$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.DeleteLostOrder),
      map((action) => action.projectId),
      mergeMap((payload) =>
        this._lostOrdersService.deleteLostOrderByProjectId(payload).pipe(
          withLatestFrom(this._store.select(ProjectSelectors.id)),
          mergeMap(([, projectId]) => {
            const actions: Array<any> = [
              LostOrderActions.DeleteLostOrderSuccess({ payload: payload }),
              ProjectActions.LoadProjects({ payload: null }),
            ];
            if (payload === projectId) {
              actions.push(ProjectActions.LoadProject({ payload: projectId }));
              actions.push(ProjectActions.LoadCarts({ payload: projectId }));
            }
            return actions;
          }),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.not_deleted',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  updateLostOrder$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.UpdateLostOrder),
      map((action) => action.payload),
      mergeMap((payload) =>
        this._lostOrdersService.updateLostOrder(payload).pipe(
          map(() =>
            LostOrderActions.LoadLostOrderSuccess({
              payload: payload as LostOrder,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.not_updated',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  loadReasons$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.LoadReasons),
      switchMap(() =>
        this._lostOrdersService.getReasonsAsync().pipe(
          map((data) => LostOrderActions.LoadReasonsSuccess({ payload: data })),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.reasons_not_loaded',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  loadCompetitors$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.LoadCompetitors),
      switchMap(() =>
        this._lostOrdersService.getCompetitorsAsync().pipe(
          map((data) =>
            LostOrderActions.LoadCompetitorsSuccess({ payload: data }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.lost_order.competitors_not_loaded',
              ),
            }),
            LostOrderActions.Error(),
          ]),
        ),
      ),
    ),
  );

  getLostOrdersReporting$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderActions.LostOrderReportingFilterChange),
      map((action) => action.payload),
      mergeMap((payload) =>
        this._lostOrdersService
          .getLostOrders(
            payload.reasons,
            payload.competitors,
            payload.startDate,
            payload.endDate,
            payload.productLines,
            payload.products,
            payload.createdBy,
          )
          .pipe(
            map((data) =>
              LostOrderActions.LoadLostOrderReportingSuccess({
                payload: data,
              }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.lost_order_reporting.not_loaded',
                ),
              }),
              LostOrderActions.Error(),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private readonly _lostOrdersService: LostOrdersService,
    private readonly _translateService: TranslateService,
  ) {}
}
