import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartItemsService, ProductItem } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { CartActions, CartSelectors } from '../../store';
import { TuningCenterActions } from './tuning-center.actions';

@Injectable()
export class TuningCenterEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store<any> = inject(Store);

  getTuningCenterOptions$ = createEffect(() =>
    this._actions.pipe(
      ofType(TuningCenterActions.GetOptions),
      withLatestFrom(
        this._store
          .select(CartSelectors.mainProduct)
          .pipe(
            filter(
              (craneCartItem): craneCartItem is ProductItem =>
                craneCartItem != null,
            ),
          ),
      ),
      switchMap(([, craneCartItem]) =>
        this._cartItemsService
          .getTuningCenterOptionsAsync(craneCartItem?.object_type || '')
          .pipe(
            map((data) =>
              TuningCenterActions.GetOptionsSuccess({ payload: data }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.tuning_center.options_not_loaded',
                ),
              }),
            ]),
          ),
      ),
    ),
  );

  // TODO move to cart
  addPositionCartItem$ = createEffect(() =>
    this._actions.pipe(
      ofType(TuningCenterActions.AddPositionCartItem),
      switchMap((action) =>
        this._cartItemsService.addTuningCenterItemAsync(action.payload).pipe(
          mergeMap((item) => [
            CartActions.AddTuningCenterItemSuccess({ item }),
            SnackbarActions.ShowSuccess({
              message: this._translateService.instant(
                'configuration.added_to_cart',
                { item: action.payload.name },
              ),
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.tuning_center.position_not_added',
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
