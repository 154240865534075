import { Action, createReducer, on } from '@ngrx/store';
import { Calculation } from '@sales-libs/project/data-access';
import { CalculationActions } from './calculation.actions';

export interface CalculationState {
  discount_and_costs: Calculation | null;
  calculation: Calculation | null;
  perspectives: string[];
  expandedItemsLookup: { [id: string]: boolean };
  exportUrl?: string;
  offerUrl?: string;
  hasOfferUrlError?: boolean;
  hasExportError?: boolean;
  isSaving: boolean;
}

export const initialCalculationState: CalculationState = {
  discount_and_costs: null,
  calculation: null,
  perspectives: ['customer', 'partner', 'general_agent'],
  expandedItemsLookup: {},
  isSaving: false,
};

const reducer = createReducer(
  initialCalculationState,
  on(CalculationActions.ResetOfferUrl, (state) => ({
    ...state,
    offerUrl: undefined,
  })),
  on(CalculationActions.LoadCalculation, (state) => ({
    ...state,
    calculation: null,
  })),
  on(CalculationActions.UpdateCalculation, (state, { payload }) => ({
    ...state,
    calculation: payload,
    isSaving: true,
  })),
  on(CalculationActions.UpdateCalculationSuccess, (state) => ({
    ...state,
    isSaving: false,
  })),
  on(CalculationActions.LoadCalculationSuccess, (state, { payload }) => ({
    ...state,
    calculation: payload,
  })),
  on(CalculationActions.AddPerspective, (state, { payload }) => {
    // if we have all 3 persepctive, we make sure the order is like initialy
    if (state.perspectives.length === 2) {
      return {
        ...state,
        perspectives: initialCalculationState.perspectives,
      };
    }
    return {
      ...state,
      perspectives: [...state.perspectives, payload],
    };
  }),
  on(CalculationActions.RemovePerspective, (state, { payload }) => ({
    ...state,
    perspectives: [...state.perspectives.filter((x) => x !== payload)],
  })),
  on(CalculationActions.ToggleItemExpanded, (state, { payload }) => ({
    ...state,
    expandedItemsLookup: {
      ...state.expandedItemsLookup,
      [payload]: !state.expandedItemsLookup[payload],
    },
  })),
  on(CalculationActions.GetExportUrl, (state) => ({
    ...state,
    exportUrl: undefined,
  })),
  on(CalculationActions.GetExportUrlSuccess, (state, { payload }) => ({
    ...state,
    exportUrl: payload.url || '',
  })),
  on(CalculationActions.GetExportUrlError, (state) => ({
    ...state,
    exportUrl: undefined,
    hasOfferUrlError: true,
  })),
  on(CalculationActions.GetOfferUrl, (state) => ({
    ...state,
    offerUrl: undefined,
    hasOfferUrlError: false,
  })),
  on(CalculationActions.GetOfferUrlSuccess, (state, { payload }) => ({
    ...state,
    offerUrl: payload,
  })),
  on(CalculationActions.GetOfferUrlError, (state) => ({
    ...state,
    offerUrl: undefined,
    hasOfferUrlError: true,
  })),
  on(CalculationActions.ResetExportState, (state) => ({
    ...state,
    exportUrl: undefined,
    hasExportError: false,
  })),
);

export function calculationReducer(
  state: CalculationState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
