<ng-container gaCategoryGroup="Projects" gaLabelGroup="OptionalCartItemList">
  <div
    *ngIf="dragContainers; else loading"
    fxLayout="column"
    cdkDropList
    [cdkDropListData]="dragContainers"
    (cdkDropListDropped)="groupDrop($event, dragContainers)"
    [cdkDropListSortPredicate]="sortPredicate"
    cdkDropListGroup
  >
    <div
      *ngFor="let dragContainer of dragContainers"
      cdkDrag
      cdkDragLockAxis="y"
      [cdkDragDisabled]="isReadOnly"
      [cdkDragData]="dragContainer[0]"
      class="drag-group"
    >
      <mat-card
        *ngFor="let container of dragContainer"
        class="no-shadow no-padding"
        [class.drop-helper]="
          !container.group?.id && container?.items?.length === 0
        "
      >
        <ng-container *ngIf="container.group?.id">
          <table
            class="pd-table"
            fxLayout
            fxLayoutAlign="start center"
            [fxLayoutGap]="1 | dsSpacing"
          >
            <caption hidden>
              Group Header
            </caption>
            <thead class="full-width">
              <tr>
                <th class="min-width-cell" id="drag_handle">
                  <button
                    cdkDragHandle
                    mat-icon-button
                    gaClickListener="ReorderGroupClick"
                    [spotlight]="dragContainerpotlight"
                  >
                    <mat-icon>drag_indicator</mat-icon>
                  </button>
                </th>
                <th id="name">
                  <h4 class="no-margin">{{ container.group?.name }}</h4>
                </th>
                <th class="min-width-cell" id="spacing">
                  <div [style.width]="2.5 | dsSpacing"></div>
                </th>
                <th
                  class="min-width-cell mat-header-cell mat-table-sticky"
                  id="actions"
                >
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    gaClickListener="GroupMoreClick"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div
                      gaCategoryGroup="Projects"
                      gaLabelGroup="OptionalCartItemsListMatMenu"
                    >
                      <button
                        mat-menu-item
                        (click)="editGroup.emit(container.group)"
                        data-test="edit"
                        gaClickListener="EditGroupClick"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>{{ 'common.edit' | translate }}</span>
                      </button>
                      <button
                        mat-menu-item
                        (click)="
                          openConfirmDeleteDialogForGroup(container.group)
                        "
                        data-test="edit"
                        gaClickListener="DeleteGroupClick"
                      >
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'common.delete' | translate }}</span>
                      </button>
                    </div>
                  </mat-menu>
                </th>
              </tr>
            </thead>
          </table>
          <ds-spotlight
            id="cpq-spotlight-drag_group"
            #dragContainerpotlight
            [tags]="['release_2021_04_01', 'cart-item-list']"
          >
            {{
              'spotlight.release_2021_04_01.grouping_sorting.drag_group'
                | translate
            }}
          </ds-spotlight>
        </ng-container>
        <ds-table [disableResponsive]="true">
          <table
            cdkDropList
            class="item-table mat-mdc-table"
            [cdkDropListData]="container"
            (cdkDropListDropped)="itemDrop($event, dragContainers)"
            [cdkDropListEnterPredicate]="dropPredicate(container)"
            role="presentation"
          >
            <tbody>
              <tr
                *ngFor="let item of container.items"
                slProjectCartItemListRow
                class="mat-row data-test-{{ item.name | slCssClass }}"
                [item]="item"
                [isReadOnly]="isReadOnly"
                [isPricingVisible]="isPricingVisible"
                [isTypeVisible]="item.is_optional"
                [isSortingSupported]="true"
                [isItemWithCustomerPriceInput]="
                  (item | isCustomArticleItem) ||
                  (item | isTuningCenterItem) ||
                  (item | isMccItem)
                "
                (configure)="configure.emit($event)"
                (backToCart)="onBackToCart($event)"
                (editTuning)="onEditTuning()"
                (editMcc)="onEditMcc()"
                (editArticle)="onEditArticle($event)"
                (priceDifferenceTo)="onPriceDifferenceTo($event, cart)"
                (showConfiguration)="onShowconfiguration($event)"
                (restoreOriginalPrice)="onRestoreOriginalPrice($event)"
                (changeGroup)="onChangeGroup(container, $event, dragContainers)"
                (removeFromGroup)="onRemoveFromGroup($event)"
                (delete)="onDelete($event)"
                (quantityChange)="onQuantityChange($event)"
                cdkDrag
                cdkDragLockAxis="y"
                [cdkDragData]="container"
                [cdkDragDisabled]="isReadOnly"
                gaClickListener="MccItemListClick"
                [ngClass]="{
                  'dragable-row': !isReadOnly
                }"
              ></tr>
            </tbody>
          </table>
        </ds-table>
      </mat-card>
    </div>
  </div>

  <ng-template #loading>
    <ds-table-loading
      [numberOfHeaders]="4"
      [numberOfRows]="3"
      gaClickListener="LoadingClick"
    >
    </ds-table-loading>
    <ds-table-loading
      [numberOfHeaders]="4"
      [numberOfRows]="3"
      gaClickListener="LoadingClick"
    >
    </ds-table-loading>
    <ds-table-loading
      [numberOfHeaders]="4"
      [numberOfRows]="3"
      gaClickListener="LoadingClick"
    >
    </ds-table-loading>
  </ng-template>
</ng-container>
