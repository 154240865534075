import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Optional,
  Output,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Project } from '@sales-libs/project/data-access';

@Component({
  selector: 'sl-project-project-list-customer-column',
  templateUrl: './project-list-customer-column.component.html',
  styleUrls: ['./project-list-customer-column.component.scss'],
  standalone: false,
})
export class SlProjectListCustomerColumnComponent extends DsColumnComponent {
  @Output()
  readonly showCustomer = new EventEmitter<Project>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
