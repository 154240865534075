<sl-project-quantity-input
  *ngIf="
    !(itemAsProductItem.is_configurable === true) &&
      !isReadOnly &&
      !(cartItem | isContractItem) &&
      !(cartItem | isWarrantyExtensionItem) &&
      !(cartItem | isTuningCenterItem) &&
      !(cartItem | isMccItem) &&
      !(cartItem | isSolutionItem);
    else plainText
  "
  [item]="cartItem"
  [disabled]="isReadOnly"
  (quantityChange)="quantityChange.emit($event)"
></sl-project-quantity-input>
<ng-template #plainText>
  <span data-test="plain-text-quantity">{{
    cartItem.quantity
  }}</span></ng-template
>
