<mat-form-field
  class="percentage-input {{ perspective }}-discount-input print-hidden"
  [gaClickListener]="{
    action: perspective + 'ItemDiscountClick',
    category: 'Cart',
    label: 'DiscountcolumnInput'
  }"
>
  <input
    (keyup.enter)="triggerBlur($event)"
    matInput
    [formControl]="formControl"
    type="number"
    max="100"
    step="0.01"
    autocomplete="off"
  />
  <span matTextSuffix>{{ percentSymbol }}</span>
</mat-form-field>
<span class="print-only">
  {{ formControl.value / 100 | percent: '1.2-2' : locale }}
</span>
