<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="Specifications">
  <div id="yourSpecification"></div>
  <h1 class="no-margin-top">
    {{ 'offer_your_specification_subheadline' | translate }}
  </h1>
  <sl-project-digital-offer-specifications-section
    [items]="specifications?.configuration"
    *ngIf="
      specifications?.configuration && specifications?.configuration?.length
    "
    data-test="configuration-specifications"
  ></sl-project-digital-offer-specifications-section>
  <sl-project-digital-offer-specifications-section
    scrollId="equipmentSpecification"
    [headline]="'Equipment'"
    [items]="specifications?.equipments"
    *ngIf="specifications?.equipments && specifications?.equipments?.length"
    data-test="equipment-specifications"
  ></sl-project-digital-offer-specifications-section>
  <sl-project-digital-offer-specifications-section
    scrollId="customArticlesSpecification"
    [headline]="'Custom articles'"
    [items]="specifications?.custom_articles"
    *ngIf="
      specifications?.custom_articles && specifications?.custom_articles?.length
    "
    data-test="custom-articles-specifications"
  ></sl-project-digital-offer-specifications-section>
  <sl-project-digital-offer-specifications-section
    scrollId="optionalItemsSpecification"
    [headline]="'Optional items and services'"
    [items]="specifications?.optional"
    *ngIf="specifications?.optional && specifications?.optional?.length"
    data-test="optional-specifications"
  >
    <ds-text-indicator gaClickListener="OptionalItemsDisclaimerClick"
      >{{ 'offer_as_alternative_we_offer_optional_items_warning' | translate }}
    </ds-text-indicator>
  </sl-project-digital-offer-specifications-section>
</ng-container>
