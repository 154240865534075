import {
  BestInvestFeatures,
  BestInvestDealerParameters,
  BestInvestProjectParameters,
} from '@sales-libs/upselling/data-access';

export interface SlUpsellingState {
  parameters?: BestInvestProjectParameters;
  bestInvestFeatures?: BestInvestFeatures;
  dealerParameters?: BestInvestDealerParameters;
}

export const initialState: SlUpsellingState = {
  parameters: undefined,
  bestInvestFeatures: undefined,
  dealerParameters: undefined,
};
