import { createAction, props } from '@ngrx/store';
import { Calculation, DocumentDownload } from '@sales-libs/project/data-access';

export namespace CalculationActions {
  /**
   * @param payload cart id
   */
  export const LoadCalculation = createAction(
    '[Calculation] LOAD',
    props<{ payload: number }>(),
  );
  export const LoadCalculationSuccess = createAction(
    '[Calculation] LOAD_SUCCESS',
    props<{ payload: Calculation }>(),
  );
  export const LoadCalculationError = createAction('[Calculation] LOAD_ERROR');

  export const UpdateCalculation = createAction(
    '[Calculation] UPDATE',
    props<{ payload: Calculation }>(),
  );
  export const UpdateCalculationSuccess = createAction(
    '[Calculation] UPDATE_SUCCESS',
  );
  export const UpdateCalculationError = createAction(
    '[Calculation] UPDATE_ERROR',
  );

  export const ToggleItemExpanded = createAction(
    '[Calculation] TOGGLE_ITEM_EXPANDED',
    props<{ payload: string }>(),
  );
  export const AddPerspective = createAction(
    '[Calculation] ADD_PERSPECTIVE',
    props<{ payload: string }>(),
  );
  export const RemovePerspective = createAction(
    '[Calculation] REMOVE_PERSPECTIVE',
    props<{ payload: string }>(),
  );

  /**
   * @param payload cart id
   */

  export const ResetExportState = createAction(
    '[Calculation] RESET_EXPORT_STATE',
  );

  export const GetExcel = createAction('[Calculation] GETEXCEL');
  export const GetExportUrl = createAction('[Calculation] GET_EXPORT_URL');
  export const GetExportUrlSuccess = createAction(
    '[Calculation] GET_EXPORT_URL_SUCCESS',
    props<{ payload: DocumentDownload }>(),
  );
  export const GetExportUrlError = createAction(
    '[Calculation] GET_EXPORT_URL_ERROR',
  );

  export const GetOfferUrl = createAction('[Calculation] GET_OFFER_URL');
  export const GetOfferUrlSuccess = createAction(
    '[Calculation] GET_OFFER_URL_SUCCESS',
    props<{ payload: string }>(),
  );
  export const GetOfferUrlError = createAction(
    '[Calculation] GET_OFFER_URL_ERROR',
  );

  export const ResetOfferUrl = createAction('[Calculation] RESET_OFFER_URL');
}
