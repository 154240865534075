import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartItemGroup } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

export interface GroupSelectionDialogData {
  groups: CartItemGroup[];
  currentGroup: CartItemGroup;
  item: SlCartItem;
}

@Component({
  selector: 'sl-project-group-selection-dialog',
  templateUrl: './group-selection-dialog.component.html',
  styleUrls: ['./group-selection-dialog.component.scss'],
  standalone: false,
})
export class SlProjectGroupSelectionDialogComponent {
  readonly formControl: FormControl<CartItemGroup>;

  constructor(
    private readonly _dialogRef: MatDialogRef<SlProjectGroupSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: GroupSelectionDialogData,
  ) {
    this.formControl = new FormControl<CartItemGroup>(this.data.currentGroup, {
      nonNullable: true,
    });
  }

  onConfirm() {
    this._dialogRef.close(this.formControl.value);
  }
}
