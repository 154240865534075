import {
  Calculation,
  CalculationActions,
  CalculationItem,
  CalculationsService,
} from '@sales-libs/project/data-access';
import {
  Observable,
  catchError,
  combineLatest,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import { SlProjectDataCreationResult } from '../types/result';
import { SlProjectCartDependendCreationRow } from './cart-dependend-row';
import {
  SlProjectDataCreationRow,
  SlProjectDataCreationRowState,
} from './data-creation-row';

export class SlProjectCalculationCreationRow extends SlProjectCartDependendCreationRow {
  private _referenceCalculation: Calculation;
  private _newCalculation: Calculation;
  private _results: SlProjectDataCreationResult[];
  private _predecessingResults$: Observable<SlProjectDataCreationResult[]>;

  constructor(
    private _calculationText: string,
    private _referenceCartId: number,
    private _calculationService: CalculationsService,
    private _predecessingRows: SlProjectDataCreationRow[],
  ) {
    super(_calculationText);
    this._predecessingResults$ = combineLatest(
      this._predecessingRows.map((row) => row.result$),
    );
  }

  protected _creationFunction = () =>
    combineLatest([
      this._getReferenceCalculation(),
      this._getNewCalculation(),
      this._getPredecessingResults(),
    ]).pipe(
      mergeMap(([previousCalculation, newCalculation, results]) =>
        this._calculationService.updateCalculation(
          this.cartId,
          this._getCalculationUpdate(
            previousCalculation,
            newCalculation,
            results,
          ),
        ),
      ),
      map((data) => ({
        result: {
          data,
        },
        state: SlProjectDataCreationRowState.Success,
      })),
      catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
    );

  private _getCalculationUpdate(
    referenceCalculation: Calculation,
    newCalculation: Calculation,
    results: SlProjectDataCreationResult[],
  ) {
    const update: Calculation = {
      ...referenceCalculation,
      id: newCalculation.id,
      items: [],
      actions: [CalculationActions.CreateOffer],
      offer_id: 0,
    };

    results.forEach((result) => {
      if (result.calculationItemReferenceId) {
        const previousItem = this._findCalculationItemByReferenceId(
          referenceCalculation.items ?? [],
          result.calculationItemReferenceId,
        );

        if (previousItem) {
          const newId = result.data.id;
          update.items.push(
            this._getCalculationItemWithNewId(previousItem, newId),
          );
        }
      }
    });
    return update;
  }

  private _getReferenceCalculation() {
    return this._referenceCalculation
      ? of(this._referenceCalculation)
      : this._calculationService
          .getCalculation(this._referenceCartId)
          .pipe(tap((data) => (this._referenceCalculation = data)));
  }

  private _getNewCalculation() {
    return this._newCalculation
      ? of(this._newCalculation)
      : this._calculationService
          .getCalculation(this.cartId)
          .pipe(tap((data) => (this._newCalculation = data)));
  }

  private _getPredecessingResults() {
    return this._results
      ? of(this._results)
      : this._predecessingResults$.pipe(tap((data) => (this._results = data)));
  }

  private _findCalculationItemByReferenceId(
    items: CalculationItem[],
    refernceId: string,
  ) {
    let result: CalculationItem | undefined;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (
        item.custom_article_item_id === refernceId ||
        item.product_item_id === refernceId ||
        item.sales_option_item_id === refernceId ||
        item.warranty_extension_item_id === refernceId
      ) {
        result = item;
      } else if (item.items) {
        result = this._findCalculationItemByReferenceId(item.items, refernceId);
      }
      if (result) {
        break;
      }
    }
    return result;
  }

  private _getCalculationItemWithNewId(item: CalculationItem, newId: string) {
    return {
      ...item,
      id: newId,
      name: item.name ?? '_', // name is needed for update
      custom_article_item_id: item.custom_article_item_id ? newId : undefined,
      product_item_id: item.product_item_id ? newId : undefined,
      sales_option_item_id: item.sales_option_item_id ? newId : undefined,
      warranty_extension_item_id: item.warranty_extension_item_id
        ? newId
        : undefined,
    };
  }
}
