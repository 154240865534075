import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import { CartActions } from '../store';

@Component({
  selector: 'sl-project-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.scss'],
  standalone: false,
})
export class SlProjectSummaryDialogComponent implements OnDestroy {
  private readonly $destroy = new Subject<void>();

  constructor(
    private _actions: Actions,
    private _router: Router,
  ) {
    this._actions
      .pipe(ofType(CartActions.LoadCart))
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: () => {
          const url = this._router.url;
          this._router.navigate([url.substring(0, url.lastIndexOf('/'))]);
        },
      });
  }
  ngOnDestroy(): void {
    this.$destroy.next();
  }
}
