<tr
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  [gaClickListener]="{
    category: 'Cart',
    action: 'SolutionItemListClick',
    label: 'Result'
  }"
  class="mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  (delete)="deleteItem($event)"
  (toOptional)="onToOptional($event)"
  (configure)="onConfigure($event)"
  (showConfiguration)="onShowConfiguration($event, isPricingVisible)"
></tr>
