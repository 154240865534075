<ng-container [matColumnDef]="perspective + 'FinalPrice'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right {{ perspective }}-total"
  >
    {{
      finalPrice
        | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
        | async
    }}
  </td>
</ng-container>
