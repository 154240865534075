import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DsFilterPaginationValue } from '@design-system/components/filter-input';
import { createAction, props } from '@ngrx/store';
import {
  DocumentDownload,
  LostOrderView,
  LostOrderViewContainer,
  ProjectsContainer,
} from '@sales-libs/project/data-access';
import { LostOrderFilters } from '../../models';

export enum LostOrderActionTypes {
  FilterChange = '[LostOrder] FILTER_CHANGE',
  PageChange = '[LostOrder] PAGE_CHANGE',
  ClearFilter = '[LostOrder] CLEAR_CHANGE',
  GetExport = '[LostOrder] GET_EXPORT',
  GetExportSuccess = '[LostOrder] GET_EXPORT_SUCCESS',
  GetExportError = '[LostOrder] GET_EXPORT_ERROR',
  ResetExportState = '[LostOrder] RESET_EXPORT_STATE',
  LoadLostOrderFilter = '[LostOrder] LOAD_LOSTORDER_FILTER',
  LoadLostOrderFilterSuccess = '[LostOrder] LOAD_LOSTORDER_FILTER_SUCCESS',
  LoadLostOrders = '[LostOrder] LOAD_LOST_ORDER_PAGE',
  LoadLostOrdersSuccess = '[LostOrder] LOAD_LOST_ORDERS_SUCCESS',
  LoadLostOrderSuccess = '[LostOrder] LOAD_LOST_ORDER_SUCCESS',
  LoadLostOrderError = '[LostOrder] LOAD_LOST_ORDER_ERROR',
  Error = '[LostOrder] ERROR',
  SearchLostOrders = '[LostOrder] SEARCH_LOST_ORDERS',
  SortChange = '[LostOrder] SORT_CHANGE',
}

export namespace LostOrderListActions {
  export const FilterChange = createAction(
    LostOrderActionTypes.FilterChange,
    props<{ payload: LostOrderFilters }>(),
  );
  export const PageChange = createAction(
    LostOrderActionTypes.PageChange,
    props<{ payload: PageEvent }>(),
  );

  export const LoadLostOrders = createAction(
    LostOrderActionTypes.LoadLostOrders,
    props<{ payload: number | null }>(),
  );

  export const ClearFilter = createAction(LostOrderActionTypes.ClearFilter);
  export const GetExport = createAction(LostOrderActionTypes.GetExport);
  export const GetExportSuccess = createAction(
    LostOrderActionTypes.GetExportSuccess,
    props<{ payload: DocumentDownload }>(),
  );
  export const GetExportError = createAction(
    LostOrderActionTypes.GetExportError,
  );
  export const ResetExportState = createAction(
    LostOrderActionTypes.ResetExportState,
  );
  export const LoadLostOrderFilter = createAction(
    LostOrderActionTypes.LoadLostOrderFilter,
    props<{ payload: DsFilterPaginationValue }>(),
  );
  export const LoadLostOrderFilterSuccess = createAction(
    LostOrderActionTypes.LoadLostOrderFilterSuccess,
    props<{ payload: ProjectsContainer }>(),
  );

  export const LoadLostOrdersSuccess = createAction(
    LostOrderActionTypes.LoadLostOrdersSuccess,
    props<{ payload: LostOrderViewContainer }>(),
  );
  export const LoadLostOrderSuccess = createAction(
    LostOrderActionTypes.LoadLostOrderSuccess,
    props<{ payload: LostOrderView }>(),
  );
  export const LoadLostOrderError = createAction(
    LostOrderActionTypes.LoadLostOrderError,
    props<{ isProjectDeleted: boolean }>(),
  );
  export const SearchLostOrders = createAction(
    LostOrderActionTypes.SearchLostOrders,
  );

  export const SortChange = createAction(
    LostOrderActionTypes.SortChange,
    props<{ payload: Sort }>(),
  );

  export const Error = createAction(LostOrderActionTypes.Error);
}
