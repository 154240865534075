import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-image-column',
  templateUrl: './image-column.component.html',
  styleUrls: ['./image-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectImageColumnComponent {
  @Input() item: SlCartItem;
}
