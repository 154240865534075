import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MccItem, MccItemUpdate } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable, map } from 'rxjs';
import { SlProjectMccDialogComponent } from '../../mcc-dialog/mcc-dialog.component';
import { CartActions, CartSelectors } from '../../store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectMccCartItems]',
  templateUrl: './mcc-cart-items.component.html',
  styleUrls: ['./mcc-cart-items.component.scss'],
  standalone: false,
})
export class SlProjectMccCartItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;

  readonly items$: Observable<MccItem[]>;

  constructor(
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
  ) {
    this.items$ = this._store
      .select(CartSelectors.mccItems)
      .pipe(map((items) => (items ?? []).filter((item) => !item.is_optional)));
  }

  onEdit() {
    this._dialog.open(SlProjectMccDialogComponent);
  }

  onDelete(itemToDelete: SlCartItem) {
    this._store.dispatch(
      CartActions.DeleteMccItem({
        cartId: itemToDelete.cart_id,
        itemId: itemToDelete.id as string,
      }),
    );
  }

  onToOptional(update: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateMccItem({
        update: { ...update, is_optional: true } as MccItemUpdate,
      }),
    );
  }
}
