import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigurationOption } from '@sales-libs/project/data-access';
import { SlCartItem, SlIsSalesOptionItemPipe } from '@sales-libs/shared/util';
import { Observable } from 'rxjs';
import { CartSelectors } from '../../../../store';

@Component({
  selector: 'sl-project-price-column-edited-indicator',
  templateUrl: './price-column-edited-indicator.component.html',
  styleUrls: ['./price-column-edited-indicator.component.scss'],
  standalone: false,
})
export class SlProjectPriceColumnEditedIndicatorComponent implements OnInit {
  @Input() item: SlCartItem;

  priceComparedTo$: Observable<
    SlCartItem | ConfigurationOption | null | undefined
  >;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    if (new SlIsSalesOptionItemPipe().transform(this.item)) {
      this.priceComparedTo$ = this._store.select(
        CartSelectors.oneOption(this.item.cart_item_price_comparer_id || ''),
      );
    } else {
      this.priceComparedTo$ = this._store.select(
        CartSelectors.one('' + this.item.cart_item_price_comparer_id),
      );
    }
  }
}
