<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="Benefits">
  <div [id]="scrollId" data-test="scroll-id"></div>
  <h2
    *ngIf="headline"
    data-test="headline"
    gaClickListener="SectionHeadlineClick"
  >
    {{ headline }}
  </h2>
  <ng-content></ng-content>
  @for (item of itemWithImages; track item) {
    <div class="image-text-row">
      <div
        fxLayout
        fxLayoutAlign="start start"
        [fxLayoutGap]="1 | dsSpacing"
        class="content"
      >
        <div class="image-column" fxFlex="0 0 auto">
          <mat-card *ngIf="item.image" gaClickListener="ImageClick">
            <ds-single-image [image]="item.image"></ds-single-image>
          </mat-card>
        </div>
        <div fxFlex="1 1 auto">
          <strong gaClickListener="ItemTitleClick" data-test="item-title">{{
            item.title
          }}</strong>
          <div
            [innerHTML]="item.description"
            data-test="item-description"
            gaClickListener="DescriptionClick"
          ></div>
        </div>
      </div>
      <hr />
    </div>
  }
</ng-container>
