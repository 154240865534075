import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShippingCost } from '../../shared/models';
import { ShippingCostsDialogComponent } from './shipping-costs-dialog/shipping-costs-dialog.component';

@Component({
  selector: 'sl-project-shipping-costs-column',
  templateUrl: './shipping-costs-column.component.html',
  styleUrls: ['./shipping-costs-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectShippingCostsColumnComponent extends DsColumnComponent {
  @Input() shippingCosts: ShippingCost[];
  @Input() calculation: Calculation;
  @Input() currencyRatio = 1;

  @Output() calculationChange = new EventEmitter();

  readonly formControl = new FormControl<ShippingCost | null>(null);
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    private readonly _dialog: MatDialog,

    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    if (this.formDirective.form.disabled) {
      this.formControl.disable();
    }

    this.formDirective.form.addControl('shippingCosts', this.formControl);

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.formControl.reset(null, {
          onlySelf: true,
          emitEvent: false,
        });
        if (value) {
          this.calculationChange.emit({
            ...this.calculation,
            customer: {
              ...this.calculation.customer,
              transport_costs: value.sales_price / this.currencyRatio,
            },
            partner: {
              ...this.calculation.partner,
              transport_costs: value.sales_price / this.currencyRatio,
            },
            general_agent: this.calculation.general_agent
              ? {
                  ...this.calculation.general_agent,
                  transport_costs: value.purchase_price / this.currencyRatio,
                }
              : this.calculation.general_agent,
          });
        }
      });
  }

  openShippingCostsDialog() {
    const dialogRef = this._dialog.open<
      ShippingCostsDialogComponent,
      ShippingCost[]
    >(ShippingCostsDialogComponent, {
      width: '70%',
      data: this.shippingCosts,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formControl.setValue(result);
      }
    });
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
