import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const projectState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace DigitalOfferSelectors {
  const digitalOfferState = createSelector(
    projectState,
    (state) => state.digitalOffer,
  );

  export const digitalOffer = createSelector(
    digitalOfferState,
    (state) => state.digitalOfferData,
  );

  export const isLoading = createSelector(
    digitalOfferState,
    (state) => state.isLoading,
  );
}
