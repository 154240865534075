<h2
  mat-dialog-title
  [gaClickListener]="{
    category: 'Cart',
    label: 'SummaryDialog',
    action: 'TitleClick'
  }"
  data-test="summary-dialog-headline"
>
  {{ 'project_detail.tab_label_summary' | translate }}
</h2>
<mat-dialog-content>
  <sl-project-cart-items [isReadOnly]="true"></sl-project-cart-items>
</mat-dialog-content>
