<ng-container [matColumnDef]="perspective + 'TransportCosts'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right"
    [gaClickListener]="{
      action: perspective + 'TransportCostClick',
      category: 'Cart',
      label: 'TransportCostsColumn'
    }"
  >
    {{
      transportCost
        | slMoneyAmount$: { display: 'symbol', roundingDigits: 2 }
        | async
    }}
  </td>
</ng-container>
