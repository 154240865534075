<ds-filter
  [formGroup]="filtersForm"
  (resetFilter)="clearFilter.emit(); filtersForm.reset()"
  gaLabelGroup="ProjectFilters"
  gaCategoryGroup="ProjectOverview"
>
  <mat-form-field
    gaClickListener="TermClick"
    *dsFilterItem="filtersForm.controls.term"
  >
    <mat-label>{{ 'common.search' | translate }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="filtersForm.controls.term"
      autocomplete="off"
      data-test="project-filter-term"
    />
    <button
      mat-icon-button
      matIconSuffix
      type="button"
      [attr.aria-label]="'common.clear' | translate"
      *ngIf="filtersForm.controls.term.value !== ''"
      (click)="filtersForm.controls.term.reset('')"
      data-test="filter-reset-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field
    gaClickListener="FromDateClick"
    *dsFilterItem="filtersForm.controls.startDate"
  >
    <mat-label>{{ 'project_filters.from' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="startDatepicker"
      autocomplete="off"
      [formControl]="filtersForm.controls.startDate"
      data-test="project-filter-start-date"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="startDatepicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #startDatepicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    gaClickListener="TillDateClick"
    *dsFilterItem="filtersForm.controls.endDate"
  >
    <mat-label>{{ 'project_filters.till' | translate }}</mat-label>
    <input
      matInput
      autocomplete="off"
      [matDatepicker]="endDatepicker"
      [formControl]="filtersForm.controls.endDate"
      data-test="project-filter-end-date"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="endDatepicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #endDatepicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field *dsFilterItem="filtersForm.controls.state">
    <mat-label>{{ 'project.status' | translate }}</mat-label>
    <mat-select
      [formControl]="filtersForm.controls.state"
      gaClickListener="StateSelectionClick"
      data-test="project-filter-state"
    >
      <mat-option
        [value]="null"
        gaClickListener="EmptyStateSelectionClick"
        data-test="option-state-All"
      >
        {{ 'general.show_all' | translate }}
      </mat-option>
      <mat-option
        [value]="states.InProgress"
        gaClickListener="InProgressSateSelectionClick"
        data-test="option-state-InProgress"
      >
        {{ 'project_filters.in_progress' | translate }}
      </mat-option>
      <mat-option
        [value]="states.Won"
        gaClickListener="WonSateSelectionClick"
        class="success-text"
        data-test="option-state-Won"
      >
        {{ 'project_filters.won' | translate }}
      </mat-option>
      <mat-option
        [value]="states.Lost"
        gaClickListener="LostSateSelectionClick"
        class="error-text"
        data-test="option-state-Lost"
      >
        {{ 'project_filters.lost' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *dsFilterItem="filtersForm.controls.chance">
    <mat-label>{{ 'project_filters.chance' | translate }} (%)</mat-label>
    <mat-select
      [formControl]="filtersForm.controls.chance"
      gaClickListener="ChanceSelectionClick"
      data-test="project-filter-chance"
    >
      <mat-option
        [value]="undefined"
        gaClickListener="ShowAllChanceSelectionClick"
      >
        {{ 'general.show_all' | translate }}
      </mat-option>
      <mat-option
        *ngFor="let value of [0, 25, 50, 75, 100]"
        [value]="value"
        gaClickListener="ChanceSelectionClick"
      >
        {{ value }}%
      </mat-option>
    </mat-select>
  </mat-form-field>
</ds-filter>
