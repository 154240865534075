import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { TranslateModule } from '@ngx-translate/core';
import { OfferPricePosition } from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

const allDisplayColumns = [
  'name',
  'quantity',
  'description',
  'discount',
  'price',
];

@Component({
  selector: 'sl-project-digital-offer-price-table',
  imports: [
    CommonModule,
    GoogleAnalytics4DirectiveModule,
    TranslateModule,
    FlexLayoutModule,
    MatTableModule,
    DsSpacingModule,
    DsTableModule,
    TranslateModule,
    DsTableLoadingModule,
  ],
  templateUrl: './digital-offer-price-table.component.html',
  styleUrl: './digital-offer-price-table.component.scss',
})
export class SlProjectDigitalOfferPriceTableComponent implements OnInit {
  @Input() dataSource: OfferPricePosition[] | null;
  @Input() displayItemPrices: boolean;
  @Input() includeDiscounts: boolean;
  @Input() currency: string;
  displayColumns: string[];

  ngOnInit(): void {
    this.displayColumns = [
      ...allDisplayColumns.filter(
        (key) =>
          (this.displayItemPrices || (key !== 'price' && key !== 'discount')) &&
          (this.includeDiscounts || key !== 'discount'),
      ),
    ];
  }
}
