/* eslint-disable max-lines */
import { createAction, props } from '@ngrx/store';
import {
  Cart,
  CartDocument,
  CartDocumentContainer,
  CartItemGroup,
  CartItemGroupInput,
  CartItemGroupUpdate,
  CartUpdate,
  ContractItem,
  ContractItemInput,
  CustomArticleItem,
  CustomArticleItemInput,
  CustomArticleItemUpdate,
  MccItem,
  MccItemInput,
  MccItemUpdate,
  ProductItem,
  ProductItemInput,
  ProductItemProductType,
  ProductItemUpdate,
  SalesOptionCartItem,
  SalesOptionCartItemInput,
  SalesOptionCartItemUpdate,
  SolutionItem,
  SolutionItemInput,
  SolutionItemUpdate,
  TuningCenterItem,
  TuningCenterItemInput,
  TuningCenterItemUpdate,
  WarrantyExtensionItem,
  WarrantyExtensionItemInput,
  WarrantyExtensionItemUpdate,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { StepperMapping } from './cart.reducer';

export namespace CartActions {
  // Load Cart
  export const LoadCart = createAction(
    '[Cart] LOAD_CART',
    props<{ payload?: number }>(),
  );
  export const LoadCartSuccess = createAction(
    '[Cart] LOAD_CART_SUCCESS',
    props<{ payload: Cart }>(),
  );
  export const LoadCartError = createAction(
    '[Cart] LOAD_CART_ERROR',
    props<{ isCartDeleted: boolean }>(),
  );

  // Update Cart
  export const UpdateCart = createAction(
    '[Cart] UPDATE_CART',
    props<{ update: CartUpdate; isActiveUpdate?: boolean }>(),
  );
  export const UpdateCartSuccess = createAction(
    '[Cart] UPDATE_CART_SUCCESS',
    props<{ payload: CartUpdate }>(),
  );
  export const UpdateCartError = createAction('[Cart] UPDATE_CART_ERROR');

  // Delete Cart
  export const DeleteCart = createAction(
    '[Cart] DELETE_CART_SUCCESS',
    props<{ payload: number }>(),
  );
  export const DeleteCartSuccess = createAction(
    '[Cart] DELETE_CART_SUCCESS',
    props<{ payload: Cart }>(),
  );
  export const DeleteCartError = createAction('[Cart] DELETE_CART_ERROR');

  // Add Optional Cart Item
  export const AddOptionAsOptionalCartItem = createAction(
    '[Cart] ADD_OPTION_AS_OPTIONAL_CART_ITEM',
    props<{ item: SlCartItem; option: SalesOptionCartItem }>(),
  );
  export const AddOptionAsOptionalCartItemSuccess = createAction(
    '[Cart] ADD_OPTION_ASOPTIONAL_CART_ITEM_SUCCESS',
    props<{ payload: Cart }>(),
  );
  export const AddOptionAsOptionalCartItemError = createAction(
    '[Cart] ADD_OPTION_AS_OPTIONAL_CART_ITEM_ERROR',
  );

  export const RemoveOptionAsOptionalCartItem = createAction(
    '[Cart] REMOVE_OPTION_AS_OPTIONAL_CART_ITEM',
    props<{ payload: SalesOptionCartItem[] }>(),
  );

  // Create Cart Group
  export const CreateItemGroup = createAction(
    '[Cart] CREATE_ITEM_GROUP',
    props<{ payload: CartItemGroupInput }>(),
  );
  export const CreateItemGroupSuccess = createAction(
    '[Cart] CREATE_ITEM_GROUP_SUCCESS',
    props<{ payload: CartItemGroup }>(),
  );
  export const CreateItemGroupError = createAction(
    '[Cart] CREATE_ITEM_GROUP_ERROR',
  );

  // Update Group
  export const UpdateCartItemGroup = createAction(
    '[Cart] UPDATE_ITEM_GROUP',
    props<{ payload: CartItemGroup }>(),
  );
  export const UpdateCartItemGroupSuccess = createAction(
    '[Cart] UPDATE_ITEM_GROUP_SUCCESS',
    props<{ update: CartItemGroupUpdate }>(),
  );
  export const UpdateCartItemGroupError = createAction(
    '[Cart] UPDATE_ITEM_GROUP_ERROR',
  );

  // Delete Group
  export const DeleteItemGroup = createAction(
    '[Cart] DELETE_ITEM_GROUP',
    props<{ payload: CartItemGroup }>(),
  );
  export const DeleteItemGroupSuccess = createAction(
    '[Cart] DELETE_ITEM_GROUP_SUCCESS',
    props<{ payload: CartItemGroup }>(),
  );
  export const DeleteItemGroupError = createAction(
    '[Cart] DELETE_ITEM_GROUP_ERROR',
  );

  // sort cart items
  export const SortOptionalItems = createAction(
    '[Cart] SORT_OPTIONAL_ITEMS',
    props<{ sorting: SlCartItem[] }>(),
  );
  export const SortOptionalItemsSuccess = createAction(
    '[Cart] SORT_OPTIONAL_ITEMS_SUCCESS',
  );
  export const SortOptionalItemsError = createAction(
    '[Cart] SORT_OPTIONAL_ITEMS_ERROR',
  );

  export const SortCustomArticleItems = createAction(
    '[Cart] SORT_CUSTOM_ARTICLE_ITEMS',
    props<{ sorting: SlCartItem[] }>(),
  );

  // update optional cart items
  export const UpdateOptionalCartItems = createAction(
    '[Cart] UPDATE_OPTIONAL_CART_ITEMS',
    props<{ items: SlCartItem[]; groups: CartItemGroup[] }>(),
  );
  // get checkout docs
  export const GetCheckoutDocuments = createAction(
    '[Cart] GET_CHECKOUT_DOCUMENT_CART',
  );
  export const GetCheckoutDocumentsSuccess = createAction(
    '[Cart] GET_CHECKOUT_DOCUMENT_SUCCESS',
    props<{ documentContainer: CartDocumentContainer }>(),
  );
  export const GetCheckoutDocumentsError = createAction(
    '[Cart] GET_CHECKOUT_DOCUMENT_ERROR',
  );

  // create checkout docs
  export const CreateCheckoutDocument = createAction(
    '[Cart] CREATE_CHECKOUT_DOCUMENT_CART',
    props<{ file: Blob; isTuningCenter: boolean; isMcc: boolean }>(),
  );
  export const CreateCheckoutDocumentSuccess = createAction(
    '[Cart] CREATE_CHECKOUT_DOCUMENT_SUCCESS',
    props<{ payload: CartDocument }>(),
  );
  export const CreateCheckoutDocumentError = createAction(
    '[Cart] CREATE_CHECKOUT_DOCUMENT_ERROR',
  );

  // delete checkout docs
  export const DeleteCheckoutDocument = createAction(
    '[Cart] DELETE_CHECKOUT_DOCUMENT_CART',
    props<{ docId: number }>(),
  );
  export const DeleteCheckoutDocumentSuccess = createAction(
    '[Cart] DELETE_CHECKOUT_DOCUMENT_SUCCESS',
    props<{ docId: number }>(),
  );
  export const DeleteCheckoutDocumentError = createAction(
    '[Cart] DELETE_CHECKOUT_DOCUMENT_ERROR',
  );

  /**
   * Release an existing object URL which was previously created by calling URL.createObjectURL().
   * Call this method when you've finished using an object URL to let the browser know not to keep the reference to the file any longer.
   */
  export const RevokeObjectUrl = createAction(
    '[Cart] REVOKE_OBJECT_URL',
    props<{ url: string }>(),
  );
  export const Reset = createAction('[Cart] RESET');
  export const SetCartId = createAction(
    '[Cart] SET_CART_ID',
    props<{ payload: number | null }>(),
  );

  export const TogglePriceVisibility = createAction(
    '[Cart] TOGGLE_SHOW_PRICES',
  );

  export const DuplicateCart = createAction(
    '[Cart] DUPLICATE_CART',
    props<{ id: number; language: string; priceListDate: Date }>(),
  );

  export const SetStep = createAction(
    '[Cart] SET_STEP',
    props<{ payload: StepperMapping }>(),
  );
  export const StepChanged = createAction(
    '[Cart] STEP_CHANGED',
    props<{ payload: StepperMapping }>(),
  );

  // TODO: rework comparison
  /*
  // Load Cart Compare
  export const LoadCartCompare = createAction(
    '[Cart] LOAD_CART_COMPARE',
    props<{ payload: number }>(),
  );
  export const LoadCartCompareSuccess = createAction(
    '[Cart] LOAD_CART_COMPARE_SUCCESS',
    props<{ payload: CartCompare }>(),
  );
  export const LoadCartCompareError = createAction(
    '[Cart] LOAD_CART_COMPARE_ERROR',
  );*/

  // Service Contract
  export const StartServiceContractPolling = createAction(
    '[Cart] START_SERVICE_CONTRACT_POLLING',
    props<{ serviceContractId: number }>(),
  );

  // CUSTOM ARTICLES ITEM
  export const GetCustomArticle = createAction(
    '[Cart] GET_CUSTOM_ARTICLE',
    props<{ id: string }>(),
  );
  export const GetCustomArticleSuccess = createAction(
    '[Cart] GET_CUSTOM_ARTICLE_SUCCESS',
    props<{ item: CustomArticleItem }>(),
  );
  export const GetCustomArticleError = createAction(
    '[Cart] GET_CUSTOM_ARTICLE_ERROR',
  );

  export const AddCustomArticle = createAction(
    '[Cart] ADD_CUSTOM_ARTICLE',
    props<{ item: CustomArticleItemInput }>(),
  );
  export const AddCustomArticleSuccess = createAction(
    '[Cart] ADD_CUSTOM_ARTICLE_SUCCESS',
    props<{ item: CustomArticleItem }>(),
  );
  export const AddCustomArticleError = createAction(
    '[Cart] ADD_CUSTOM_ARTICLE_ERROR',
  );

  export const UpdateCustomArticle = createAction(
    '[Cart] UPDATE_CUSTOM_ARTICLE',
    props<{ update: CustomArticleItemUpdate }>(),
  );
  export const UpdateCustomArticleSuccess = createAction(
    '[Cart] UPDATE_CUSTOM_ARTICLE_SUCCESS',
    props<{ update: CustomArticleItemUpdate }>(),
  );
  export const UpdateCustomArticleError = createAction(
    '[Cart] UPDATE_CUSTOM_ARTICLE_ERROR',
  );

  export const DeleteCustomArticle = createAction(
    '[Cart] DELETE_CUSTOM_ARTICLE',
    props<{
      itemId: string;
      cartId: number;
    }>(),
  );
  export const DeleteCustomArticleSuccess = createAction(
    '[Cart] DELETE_CUSTOM_ARTICLE_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteCustomArticleError = createAction(
    '[Cart] Delete_CUSTOM_ARTICLE_ERROR',
  );

  // PRODUCT ITEM
  export const GetProductItem = createAction(
    '[Cart] GET_PRODUCT_ITEM',
    props<{ id: string }>(),
  );
  export const GetProductItemSuccess = createAction(
    '[Cart] GET_PRODUCT_ITEM_SUCCESS',
    props<{ item: ProductItem }>(),
  );
  export const GetProductItemError = createAction(
    '[Cart] GET_PRODUCT_ITEM_ERROR',
  );

  export const AddProductItem = createAction(
    '[Cart] ADD_PRODUCT_ITEM',
    props<{ item: ProductItemInput }>(),
  );
  export const AddProductItemSuccess = createAction(
    '[Cart] ADD_PRODUCT_ITEM_SUCCESS',
    props<{ item: ProductItem }>(),
  );
  export const AddProductItemError = createAction(
    '[Cart] ADD_PRODUCT_ITEM_ERROR',
  );

  export const UpdateProductItem = createAction(
    '[Cart] UPDATE_PRODUCT_ITEM',
    props<{
      update: ProductItemUpdate;
      productType: ProductItemProductType;
      showSuccessSnackbar?: boolean;
    }>(),
  );
  export const UpdateProductItemSuccess = createAction(
    '[Cart] UPDATE_PRODUCT_ITEM_SUCCESS',
    props<{ update: ProductItemUpdate; productType: ProductItemProductType }>(),
  );
  export const UpdateProductItemError = createAction(
    '[Cart] UPDATE_PRODUCT_ITEM_ERROR',
  );

  export const DeleteProductItem = createAction(
    '[Cart] DELETE_PRODUCT_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteProductItemSuccess = createAction(
    '[Cart] DELETE_PRODUCT_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteProductItemError = createAction(
    '[Cart] DELETE_PRODUCT_ITEM_ERROR',
  );

  // CONTRACT ITEM
  export const GetContractItem = createAction(
    '[Cart] GET_CONTRACT_ITEM',
    props<{ id: string }>(),
  );
  export const GetContractItemSuccess = createAction(
    '[Cart] GET_CONTRACT_ITEM_SUCCESS',
    props<{ item: ContractItem }>(),
  );
  export const GetContractItemError = createAction(
    '[Cart] GET_CONTRACT_ITEM_ERROR',
  );

  export const AddContractItem = createAction(
    '[Cart] ADD_CONTRACT_ITEM',
    props<{ item: ContractItemInput }>(),
  );
  export const AddContractItemSuccess = createAction(
    '[Cart] ADD_CONTRACT_ITEM_SUCCESS',
    props<{ item: ContractItem }>(),
  );
  export const AddContractItemError = createAction(
    '[Cart] ADD_CONTRACT_ITEM_ERROR',
  );

  export const UpdateContractItem = createAction(
    '[Cart] UPDATE_CONTRACT_ITEM',
    props<{ update: ContractItem }>(),
  );

  export const UpdateContractItemSuccess = createAction(
    '[Cart] UPDATE_CONTRACT_ITEM_SUCCESS',
    props<{ update: ContractItem }>(),
  );

  export const UpdateContractItemError = createAction(
    '[Cart] UPDATE_CONTRACT_ITEM_ERROR',
  );

  export const DeleteContractItem = createAction(
    '[Cart] DELETE_CONTRACT_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteContractItemSuccess = createAction(
    '[Cart] DELETE_CONTRACT_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteContractItemError = createAction(
    '[Cart] DELETE_CONTRACT_ITEM_ERROR',
  );

  // MCC ITEM
  export const GetMccItem = createAction(
    '[Cart] GET_MCC_ITEM',
    props<{ id: string }>(),
  );
  export const GetMccItemSuccess = createAction(
    '[Cart] GET_MCC_ITEM_SUCCESS',
    props<{ item: MccItem }>(),
  );
  export const GetMccItemError = createAction('[Cart] GET_MCC_ITEM_ERROR');

  export const AddMccItem = createAction(
    '[Cart] ADD_MCC_ITEM',
    props<{ item: MccItemInput }>(),
  );
  export const AddMccItemSuccess = createAction(
    '[Cart] ADD_MCC_ITEM_SUCCESS',
    props<{ item: MccItem }>(),
  );
  export const AddMccItemError = createAction('[Cart] ADD_MCC_ITEM_ERROR');

  export const UpdateMccItem = createAction(
    '[Cart] UPDATE_MCC_ITEM',
    props<{ update: MccItemUpdate }>(),
  );
  export const UpdateMccItemSuccess = createAction(
    '[Cart] UPDATE_MCC_ITEM_SUCCESS',
    props<{ update: MccItemUpdate }>(),
  );
  export const UpdateMccItemError = createAction(
    '[Cart] UPDATE_MCC_ITEM_ERROR',
  );

  export const DeleteMccItem = createAction(
    '[Cart] DELETE_MCC_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteMccItemSuccess = createAction(
    '[Cart] DELETE_MCC_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteMccItemError = createAction(
    '[Cart] DELETE_MCC_ITEM_ERROR',
  );

  // TUNING CENTER ITEM
  export const GetTuningCenterItem = createAction(
    '[Cart] GET_TUNING_CENTER_ITEM',
    props<{ id: string }>(),
  );
  export const GetTuningCenterItemSuccess = createAction(
    '[Cart] GET_TUNING_CENTER_ITEM_SUCCESS',
    props<{ item: TuningCenterItem }>(),
  );
  export const GetTuningCenterItemError = createAction(
    '[Cart] GET_TUNING_CENTER_ITEM_ERROR',
  );

  export const AddTuningCenterItem = createAction(
    '[Cart] ADD_TUNING_CENTER_ITEM',
    props<{ item: TuningCenterItemInput }>(),
  );
  export const AddTuningCenterItemSuccess = createAction(
    '[Cart] ADD_TUNING_CENTER_ITEM_SUCCESS',
    props<{ item: TuningCenterItem }>(),
  );
  export const AddTuningCenterItemError = createAction(
    '[Cart] ADD_TUNING_CENTER_ITEM_ERROR',
  );

  export const UpdateTuningCenterItem = createAction(
    '[Cart] UPDATE_TUNING_CENTER_ITEM',
    props<{ update: TuningCenterItemUpdate }>(),
  );
  export const UpdateTuningCenterItemSuccess = createAction(
    '[Cart] UPDATE_TUNING_CENTER_ITEM_SUCCESS',
    props<{ update: TuningCenterItemUpdate }>(),
  );
  export const UpdateTuningCenterItemError = createAction(
    '[Cart] UPDATE_TUNING_CENTER_ITEM_ERROR',
  );

  export const DeleteTuningCenterItem = createAction(
    '[Cart] DELETE_TUNING_CENTER_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteTuningCenterItemSuccess = createAction(
    '[Cart] DELETE_TUNING_CENTER_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteTuningCenterItemError = createAction(
    '[Cart] DELETE_TUNING_CENTER_ITEM_ERROR',
  );

  // WARRANTY EXTENSION ITEM
  export const GetWarrantyExtensionItem = createAction(
    '[Cart] GET_WARRANTY_EXTENSION_ITEM',
    props<{ id: string }>(),
  );
  export const GetWarrantyExtensionItemSuccess = createAction(
    '[Cart] GET_WARRANTY_EXTENSION_ITEM_SUCCESS',
    props<{ item: WarrantyExtensionItem }>(),
  );
  export const GetWarrantyExtensionItemError = createAction(
    '[Cart] GET_WARRANTY_EXTENSION_ITEM_ERROR',
  );

  export const AddWarrantyExtensionItem = createAction(
    '[Cart] ADD_WARRANTY_EXTENSION_ITEM',
    props<{ item: WarrantyExtensionItemInput }>(),
  );
  export const AddWarrantyExtensionItemSuccess = createAction(
    '[Cart] ADD_WARRANTY_EXTENSION_ITEM_SUCCESS',
    props<{ item: WarrantyExtensionItem }>(),
  );
  export const AddWarrantyExtensionItemError = createAction(
    '[Cart] ADD_WARRANTY_EXTENSION_ITEM_ERROR',
  );

  export const UpdateWarrantyExtensionItem = createAction(
    '[Cart] UPDATE_WARRANTY_EXTENSION_ITEM',
    props<{
      update: WarrantyExtensionItemUpdate;
    }>(),
  );
  export const UpdateWarrantyExtensionItemSuccess = createAction(
    '[Cart] UPDATE_WARRANTY_EXTENSION_ITEM_SUCCESS',
    props<{ update: WarrantyExtensionItemUpdate }>(),
  );
  export const UpdateWarrantyExtensionItemError = createAction(
    '[Cart] UPDATE_WARRANTY_EXTENSION_ITEM_ERROR',
  );

  export const DeleteWarrantyExtensionItem = createAction(
    '[Cart] DELETE_WARRANTY_EXTENSION_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteWarrantyExtensionItemSuccess = createAction(
    '[Cart] DELETE_WARRANTY_EXTENSION_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteWarrantyExtensionItemError = createAction(
    '[Cart] DELETE_WARRANTY_EXTENSION_ITEM_ERROR',
  );

  // SALES OPTION ITEM
  export const GetSalesOptionItem = createAction(
    '[Cart] GET_SALES_OPTION_ITEM',
    props<{ id: string }>(),
  );
  export const GetSalesOptionItemSuccess = createAction(
    '[Cart] GET_SALES_OPTION_ITEM_SUCCESS',
    props<{ item: SalesOptionCartItem }>(),
  );
  export const GetSalesOptionItemError = createAction(
    '[Cart] GET_SALES_OPTION_ITEM_ERROR',
  );

  export const AddSalesOptionItem = createAction(
    '[Cart] ADD_SALES_OPTION_ITEM',
    props<{ item: SalesOptionCartItemInput }>(),
  );
  export const AddSalesOptionItemSuccess = createAction(
    '[Cart] ADD_SALES_OPTION_ITEM_SUCCESS',
    props<{ item: SalesOptionCartItem }>(),
  );
  export const AddSalesOptionItemError = createAction(
    '[Cart] ADD_SALES_OPTION_ITEM_ERROR',
  );

  export const UpdateSalesOptionItem = createAction(
    '[Cart] UPDATE_SALES_OPTION_ITEM',
    props<{
      update: SalesOptionCartItemUpdate;
    }>(),
  );
  export const UpdateSalesOptionItemSuccess = createAction(
    '[Cart] UPDATE_SALES_OPTION_ITEM_SUCCESS',
    props<{ update: SalesOptionCartItemUpdate }>(),
  );
  export const UpdateSalesOptionItemError = createAction(
    '[Cart] UPDATE_SALES_OPTION_ITEM_ERROR',
  );

  export const DeleteSalesOptionItem = createAction(
    '[Cart] DELETE_SALES_OPTION_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteSalesOptionItemSuccess = createAction(
    '[Cart] DELETE_SALES_OPTION_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteSalesOptionItemError = createAction(
    '[Cart] DELETE_SALES_OPTION_ITEM_ERROR',
  );

  // SOLUTION ITEM
  export const GetSolutionItem = createAction(
    '[Cart] GET_SOLUTION_ITEM',
    props<{ id: string }>(),
  );
  export const GetSolutionItemSuccess = createAction(
    '[Cart] GET_SOLUTION_ITEM_SUCCESS',
    props<{ item: SolutionItem }>(),
  );
  export const GetSolutionItemError = createAction(
    '[Cart] GET_SOLUTION_ITEM_ERROR',
  );

  export const AddSolutionItem = createAction(
    '[Cart] ADD_SOLUTION_ITEM',
    props<{ item: SolutionItemInput }>(),
  );
  export const AddSolutionItemSuccess = createAction(
    '[Cart] ADD_SOLUTION_ITEM_SUCCESS',
    props<{ item: SolutionItem }>(),
  );
  export const AddSolutionItemError = createAction(
    '[Cart] ADD_SOLUTION_ITEM_ERROR',
  );

  export const UpdateSolutionItem = createAction(
    '[Cart] UPDATE_SOLUTION_ITEM',
    props<{ update: SolutionItemUpdate }>(),
  );
  export const UpdateSolutionItemSuccess = createAction(
    '[Cart] UPDATE_SOLUTION_ITEM_SUCCESS',
    props<{ update: SolutionItemUpdate }>(),
  );
  export const UpdateSolutionItemError = createAction(
    '[Cart] UPDATE_SOLUTION_ITEM_ERROR',
  );

  export const DeleteSolutionItem = createAction(
    '[Cart] DELETE_SOLUTION_ITEM',
    props<{ itemId: string; cartId: number }>(),
  );
  export const DeleteSolutionItemSuccess = createAction(
    '[Cart] DELETE_SOLUTION_ITEM_SUCCESS',
    props<{ id: string }>(),
  );
  export const DeleteSolutionItemError = createAction(
    '[Cart] DELETE_SOLUTION_ITEM_ERROR',
  );
}
