import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTableModule } from '@design-system/components/table';
import { TranslateModule } from '@ngx-translate/core';
import { SalestoolPipesModule } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlProjectIsUserPriceInputItemPipe } from '../shared/is-user-price-input-item.pipe';
import { SlProjectSalesPricePipe } from '../shared/sales-price-pipe';
import { SlProjectAbsoluteDiscountedSlProjectPriceColumnComponent } from './absolute-discounted-price-column/absolute-discounted-price-column.component';
import { SlProjectAverageSlProjectDiscountColumnComponent } from './average-discount-column/average-discount-column.component';
import { SlProjectAverageDiscountExclNetColumnComponent } from './average-discount-excluding-net-column/average-discount-excluding-net-column.component';
import { SlProjectBulkDiscountHeaderComponent } from './bulk-discount-header/bulk-discount-header.component';
import { SlProjectCalculationTableComponent } from './calculation-table.component';
import { SlProjectDiscountColumnInputComponent } from './discount-column/discount-column-input/discount-column-input.component';
import { SlProjectDiscountColumnComponent } from './discount-column/discount-column.component';
import { SlProjectEmptyColumnComponent } from './empty-column/empty-column.component';
import { SlProjectFinalPriceColumnComponent } from './final-price-column/final-price-column.component';
import { SlProjectFinalPriceInputColumnComponent } from './final-price-input-column/final-price-input-column.component';
import { SlProjectIndexSlProjectPriceColumnComponent } from './index-price-column/index-price-column.component';
import { SlProjectIndexPriceDescriptionDialogComponent } from './index-price-description-dialog/index-price-description-dialog.component';
import { SlProjectIndexPriceFactorColumnComponent } from './index-price-factor-column/index-price-factor-column.component';
import { SlProjectMarginColumnComponent } from './margin-column/margin-column.component';
import { SlProjectNameColumnComponent } from './name-column/name-column.component';
import { SlProjectPurchasePriceColumnComponent } from './purchase-price-column/purchase-price-column.component';
import { SlProjectRelativeDiscountInputColumnComponent } from './relative-discount-input-column/relative-discount-input-column.component';
import { SlProjectRelativeSlProjectDiscountColumnComponent } from './relative-discount-price-column/relative-discount-column.component';
import { SlProjectSalesSlProjectPriceColumnComponent } from './sales-price-column/sales-price-column.component';
import { SlProjectSalesPriceInputComponent } from './sales-price-column/sales-price-input/sales-price-input.component';
import { SlProjectShippingCostsColumnComponent } from './shipping-costs-column/shipping-costs-column.component';
import { SlProjectTransportCostInputColumnComponent } from './transport-cost-input-column/transport-cost-input-column.component';
import { SlProjectTransportCostsColumnComponent } from './transport-costs-column/transport-costs-column.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleAnalytics4DirectiveModule,
    SalestoolPipesModule,
    TranslateModule,
    // material
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    // design system
    DsTableModule,
    DsSpacingModule,
  ],
  declarations: [
    SlProjectCalculationTableComponent,
    SlProjectEmptyColumnComponent,
    SlProjectBulkDiscountHeaderComponent,
    SlProjectNameColumnComponent,
    SlProjectPurchasePriceColumnComponent,
    SlProjectTransportCostsColumnComponent,
    SlProjectDiscountColumnComponent,
    SlProjectAverageSlProjectDiscountColumnComponent,
    SlProjectAverageDiscountExclNetColumnComponent,
    SlProjectRelativeSlProjectDiscountColumnComponent,
    SlProjectSalesSlProjectPriceColumnComponent,
    SlProjectTransportCostInputColumnComponent,
    SlProjectRelativeDiscountInputColumnComponent,
    SlProjectAbsoluteDiscountedSlProjectPriceColumnComponent,
    SlProjectFinalPriceColumnComponent,
    SlProjectFinalPriceInputColumnComponent,
    SlProjectMarginColumnComponent,
    SlProjectShippingCostsColumnComponent,
    SlProjectSalesPriceInputComponent,
    SlProjectDiscountColumnInputComponent,
    SlProjectSalesPricePipe,
    SlProjectIsUserPriceInputItemPipe,
    SlProjectIndexSlProjectPriceColumnComponent,
    SlProjectIndexPriceFactorColumnComponent,
    SlProjectIndexPriceDescriptionDialogComponent,
  ],
  exports: [SlProjectCalculationTableComponent],
})
export class CalculationTableModule {}
