<ng-container [matColumnDef]="perspective + 'IndexPriceFactor'">
  <td
    [gaClickListener]="{
      action: perspective + 'IndexPriceFactorClick',
      category: 'Cart',
      label: 'IndexPriceFactorColumn'
    }"
    mat-footer-cell
    *matFooterCellDef
    class="text-center"
    data-test="index-price-factor-column"
  >
    {{ factor | percent: '1.2-2' : locale }}
  </td>
</ng-container>
