<ng-container matColumnDef="transportCosts" sticky>
  <td
    mat-footer-cell
    *matFooterCellDef
    [gaClickListener]="{
      action: 'TransportCostClick',
      category: 'Cart',
      label: 'ShippingCostsColumn'
    }"
    id="shipping_costs_column"
  >
    <div fxLayout fxLayoutAlign="start center" [fxLayoutGap]="0.5 | dsSpacing">
      <span>{{ 'calculation.freight_and_transport_costs' | translate }}</span>

      <div fxFlex="0 0 {{ 8 | dsSpacing }}" class="print-hidden">
        <button
          mat-stroked-button
          (click)="openShippingCostsDialog()"
          data-test="choose-button"
        >
          {{ 'common.choose' | translate }}
        </button>
      </div>
    </div>
  </td>
</ng-container>
