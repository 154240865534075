<sl-project-cart-valid-dependend-page
  [placeholderHeadline]="'calculation.invalid_config_headline' | translate"
>
  <ng-container
    gaLabelGroup="Calculation"
    gaCategoryGroup="Cart"
    *ngIf="project$ | async as project"
  >
    <div fxLayout="column">
      <shared-lib-print-header>
        <span sharedUtilsPrintBannerContent>
          {{ 'common.calculation' | translate }}<br />
          {{ 'breadcrumb.sales_project' | translate }}: {{ project.code }}<br />
          {{ 'project.created_by' | translate }}: {{ project.created_by }}
        </span>
        <span sharedUtilsRightContent
          >{{ getDate() | date: 'dd.MM.yyyy' }}
        </span>
      </shared-lib-print-header>
      <br class="print-only" />
    </div>

    <div
      fxLayout
      fxLayoutAlign="space-between center"
      [fxLayoutGap]="1 | dsSpacing"
      class="print-hidden"
    >
      <a
        mat-button
        back-button
        [routerLink]="'/projects/' + cart.project_id + '/carts/' + cart.id"
        *ngIf="cart$ | async as cart"
        data-test="back-button"
        gaClickListener="BackClick"
      >
        <mat-icon>arrow_back</mat-icon>
        {{ 'solution.back_to_configuration' | translate }}
      </a>

      <ds-page-actions *ngIf="calculation$ | async as calculation">
        <ng-container *ngIf="!project.is_whole_sale_order; else goToCheckout">
          <ng-container *ngIf="canCreateOffer$ | async">
            <a
              mat-button
              *dsPageAction
              page-action
              [routerLink]="['../offer']"
              [dsButtonPending]="isSaving$ | async"
              gaClickListener="CreateOfferClick"
              data-test="create-offer-button"
            >
              <mat-icon>chrome_reader_mode</mat-icon>
              {{ 'calculation.get_offer' | translate }}
            </a>
          </ng-container>
        </ng-container>
        <ng-template #goToCheckout>
          <a
            mat-button
            *dsPageAction
            page-action
            [routerLink]="['../checkout']"
            [dsButtonPending]="isSaving$ | async"
            gaClickListener="GoToCheckoutClick"
            data-test="go-to-checkout-button"
          >
            <mat-icon>send</mat-icon>
            {{ 'calculation.go_to_checkout' | translate }}
          </a>
        </ng-template>

        <button
          mat-button
          *dsPageAction
          page-action
          type="submit"
          [dsButtonPending]="isSaving$ | async"
          (click)="print()"
          gaClickListener="PrintClick"
          data-test="print-button"
        >
          <mat-icon>print</mat-icon>
          {{ 'general.print' | translate }}
        </button>

        <button
          mat-button
          *dsPageAction
          page-action
          target="_blank"
          data-test="calculation-export-button"
          (click)="onExportClick()"
          download
          [dsButtonPending]="isSaving$ | async"
          gaClickListener="ExportClick"
          [spotlight]="exportSpotlight"
        >
          <mat-icon>cloud_download</mat-icon>
          {{ 'common.export' | translate }}
        </button>

        <ds-spotlight
          #exportSpotlight
          id="sl-project-spotlight-calculation-excel-export"
        >
          {{ 'spotlight.release_2020_06.calculation_excel_export' | translate }}
        </ds-spotlight>
        <ng-container *ngIf="hasOffer$ | async">
          <ng-container *ngIf="cart$ | async as cart">
            @if (isDigitalOfferFeatureFlagSet$ | async) {
              <button
                *dsPageAction
                mat-button
                page-action
                (click)="onDigitalOfferClick(cart)"
                data-test="digital-offer-button"
                gaClickListener="DigitalOfferClick"
                [matBadge]="
                  digitalOfferFeatureFlag === 'EARLY ACCESS'
                    ? ('common.early_access_badge' | translate)
                    : null
                "
                matBadgePosition="below"
                [class.early-access-highlight]="
                  digitalOfferFeatureFlag === 'EARLY ACCESS'
                "
              >
                <mat-icon>play_lesson</mat-icon>
                {{ 'offer.digital_offer.title' | translate }}
              </button>
            }
            <button
              *dsPageAction
              mat-button
              page-action
              (click)="openDocumentDownloadDialog(cart)"
              gaClickListener="DownloadOfferClick"
              data-test="download-offer-button"
            >
              <mat-icon>cloud_download</mat-icon>
              {{ 'offer.download_offer' | translate }}
            </button>
          </ng-container>
        </ng-container>
      </ds-page-actions>
    </div>

    <h1
      fxLayout
      [fxLayoutGap]="1 | dsSpacing"
      fxLayoutAlign="space-between baseline"
      class="no-margin print-hidden"
    >
      <div
        fxLayout
        [fxLayoutGap]="1 | dsSpacing"
        fxLayoutAlign="start baseline"
      >
        <span>{{ 'calculation.calculation' | translate }}</span>

        <mat-form-field>
          <mat-select
            [(ngModel)]="showAlternative"
            gaClickListener="CalculationSelectionClick"
          >
            <mat-option
              [value]="false"
              gaClickListener="CartItemsSelectionClick"
              >{{ 'project.cart' | translate }}</mat-option
            >
            <mat-option
              [value]="true"
              gaClickListener="AlternativeItemsSelectionClick"
              >{{ 'project_detail.optional_items' | translate }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="calculation$ | async as calculation">
        <div
          fxLayout
          [fxLayoutGap]="1 | dsSpacing"
          [spotlight]="viewsSpotlight"
        >
          <mat-checkbox
            data-test="purchasing-checkbox"
            [formControl]="partnerPerspectiveForm"
            [gaClickListener]="
              partnerPerspectiveForm.value
                ? 'hidePartnerPerspectiveClick'
                : 'showPartnerPerspectiveClick'
            "
          >
            {{ 'calculation.purchasing' | translate }}
          </mat-checkbox>
          <mat-checkbox
            *ngIf="calculation.general_agent"
            data-test="total-purchasing-checkbox"
            [formControl]="generalAgentPerspectiveForm"
            [gaClickListener]="
              generalAgentPerspectiveForm.value
                ? 'hideGeneralAgentPerspectiveClick'
                : 'showGeneralAgentPerspectiveClick'
            "
          >
            {{ 'calculation.total_purchasing' | translate }}
          </mat-checkbox>
          <ds-spotlight
            id="sl-project-spotlight-calculation-views"
            #viewsSpotlight
            position="left"
          >
            {{ 'spotlight.release_2020_05.calculation.views' | translate }}
          </ds-spotlight>
        </div>
      </ng-container>
    </h1>

    <div [class.print-only]="showAlternative">
      <ng-container *ngIf="calculation$ | async as calculation; else loading">
        <sl-project-calculation-table
          [shippingCosts]="shippingCosts"
          [calculation]="calculation"
          [items]="tableItems$ | async"
          [perspectives]="perspectives$ | async"
          (updated)="update($event)"
          data-test="calculation-table"
        ></sl-project-calculation-table>
      </ng-container>
    </div>
    <div
      [class.print-only]="!showAlternative"
      fxLayout="column"
      [fxLayoutGap]="1 | dsSpacing"
    >
      <div class="print-only">
        <br />
        <strong>{{ 'project_detail.optional_items' | translate }}</strong>
      </div>
      <ds-text-indicator type="warning">{{
        'project.optional_items_disclaimer' | translate
      }}</ds-text-indicator>

      <ng-container *ngIf="calculation$ | async as calculation; else loading">
        <sl-project-calculation-table
          [shippingCosts]="shippingCosts"
          [showAlternatives]="true"
          [calculation]="calculation"
          [items]="tableItems$ | async"
          [perspectives]="perspectives$ | async"
          (updated)="update($event)"
        >
        </sl-project-calculation-table>
      </ng-container>
    </div>

    <ng-template #loading>
      <mat-card>
        <mat-card-content>
          <ds-table-loading
            [numberOfHeaders]="7"
            [numberOfRows]="10"
            gaClickListener="CalculationLoadingClick"
          ></ds-table-loading>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </ng-container>
</sl-project-cart-valid-dependend-page>
