<ng-container matColumnDef="name">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="name"
    data-test="lost-order-name-header"
  >
    {{ 'table_columns.name' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'NameClick'
    }"
    data-test="lost-order-name"
  >
    {{ lostOrder.name }}
  </td>
</ng-container>
