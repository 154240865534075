import { createAction, props } from '@ngrx/store';
import { ProjectDocumentsContainer } from '@sales-libs/project/data-access';
import { DocumentsFilterState } from '.';

export enum DocumentsActionTypes {
  LoadDocuments = '[Documents] LOAD_DOCUMENTS',
  RefreshDocuments = '[Documents] REFRESH_DOCUMENTS',
  LoadDocumentsSuccess = '[Documents] LOAD_DOCUMENTS_SUCCESS',
  ChangeFilter = '[Documents] CHANGE_FILTER',
  UploadDocument = '[Documents] UPLOAD_DOCUMENT',
  UploadDocumentSuccess = '[Documents] UPLOAD_DOCUMENT_SUCCESS',
  DeleteDocument = '[Documents] DELETE_DOCUMENTS',
  DeleteDocumentSuccess = '[Documents] DELETE_DOCUMENTS_SUCCESS',
}

export namespace DocumentsActions {
  /**
   * Load documents by project
   *
   * @param payload project id
   */
  export const LoadDocuments = createAction(
    DocumentsActionTypes.LoadDocuments,
    props<{ payload: number }>(),
  );
  export const RefreshDocuments = createAction(
    DocumentsActionTypes.RefreshDocuments,
  );
  export const LoadDocumentsSuccess = createAction(
    DocumentsActionTypes.LoadDocumentsSuccess,
    props<{ payload: ProjectDocumentsContainer }>(),
  );
  export const ChangeFilter = createAction(
    DocumentsActionTypes.ChangeFilter,
    props<{ payload: DocumentsFilterState }>(),
  );

  export const UploadDocument = createAction(
    DocumentsActionTypes.UploadDocument,
    props<{ payload: File }>(),
  );
  export const UploadDocumentSuccess = createAction(
    DocumentsActionTypes.UploadDocumentSuccess,
  );
  /**
   * @param payload document id
   */
  export const DeleteDocument = createAction(
    DocumentsActionTypes.DeleteDocument,
    props<{ payload: number }>(),
  );
  export const DeleteDocumentSuccess = createAction(
    DocumentsActionTypes.DeleteDocumentSuccess,
  );
}
