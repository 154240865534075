import { NgModule } from '@angular/core';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { SalestoolSharedModule } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlProjectCartItemListComponent } from './cart-item-list/cart-item-list.component';
import { SlProjectCartItemsComponent } from './cart-items.component';
import { SlProjectContractCartItemsComponent } from './contract-cart-items/contract-cart-items.component';
import { SlProjectCustomArticleCartItemsComponent } from './custom-article-cart-items/custom-article-cart-items.component';
import { SlProjectEditCartItemGroupDialogComponent } from './edit-cart-item-group-dialog/edit-cart-item-group-dialog.component';
import { SlProjectGroupSelectionDialogComponent } from './group-selection-dialog/group-selection-dialog.component';
import { SlProjectMccCartItemsComponent } from './mcc-cart-items/mcc-cart-items.component';
import { SlProjectMissingOriginalDataInfoDialogComponent } from './optional-cart-item-list/missing-originial-data-info-dialog/missing-original-data-info-dialog.component';
import { SlProjectOptionalCartItemListComponent } from './optional-cart-item-list/optional-cart-item-list.component';
import { SlProjectProductCartItemsComponent } from './product-cart-items/product-cart-items.component';
import { SlProjectRelatedRemovalDialogComponent } from './related-removal-dialog/related-removal-dialog.component';
import { SlProjectSalesOptionCartItemsComponent } from './sales-option-cart-items/sales-option-cart-items.component';
import { SlProjectActionsColumnComponent } from './shared/actions-column/actions-column.component';
import { SlProjectCartItemListHeaderComponent } from './shared/cart-item-list-header/cart-item-list-header.component';
import { SlProjectCartItemRowComponent } from './shared/cart-item-row/cart-item-row.component';
import { SlProjectConfigurationStateColumnComponent } from './shared/configuration-state-column/configuration-state-column.component';
import { SlProjectDescriptionColumnComponent } from './shared/description-column/description-column.component';
import { SlProjectImageColumnComponent } from './shared/image-column/image-column.component';
import { SlProjectNameColumnComponent } from './shared/name-column/name-column.component';
import { SlProjectOptionalActionsColumnComponent } from './shared/optional-actions-column/optional-actions-column.component';
import { SlProjectOptionalIndicatorColumnComponent } from './shared/optional-indicator-column/optional-indicator-column.component';
import { SlProjectPriceColumnEditedIndicatorComponent } from './shared/price-column/price-column-edited-indicator/price-column-edited-indicator.component';
import { SlProjectPriceColumnComponent } from './shared/price-column/price-column.component';
import { SlProjectQuantityColumnComponent } from './shared/quantity-column/quantity-column.component';
import { SlProjectQuantityInputComponent } from './shared/quantity-column/quantity-input/quantity-input.component';
import { SlProjectSortColumnComponent } from './shared/sort-column/sort-column.component';
import { SlProjectStateColumnComponent } from './shared/state-column/state-column.component';
import { SlProjectSolutionOptionsDialogComponent } from './solution-cart-items/solution-cart-item-options-dialog/solution-cart-item-options-dialog.component';
import { SlProjectSolutionItemsComponent } from './solution-cart-items/solution-cart-items.component';
import { SlProjectTuningCenterCartItemsComponent } from './tuning-center-cart-items/tuning-center-cart-items.component';
import { SlProjectWarrantyExtensionCartItemsComponent } from './warranty-extension-cart-items/warranty-extension-cart-items.component';

@NgModule({
  imports: [
    SalestoolSharedModule,
    DsTableModule,
    DsTableLoadingModule,
    DsSpotlightModule,
    DsSpacingModule,
    DsTextIndicatorModule,
    DsPlaceholderModule,
    GoogleAnalytics4DirectiveModule,
  ],
  declarations: [
    SlProjectCartItemsComponent,
    SlProjectSolutionItemsComponent,
    SlProjectCartItemListComponent,
    SlProjectImageColumnComponent,
    SlProjectNameColumnComponent,
    SlProjectStateColumnComponent,
    SlProjectDescriptionColumnComponent,
    SlProjectPriceColumnComponent,
    SlProjectQuantityColumnComponent,
    SlProjectActionsColumnComponent,
    SlProjectQuantityInputComponent,
    SlProjectOptionalCartItemListComponent,
    SlProjectOptionalActionsColumnComponent,
    SlProjectSortColumnComponent,
    SlProjectEditCartItemGroupDialogComponent,
    SlProjectGroupSelectionDialogComponent,
    SlProjectPriceColumnEditedIndicatorComponent,
    SlProjectConfigurationStateColumnComponent,
    SlProjectRelatedRemovalDialogComponent,
    SlProjectOptionalIndicatorColumnComponent,
    SlProjectCustomArticleCartItemsComponent,
    SlProjectProductCartItemsComponent,
    SlProjectContractCartItemsComponent,
    SlProjectMccCartItemsComponent,
    SlProjectTuningCenterCartItemsComponent,
    SlProjectWarrantyExtensionCartItemsComponent,
    SlProjectSalesOptionCartItemsComponent,
    SlProjectCartItemRowComponent,
    SlProjectCartItemListHeaderComponent,
    SlProjectMissingOriginalDataInfoDialogComponent,
    SlProjectSolutionOptionsDialogComponent,
  ],
  exports: [SlProjectCartItemsComponent, SlProjectCartItemListComponent],
})
export class SlProjectCartItemsModule {}
