<ng-container [matColumnDef]="perspective">
  <th
    mat-header-cell
    *matHeaderCellDef
    colspan="3"
    id="bulk_discount_header"
    class="{{ perspective }}-bulk-discount-header"
  >
    <div fxLayout fxLayoutAlign="start center" [fxLayoutGap]="1 | dsSpacing">
      <span>{{ label }}</span>
      <div
        fxFlex
        class="print-hidden"
        gaLabelGroup="BulkDiscountHeader"
        gaCategoryGroup="Cart"
      >
        <mat-form-field [gaClickListener]="perspective + 'BulkDiscountClick'">
          <mat-label>{{
            'calculation.overwrite_discounts' | translate
          }}</mat-label>
          <input
            (keyup.enter)="triggerBlur($event)"
            matInput
            [formControl]="formControl"
            type="number"
            max="100"
            data-test="bulk-discount-input"
            step="0.01"
            autocomplete="off"
          />
          <span matTextSuffix>{{ percentSymbol }}</span>
        </mat-form-field>
      </div>
    </div>
  </th>
</ng-container>
