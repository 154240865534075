import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import {
  CalculationItem,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';

import { DsColumnComponent } from '@design-system/cdk/column';

@Component({
  selector: 'sl-project-name-column',
  templateUrl: './name-column.component.html',
  styleUrls: ['./name-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectNameColumnComponent extends DsColumnComponent {
  @Input() expandedItemsLookup: { [id: number]: boolean };

  @Output() toggleClick = new EventEmitter<CalculationItem>();

  itemTypes = CalculationItemItemType;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
