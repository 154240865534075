import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Cart,
  CartItemGroup,
  CustomArticleItem,
  ProductItem,
  ProductItemProductType,
  SolutionItem,
} from '@sales-libs/project/data-access';
import { SlCartItemGroupContainer } from '@sales-libs/project/ui';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable, Subject, filter, map, tap } from 'rxjs';
import { CartActions, CartSelectors } from '../store';
import { SlProjectEditCartItemGroupDialogComponent } from './edit-cart-item-group-dialog/edit-cart-item-group-dialog.component';

@Component({
  selector: 'sl-project-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
  standalone: false,
})
export class SlProjectCartItemsComponent implements OnDestroy {
  @Input() isReadOnly = false;
  @Output() configureItem = new EventEmitter<ProductItem>();
  @Output() configureSolution = new EventEmitter<SolutionItem>();

  productTypes = ProductItemProductType;
  cart$: Observable<Cart | null>;
  isPricingVisible$: Observable<boolean>;
  allItems$: Observable<SlCartItem[]>;
  customArticleItems$: Observable<CustomArticleItem[] | null | undefined>;
  optionalItemGroupContainers$: Observable<
    SlCartItemGroupContainer[] | undefined
  >;
  customArticleSortingUpdateCount = 0;

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
  ) {
    this.isPricingVisible$ = this._store.select(CartSelectors.isPricingVisible);
    this.cart$ = this._store.select(CartSelectors.current);
    this.allItems$ = this._store.select(CartSelectors.allItems);
    this.optionalItemGroupContainers$ = this._store.select(
      CartSelectors.optionalCartItemsGrouped,
    );
    this.customArticleItems$ = this._store
      .select(CartSelectors.customArticleItems)
      .pipe(
        tap(() => this.customArticleSortingUpdateCount--),
        filter(() => this.customArticleSortingUpdateCount <= 0),
        filter((items) => (items?.length ?? 0) > 0),
        map((items) => items?.sort((a, b) => a.sort_key - b.sort_key)),
      );
  }

  onConfigureOptionalItem(item: SlCartItem) {
    this.configureItem.emit(item as ProductItem);
  }

  drop(event: CdkDragDrop<CustomArticleItem[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    this.customArticleSortingUpdateCount = event.container.data.length;
    event.container.data.forEach((item, index) => {
      const update = { ...item, sort_key: index };
      this._store.dispatch(
        CartActions.UpdateCustomArticle({
          update,
        }),
      );
    });
  }

  openCartItemGroupDialog(cartId: number, group?: CartItemGroup) {
    const dialogRef = this._dialog.open(
      SlProjectEditCartItemGroupDialogComponent,
      {
        data: group,
      },
    );
    dialogRef
      .afterClosed()
      .pipe(filter((data) => !!(data as CartItemGroup)))
      .subscribe((data) => {
        this._store.dispatch(
          data.id
            ? CartActions.UpdateCartItemGroup({
                payload: data,
              })
            : CartActions.CreateItemGroup({
                payload: { ...data, cart_id: cartId },
              }),
        );
        dialogRef.close();
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
