<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="Specifications">
  <div [id]="scrollId"></div>
  <h2 *ngIf="headline" gaClickListener="SubheadlineClick">{{ headline }}</h2>
  <ng-content></ng-content>
  @for (item of itemWithImages; track item) {
    <div class="image-text-row">
      <div
        fxLayout
        fxLayoutAlign="start start"
        [fxLayoutGap]="1 | dsSpacing"
        class="content"
      >
        <div class="image-column" fxFlex="0 0 auto">
          <mat-card *ngIf="item.image" gaClickListener="ImageClick">
            <ds-single-image [image]="item.image"></ds-single-image>
          </mat-card>
        </div>
        <div fxFlex="1 1 auto">
          <strong gaClickListener="ItemNameClick" data-test="item-name">{{
            item.name
          }}</strong>
          <p
            [innerHTML]="item.description?.value"
            *ngIf="item.description?.value"
            gaClickListener="DescriptionClick"
            data-test="item-description"
          ></p>
          <p
            [innerHTML]="item.tech_description?.value"
            *ngIf="item.tech_description?.value"
            gaClickListener="TechDescriptionClick"
            data-test="item-tech-description"
          ></p>
          <p
            [innerHTML]="item.customer_value?.value"
            *ngIf="item.customer_value?.value"
            gaClickListener="CustomerValueClick"
            data-test="item-customer-value"
          ></p>
        </div>
      </div>
      <hr />
    </div>
  }
</ng-container>
