<ng-container gaLabelGroup="ShippingCostsDialog" gaCategoryGroup="Calculation">
  <h3 matDialogTitle gaClickListener="TitleClick" data-test="title">
    {{ 'calculation.freight_and_transport_costs' | translate }}
  </h3>
  <mat-dialog-content>
    <ng-container sticky>
      <mat-form-field class="full-width">
        <mat-label>
          {{ 'calculation.shipping_costs_search_label' | translate }}</mat-label
        >
        <input
          matInput
          type="text"
          [formControl]="searchForm.controls.term"
          (input)="searchItems(searchForm.controls.term.value)"
          data-test="search-input"
        />
        <button
          mat-icon-button
          matIconSuffix
          type="button"
          [attr.aria-label]="'common.clear' | translate"
          *ngIf="searchForm.controls.term.value !== ''"
          (click)="resetTermClick()"
          data-test="reset-button"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>
    @if (hasNoItem === true) {
      <ds-placeholder
        [type]="'info'"
        [customHeadline]="'calculation.no_item_available' | translate"
        data-test="no-item-placeholder"
      ></ds-placeholder>
    } @else {
      <div class="overflow-auto">
        <ds-table>
          <table class="pd-table">
            <thead>
              <tr>
                <th id="code" sticky>{{ 'common.code' | translate }}</th>
                <th id="name" sticky>{{ 'common.name' | translate }}</th>
                <th id="sales-price" sticky>
                  {{ 'calculation.sales_price' | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of shippingCosts"
                data-test="shipping-cost-row"
              >
                <td data-test="code-cell">{{ item.code }}</td>
                <td data-test="name-cell">{{ item.name }}</td>
                <td>
                  {{ item.sales_price }}
                </td>

                <td>
                  <button
                    mat-stroked-button
                    class="full-width"
                    (click)="onSelect(item)"
                    data-test="select-button"
                  >
                    {{ 'common.select' | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </ds-table>
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      type="button"
      mat-dialog-close
      gaClickListener="CancelClick"
      data-test="cancel-button"
    >
      {{ 'general.cancel' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
