import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SolutionOption } from '@sales-libs/project/data-access';

export interface SlProjectSolutionOptionsDialogData {
  options: SolutionOption[];
  isPricingVisible: boolean;
  name: string;
}

@Component({
  selector: 'sl-project-solution-cart-item-options-dialog',
  templateUrl: './solution-cart-item-options-dialog.component.html',
  standalone: false,
})
export class SlProjectSolutionOptionsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SlProjectSolutionOptionsDialogData,
  ) {
    this.displayColumns = this._allColumns.filter(
      (x) => this.data.isPricingVisible || x !== 'price',
    );
  }

  private _allColumns = ['code', 'name', 'price'];
  displayColumns: string[];
}
