import { CartItemsService } from '@sales-libs/project/data-access';
import { catchError, map } from 'rxjs';
import { SlProjectDataCreationProductItem } from '../types';
import { SlProjectCartItemCreationRow } from './cart-item-creation-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectProductItemCreationRow extends SlProjectCartItemCreationRow {
  constructor(
    private _productItem: SlProjectDataCreationProductItem,
    private _cartItemsService: CartItemsService,
  ) {
    super(_productItem.name);
  }

  protected _creationFunction = () =>
    this._cartItemsService
      .addProductItemAsync({
        ...this._productItem,
        cart_id: this.cartId,
        cart_item_group_id: this.cartItemGroupId,
      })
      .pipe(
        map((data) => ({
          result: {
            data,
            calculationItemReferenceId:
              this._productItem.calculationItemReferenceId,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
