import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';

import { Calculation, CalculationItem } from '@sales-libs/project/data-access';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalculationUtils } from '../../../shared/calculation-utils';

@Component({
  selector: 'sl-project-sales-price-input',
  templateUrl: './sales-price-input.component.html',
  styleUrls: ['./sales-price-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectSalesPriceInputComponent implements OnInit, OnDestroy {
  @Input() item: CalculationItem;
  @Input() perspective: string;
  @Input() currencySymbol: string;
  @Input() currencyRatio: number;
  @Input() currencyRoundingDigits: number;
  @Input() isAlternativeView = false;
  @Input() calculation: Calculation;
  @Input() parentForm: FormGroup;

  @Output() calculationChange = new EventEmitter<Calculation>();
  @Output() invalidChange = new EventEmitter<ValidationErrors>();

  readonly triggerBlur = triggerBlur;

  formControl: FormControl<number>;
  private readonly _destroy$ = new Subject<void>();

  ngOnInit() {
    const isDisabled =
      this.item.is_read_only ||
      this.parentForm.disabled ||
      this.item[this.perspective].purchase_price === 0;

    this.formControl = new FormControl<number>(
      {
        value: +CalculationUtils.getItemsTotal(
          this.item.items && this.item.items.length > 0
            ? this.item.items
            : [this.item],
          this.perspective,
          this.item.is_optional,
          true,
          true,
          this.currencyRatio,
          this.currencyRoundingDigits,
        ).toFixed(2),
        disabled: isDisabled,
      },
      { nonNullable: true, updateOn: 'blur' },
    );

    this.parentForm.addControl(
      'item' + this.item.id + this.perspective + 'SalesPrice',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        const baseCurrencyValue = value / this.currencyRatio;
        const calculatedDiscount =
          ((this.item[this.perspective].purchase_price -
            baseCurrencyValue / this.item.quantity) /
            this.item[this.perspective].purchase_price) *
          100;

        this.calculationChange.emit({
          ...this.calculation,
          items: [
            ...this.calculation.items.map((x) =>
              CalculationUtils.overwriteItemDiscount(
                this.item.id || '',
                x,
                calculatedDiscount,
                this.perspective,
              ),
            ),
          ],
          [this.perspective]:
            this.calculation[this.perspective] && !this.isAlternativeView // don't reset absolute discount if alternative item changes
              ? {
                  ...this.calculation[this.perspective],
                  absolute_discount: 0,
                }
              : this.calculation[this.perspective],
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
