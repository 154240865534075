<ng-container
  matColumnDef="name"
  sticky
  gaCategoryGroup="Cart"
  gaLabelGroup="NameColumn"
>
  <th mat-header-cell *matHeaderCellDef id="name"></th>
  <td
    mat-cell
    *matCellDef="let item"
    class="text-nowrap"
    gaClickListener="ItemNameClick"
    data-test="name-cell"
  >
    <div
      *ngIf="
        item.item_type === itemTypes.Item || item.item_type === itemTypes.Group
      "
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      {{ item.quantity }}x {{ item.name }}
      <button
        *ngIf="item.items?.length > 0"
        mat-icon-button
        class="mat-mini-icon-button print-hidden"
        (click)="toggleClick.emit(item)"
        [gaClickListener]="
          expandedItemsLookup[item.id] ? 'CollapseItemClick' : 'ExpandItemClick'
        "
        data-test="expand-calculation-item-button"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ expandedItemsLookup[item.id] ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
    </div>
    <small
      *ngIf="
        item.item_type === itemTypes.Option ||
        item.item_type === itemTypes.GroupItem
      "
      class="offset"
    >
      <span *ngIf="item.item_type === itemTypes.GroupItem">
        {{ item.quantity }}x
      </span>
      {{ item.name }}
    </small>
  </td>
  <td mat-footer-cell *matFooterCellDef id="sub_total">
    {{ 'calculation.subtotal' | translate }}
  </td>
</ng-container>
