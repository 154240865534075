<button
  mat-button
  (click)="openUtilizationDialog()"
  gaCategoryGroup="Upselling"
  gaLabelGroup="Utilization"
  gaClickListener="ButtonClick"
  data-test="utilization-button"
>
  <mat-icon>insert_chart</mat-icon>
  {{ 'project_detail.utilization' | translate }}
</button>
