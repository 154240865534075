import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Cart, ProductItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { nameofFactory } from '@utils/name-of';
import { Observable } from 'rxjs';
import { CartSelectors } from '../../store';

@Component({
  selector: 'sl-project-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  standalone: false,
})
export class SlProjectCartItemListComponent {
  @Input() isReadOnly = false;
  @Input() items: SlCartItem[];
  @Input() isTypeVisible = false;
  @Input() isSelectOnly = false;
  @Output() selectItem = new EventEmitter<SlCartItem>();

  readonly nameof = nameofFactory<ProductItem>();
  readonly cart$: Observable<Cart | null>;
  readonly isPricingVisible$: Observable<boolean>;

  constructor(private readonly _store: Store) {
    this.cart$ = this._store.select(CartSelectors.current);
    this.isPricingVisible$ = this._store.select(CartSelectors.isPricingVisible);
  }
}
