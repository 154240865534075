import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Cart,
  CartOverview,
  CartState,
  Project,
  ProjectsContainer,
} from '@sales-libs/project/data-access';
import { ProjectFilters } from '../../models';
import { ProjectActions } from './project.actions';

export interface Page {
  /**
   * Zero-based index
   */
  index: number;
  ids: number[];
}
export interface ProjectState extends EntityState<Project> {
  id: number | null;
  loading: boolean;
  filters: ProjectFilters;
  pagination: ProjectPaginationState;
  sort?: Sort;
  currentProject?: Project;
  cart: ProjectCartState;
  isUpdating: boolean;
  isProjectDeleted: boolean;
  hasLoadingError: boolean;
  exportUrl?: string;
  hasExportError: boolean;
  isFiltersLoading: boolean;
  filterProjects?: ProjectsContainer;
}

export interface ProjectPaginationState extends EntityState<Page>, PageEvent {}
export interface ProjectCartState extends EntityState<Cart | CartOverview> {
  loading: boolean;
}

export const projectAdapter: EntityAdapter<Project> =
  createEntityAdapter<Project>({});
export const cartAdapter: EntityAdapter<Cart | CartOverview> =
  createEntityAdapter<Cart | CartOverview>({});
export const pageAdapter: EntityAdapter<Page> = createEntityAdapter<Page>({
  selectId: (entity) => entity.index,
});

export const initialProjectState: ProjectState = projectAdapter.getInitialState(
  {
    id: null,
    loading: false,
    filters: {
      term: '',
      state: undefined,
      chance: undefined,
      startDate: undefined,
      endDate: undefined,
    },
    pagination: pageAdapter.getInitialState({
      pageIndex: 0,
      pageSize: 10,
      length: 0,
    }),
    sort: {
      active: 'created_date',
      direction: 'desc',
    },
    currentProject: undefined,
    cart: cartAdapter.getInitialState({
      loading: false,
    }),
    isUpdating: false,
    isProjectDeleted: false,
    hasLoadingError: false,
    hasExportError: false,
    isFiltersLoading: false,
    filterProjects: undefined,
  },
);

const reducer = createReducer(
  initialProjectState,
  on(ProjectActions.LoadProject, (state, { payload }) => ({
    ...state,
    loading: true,
    id: payload,
    currentProject:
      state.currentProject && state.currentProject.id === payload
        ? state.currentProject
        : initialProjectState.currentProject,
  })),
  on(ProjectActions.LoadProjects, ProjectActions.SearchProjects, (state) => ({
    ...state,
    loading: true,
    isCartDeleted: false,
    hasLoadingError: false,
  })),
  on(ProjectActions.LoadProjectsSuccess, (state, { payload }) => ({
    ...projectAdapter.upsertMany(payload.items, state),
    loading: false,
    pagination: {
      ...state.pagination,
      ...pageAdapter.upsertOne(
        {
          index: state.pagination.pageIndex,
          ids: payload.items.map((project) => project.id),
        },
        state.pagination,
      ),
      length: payload.count,
    },
  })),
  on(ProjectActions.LoadProjectFilter, (state) => ({
    ...state,
    isFiltersLoading: true,
    hasLoadingError: false,
  })),
  on(ProjectActions.LoadProjectFilterSuccess, (state, { payload }) => ({
    ...state,
    isFiltersLoading: true,
    hasLoadingError: false,
    filterProjects: payload,
  })),
  on(ProjectActions.SelectProject, (state, { payload }) => ({
    ...state,
    currentProject:
      (state.currentProject && state.currentProject.id) !== payload
        ? state.entities[payload]
        : state.currentProject,
    cart: initialProjectState.cart,
    hasLoadingError: false,
    isProjectDeleted: false,
  })),
  on(ProjectActions.Update, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(ProjectActions.LoadProjectSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    isUpdating: false,
    currentProject: payload,
  })),
  on(ProjectActions.SaveProjectSuccess, (state, { payload }) => ({
    ...projectAdapter.upsertOne(payload, state),
    isUpdating: false,
  })),
  on(ProjectActions.DeleteProjectSuccess, (state, { payload }) => ({
    ...projectAdapter.removeOne(payload, state),
    pagination: {
      ...pageAdapter.updateOne(
        {
          id: state.pagination.pageIndex,
          changes: {
            ids: state.pagination.entities[
              state.pagination.pageIndex
            ]?.ids.filter((id: number | string) => id !== payload),
          },
        },
        state.pagination,
      ),
    },
  })),
  on(ProjectActions.DeleteCartSuccess, (state, { payload }) => ({
    ...state,
    cart: cartAdapter.removeOne(payload, state.cart),
  })),
  on(ProjectActions.FilterChange, (state, { payload }) => ({
    ...state,
    filters: payload,
    pagination: {
      ...state.pagination,
      pageIndex: 0,
    },
  })),
  on(ProjectActions.PageChange, (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...payload,
    },
  })),
  on(ProjectActions.SortChange, (state, { payload }) => ({
    ...state,
    sort: payload,
    pagination: {
      ...state.pagination,
      pageIndex: 0,
    },
  })),
  on(ProjectActions.ClearFilter, (state) => ({
    ...state,
    filters: initialProjectState.filters,
    sort: undefined,
  })),
  on(ProjectActions.LoadCarts, (state) => ({
    ...state,
    cart: { ...state.cart, loading: true },
  })),
  on(ProjectActions.LoadCartsSuccess, (state, { payload }) => ({
    ...state,
    cart: {
      ...cartAdapter.setAll(payload.items || [], state.cart),
      loading: false,
    },
  })),
  on(ProjectActions.CreateCartSuccess, (state) => ({
    ...state,
  })),
  on(ProjectActions.LoadProjectError, (state, { isProjectDeleted }) => ({
    ...state,
    hasLoadingError: !isProjectDeleted,
    isProjectDeleted: isProjectDeleted,
  })),
  on(ProjectActions.GetExportSuccess, (state, { payload }) => ({
    ...state,
    exportUrl: payload.url || '',
  })),
  on(ProjectActions.GetExportError, (state) => ({
    ...state,
    hasExportError: true,
  })),
  on(ProjectActions.ResetExportState, (state) => ({
    ...state,
    exportUrl: undefined,
    hasExportError: false,
  })),
  on(ProjectActions.SetWholeSaleOrder, (state, { payload }) => ({
    ...state,
    currentProject: state.currentProject
      ? {
          ...state.currentProject,
          is_whole_sale_order: payload,
        }
      : undefined,
  })),
  on(ProjectActions.SetCartActive, (state, { cartId }) => {
    const entitiesUpdate = state.cart.ids
      .map((id) => {
        const entitiy = state.cart.entities[id] as Cart;
        if (entitiy && id === cartId) {
          return { ...entitiy, active: true, state: CartState.Active };
        } else if (entitiy?.state === CartState.Active) {
          return { ...entitiy, active: false, state: CartState.Created };
        } else {
          return entitiy;
        }
      })
      .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
    return {
      ...state,
      cart: {
        ...state.cart,
        entities: entitiesUpdate,
        loading: false,
      },
    };
  }),
);

export function projectReducer(
  state: ProjectState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
