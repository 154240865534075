<mat-form-field
  class="{{ perspective }}-sales-price-input print-hidden"
  [gaClickListener]="{
    action: perspective + 'ItemDiscountedPriceClick',
    category: 'Cart',
    label: 'SalePriceInput'
  }"
>
  <input
    (keyup.enter)="triggerBlur($event)"
    matInput
    [formControl]="formControl"
    type="number"
    min="0"
    autocomplete="off"
  />
  <span matTextSuffix>&nbsp;{{ currencySymbol }}</span>
</mat-form-field>
<span class="print-only">
  {{
    formControl.value
      | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
      | async
  }}
</span>
