import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sl-project-sort-column',
  templateUrl: './sort-column.component.html',
  styleUrls: ['./sort-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectSortColumnComponent {}
