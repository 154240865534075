<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="TermsAndConditions">
  <h2 id="termsAndConditions">
    {{ 'offer_terms_and_conditions_headline' | translate }}
  </h2>
  <div
    [innerHTML]="termsAndConditions"
    gaClickListener="TermsAndConditionsClick"
    data-test="terms-and-conditions"
  ></div>
</ng-container>
