<tr
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  [gaClickListener]="{
    action: 'TuningCenterItemListClick',
    category: 'Cart',
    label: 'Result'
  }"
  class="mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  (delete)="onDelete($event)"
  (toOptional)="onToOptional($event)"
></tr>
