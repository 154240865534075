import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-average-discount-excluding-net-column',
  templateUrl: './average-discount-excluding-net-column.component.html',
  styleUrls: ['./average-discount-excluding-net-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectAverageDiscountExclNetColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() locale: string;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    const purchasePrice = CalculationUtils.getTotalPurchasePrice(
      value.items,
      this.perspective,
      this.isAlternativeView,
      true,
    );
    const netPositions = CalculationUtils.getTotalPurchasePrice(
      value.items,
      this.perspective,
      this.isAlternativeView,
      false,
    );
    const finalPrice = CalculationUtils.getFinalPrice(
      value,
      this.perspective,
      this.isAlternativeView,
    );

    this.averageDiscountExclNet =
      purchasePrice === netPositions
        ? 0
        : 1 -
          (finalPrice -
            value[this.perspective].transport_costs -
            netPositions) /
            (purchasePrice - netPositions);
  }

  averageDiscountExclNet: number;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
