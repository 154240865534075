import { TranslateService } from '@ngx-translate/core';
import { ContractItem } from '@sales-libs/project/data-access';
import { ContractName } from '@sales-libs/shared/util';
import {
  SlProjectConnectedTypePipe,
  SlProjectContractTypePipe,
  SlProjectPaymentPeriodTypePipe,
} from '../pipes';

export class ContractUtils {
  private static _connectedTypePipe = new SlProjectConnectedTypePipe();
  private static _contractTypePipe = new SlProjectContractTypePipe();
  private static _paymentPeriodTypePipe = new SlProjectPaymentPeriodTypePipe();

  public static getContractDescription(
    contract: ContractItem,
    translateService: TranslateService,
  ) {
    return (
      translateService.instant('contract.period_text', {
        value: contract.service_contract?.contract_period,
      }) +
      ', ' +
      this._getPackageText(contract, translateService) +
      translateService.instant('contract.payment_interval_text', {
        value: translateService.instant(
          this._paymentPeriodTypePipe.transform(contract.payment_period_type),
        ),
      }) +
      ', ' +
      translateService.instant('contract.operating_hours_text', {
        value: contract.service_contract?.operation_time,
      })
    );
  }

  private static _getPackageText(
    item: ContractItem,
    translateService: TranslateService,
  ): string {
    if (item.name === ContractName.CONNECTED && item.connected_type) {
      return (
        translateService.instant('contract.connected_package_text', {
          value: translateService.instant(
            this._connectedTypePipe.transform(item.connected_type),
          ),
        }) + ', '
      );
    } else if (item.contract_type) {
      return (
        translateService.instant('contract.package_text', {
          value: translateService.instant(
            this._contractTypePipe.transform(item.contract_type),
          ),
        }) + ', '
      );
    } else return '';
  }
}
