import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ShippingCost } from '../../../shared/models';

@Component({
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    FlexLayoutModule,
    DsSpacingModule,
    DsPlaceholderModule,
    DsFilterModule,
    DsTableModule,
  ],
  selector: 'sl-project-shipping-costs-dialog',
  templateUrl: './shipping-costs-dialog.component.html',
  styleUrls: ['./shipping-costs-dialog.component.scss'],
})
export class ShippingCostsDialogComponent {
  shippingCosts: ShippingCost[];
  hasNoItem = false;

  readonly searchForm = new FormGroup<ShippingCostsForm>({
    term: new FormControl<string>('', { nonNullable: true }),
  });

  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    private readonly _selfRef: MatDialogRef<ShippingCostsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: ShippingCost[],

    protected cdRef: ChangeDetectorRef,
  ) {
    this.shippingCosts = this._data;
  }

  searchItems(searchTerm: string): void {
    this.hasNoItem = false;

    // If the searchTerm is empty, reset the shippingCosts array to the original data
    if (searchTerm === '') {
      this.shippingCosts = this._data;
      return;
    }

    // Filter the shippingCosts array based on the searchTerm
    const filteredItems = this._data.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.code.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    // Update the shippingCosts array with the filtered items
    this.shippingCosts = filteredItems;

    // Check if there are no items found
    if (filteredItems.length === 0) {
      this.hasNoItem = true;
    }
  }

  resetTermClick(): void {
    // Reset the search term to an empty string
    this.hasNoItem = false;
    this.searchForm.controls.term.setValue('');
    this.shippingCosts = this._data;
  }

  onSelect(shippingCost: ShippingCost): void {
    this._selfRef.close(shippingCost);
  }

  onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

interface ShippingCostsForm {
  term: FormControl<string>;
}
