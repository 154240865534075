<ng-container [matColumnDef]="perspective + 'IndexPrice'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right"
    [gaClickListener]="{
      action: perspective + 'IndexPriceClick',
      category: 'Cart',
      label: 'IndexPriceColumn'
    }"
  >
    {{
      indexPrice
        | slMoneyAmount$: { display: 'symbol', roundingDigits: 2 }
        | async
    }}
  </td>
</ng-container>
