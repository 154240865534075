<ng-container matColumnDef="model">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="model"
    data-test="lost-order-model-header"
  >
    {{ 'table_columns.model' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'ModelClick'
    }"
    data-test="lost-order-model"
  >
    {{
      lostOrder.model
        ? lostOrder.model
        : ('error_messages.lost_order.no_model' | translate)
    }}
  </td>
</ng-container>
