<div gaCategoryGroup="Cart" gaLabelGroup="InvestPreview" class="padding">
  <ng-container *ngIf="cart; else loading">
    @if (bestInvestFeaturesAvailable) {
      @if (totalAmounts.length > 0) {
        <div class="chart-container full-width">
          <div class="full-width">
            <ngx-charts-line-chart
              *ngIf="chartData?.length"
              [xAxis]="true"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [legend]="true"
              [view]="view"
              [scheme]="colorScheme"
              [results]="chartData"
              [xAxisLabel]="'benefits.years' | translate: { years: '' }"
              [yAxisLabel]="
                'benefits.money_saved' | translate: { currency: currencyCode }
              "
              [legendPosition]="legendPosition"
              [yAxisTickFormatting]="formatYAxisTick"
              gaClickListener="MoneySavedByYearsChartClick"
            >
            </ngx-charts-line-chart>
          </div>
        </div>
      } @else {
        <div fxLayout="column wrap" [fxLayoutGap]="1 | dsSpacing">
          <ds-loading-placeholder
            [height]="5 | dsSpacing"
          ></ds-loading-placeholder>
        </div>
      }
      <hr />
      <br />

      <div class="full-width" fxLayoutAlign="center center">
        <mat-slider
          min="1"
          max="20"
          step="1"
          [color]="'accent'"
          discrete
          [displayWith]="formatLabel.bind(this)"
          data-test="invest-preview-slider"
          gaClickListener="InvestPreviewSlider"
        >
          <input matSliderThumb />
        </mat-slider>
      </div>
      <p
        [innerHtml]="
          'project_detail.invest_context'
            | translate
              : {
                  amount:
                    totalAmountWithSlider
                    | currency: currencyCode : 'symbol' : '1.2-2' : 'de-DE',
                  years: sliderValue
                }
        "
      ></p>
      <br />
      <div class="wrapper">
        <div class="header">
          <p>{{ 'benefits.feature' | translate }}</p>
          <p>
            {{ 'benefits.cost_savings.headline' | translate }}
          </p>
        </div>

        <div
          class="row"
          *ngFor="let item of monetaryBenefitsData"
          data-test="monetary-benefits-row"
        >
          <div *ngTemplateOutlet="itemTemplate; context: { item: item }"></div>
        </div>
      </div>

      <hr />
      <br />

      <div class="wrapper">
        <div class="header">
          <p>{{ 'benefits.additional_benefits' | translate }}</p>
          <p>
            {{ 'benefits.cost_savings.headline' | translate }}
          </p>
        </div>

        <div
          class="row"
          *ngFor="let item of softFactsData"
          data-test="soft-facts-row"
        >
          <div *ngTemplateOutlet="itemTemplate; context: { item: item }"></div>
        </div>
      </div>
    } @else {
      <ds-placeholder [type]="'no_data'">
        {{ 'benefits.best_invest.no_features_available' | translate }}
      </ds-placeholder>
    }
  </ng-container>
</div>

<ng-template #loading>
  <div fxLayout="column wrap" [fxLayoutGap]="1 | dsSpacing">
    <ds-loading-placeholder [height]="1 | dsSpacing"></ds-loading-placeholder>
    <ds-loading-placeholder [height]="2 | dsSpacing"></ds-loading-placeholder>
  </div>
  <ds-table-loading [numberOfHeaders]="2" [numberOfRows]="5"></ds-table-loading>
</ng-template>

<ng-template #itemTemplate let-item="item">
  <p fxLayout="row" fxLayoutAlign="flex-start center">
    {{ item.name }}
    <span
      matTooltip="{{ 'benefits.info_tooltip_text' | translate }}"
      [matTooltipPosition]="'above'"
    >
      <button
        mat-icon-button
        *ngIf="item.description || item.assumptions"
        data-test="item_info_btn"
        (click)="openMoreDetailsDialog(item)"
        gaClickListener="OpenMoreDetailsDialogInfoIconClick"
        disableRipple
      >
        <mat-icon
          matTooltip="{{
            !item.description && !item.assumptions
              ? ('sales_shared.common.no_additional_information_available'
                | translate)
              : ''
          }}"
          matTooltipPosition="right"
          matTooltipClass="multiline-tooltip"
          >info_outlined</mat-icon
        >
      </button>
    </span>
  </p>
  <p class="amount">
    @if (item.value !== null && item.value !== undefined) {
      +{{
        sliderValue * item.value
          | currency: currencyCode : 'symbol' : '1.2-2' : 'de-DE'
      }}
    }
  </p>
</ng-template>
