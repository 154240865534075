import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { TranslateModule } from '@ngx-translate/core';
import { OfferBaseInfo, OfferContact } from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

@Component({
  selector: 'sl-project-digital-offer-info',
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    DsEquipmentIconModule,
    DsLoadingModule,
    DsSpacingModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    GoogleAnalytics4DirectiveModule,
  ],
  templateUrl: './digital-offer-info.component.html',
  styleUrl: './digital-offer-info.component.scss',
})
export class SlProjectDigitalOfferInfoComponent {
  @Input() set baseData(value: OfferBaseInfo) {
    this._baseData = value;
    this.mainImage = this._baseData?.main_image_url?.replace('_md', '_xl');
  }
  get baseData(): OfferBaseInfo {
    return this._baseData;
  }
  @Input() customerData: OfferContact;
  @Input() dealerData: OfferContact;

  mainImage: string | undefined | null;
  private _baseData: OfferBaseInfo;
}
