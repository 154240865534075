<ng-container matColumnDef="created_by">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="created_by">
    {{ 'table_columns.created_by' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'CreatedByClick'
    }"
  >
    {{ project.created_by }}
  </td>
</ng-container>
