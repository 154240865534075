<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="Benefits">
  <div id="yourBenefits"></div>
  <h1 class="no-margin-top" gaClickListener="TitleClick">
    {{ 'offer_your_benefits_headline' | translate }}
  </h1>
  <sl-project-digital-offer-benefits-section
    [items]="benefits?.configuration"
    *ngIf="benefits?.configuration && benefits?.configuration?.length"
    data-test="configuration-benefits"
  ></sl-project-digital-offer-benefits-section>
  <sl-project-digital-offer-benefits-section
    scrollId="optionalItemsBenefits"
    [headline]="'Optional items and services'"
    [items]="benefits?.optional"
    *ngIf="benefits?.optional && benefits?.optional?.length"
    data-test="optional-benefits"
  >
  </sl-project-digital-offer-benefits-section>
</ng-container>
