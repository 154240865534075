<ng-container [matColumnDef]="perspective + 'FinalPriceInput'">
  <td mat-footer-cell *matFooterCellDef class="text-right">
    <mat-form-field
      [gaClickListener]="{
        action: perspective + 'FinalPriceInputClick',
        category: 'Cart',
        label: 'FinalPriceInputColumn'
      }"
    >
      <input
        (keyup.enter)="triggerBlur($event)"
        matInput
        [formControl]="formControl"
        type="number"
        min="0"
        step="0.01"
        autocomplete="off"
      />
      <span matTextSuffix>&nbsp;{{ currencySymbol }}</span>
    </mat-form-field>
  </td>
</ng-container>
