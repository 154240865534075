<ng-container gaLabelGroup="CartList" gaCategoryGroup="ProjectDetail">
  <ng-container *ngIf="isLoaded$ | async; else loading">
    <ng-container *ngIf="cartsWithExpiration$ | async as cartsWithExpiration">
      <ng-container *ngIf="project$ | async as project">
        <div
          fxLayout="row wrap"
          [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
          [@list]="cartsWithExpiration.length"
        >
          <div
            fxFlex.xl="0 1 calc(100% / 4)"
            fxFlex.lg="0 1 calc(100% / 3)"
            fxFlex.md="0 1 calc(100% / 2)"
            fxFlex.lt-md="100%"
            *ngFor="let cartWithExp of cartsWithExpiration"
            data-test="cart-list-item"
          >
            <mat-card
              [class.ribbon]="
                cartWithExp.cart.state === cartStates.Active ||
                cartWithExp.cart.state === cartStates.Ordered ||
                cartWithExp.cart.state === cartStates.Done
              "
              class="data-test-version-{{ cartWithExp.cart.version }}"
              fxLayout="column"
            >
              <ds-expansion-panel
                #expansionPanel
                [zIndex]="1"
                class="full-width"
                [isContentFaded]="false"
                [class.expansion-panel-padding]="
                  cartWithExp.cart.state === cartStates.Active ||
                  cartWithExp.cart.state === cartStates.Ordered ||
                  cartWithExp.cart.state === cartStates.Done
                "
              >
                <div class="padding">
                  <div fxLayout="row" [fxLayoutGap]="1 | dsSpacing">
                    <h2
                      [class.primary-text]="
                        cartWithExp.cart.state === cartStates.Active ||
                        cartWithExp.cart.state === cartStates.Ordered ||
                        cartWithExp.cart.state === cartStates.Done
                      "
                      class="no-margin-top"
                      gaClickListener="VersionClick"
                      data-test="cart-version"
                    >
                      {{ cartWithExp.cart.version }}
                    </h2>
                    <div
                      fxLayout="column"
                      [fxLayoutGap]="0.5 | dsSpacing"
                      fxFlex="1 1 auto"
                    >
                      <mat-card-title
                        gaClickListener="ModelClick"
                        data-test="cart-title"
                      >
                        <div fxLayout="row wrap" [fxLayoutGap]="1 | dsSpacing">
                          <span>{{
                            cartWithExp.cart.model || 'general.not_available'
                              | translate
                          }}</span>
                          @if (
                            cartWithExp.expirationInfo.isExpireSoon &&
                            cartWithExp.expirationInfo.expireSoon
                          ) {
                            <mat-chip
                              class="no-padding no-margin background-warning"
                              ><h6>
                                {{ 'common.expires_in' | translate }}
                                {{ cartWithExp.expirationInfo.expireSoon }}
                                {{
                                  cartWithExp.expirationInfo.expireSoon > 1
                                    ? ('common.days' | translate)
                                    : ('common.day' | translate)
                                }}
                              </h6>
                            </mat-chip>
                          }
                          @if (cartWithExp.cart.state === cartStates.Expired) {
                            <mat-chip
                              ><h6>
                                {{ 'common.expired' | translate }}
                              </h6></mat-chip
                            >
                          }
                        </div>
                      </mat-card-title>
                      <mat-card-subtitle gaClickListener="PriceListDateClick">
                        <span>
                          {{ 'project_detail.created_on_date' | translate }}
                          {{ cartWithExp.cart.created_on | date }} </span
                        ><br />
                        <span data-test="cart-valid-until"
                          >{{ 'project_detail.cart_valid_until' | translate }}
                          <span
                            [ngClass]="{
                              'warning-text':
                                cartWithExp.expirationInfo.isExpireSoon
                            }"
                            >{{ cartWithExp.cart.valid_until | date }}
                          </span>
                        </span>
                        <br />
                        <span>{{
                          'project_detail.offer_created_on' | translate
                        }}</span>
                        @if (cartWithExp.cart.offer_created_on) {
                          <span>
                            {{ cartWithExp.cart.offer_created_on | date }}</span
                          >
                        } @else {
                          <span>
                            {{ 'project_detail.offer_not_created' | translate }}
                          </span>
                        }
                        <br />
                        <div
                          class="date-spacing-helper"
                          [class.is-expanded]="expansionPanel.isExpanded"
                        ></div>

                        <span
                          >{{ 'project_detail.cart_modified_on' | translate }}
                          {{ cartWithExp.cart.modified_on | date }}</span
                        ><br />
                        <span
                          >{{ 'project_detail.price_date' | translate }}
                          {{ cartWithExp.cart.price_list_date | date }}</span
                        ><br />
                      </mat-card-subtitle>
                    </div>
                    <div>
                      <button
                        [spotlight]="copyCompareWithOptionalItems"
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        gaClickListener="MoreClick"
                        data-test="cart-actions-button"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" data-test="actions-mat-menu">
                        <div
                          gaCategoryGroup="ProjectDetail"
                          gaLabelGroup="CartListMatMenu"
                        >
                          <button
                            mat-menu-item
                            (click)="setCartActive(cartWithExp.cart)"
                            [disabled]="
                              project.state !== projectStates.InProgress ||
                              cartWithExp.cart.state === cartStates.Active ||
                              cartWithExp.cart.state === cartStates.Expired
                            "
                            gaClickListener="SetActiveClick"
                            data-test="set-active-button"
                          >
                            <mat-icon>star</mat-icon>
                            <span>{{ 'project.set_active' | translate }}</span>
                          </button>
                          <div
                            data-test="duplicate-cart-tooltip"
                            [matTooltip]="
                              project.state !== projectStates.InProgress
                                ? ('project_detail.not_possible_project_state'
                                    | translate) +
                                  ' ' +
                                  (project.state
                                    | slProjectStateTranslationKey
                                    | translate)
                                : null
                            "
                          >
                            <button
                              mat-menu-item
                              (click)="duplicateCart.emit(cartWithExp.cart)"
                              [disabled]="
                                project.state !== projectStates.InProgress
                              "
                              gaClickListener="DuplicateClick"
                              data-test="duplicate-cart-button"
                            >
                              <mat-icon>content_copy</mat-icon>
                              <span>{{ 'common.duplicate' | translate }}</span>
                            </button>
                          </div>
                          <button
                            mat-menu-item
                            (click)="
                              duplicateCartToProject.emit(cartWithExp.cart)
                            "
                            gaClickListener="DuplicateToNewProjectClick"
                            data-test="duplicate-into-project-button"
                          >
                            <mat-icon>content_copy</mat-icon>
                            <span>{{
                              'common.duplicate_cart_project' | translate
                            }}</span>
                          </button>
                          <div
                            [matTooltip]="
                              cartWithExp.cart.state === cartStates.Active
                                ? ('project_detail.not_possible_active'
                                  | translate)
                                : cartWithExp.cart.locked
                                  ? ('project_detail.not_possible_locked'
                                    | translate)
                                  : null
                            "
                          >
                            <button
                              mat-menu-item
                              [disabled]="
                                cartWithExp.cart.state === cartStates.Active ||
                                cartWithExp.cart.state === cartStates.Closed ||
                                cartWithExp.cart.state === cartStates.Ordered ||
                                cartWithExp.cart.locked
                              "
                              (click)="deleteCart(cartWithExp.cart)"
                              gaClickListener="DeleteClick"
                              data-test="delete-button"
                            >
                              <mat-icon>delete</mat-icon>
                              <span>{{ 'common.delete' | translate }}</span>
                            </button>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </ds-expansion-panel>
              <div class="image-container">
                <img
                  mat-card-image
                  loading="lazy"
                  [src]="
                    cartWithExp.cart.cart_image
                      ? (cartWithExp.cart.cart_image | slSharedImageSize: 'lg')
                      : '/assets/img/product-image-placeholder.png'
                  "
                  [alt]="cartWithExp.cart.model"
                  class="full-width"
                  gaClickListener="ImageClick"
                />
              </div>
              <mat-card-content>
                <div>
                  <table list role="presentation">
                    <tr gaClickListener="SalesPriceClick">
                      <td>{{ 'project.brutto_price' | translate }}:</td>
                      <td data-test="cart-sales-price">
                        {{
                          cartWithExp.cart.sales_price
                            | slMoneyAmount$
                              : { currency: cartWithExp.cart.currency_settings }
                            | async
                        }}
                      </td>
                    </tr>
                    <tr gaClickListener="NettoPriceClick">
                      <td>{{ 'project.netto_price' | translate }}:</td>
                      <td data-test="cart-final-price">
                        {{
                          cartWithExp.cart.final_price
                            ? (cartWithExp.cart.final_price
                              | slMoneyAmount$
                                : {
                                    currency: cartWithExp.cart.currency_settings
                                  }
                              | async)
                            : ('general.not_available' | translate)
                        }}
                      </td>
                    </tr>
                    <tr gaClickListener="CommentClick">
                      <td>{{ 'project.comment' | translate }}:</td>
                      <td>
                        {{
                          cartWithExp.cart.comment || 'general.not_available'
                            | translate
                        }}
                      </td>
                    </tr>
                    <tr
                      gaClickListener="ConfigurationsStateClick"
                      [spotlight]="configurationStateSpotlight"
                    >
                      <td>{{ 'project.configuration' | translate }}:</td>
                      <td>
                        <span
                          *ngIf="!cartWithExp.cart.valid"
                          class="error-text"
                          >{{ 'configuration.invalid' | translate }}</span
                        >
                        <span *ngIf="cartWithExp.cart.valid">{{
                          'configuration.valid' | translate
                        }}</span>
                      </td>
                    </tr>
                    <tr gaClickListener="StateClick">
                      <td>{{ 'project.state' | translate }}:</td>
                      <td>
                        <mat-icon
                          *ngIf="!cartWithExp.cart.locked"
                          data-test="unlocked-icon"
                          >lock_open</mat-icon
                        >
                        <mat-icon
                          *ngIf="cartWithExp.cart.locked"
                          data-test="locked-icon"
                          >lock</mat-icon
                        >
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="buttons">
                  <div
                    fxLayout="row wrap"
                    [fxLayoutGap]="(0.5 | dsSpacing) + ' grid'"
                  >
                    <div fxFlex>
                      <a
                        mat-stroked-button
                        [routerLink]="'carts/' + cartWithExp.cart.id"
                        data-cy="configure-cart"
                        class="full-width"
                        gaClickListener="ConfigureClick"
                        >{{ 'common.configure' | translate }}
                      </a>
                    </div>
                    <div
                      fxFlex
                      [matTooltip]="
                        !cartWithExp.cart.valid
                          ? ('debug_errors.calculation_hint' | translate)
                          : null
                      "
                    >
                      <a
                        mat-button
                        [routerLink]="
                          cartWithExp.cart.valid
                            ? ['carts', cartWithExp.cart.id, 'calculation']
                            : null
                        "
                        [disabled]="!cartWithExp.cart.valid"
                        class="full-width"
                        gaClickListener="CalculationClick"
                      >
                        {{ 'common.calculation' | translate }}
                      </a>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div
            fxFlex.xl="0 1 calc(100% / 4)"
            fxFlex.lg="0 1 calc(100% / 3)"
            fxFlex.md="0 1 calc(100% / 2)"
            fxFlex.lt-md="100%"
            *ngIf="project.state === projectStates.InProgress"
            gaClickListener="AddNewClick"
          >
            <button
              mat-button
              class="new-placeholder full-width"
              (click)="add.emit()"
              data-test="add-cart-button"
            >
              <div
                class="padding"
                fxLayout="column"
                fxLayoutAlign="center center"
                [fxLayoutGap]="1 | dsSpacing"
              >
                <button mat-fab class="mat-accent">
                  <mat-icon>add</mat-icon>
                </button>
                <div class="new-placeholder-text">
                  {{ 'project_detail.create_cart' | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <ds-spotlight
          #configurationStateSpotlight
          id="sl-project-cart-list-configuration-state-spotlight"
          [tags]="['release_2021_10', 'project-details', 'cart-list']"
        >
          {{
            'spotlight.release_2021_10.configuration_state_solution' | translate
          }}
        </ds-spotlight>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div
      fxLayout="row wrap"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      gaClickListener="LoadingClick"
    >
      <div
        fxFlex.xl="0 1 calc(100% / 4)"
        fxFlex.lg="0 1 calc(100% / 3)"
        fxFlex.md="0 1 calc(100% / 2)"
        fxFlex.lt-md="100%"
        *ngFor="let i of [1, 2, 3, 4]"
        [fxHide.lt-xl]="i > 3"
        [fxHide.lt-lg]="i > 2"
        [fxHide.lt-md]="i > 1"
      >
        <mat-card>
          <div fxLayout [fxLayoutGap]="1 | dsSpacing">
            <mat-card-header fxFlex="1 1 auto">
              <mat-card-title>
                <ds-loading-placeholder
                  [width]="1.5 | dsSpacing"
                ></ds-loading-placeholder>
              </mat-card-title>
              <ds-loading-placeholder></ds-loading-placeholder>
            </mat-card-header>
            <ds-loading-placeholder height="2.5em"></ds-loading-placeholder>
          </div>
          <mat-card-content>
            <div class="image-container" mat-card-image>
              <ds-loading-placeholder height="100%"></ds-loading-placeholder>
            </div>
            <div>
              <table list role="presentation">
                <tr>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                  <td>
                    <ds-loading-placeholder
                      height="1em"
                    ></ds-loading-placeholder>
                  </td>
                </tr>
              </table>
              <br />
              <div fxLayout="column" [fxLayoutGap]="0.5 | dsSpacing">
                <ds-loading-placeholder></ds-loading-placeholder>
                <ds-loading-placeholder></ds-loading-placeholder>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-template>
</ng-container>

<ds-spotlight
  id="cpq-cart-cart-list-duplicate-with-optional-items-spotlight"
  #copyCompareWithOptionalItems
>
  {{ 'spotlight.release_2022_06_01.copyCompareWithOptionalItems' | translate }}
</ds-spotlight>
