import { Action, createReducer, on } from '@ngrx/store';
import {
  MountingCategoriesContainer,
  TrucksContainer,
} from '@sales-libs/project/data-access';
import { MccActions } from './mcc.actions';

export interface MccState {
  trucks: TrucksContainer | null;
  mountingOptionCategories: MountingCategoriesContainer | null;
}

export const initialMccState: MccState = {
  trucks: null,
  mountingOptionCategories: null,
};

const reducer = createReducer(
  initialMccState,
  on(MccActions.GetMountingOptionsSuccess, (state, { payload }) => ({
    ...state,
    mountingOptionCategories: payload,
  })),
  on(MccActions.GetTruckOptionsSuccess, (state, { payload }) => ({
    ...state,
    trucks: payload,
  })),
);

export function mccReducer(state: MccState | undefined, action: Action) {
  return reducer(state, action);
}
