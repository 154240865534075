<ng-container matColumnDef="customer">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="customer"
    data-test="lost-order-customer-header"
  >
    {{ 'table_columns.customer' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'CustomerClick'
    }"
    data-test="lost-order-customer"
  >
    {{
      lostOrder.customer
        ? lostOrder.customer
        : ('error_messages.lost_order.no_customer' | translate)
    }}
  </td>
</ng-container>
