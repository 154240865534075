import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sl-project-missing-original-data-info-dialog',
  templateUrl: './missing-original-data-info-dialog.component.html',
  styleUrl: './missing-original-data-info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectMissingOriginalDataInfoDialogComponent {}
