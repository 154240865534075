<div
  [gaClickListener]="{
    action: 'ConfigurationStateClick',
    category: 'Projects',
    label: 'ConfigurationStateColumn'
  }"
  [spotlight]="configurationStateSpotlight"
>
  <ng-container *ngIf="itemAsProductItem.is_configurable; else notConfigurable">
    <span *ngIf="itemAsProductItem.is_valid" class="success-text">
      {{ 'configuration.valid' | translate }}</span
    >
    <span *ngIf="!itemAsProductItem.is_valid" class="error-text">
      {{ 'configuration.invalid' | translate }}</span
    >
  </ng-container>
  <ng-template #notConfigurable>
    <span data-test="not-configureable-indicator">{{
      'configuration.not_configurable' | translate
    }}</span>
  </ng-template>
</div>
<ds-spotlight
  #configurationStateSpotlight
  id="sl-project-cart-item-list-configuration-state-spotlight"
  [tags]="['release_2021_10', 'cart-details', 'result']"
>
  {{ 'spotlight.release_2021_10.configuration_state_result' | translate }}
</ds-spotlight>
