import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  OfferJsReportInput,
  OffersService,
} from '@sales-libs/project/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DigitalOfferActions } from './digital-offer.actions';

@Injectable()
export class DigitalOfferEffects {
  private readonly _actions: Actions = inject(Actions);

  getDigitalOfferDataByOfferNumber$: Observable<Action> = createEffect(() =>
    this._actions.pipe(
      ofType(DigitalOfferActions.getDigitalOfferDataByOfferNumber),
      switchMap((payload) =>
        this.offersService
          .getDigitalOfferDataByOfferNumber(payload.digitalOfferId)
          .pipe(
            map((digitalOfferDataPayload: OfferJsReportInput) =>
              DigitalOfferActions.getDigitalOfferDataByOfferNumberSuccess({
                digitalOfferData: digitalOfferDataPayload,
              }),
            ),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                DigitalOfferActions.getDigitalOfferDataByOfferNumberError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private readonly offersService: OffersService,
    private readonly _router: Router,
  ) {}
}
