<div
  [gaClickListener]="{
    action: 'SalesPriceClick',
    category: 'Projects',
    label: 'CartItemList'
  }"
>
  <div data-test="price">
    {{
      cartItem.sales_price
        | slMoneyAmount$
          : {
              display: 'code',
              roundingDigits: isItemWithCustomerPriceInput ? 2 : undefined,
              nullValue: ('configuration.zero-price' | translate)
            }
        | async
    }}
  </div>
  <sl-project-price-column-edited-indicator
    [item]="cartItem"
    *ngIf="
      itemAsCustomPriceItem.original_sales_price !== undefined &&
      itemAsCustomPriceItem.original_sales_price !== cartItem.sales_price
    "
  ></sl-project-price-column-edited-indicator>
</div>
