<ng-container gaCategoryGroup="DigitalOffer" gaLabelGroup="Prices">
  <div id="prices"></div>
  <h1>{{ 'offer_prices_headline' | translate }}</h1>

  @if ((prices?.configuration ?? []).length > 0) {
    <h3>{{ 'offer_your_specification_subheadline' | translate }}</h3>
    <sl-project-digital-offer-price-table
      [dataSource]="prices?.configuration"
      [displayItemPrices]="displayItemPrices"
      [includeDiscounts]="includeDiscounts"
      [currency]="prices?.currency"
      data-test="configuration-table"
    ></sl-project-digital-offer-price-table>
  }

  @if ((prices?.equipments ?? []).length > 0) {
    <h3>{{ 'offer_equipment' | translate }}</h3>
    <sl-project-digital-offer-price-table
      [dataSource]="equipments"
      [displayItemPrices]="displayItemPrices"
      [includeDiscounts]="includeDiscounts"
      [currency]="prices?.currency"
      data-test="equipments-table"
    ></sl-project-digital-offer-price-table>
  }

  @if ((prices?.custom_articles ?? []).length > 0) {
    <br />
    <sl-project-digital-offer-price-table
      [dataSource]="prices?.custom_articles"
      [displayItemPrices]="displayItemPrices"
      [includeDiscounts]="includeDiscounts"
      [currency]="prices?.currency"
      data-test="custom-articles-table"
    ></sl-project-digital-offer-price-table>
  }

  <br />
  <mat-card>
    <mat-card-content>
      <div fxLayout="column" [fxLayoutGap]="0.5 | dsSpacing">
        @if (displayItemPrices) {
          @if (this.prices?.summary?.subtotal) {
            <div
              *ngIf="prices; else infoLoading"
              fxLayout
              fxLayoutAlign="space-between"
            >
              <h4 class="no-margin-top">
                {{ 'offer_subtotal' | translate }}
              </h4>
              <h4
                class="no-margin-top"
                gaClickListener="SubtotalClick"
                data-test="sub-total"
              >
                {{ this.prices?.summary?.subtotal }}
              </h4>
            </div>
          }
          @if (this.prices?.summary?.freight_costs) {
            <div
              *ngIf="prices; else infoLoading"
              fxLayout
              fxLayoutAlign="space-between"
            >
              <h4 class="no-margin-top">
                {{ 'offer_freight_transport_costs' | translate }}
              </h4>
              <h4
                class="no-margin-top"
                gaClickListener="FreightCostsClick"
                data-test="freight-cost"
              >
                {{ this.prices?.summary?.freight_costs }}
              </h4>
            </div>
          }
          @if (
            this.prices?.summary?.special_discount &&
            this.prices?.summary?.special_discount_percentage
          ) {
            <div
              *ngIf="prices; else infoLoading"
              fxLayout
              fxLayoutAlign="space-between"
            >
              <h4 class="no-margin-top">
                {{ 'offer_special_discount' | translate }}
              </h4>
              <h4
                class="no-margin-top"
                gaClickListener="SpecialDiscountPercentageClick"
                data-test="special-discount-percentage"
              >
                {{ this.prices?.summary?.special_discount_percentage }}
              </h4>
              <h4
                class="no-margin-top"
                gaClickListener="SpecialDiscountClick"
                data-test="special-discount"
              >
                {{ this.prices?.summary?.special_discount }}
              </h4>
            </div>
          }
          @if (this.prices?.summary?.special_decline) {
            <div
              *ngIf="prices; else infoLoading"
              fxLayout
              fxLayoutAlign="space-between"
            >
              <h4 class="no-margin-top">
                {{ 'offer_special_decline' | translate }}
              </h4>
              <h4
                class="no-margin-top"
                gaClickListener="SpecialDeclineClick"
                data-test="special-decline"
              >
                {{ this.prices?.summary?.special_decline }}
              </h4>
            </div>
          }
        }

        <hr />

        <div
          *ngIf="prices; else infoLoading"
          fxLayout
          fxLayoutAlign="space-between"
        >
          <h3 class="no-margin-top">
            {{ 'offer_total_price' | translate }}
          </h3>
          <h3
            gaClickListener="TotalPriceClick"
            data-test="total-price"
            class="no-margin-top"
          >
            {{ this.prices?.summary?.sum_netto }}
          </h3>
        </div>
        @if (!displayItemPrices && this.prices?.summary?.freight_costs) {
          <h4
            class="no-margin-top"
            gaClickListener="FreightCostsInfoClick"
            data-test="freight-cost-info"
          >
            {{ 'offer_including_transport_costs' | translate }}
          </h4>
        }

        @if (displayItemPrices && displayIndexPrices) {
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            *ngIf="prices; else infoLoading"
          >
            <h4 class="no-margin-top">
              {{ 'offer_index_price_ppi' | translate }}
            </h4>
            <h4
              class="no-margin-top"
              gaClickListener="PpiSumClick"
              data-test="ppi-sum"
            >
              {{ this.prices?.summary?.ppi?.ppi_sum }}
            </h4>
          </div>
        }
      </div>
    </mat-card-content>
  </mat-card>

  @if ((prices?.optional_items ?? []).length > 0) {
    <h3>{{ 'offer_optional_items_and_services' | translate }}</h3>
    <ds-text-indicator type="info" gaClickListener="OptionalItemsInfoClick">
      {{ 'offer_optional_items_and_services_attention' | translate }}
    </ds-text-indicator>
    <sl-project-digital-offer-price-table
      [dataSource]="prices?.optional_items"
      [displayItemPrices]="displayItemPrices"
      [includeDiscounts]="includeDiscounts"
      [currency]="prices?.currency"
      data-test="optional-items-table"
    ></sl-project-digital-offer-price-table>
  }

  <ng-template #infoLoading>
    <ds-loading-placeholder
      gaClickListener="LoadingClick"
      [width]="10 | dsSpacing"
      [height]="2 | dsSpacing"
      data-test="loading-placeholder"
    ></ds-loading-placeholder>
  </ng-template>
</ng-container>
