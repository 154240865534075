import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import { dsConfig } from '@design-system/cdk/config';
import {
  DisabledPositionType,
  TableSettings,
  TableSettingsService,
} from '@design-system/components/table-settings';
import { Store } from '@ngrx/store';
import { LostOrderView } from '@sales-libs/project/data-access';
import { DocumentDownloadDialogComponent } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { LostOrderFilters } from '../models';
import { LostOrderListActions, LostOrderListSelectors } from '../store';

@Component({
  selector: 'sl-project-lost-order',
  templateUrl: './lost-order.component.html',
  styleUrls: ['./lost-order.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderComponent implements OnDestroy, OnInit {
  displayColumns: TableSettings[];
  readonly loading$: Observable<boolean>;
  readonly lostOrders$: Observable<LostOrderView[]>;
  readonly pagination$: Observable<PageEvent>;
  readonly lostOrderFilters$: Observable<LostOrderFilters>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _defaultColumns: TableSettings[] = [
    {
      name: 'code',
      selected: true,
      disabledPosition: DisabledPositionType.Start,
    },
    { name: 'created_date', selected: true },
    { name: 'name', selected: true },
    { name: 'customer', selected: true },
    { name: 'model', selected: true },
    { name: 'created_by', selected: true },
    { name: 'end_date', selected: true },
    { name: 'delivery_date', selected: true },
    { name: 'amount', selected: true },
    { name: 'reason', selected: true },
    { name: 'competitor', selected: true },
    { name: 'competitor_type', selected: true },
    { name: 'competitor_price', selected: true },
    { name: 'comment', selected: true },
    {
      name: 'actions',
      selected: true,
      disabledPosition: DisabledPositionType.End,
    },
  ];

  constructor(
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
    private readonly _tableSettingsService: TableSettingsService,
  ) {
    this.loading$ = this._store.select(LostOrderListSelectors.loading);
    this.lostOrders$ = this._store
      .select(LostOrderListSelectors.currentPage)
      .pipe(filter((value): value is LostOrderView[] => !!value));
    this.pagination$ = this._store.select(LostOrderListSelectors.pagination);
    this.lostOrderFilters$ = this._store.select(
      LostOrderListSelectors.lostOrderFilters,
    );
  }

  setUserSettings(userSettings: TableSettings[]): void {
    this.displayColumns = userSettings;
  }

  ngOnInit() {
    this._store.dispatch(LostOrderListActions.SearchLostOrders());

    this._tableSettingsService
      .get('CPQ.lostOrder.table_settings', this._defaultColumns)
      .pipe(takeUntil(this._destroy$))
      .subscribe((storedUserColumns) => {
        this.setUserSettings(
          storedUserColumns?.length ? storedUserColumns : this._defaultColumns,
        );
      });
  }

  /**
   * get lostOrders, default first page
   * @param page
   */
  getPage(page: PageEvent) {
    this._store.dispatch(LostOrderListActions.PageChange({ payload: page }));
    this._store.dispatch(LostOrderListActions.SearchLostOrders());
  }

  /**
   * dispatch filter change and take first page
   * @param filters
   */
  updateFilter(filters: LostOrderFilters) {
    this._store.dispatch(
      LostOrderListActions.FilterChange({ payload: filters }),
    );
    this._store.dispatch(LostOrderListActions.SearchLostOrders());
  }

  /**
   * clear filter and take first page
   */
  clearFilter() {
    this._store.dispatch(LostOrderListActions.ClearFilter());
    this._store.dispatch(LostOrderListActions.SearchLostOrders());
  }

  sortChange(sort: Sort) {
    this._store.dispatch(LostOrderListActions.SortChange({ payload: sort }));
    this._store.dispatch(LostOrderListActions.SearchLostOrders());
  }

  openProjectsDownloadDialog(): void {
    // reset
    this._store.dispatch(LostOrderListActions.ResetExportState());

    // open dialog and prefill name
    const dialogRef = this._dialog.open(DocumentDownloadDialogComponent, {
      width: 25 * dsConfig.spacing + 'px',
    });
    dialogRef.componentInstance.fileName = 'lostOrder-export.xls';

    // handle sucess
    this._store
      .select(LostOrderListSelectors.exportUrl)
      .pipe(filterTruthy(), take(1))
      .subscribe((url) => {
        dialogRef.componentInstance.documentUrl = url;
      });

    // handle error
    this._store
      .select(LostOrderListSelectors.hasExportError)
      .pipe(
        filter((x) => x === true),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe(() => {
        dialogRef.componentInstance.hasError = true;
        dialogRef.componentInstance.tryAgainClicked
          .pipe(take(1))
          .subscribe(() => {
            dialogRef.componentInstance.hasError = false;
            this._store.dispatch(LostOrderListActions.ResetExportState());
            this._store.dispatch(LostOrderListActions.GetExport());
          });
      });

    // start export
    this._store.dispatch(LostOrderListActions.GetExport());
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
