import { Pipe, PipeTransform } from '@angular/core';
import { ProjectState } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectStateTranslationKey',
  standalone: false,
})
export class SlProjectStateTranslationKeyPipe implements PipeTransform {
  readonly projectStateTranslationKeyMapping = {
    [ProjectState.InProgress]: 'project_filters.in_progress',
    [ProjectState.Won]: 'project_filters.won',
    [ProjectState.Lost]: 'project_filters.lost',
    [ProjectState.Offer]: 'project_filters.offer',
    [ProjectState.Closed]: 'project_filters.won',
  };

  transform(state: ProjectState | undefined | null): string {
    return state ? this.projectStateTranslationKeyMapping[state] : '';
  }
}
