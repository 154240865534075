import {
  CartItemsService,
  SolutionItemInput,
} from '@sales-libs/project/data-access';
import { SlProjectSolutionItemCreationRow } from '../rows/solution-cart-item-creation-row';

export class SlProjectSolutionItemCreationRowFactory {
  constructor(private _cartItemsService: CartItemsService) {}

  create(item: SolutionItemInput) {
    return new SlProjectSolutionItemCreationRow(item, this._cartItemsService);
  }
}
