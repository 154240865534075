import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  ContractItem,
  ProductItem,
  ProductItemProductType,
} from '@sales-libs/project/data-access';
import { ContractName, SCC_DOMAIN, SlCartItem } from '@sales-libs/shared/util';

@Component({
  selector: 'sl-project-actions-column',
  templateUrl: './actions-column.component.html',
  styleUrls: ['./actions-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectActionsColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsProductItem = value as ProductItem;
    this.itemAsContractItem = value as ContractItem;
  }
  @Input() isReadOnly: boolean;
  @Input() isSelectOnly: boolean;

  @Output() selectClick = new EventEmitter<SlCartItem>();
  @Output() deleteClick = new EventEmitter<SlCartItem>();
  @Output() configureClick = new EventEmitter<SlCartItem>();
  @Output() toOptionalClick = new EventEmitter<SlCartItem>();
  @Output() editClick = new EventEmitter();
  @Output() showConfigurationClick = new EventEmitter<SlCartItem>();

  contractName = ContractName;
  cartItem: SlCartItem;
  itemAsProductItem: ProductItem;
  itemAsContractItem: ContractItem;
  readonly productTypes = ProductItemProductType;

  constructor(@Inject(SCC_DOMAIN) public sccDomain) {}
}
