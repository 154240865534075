<ng-container
  [matColumnDef]="perspective + 'AverageDiscountExclNet'"
  gaLabelGroup="AverageDiscountExclNetColumn"
  gaCategoryGroup="Cart"
>
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right"
    [gaClickListener]="perspective + 'AverageDiscountExclNetClick'"
  >
    {{ averageDiscountExclNet | percent: '1.2-2' : locale }}
  </td>
</ng-container>
