import { Action, createReducer, on } from '@ngrx/store';
import { OfferJsReportInput } from '@sales-libs/project/data-access';
import { DigitalOfferActions } from './digital-offer.actions';

export interface DigitalOfferState {
  digitalOfferId?: string;
  digitalOfferData?: OfferJsReportInput;
  isLoading: boolean;
}

export const initialState: DigitalOfferState = {
  digitalOfferId: undefined,
  digitalOfferData: undefined,
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(
    DigitalOfferActions.getDigitalOfferDataByOfferNumber,
    (state, { digitalOfferId }) => ({
      ...state,
      digitalOfferId: digitalOfferId,
      isLoading: true,
    }),
  ),
  on(
    DigitalOfferActions.getDigitalOfferDataByOfferNumberSuccess,
    (state, { digitalOfferData }) => ({
      ...state,
      digitalOfferData: digitalOfferData,
      isLoading: false,
    }),
  ),
  on(
    DigitalOfferActions.getDigitalOfferDataByOfferNumberError,
    (state, { payload }) => ({
      ...state,
      gettingDigitalOfferDataByOfferNumberError: payload,
      isLoading: false,
    }),
  ),
);

export function digitalOfferReducer(
  state: DigitalOfferState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
