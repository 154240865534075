import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import { Calculation } from '@sales-libs/project/data-access';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-relative-discount-column',
  templateUrl: './relative-discount-column.component.html',
  styleUrls: ['./relative-discount-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectRelativeSlProjectDiscountColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() isAlternativeView = false;
  @Input() currencyRatio: number;
  @Input() currencyRoundingDigits: number;
  @Input() set calculation(value: Calculation) {
    const subTotal = CalculationUtils.getItemsTotal(
      value.items,
      this.perspective,
      this.isAlternativeView,
      true,
      false,
      this.currencyRatio,
      this.currencyRoundingDigits,
    );
    this.relativeDiscount =
      subTotal * value[this.perspective].relative_discount;
  }

  relativeDiscount: number;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  formatNegativeValue(value: string | null): string {
    if (value === null) return '';
    return value.includes('-') ? `${value.replace('-', '+')}` : `-${value}`;
  }
}
