import { createAction, props } from '@ngrx/store';
import {
  OfferJsReportInput,
  ProblemDetails,
} from '@sales-libs/project/data-access';

export namespace DigitalOfferActions {
  export const getDigitalOfferDataByOfferNumber = createAction(
    '[Digital_Offer] GET_DIGITAL_OFFER_DATA_BY_OFFER_NUMBER',
    props<{ digitalOfferId: string }>(),
  );
  export const getDigitalOfferDataByOfferNumberSuccess = createAction(
    '[Digital_Offer] GET_DIGITAL_OFFER_DATA_BY_OFFER_NUMBER_SUCCESS',
    props<{ digitalOfferData: OfferJsReportInput }>(),
  );
  export const getDigitalOfferDataByOfferNumberError = createAction(
    '[Digital_Offer] GET_DIGITAL_OFFER_DATA_BY_OFFER_NUMBER_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
