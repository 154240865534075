import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { matFormFieldAnimations } from '@angular/material/form-field';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  MccItem,
  MountingCategoriesContainer,
  TrucksContainer,
} from '@sales-libs/project/data-access';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CartActions, CartSelectors } from '../store';
import { MccActions, MccSelectors } from './store';

@Component({
  selector: 'sl-project-mcc-dialog',
  templateUrl: './mcc-dialog.component.html',
  styleUrls: ['./mcc-dialog.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  standalone: false,
})
export class SlProjectMccDialogComponent implements OnInit {
  truck$: Observable<TrucksContainer | null>;
  mountingCatergory$: Observable<MountingCategoriesContainer | null>;
  isUserWithPurchasingGpRole: boolean;

  readonly form: FormGroup<MccForm>;
  mccItem: MccItem | null;
  ratio$: Observable<number>;
  cartId$: Observable<number | null>;

  constructor(
    private readonly _store: Store<any>,
    private readonly _translateService: TranslateService,
    private readonly _dialogRef: MatDialogRef<SlProjectMccDialogComponent>,
    private readonly _userService: UserService,
  ) {
    this.form = new FormGroup<MccForm>({
      is_discountable: new FormControl<boolean>(false, { nonNullable: true }),
      sales_price: new FormControl<number>(0, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      purchase_price: new FormControl<number>(0, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      long_description: new FormControl<string | null>(null),
      cit_material: new FormControl<string | null>(null, Validators.required),
      truck_material: new FormControl<string | null>(null),
      short_description: new FormControl<string | null>(null), // TODO: required wait for backend
    });

    this.mountingCatergory$ = this._store.select(
      MccSelectors.mountingOptionCategories,
    );
    this.ratio$ = this._store.select(CartSelectors.currencyRatio);
    this.truck$ = this._store.select(MccSelectors.trucks);
    this.cartId$ = this._store.select(CartSelectors.id);

    combineLatest([
      this._store.select(CartSelectors.mccItems).pipe(
        filterTruthy(),
        filter((items) => items.length > 0),
        map((items) => items[0] as MccItem),
      ),
      this.ratio$,
    ])
      .pipe(
        map(([item, ratio]) => ({
          ...item,
          sales_price: Math.round(item.sales_price * ratio * 100) / 100,
          purchase_price: Math.round(item.purchase_price * ratio * 100) / 100,
        })),
        take(1),
      )
      .subscribe((item) => {
        this.mccItem = item;
        this.form.patchValue(this.mccItem);
      });

    this.isUserWithPurchasingGpRole = this._userService.hasRole(
      APPS.PALDESK_CPQ.ROLES.CPQ_PURCHASINGP,
    );
  }

  ngOnInit(): void {
    this._store.dispatch(MccActions.GetMountingOptions());
    this._store.dispatch(MccActions.GetTruckOptions());
    this.form.controls.truck_material.valueChanges.subscribe(
      (truckMaterial) => {
        if (!truckMaterial) {
          this.form.controls.long_description.reset();
        }
      },
    );
  }

  confirm(cartId: number, ratio: number) {
    const formValue = this.form.getRawValue();
    const adjustedFormValue = {
      ...this.form.getRawValue(),
      // user will enter values in his currency so we need to convert it back
      sales_price: formValue.sales_price / ratio,
      purchase_price: formValue.purchase_price / ratio,
      // order number only if truck is selected
      long_description: this.form.value.truck_material
        ? this.form.value.long_description
        : undefined,
    };

    if (this.mccItem) {
      this._store.dispatch(
        CartActions.UpdateMccItem({
          update: {
            ...this.mccItem,
            ...adjustedFormValue,
          },
        }),
      );
    } else {
      this._store.dispatch(
        CartActions.AddMccItem({
          item: {
            ...adjustedFormValue,
            cart_id: cartId,
            is_optional: false,
            name: this._translateService.instant('mcc.cart_item_name'),
            sort_key: 0,
            sort_key_optional: 0,
            is_edited: false,
          },
        }),
      );
    }
    this._dialogRef.close();
  }

  cancel(includedDocs: number[]) {
    if (!this.mccItem && includedDocs && includedDocs.length) {
      for (const id of includedDocs) {
        this._store.dispatch(CartActions.DeleteCheckoutDocument({ docId: id }));
      }
    }
    this._dialogRef.close();
  }
}

interface MccForm {
  is_discountable: FormControl<boolean>;
  sales_price: FormControl<number>;
  purchase_price: FormControl<number>;
  short_description: FormControl<string | null | undefined>;
  long_description: FormControl<string | null | undefined>;
  cit_material: FormControl<string | null | undefined>;
  truck_material: FormControl<string | null | undefined>;
}
