import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  CartState,
  Currency,
  Project,
  ProjectState,
} from '@sales-libs/project/data-access';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ProjectSelectors } from '../store';

@Component({
  selector: 'sl-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  standalone: false,
})
export class SlProjectDetailsComponent implements OnInit, OnDestroy {
  @Input() set project(value: Project) {
    if (value.id !== null) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
    this._project = value;
    this.form.controls.comment.setValue(value.comment ?? '', {
      emitEvent: false,
    });
  }
  get project(): Project {
    return this._project;
  }
  @Input() priceListDate: Date | undefined;
  @Input() projectVolume: number | undefined;
  @Output() commentChange = new EventEmitter<string>();
  @Output() lostStateClick = new EventEmitter<Project>();
  @Output() wonStateClick = new EventEmitter<Project>();

  primaryCartCurrency$: Observable<Currency | undefined>;
  public form = new FormGroup({
    comment: new FormControl<string | null>(null, { updateOn: 'blur' }),
  });
  public states = ProjectState;
  private _project: Project;
  private _destroyed$ = new Subject<void>();

  constructor(private _store: Store) {
    this.primaryCartCurrency$ = this._store
      .select(ProjectSelectors.carts)
      .pipe(
        map(
          (carts) =>
            carts.find(
              (cart) =>
                cart.state === CartState.Active ||
                cart.state === CartState.Ordered ||
                cart.state === CartState.Done,
            )?.currency_settings,
        ),
      );
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this.commentChange.emit(value.comment || '');
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
