<ds-table [style.min-width]="(models.length + 1) * 7 | dsSpacing">
  <div class="padding" fxLayout="row" fxLayoutAlign="end center">
    <mat-slide-toggle [(ngModel)]="showOnlyDifferences">
      {{ 'general.show_only_differences' | translate }}
    </mat-slide-toggle>
  </div>
  <table class="pd-table">
    <thead>
      <tr>
        <th>
          <h4 class="no-margin">
            {{ heading1 | translate }}
          </h4>
          <h4 class="no-margin">
            {{ heading2 | translate }}
          </h4>
        </th>
        @for (model of models; track model; let last = $last) {
          <th class="no-padding" fxLayout="column">
            @if (model.image) {
              <img
                class="full-width"
                src="{{ model.image }}"
                alt="{{ model.title | translate }}"
              />
            }
            <button
              mat-raised-button
              color="white"
              (click)="removeModel.emit(model)"
            >
              <mat-icon>delete</mat-icon>
              {{ 'general.delete' | translate }}
            </button>
            <h2
              class="option-title no-margin text-center padding overflow-hidden"
            >
              {{ model.title | translate }}
            </h2>
          </th>
          <th *ngIf="!last" class="spacer"></th>
        }
      </tr>
    </thead>
    <tbody>
      @for (option of options; track option) {
        <tr [hidden]="!hasModelDifferences(option)">
          <td class="normal-white-space">
            {{ option.title | translate | titlecase }}
            @if (option.label) {
              <mat-icon inline [matTooltip]="option.label"
                >info_outline</mat-icon
              >
            }
          </td>
          @for (model of models; track model; let last = $last) {
            <td class="text-center">
              {{ getFormattedOptionValue(model, option) }}
            </td>
            <td *ngIf="!last" class="spacer"></td>
          }
        </tr>
      }
      <tr class="no-hover-effect">
        <td></td>
        @for (model of models; track model; let last = $last) {
          <td class="text-center">
            <button
              mat-button
              type="button"
              (click)="configureModel.emit(model)"
            >
              <mat-icon>build</mat-icon>
              {{ 'general.configuration' | translate }}
            </button>
          </td>
          <td *ngIf="!last" class="spacer"></td>
        }
      </tr>
    </tbody>
  </table>
</ds-table>
