<ng-container matColumnDef="created_date">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="created_date"
    data-test="lost-order-created-date-header"
  >
    {{ 'table_columns.created_date' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'CreatedDateClick'
    }"
    data-test="lost-order-created-date"
  >
    {{ lostOrder.created_on | date: 'shortDate' }}
  </td>
</ng-container>
