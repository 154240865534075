import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlCartItem } from '@sales-libs/shared/util';

// TODO handle all in template
export interface SlProjectItemDependendRemovalDialogData {
  items: SlCartItem[];
}

@Component({
  selector: 'sl-project-item-dependend-removal',
  templateUrl: './product-dependend-removal.component.html',
  styleUrls: ['./product-dependend-removal.component.scss'],
  standalone: false,
})
export class SlProjectItemDependendRemovalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SlProjectItemDependendRemovalDialogData,
  ) {}
}
