<ng-container matColumnDef="name">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="name">
    {{ 'table_columns.name' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'NameClick'
    }"
    data-test="project-name-cell"
  >
    <div class="cut-text project-name" [matTooltip]="project.name">
      {{ project.name }}
    </div>
  </td>
</ng-container>
