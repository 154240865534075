import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Calculation, CalculationItem } from '@sales-libs/project/data-access';
import { writePercentage } from '@sales-libs/shared/util';
import { triggerBlur } from '@ui-kit/form-control';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalculationUtils } from '../../../shared/calculation-utils';

@Component({
  selector: 'sl-project-discount-column-input',
  templateUrl: './discount-column-input.component.html',
  styleUrls: ['./discount-column-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectDiscountColumnInputComponent
  implements OnInit, OnDestroy
{
  @Input() item: CalculationItem;
  @Input() perspective: string;
  @Input() locale: string;
  @Input() percentSymbol: string;
  @Input() isAlternativeView = false;
  @Input() calculation: Calculation;
  @Input() parentForm: FormGroup;

  @Output() calculationChange = new EventEmitter<Calculation>();

  readonly triggerBlur = triggerBlur;

  formControl: FormControl<number>;
  private readonly _destroy$ = new Subject<void>();

  ngOnInit() {
    const isDisabled =
      this.item.is_read_only ||
      this.parentForm.disabled ||
      this.item[this.perspective].purchase_price === 0;
    this.formControl = new FormControl<number>(
      {
        value:
          this.item[this.perspective] &&
          writePercentage(this.item[this.perspective]?.discount || 0),
        disabled: isDisabled,
      },
      { nonNullable: true, updateOn: 'blur' },
    );

    this.parentForm.addControl(
      'item' + this.item.id + this.perspective + 'Discount',
      this.formControl,
    );

    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.calculationChange.emit({
          ...this.calculation,
          items: [
            ...this.calculation.items.map((x) => ({
              ...CalculationUtils.overwriteItemDiscount(
                this.item.id ?? '',
                x,
                value,
                this.perspective,
              ),
            })),
          ],
          [this.perspective]:
            this.calculation[this.perspective] && !this.isAlternativeView // don't reset absolute discount if alternative item changes
              ? {
                  ...this.calculation[this.perspective],
                  absolute_discount: 0,
                }
              : this.calculation[this.perspective],
        });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
