import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WarrantyExtensionItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable, map } from 'rxjs';
import { CartActions, CartSelectors } from '../../store';
import { SlProjectTuningCenterDialogComponent } from '../../tuning-center-dialog/tuning-center-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectWarrantyExtensionCartItems]',
  templateUrl: './warranty-extension-cart-items.component.html',
  styleUrls: ['./warranty-extension-cart-items.component.scss'],
  standalone: false,
})
export class SlProjectWarrantyExtensionCartItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;

  readonly items$: Observable<WarrantyExtensionItem[]>;
  readonly isPricingVisible$: Observable<boolean>;

  constructor(
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
  ) {
    this.items$ = this._store
      .select(CartSelectors.warrantyExtensionItems)
      .pipe(map((items) => items ?? []));
    this.isPricingVisible$ = this._store.select(CartSelectors.isPricingVisible);
  }

  onEdit() {
    this._dialog.open(SlProjectTuningCenterDialogComponent);
  }

  onDelete(itemToDelete: SlCartItem) {
    this._store.dispatch(
      CartActions.DeleteWarrantyExtensionItem({
        cartId: itemToDelete.cart_id,
        itemId: itemToDelete.id as string,
      }),
    );
  }

  onToOptional(update: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateWarrantyExtensionItem({
        update: { ...update, is_optional: true } as WarrantyExtensionItem,
      }),
    );
  }
}
