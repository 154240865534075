import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { dsConfig } from '@design-system/cdk/config';
import { DsTableModule } from '@design-system/components/table';
import { TranslateModule } from '@ngx-translate/core';
import { BestInvestFeature } from '@sales-libs/upselling/data-access';
import { SlDetailsDialogComponent } from '@sales-libs/upselling/ui';

export const imports = [
  CommonModule,
  TranslateModule,
  MatIconModule,
  MatCardModule,
  DsTableModule,
  FlexModule,
  MatButtonModule,
];

@Component({
  selector: 'sl-upselling-features-table',
  standalone: true,
  imports: imports,
  templateUrl: './best-invest-features-table.component.html',
  styleUrls: ['./best-invest-features-table.component.scss'],
})
export class SlUpsellingBestInvestFeaturesTableComponent {
  @Input()
  features: BestInvestFeature[] = [];
  @Input()
  currencyCode: string;

  constructor(readonly _dialog: MatDialog) {}

  openMoreDetailsDialog(dataId: string): void {
    const feature = this.features.find((data) => data.id === dataId);

    if (feature)
      this._dialog.open(SlDetailsDialogComponent, {
        width: String(dsConfig.spacing * 25) + 'px',
        data: {
          feature: feature,
        },
      });
  }
}
