<ng-container matColumnDef="chance">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="chance">
    {{ 'table_columns.chance' | translate }}
  </th>
  <td
    data-test="project-chance-cell"
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      label: 'ProjectListChanceColumn',
      category: 'ProjectOverview',
      action: 'ChanceClick'
    }"
  >
    {{ project.chance }} %
  </td>
</ng-container>
