import { CartInput, CartsService } from '@sales-libs/project/data-access';
import { catchError, map, tap } from 'rxjs';
import { SlProjectCartDependendCreationRow } from './cart-dependend-row';
import {
  SlProjectDataCreationRow,
  SlProjectDataCreationRowState,
} from './data-creation-row';

export class SlProjectCartCreationRow extends SlProjectDataCreationRow {
  projectId: number;

  constructor(
    private _cartText: string,
    private _cartInput: CartInput,
    private _cartsService: CartsService,
    private _dependingCreationRows: SlProjectCartDependendCreationRow[],
  ) {
    super(_cartText);
  }

  protected _creationFunction = () =>
    this._cartsService
      .createCartAsync({
        ...this._cartInput,
        project_id: this.projectId,
      })
      .pipe(
        tap((data) => {
          this._dependingCreationRows.forEach((row) => {
            row.cartId = data.id;
            row.create();
          });
        }),
        map((data) => ({
          result: {
            data,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
