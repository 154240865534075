<ng-container gaLabelGroup="RelatedRemovalDialog" gaCategoryGroup="Cart">
  <h2 mat-dialog-title gaClickListener="TitleClick">
    {{ 'project_detail.related_removal_dialog.headline' | translate }}
  </h2>

  <mat-dialog-content>
    <span
      [innerHTML]="
        'project_detail.related_removal_dialog.message'
          | translate: { itemName: data.name }
      "
    ></span>
    <br /><br />
    <sl-project-cart-item-list
      [items]="data.relatedItems"
      [isTypeVisible]="false"
      [isReadOnly]="true"
      gaClickListener="CartItemListClick"
    ></sl-project-cart-item-list>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">
      {{ 'common.confirm' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
