import { Action, createReducer, on } from '@ngrx/store';
import { SlUpsellingActions } from './upselling.actions';
import { SlUpsellingState, initialState } from './upselling.store';

export const slUpsellingReducer = createReducer(
  initialState,

  on(SlUpsellingActions.getBestInvestProjectParameters, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.getBestInvestProjectParametersSuccess,
    (state, { parameters }) => ({
      ...state,
      parameters: parameters,
    }),
  ),
  on(
    SlUpsellingActions.getBestInvestProjectParametersError,
    (state, { payload }) => ({
      ...state,
      gettingBestInvestProjectParametersError: payload,
    }),
  ),

  on(SlUpsellingActions.updateBestInvestProjectParameters, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.updateBestInvestProjectParametersSuccess,
    (state, { parameters }) => ({
      ...state,
      parameters: parameters,
    }),
  ),
  on(
    SlUpsellingActions.updateBestInvestProjectParametersError,
    (state, { payload }) => ({
      ...state,
      updateBestInvestProjectParametersError: payload,
    }),
  ),

  on(SlUpsellingActions.getBestInvestFeatures, (state) => ({
    ...state,
  })),
  on(SlUpsellingActions.getBestInvestFeaturesSuccess, (state, { payload }) => ({
    ...state,
    payload: payload,
  })),
  on(SlUpsellingActions.getBestInvestFeaturesError, (state, { payload }) => ({
    ...state,
    gettingBestInvestFeaturesError: payload,
  })),

  on(SlUpsellingActions.getBestInvestDealerParameters, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.getBestInvestDealerParametersSuccess,
    (state, { dealerParameters }) => ({
      ...state,
      dealerParameters: dealerParameters,
    }),
  ),
  on(
    SlUpsellingActions.getBestInvestDealerParametersError,
    (state, { payload }) => ({
      ...state,
      gettingBestInvestDealerParametersError: payload,
    }),
  ),

  on(SlUpsellingActions.setProjectParametersToDealerDefaults, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.setProjectParametersToDealerDefaultsSuccess,
    (state, { parameters }) => ({
      ...state,
      parameters: parameters,
    }),
  ),
  on(
    SlUpsellingActions.setProjectParametersToDealerDefaultsError,
    (state, { payload }) => ({
      ...state,
      setProjectParametersToDealerDefaultsError: payload,
    }),
  ),
);

export function reducer(state: SlUpsellingState | undefined, action: Action) {
  return slUpsellingReducer(state, action);
}
