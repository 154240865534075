<form
  [formGroup]="bestInvestForm"
  class="form-grid input-data-form"
  gaLabelGroup="BestInvestForm"
  gaCategoryGroup="BestInvestData"
>
  <p
    fxLayout="row"
    fxLayoutAlign="end center"
    class="input-data-form__mandatory_text"
  >
    * {{ 'sales_shared.common.mandatory' | translate }}
  </p>
  <sl-settings-currency-dependend-page (currencyLoad)="loadCurrency($event)">
    <mat-card class="input-data-card">
      <mat-card-content>
        <h4 class="no-margin-top">
          {{ 'benefits.data_input.utilization' | translate }}
        </h4>
        <mat-form-field
          class="full-width"
          gaClickListener="KmUtilizationPerYearClick"
        >
          <mat-label>{{
            'benefits.data_input.km_utilization' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            min="0"
            formControlName="utilization_km_per_year"
            data-test="utilization_km_per_year"
            autocomplete="off"
          />
          <span matTextSuffix>{{ Units.KmPerYear }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.utilization_km_per_year.invalid"
          >
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="WorkingHoursPerDayClick"
        >
          <mat-label>{{
            'benefits.data_input.working_hours_per_day' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="working_hours_per_day"
            data-test="working_hours_per_day"
            autocomplete="off"
            step="0.1"
            (blur)="updateUtilizationParameters(true)"
          />
          <span matTextSuffix>{{ Units.HoursPerYear }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.working_hours_per_day.invalid"
          >
            <span
              *ngIf="
                bestInvestForm.get('working_hours_per_day')?.hasError('max')
              "
              >{{
                'benefits.data_input.maximum_exceeded_error' | translate
              }}</span
            >
            <span
              *ngIf="
                bestInvestForm
                  .get('working_hours_per_day')
                  ?.hasError('required')
              "
              >{{ 'sales_shared.common.required' | translate }}</span
            >
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="WorkingDaysPerYearClick"
        >
          <mat-label>{{
            'benefits.data_input.working_days_per_year' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            min="0"
            formControlName="working_days_per_year"
            data-test="working_days_per_year"
            autocomplete="off"
            step="1"
            (blur)="updateUtilizationParameters(true)"
          />
          <span matTextSuffix>{{ Units.HoursPerYear }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.working_days_per_year.invalid"
          >
            <span
              *ngIf="
                bestInvestForm.get('working_days_per_year')?.hasError('max')
              "
              >{{
                'benefits.data_input.maximum_exceeded_error' | translate
              }}</span
            >
            <span
              *ngIf="
                bestInvestForm
                  .get('working_days_per_year')
                  ?.hasError('required')
              "
              >{{ 'sales_shared.common.required' | translate }}</span
            >
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="WorkingHoursPerYearClick"
        >
          <mat-label>{{
            'benefits.data_input.working_hours_per_year' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="working_hours_per_year"
            data-test="working_hours_per_year"
            autocomplete="off"
            step="0.1"
            (blur)="updateUtilizationParameters()"
          />
          <span matTextSuffix>{{ Units.HoursPerYear }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.working_hours_per_year.invalid"
          >
            <span
              *ngIf="
                bestInvestForm.get('working_hours_per_year')?.hasError('max')
              "
              >{{
                'benefits.data_input.maximum_exceeded_error' | translate
              }}</span
            >
            <span
              *ngIf="
                bestInvestForm
                  .get('working_hours_per_year')
                  ?.hasError('required')
              "
              >{{ 'sales_shared.common.required' | translate }}</span
            >
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="input-data-card">
      <mat-card-content>
        <h4 class="no-margin-top">
          {{ 'benefits.data_input.distribution' | translate }}
        </h4>
        <mat-form-field
          class="full-width"
          gaClickListener="ShareLowActivityClick"
        >
          <mat-label>{{ 'benefits.data_input.holding' | translate }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="share_low_activity"
            data-test="share_low_activity"
            autocomplete="off"
            step="0.1"
            (blur)="updateControlParameter()"
          />
          <span matTextSuffix>{{ Units.Percentage }}</span>
          <mat-error *ngIf="bestInvestForm.controls.share_low_activity.invalid">
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="ShareSettingUpClick"
        >
          <mat-label>{{
            'benefits.data_input.setting_up' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="share_setting_up"
            data-test="share_setting_up"
            autocomplete="off"
            step="0.1"
            (blur)="updateControlParameter()"
          />
          <span matTextSuffix>{{ Units.Percentage }}</span>
          <mat-error *ngIf="bestInvestForm.controls.share_setting_up.invalid">
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="ShareHighActivityClick"
        >
          <mat-label>{{ 'benefits.data_input.lifting' | translate }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="share_high_activity"
            data-test="share_high_activity"
            autocomplete="off"
            step="0.1"
            (blur)="updateControlParameter()"
          />
          <span matTextSuffix>{{ Units.Percentage }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.share_high_activity.invalid"
          >
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <p class="input-data-card__control">
        {{ 'benefits.data_input.control' | translate }}
        {{ bestInvestForm.get('control')?.value }} {{ Units.Percentage }}
        <mat-error
          *ngIf="
            bestInvestForm.controls.control.invalid &&
            (bestInvestForm.get('control')?.hasError('max') ||
              bestInvestForm.get('control')?.hasError('min'))
          "
        >
          <span>{{
            'benefits.data_input.distribution_error' | translate
          }}</span>
        </mat-error>
      </p>
    </mat-card>

    <mat-card class="input-data-card">
      <mat-card-content>
        <h4 class="no-margin-top">
          {{ 'benefits.extended_data_input.costs_and_prices' | translate }}
        </h4>
        <mat-form-field
          class="full-width"
          gaClickListener="InternalWorkingHourCostsClick"
        >
          <mat-label>{{
            'benefits.extended_data_input.internal_working_hour' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="costs_internal_working_hour"
            data-test="costs_internal_working_hour"
            autocomplete="off"
            step="0.1"
          />

          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Hour }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.costs_internal_working_hour.invalid"
          >
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="ExternalWorkingHourCostsClick"
        >
          <mat-label>{{
            'benefits.extended_data_input.external_working_hour' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [pattern]="decimalPlacesRegex"
            formControlName="costs_external_working_hour"
            data-test="costs_external_working_hour"
            autocomplete="off"
            step="0.1"
          />
          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Hour }}</span>
          <mat-error
            *ngIf="bestInvestForm.controls.costs_external_working_hour.invalid"
          >
            <span>{{ 'sales_shared.common.required' | translate }}</span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </sl-settings-currency-dependend-page>
</form>
