// tslint:disable:no-console
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { APPS } from '@config';
import { dsConfig } from '@design-system/cdk/config';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Calculation,
  CalculationActions as CalculationActionsEnum,
  CalculationItem,
  Cart,
  CartState,
  Project,
} from '@sales-libs/project/data-access';
import { SlProjectDigitalOfferLinkDialogComponent } from '@sales-libs/project/ui';
import {
  DocumentDownloadDialogComponent,
  FEATURE_FLAG_DIGITAL_OFFER,
  FeatureFlagPipe,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import { catchError, filter, map, take, takeUntil } from 'rxjs/operators';
import { CartSelectors, ProjectSelectors } from '../store';
import { CalculationMapping } from './shared/calculation-mapping';
import { CalculationUtils } from './shared/calculation-utils';
import { ShippingCost } from './shared/models';
import { CalculationActions, CalculationSelectors } from './store';

@Component({
  selector: 'sl-project-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
  standalone: false,
})
export class SlCalculationComponent implements OnInit, OnDestroy {
  @Input() shippingCosts: ShippingCost[];

  calculation$: Observable<Calculation | null> = EMPTY;
  tableItems$: Observable<CalculationItem[] | null>;
  cart$: Observable<Cart | null>;
  discountAndCosts$: Observable<Calculation | null>;
  canCreateOffer$: Observable<boolean>;
  readonly project$: Observable<Project | undefined>;
  readonly isSaving$: Observable<boolean>;
  hasOffer$: Observable<boolean>;
  readonly offerUrl$: Observable<string | undefined>;
  readonly perspectives$: Observable<string[]>;
  readonly exportFileUrl$: Observable<string | undefined>;
  readonly partnerPerspectiveForm = new FormControl<boolean>(true, {
    nonNullable: true,
  });
  readonly generalAgentPerspectiveForm = new FormControl<boolean>(true, {
    nonNullable: true,
  });
  showAlternative = false;
  private readonly _destroy$ = new Subject<void>();

  cartId: number | null = null;
  isDigitalOfferFeatureFlagSet$: Observable<boolean>;

  constructor(
    private readonly _store: Store<any>,
    private _dialog: MatDialog,
    private _translateService: TranslateService,
    private _userService: UserService,
    @Inject(FEATURE_FLAG_DIGITAL_OFFER)
    public digitalOfferFeatureFlag,
  ) {
    this.cart$ = this._store.select(CartSelectors.current);
    this.project$ = this._store.select(ProjectSelectors.currentProject);
    this.isSaving$ = this._store.select(CalculationSelectors.isSaving);
    this.offerUrl$ = this._store.select(CalculationSelectors.offerUrl);
    this.exportFileUrl$ = this._store.select(CalculationSelectors.exportUrl);
    this.perspectives$ = this._store.select(CalculationSelectors.perspectives);
    this.isDigitalOfferFeatureFlagSet$ = new FeatureFlagPipe().transform(
      this.digitalOfferFeatureFlag,
    );

    this.calculation$ = combineLatest([
      this._store.select(CalculationSelectors.calculation).pipe(filterTruthy()),
      this._store
        .select(CartSelectors.optionalCartItemsGrouped)
        .pipe(filterTruthy()),
      this.cart$.pipe(filterTruthy()),
      this._store.select(CartSelectors.currency).pipe(filterTruthy()),
    ]).pipe(
      map(([calculation, optionalCartItemsGrouped, cart, currency]) => {
        const transformedCalc = CalculationMapping.transform(
          calculation,
          cart,
          currency,
          optionalCartItemsGrouped,
          this._userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_PURCHASINGP),
        );

        if (transformedCalc) {
          transformedCalc.is_read_only =
            transformedCalc?.is_read_only ||
            !(
              cart?.state === CartState.Created ||
              cart?.state === CartState.Active
            );
        }

        return transformedCalc;
      }),
      catchError((error) => {
        console.error('An error occurred:', error);
        return EMPTY;
      }),
      takeUntil(this._destroy$),
    );

    this.canCreateOffer$ = this.calculation$.pipe(
      map(
        (calc) =>
          calc?.actions?.includes(CalculationActionsEnum.CreateOffer) ?? false,
      ),
    );
    this.hasOffer$ = this.calculation$.pipe(
      map(
        (calc) =>
          calc?.actions?.includes(CalculationActionsEnum.ViewOffer) ?? false,
      ),
    );
    this.tableItems$ = combineLatest([
      this.calculation$.pipe(filterTruthy()),
      this._store.select(CalculationSelectors.expandedItemsLookup),
    ]).pipe(
      map(([calculation, expandedItemsLookup]) =>
        CalculationUtils.tableItems(calculation, expandedItemsLookup),
      ),
    );
  }

  ngOnInit(): void {
    // Subscribe to partner perspective value from the store
    this._store
      .select(CalculationSelectors.perspectives)
      .pipe(takeUntil(this._destroy$))
      .subscribe((perspectives: string[]) => {
        this.partnerPerspectiveForm.setValue(perspectives.includes('partner'), {
          emitEvent: false,
        });
        this.generalAgentPerspectiveForm.setValue(
          perspectives.includes('general_agent'),
          { emitEvent: false },
        );
      });

    this.partnerPerspectiveForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((show) => {
        this.updatePerspective(show, 'partner');
      });

    this.generalAgentPerspectiveForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((show) => {
        this.updatePerspective(show, 'general_agent');
      });

    this._store
      .select(CartSelectors.id)
      .pipe(
        filter((cartId): cartId is number => cartId != null),
        takeUntil(this._destroy$),
      )
      .subscribe((cartId) =>
        this._store.dispatch(
          CalculationActions.LoadCalculation({ payload: cartId }),
        ),
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onDigitalOfferClick(cart: Cart) {
    this._dialog.open(SlProjectDigitalOfferLinkDialogComponent, {
      data: `${window.location.origin}/offer/${cart.project_id}-${cart.id}`,
    });
  }

  onExportClick(): void {
    // open dialog and prefill name
    this._store.dispatch(CalculationActions.ResetExportState());
    const dialogRef = this._dialog.open(DocumentDownloadDialogComponent, {
      width: 25 * dsConfig.spacing + 'px',
    });
    dialogRef.componentInstance.fileName = 'calculation.xls';

    // handle sucess
    this._store
      .select(CalculationSelectors.exportUrl)
      .pipe(filterTruthy(), take(1))
      .subscribe((url) => {
        dialogRef.componentInstance.documentUrl = url;
      });

    // handle error
    this._store
      .select(CalculationSelectors.hasExportError)
      .pipe(
        filter((x) => x === true),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe(() => {
        dialogRef.componentInstance.hasError = true;
        dialogRef.componentInstance.tryAgainClicked
          .pipe(take(1))
          .subscribe(() => {
            dialogRef.componentInstance.hasError = false;
            this._store.dispatch(CalculationActions.ResetExportState());
            this._store.dispatch(CalculationActions.GetExportUrl());
          });
      });
    this._store.dispatch(CalculationActions.GetExportUrl());
  }

  updatePerspective(show: boolean, perspective: string) {
    if (show) {
      this.showPerspective(perspective);
    } else {
      this.hidePerspective(perspective);
    }
  }

  update(calculation: Calculation): void {
    this._store.dispatch(
      CalculationActions.UpdateCalculation({
        payload: calculation,
      }),
    );
  }

  print() {
    window.print();
  }

  getDate() {
    return new Date();
  }

  hidePerspective(perspective: string) {
    this._store.dispatch(
      CalculationActions.RemovePerspective({ payload: perspective }),
    );
  }
  showPerspective(perspective: string) {
    this._store.dispatch(
      CalculationActions.AddPerspective({ payload: perspective }),
    );
  }

  openDocumentDownloadDialog(cart: Cart): void {
    const dialogRef = this._dialog.open(DocumentDownloadDialogComponent, {
      width: 25 * dsConfig.spacing + 'px',
    });
    dialogRef.componentInstance.fileName =
      this._translateService.instant('offer.offer') +
      '_' +
      cart.project_id +
      '_' +
      cart.version +
      '.pdf';
    this._store.dispatch(CalculationActions.GetOfferUrl());

    this._store
      .select(CalculationSelectors.offerUrl)
      .pipe(filterTruthy(), take(1))
      .subscribe((url) => {
        dialogRef.componentInstance.documentUrl = url;
      });

    this._store
      .select(CalculationSelectors.hasOfferUrlError)
      .pipe(
        filter((x) => x === true),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe(() => {
        dialogRef.componentInstance.hasError = true;
        dialogRef.componentInstance.tryAgainClicked
          .pipe(take(1))
          .subscribe(() => {
            dialogRef.componentInstance.hasError = false;
            this._store.dispatch(CalculationActions.GetOfferUrl());
          });
      });
  }
}
