<ng-container matColumnDef="competitor_type">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="competitor_type"
    data-test="lost-order-competitor-type-header"
  >
    {{ 'table_columns.competitor_type' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'competitorTypeClick'
    }"
    data-test="lost-order-competitor-type"
  >
    {{
      lostOrder.competitor_type
        ? lostOrder.competitor_type
        : ('error_messages.lost_order.no_competitor_type' | translate)
    }}
  </td>
</ng-container>
