import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BestInvestService } from '@sales-libs/upselling/data-access';
import { SlUpsellingEffects, slUpsellingReducer } from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('SlUpsellingState', slUpsellingReducer),
    EffectsModule.forFeature([SlUpsellingEffects]),
  ],
  providers: [BestInvestService],
})
export class SlUpsellingModule {}
