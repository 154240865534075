import { Pipe, PipeTransform } from '@angular/core';
import { ContractItemContractType } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectContractTypeTranslationKey',
  standalone: false,
})
export class SlProjectContractTypePipe implements PipeTransform {
  readonly ContractTranslationKeyMapping = {
    [ContractItemContractType.Pure]: 'contract.type.pure',
    [ContractItemContractType.Perform]: 'contract.type.perform',
    [ContractItemContractType.Pro]: 'contract.type.pro',
    [ContractItemContractType.Prime]: 'contract.type.prime',
  };

  transform(type: ContractItemContractType): string {
    return this.ContractTranslationKeyMapping[type];
  }
}
