import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import {
  Calculation,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';

import { DsColumnComponent } from '@design-system/cdk/column';

@Component({
  selector: 'sl-project-discount-column',
  templateUrl: './discount-column.component.html',
  styleUrls: ['./discount-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectDiscountColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() percentSymbol: string;
  @Input() locale: string;
  @Input() isAlternativeView = false;
  @Input() calculation: Calculation;

  @Output() calculationChange = new EventEmitter<Calculation>();

  parentForm: FormGroup;
  itemTypes = CalculationItemItemType;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    this.parentForm = this.formDirective.form;
  }
}
