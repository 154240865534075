import { Pipe, PipeTransform } from '@angular/core';

import { CalculationItemPerspective } from '@sales-libs/project/data-access';
import { CalculationUtils } from './calculation-utils';

/**
 * calculate item sales price from purchase price and discount
 */
@Pipe({
  name: 'cpqSalesPrice',
  standalone: false,
})
export class SlProjectSalesPricePipe implements PipeTransform {
  transform(
    perspective: CalculationItemPerspective,
    quantity: number,
    ratio = 1,
    roundingDigits = 2,
  ): number {
    return CalculationUtils.getSalesPrice(
      perspective,
      quantity,
      ratio,
      roundingDigits,
    );
  }
}
