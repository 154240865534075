import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  TuningCenterItem,
  TuningCenterOptionCategory,
} from '@sales-libs/project/data-access';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { CartActions, CartSelectors } from '../store';
import { TuningCenterActions, TuningCenterSelectors } from './store';

@Component({
  selector: 'sl-project-tuning-center-dialog',
  templateUrl: './tuning-center-dialog.component.html',
  styleUrls: ['./tuning-center-dialog.component.scss'],
  standalone: false,
})
export class SlProjectTuningCenterDialogComponent implements OnInit, OnDestroy {
  cartId: number;
  ratio$: Observable<number>;
  selectedOptionsFormGroup: FormGroup;
  isUserWithPurchasingGpRole: boolean;
  tuningCenterItem: TuningCenterItem | null;
  optionCategories$: Observable<TuningCenterOptionCategory[]>;

  readonly form: FormGroup<TuningCenterForm>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly _store: Store<any>,
    private readonly _translateService: TranslateService,
    private readonly _dialogRef: MatDialogRef<SlProjectTuningCenterDialogComponent>,
    private readonly _userService: UserService,
  ) {
    this._store
      .select(CartSelectors.id)
      .pipe(filterTruthy(), takeUntil(this.destroy$))
      .subscribe((id) => (this.cartId = id));
    this.form = new FormGroup<TuningCenterForm>({
      short_description: new FormControl<string | null | undefined>(null),
      sales_price: new FormControl<number>(0, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      purchase_price: new FormControl<number>(0, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      long_description: new FormControl<string | null | undefined>(null),
    });

    this.ratio$ = this._store.select(CartSelectors.currencyRatio);
    this.optionCategories$ = _store.select(
      TuningCenterSelectors.optionCategories,
    );

    this.isUserWithPurchasingGpRole = this._userService.hasRole(
      APPS.PALDESK_CPQ.ROLES.CPQ_PURCHASINGP,
    );
  }

  ngOnInit() {
    this._store.dispatch(TuningCenterActions.GetOptions());

    const itemWithRatio$ = combineLatest([
      this._store.select(CartSelectors.tuningCenterItems).pipe(
        filterTruthy(),
        filter((items) => items.length > 0),
        map((items) => items[0] as TuningCenterItem),
      ),
      this.ratio$,
    ]).pipe(
      map(([item, ratio]) => ({
        ...item,
        sales_price: Math.round(item.sales_price * ratio * 100) / 100,
        purchase_price: Math.round(item.purchase_price * ratio * 100) / 100,
      })),
      take(1),
    );

    combineLatest([
      itemWithRatio$.pipe(startWith(null)),
      this.optionCategories$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([item, optionCategories]) => {
        this.tuningCenterItem = item;
        this.form.patchValue(this.tuningCenterItem as TuningCenterItem);
        this.selectedOptionsFormGroup = new FormGroup({});
        optionCategories.forEach((category) => {
          if (category?.options) {
            category.options.forEach((option) => {
              this.selectedOptionsFormGroup.addControl(
                '' + option.id,
                new FormControl<boolean>(
                  this.tuningCenterItem?.selected_options?.includes(
                    option.id,
                  ) ?? false,
                ),
              );
            });
          }
        });
      });
  }

  confirm(ratio: number) {
    const selectedOptions = Object.keys(this.selectedOptionsFormGroup.value)
      .filter((x) => this.selectedOptionsFormGroup.value[x] === true)
      .map((key) => +key);

    const formValue = this.form.getRawValue();
    const adjustedFormValue = {
      ...formValue,
      // user will enter values in his currency so we need to convert it back
      sales_price: (formValue.sales_price ?? 0) / ratio,
      purchase_price: (formValue.purchase_price ?? 0) / ratio,
    };

    if (this.tuningCenterItem) {
      this._store.dispatch(
        CartActions.UpdateTuningCenterItem({
          update: {
            ...this.tuningCenterItem,
            ...adjustedFormValue,
            selected_options: selectedOptions,
          },
        }),
      );
    } else {
      this._store.dispatch(
        CartActions.AddTuningCenterItem({
          item: {
            ...adjustedFormValue,
            cart_id: this.cartId,
            is_discountable: true,
            is_optional: false,
            name: this._translateService.instant(
              'tuning_center.cart_item_name',
            ),
            selected_options: selectedOptions,
            sort_key: 0,
            sort_key_optional: 0,
            is_edited: false,
          },
        }),
      );
    }

    this._dialogRef.close();
  }

  cancel(includedDocs: number[]) {
    if (!this.tuningCenterItem && includedDocs && includedDocs.length) {
      for (const id of includedDocs) {
        this._store.dispatch(CartActions.DeleteCheckoutDocument({ docId: id }));
      }
    }
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface TuningCenterForm {
  sales_price: FormControl<number>;
  purchase_price: FormControl<number>;
  short_description: FormControl<string | null | undefined>;
  long_description: FormControl<string | null | undefined>;
}
