import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CartItemCompare,
  CartItemCompareCompareDifferenceType,
  Currency,
} from '@sales-libs/project/data-access';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectCartCompareRow]',
  templateUrl: './compare-row.component.html',
  styleUrls: ['./compare-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectCartCompareRowComponent {
  @Input() sourceCurrency: Currency;
  @Input() newCurrency: Currency;
  @Input() isLoading = true;
  @Input() isTextChangeVisible = false;
  @Input() isComparisonSuccessfull = false;
  @Input() isActionColumnVisible = true;
  @Input() item: CartItemCompare;
  @Output() tryAgainClick = new EventEmitter();
  @Output() removeClick = new EventEmitter();
  compareDifferenceType = CartItemCompareCompareDifferenceType;

  getClass(
    item: CartItemCompare,
    isPropertyChanged: boolean,
    isProperty = true,
  ) {
    return {
      'error-text': !this.isLoading && !this.isComparisonSuccessfull,
      'text-line-through':
        (item?.compare_difference_type ===
          CartItemCompareCompareDifferenceType.Removed ||
          isPropertyChanged) &&
        isProperty,
      'warning-text':
        item?.compare_difference_type ===
        CartItemCompareCompareDifferenceType.Removed,
      'success-text':
        item?.compare_difference_type ===
        CartItemCompareCompareDifferenceType.Added,
      'info-text': isPropertyChanged,
    };
  }
}
