import { CartItemsService } from '@sales-libs/project/data-access';
import { catchError, map } from 'rxjs';
import { SlProjectDataCreationCustomArticleItem } from '../types';
import { SlProjectCartItemCreationRow } from './cart-item-creation-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectCustomArticleItemCreationRow extends SlProjectCartItemCreationRow {
  constructor(
    private _customArticleItem: SlProjectDataCreationCustomArticleItem,
    private _cartItemsService: CartItemsService,
  ) {
    super(
      _customArticleItem.name ??
        _customArticleItem.short_description ??
        _customArticleItem.long_description ??
        '',
    );
  }

  protected _creationFunction = () =>
    this._cartItemsService
      .addCustomArticleItemAsync({
        ...this._customArticleItem,
        cart_id: this.cartId,
        cart_item_group_id: this.cartItemGroupId,
      })
      .pipe(
        map((data) => ({
          result: {
            data,
            calculationItemReferenceId:
              this._customArticleItem.calculationItemReferenceId,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
