<ng-container matColumnDef="model">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="model">
    {{ 'project.model' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'ModelClick'
    }"
    data-test="model"
  >
    {{ project.model }}
  </td>
</ng-container>
