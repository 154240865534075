import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

@Component({
  selector: 'sl-project-digital-offer-link-dialog',
  imports: [
    CommonModule,
    GoogleAnalytics4DirectiveModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
    CdkCopyToClipboard,
    TranslateModule,
    DsSpacingModule,
  ],
  templateUrl: './digital-offer-link-dialog.component.html',
  styleUrl: './digital-offer-link-dialog.component.scss',
})
export class SlProjectDigitalOfferLinkDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public link: string) {}
}
