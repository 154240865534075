<ng-container [matColumnDef]="perspective + 'AbsoluteDiscount'">
  <td mat-footer-cell *matFooterCellDef class="text-right">
    <mat-form-field
      [gaClickListener]="{
        action: perspective + 'SpecialDeclineClick',
        category: 'Cart',
        label: 'Calculation'
      }"
      class="{{ perspective }}-absolute-discount-input print-hidden"
    >
      <input
        (keyup.enter)="triggerBlur($event)"
        matInput
        [formControl]="formControl"
        type="number"
        min="0"
        step="0.01"
        autocomplete="off"
      />
      <span matTextSuffix>&nbsp;{{ currencySymbol }}</span>
    </mat-form-field>
    <span class="print-only">
      {{
        formControl.value
          | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
          | async
      }}
    </span>
  </td>
</ng-container>
