import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import {
  Calculation,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-index-price-column',
  templateUrl: './index-price-column.component.html',
  styleUrls: ['./index-price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectIndexSlProjectPriceColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    this.indexPrice = CalculationUtils.getIndexPrice(
      value,
      this.perspective,
      this.isAlternativeView,
    );
  }
  itemTypes = CalculationItemItemType;
  indexPrice: number;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
