import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';
import { cartAdapter, pageAdapter, projectAdapter } from './project.reducer';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace ProjectSelectors {
  const projectSelectors = projectAdapter.getSelectors();
  const cartSelectors = cartAdapter.getSelectors();
  const pageSelectors = pageAdapter.getSelectors();

  const projectState = createSelector(moduleState, (state) => state.project);

  export const pagination = createSelector(
    projectState,
    (state) => state.pagination,
  );
  export const sort = createSelector(projectState, (state) => state.sort);
  export const projectFilters = createSelector(
    projectState,
    (state) => state.filters,
  );
  export const id = createSelector(projectState, (state) => state.id);
  export const loading = createSelector(projectState, (state) => state.loading);

  export const isUpdating = createSelector(
    projectState,
    (state) => state.isUpdating,
  );
  export const currentProject = createSelector(
    projectState,
    (state) => state.currentProject,
  );
  export const currentProjectName = createSelector(
    currentProject,
    (state) => state?.name,
  );
  export const isWholeSaleOrder = createSelector(
    currentProject,
    (state) => state?.is_whole_sale_order,
  );
  const projectEntitiesState = createSelector(
    projectState,
    projectSelectors.selectEntities,
  );

  export const getFilteredProjects = createSelector(
    projectState,
    (state) => state.filterProjects,
  );
  export const getFilteredProjectsLoading = createSelector(
    projectState,
    (state) => state.isFiltersLoading,
  );
  export const project = (projectId: number) =>
    createSelector(projectEntitiesState, (entities) => entities[projectId]);
  export const pageEntitesState = createSelector(
    pagination,
    pageSelectors.selectEntities,
  );
  export const currentPage = createSelector(
    pagination,
    pageEntitesState,
    projectEntitiesState,
    (pagination1, pageEntities, projectEntities) => {
      const page = pageEntities[pagination1.pageIndex];
      return page && page.ids.map((projectId) => projectEntities[projectId]);
    },
  );

  const cartState = createSelector(projectState, (state) => state.cart);
  export const carts = createSelector(cartState, cartSelectors.selectAll);

  export const cartsLoading = createSelector(
    cartState,
    (state) => state.loading,
  );

  export const activeCartPriceListDate = createSelector(
    carts,
    (items) => items?.find((x) => x.active)?.price_list_date ?? null,
  );
  export const projectVolume = createSelector(carts, (items) => {
    const active = items?.find((x) => x.active);

    if (!active) return null;
    // todo calculate final_price properly first , Bug 156871 created
    // after it is done replace lines
    //const aaaa = active.final_price ?? active.sales_price ?? 0;
    return active.sales_price;
  });
  export const isProjectDeleted = createSelector(
    projectState,
    (state) => state.isProjectDeleted,
  );

  export const hasLoadingError = createSelector(
    projectState,
    (state) => state.hasLoadingError,
  );

  export const exportUrl = createSelector(
    projectState,
    (state) => state.exportUrl,
  );

  export const hasExportError = createSelector(
    projectState,
    (state) => state.hasExportError,
  );
}
