import { Pipe, PipeTransform } from '@angular/core';
import { CartItemCompareCartItemType } from '@sales-libs/project/data-access';

@Pipe({
  name: 'slProjectCartItemTypeTranslationKey',
  standalone: false,
})
export class SlProjectCartItemTypePipe implements PipeTransform {
  readonly cartItemTranslationKeyMapping = {
    [CartItemCompareCartItemType.Accessory]:
      'cart_compare.cart_item_type.accessory',
    [CartItemCompareCartItemType.Crane]: 'cart_compare.cart_item_type.crane',
    [CartItemCompareCartItemType.Custom]: 'cart_compare.cart_item_type.custom',
    [CartItemCompareCartItemType.TuningCenter]:
      'cart_compare.cart_item_type.tuningCenter',
    [CartItemCompareCartItemType.WarrantyExtension]:
      'cart_compare.cart_item_type.warrantyExtension',
    [CartItemCompareCartItemType.Option]: 'cart_compare.cart_item_type.option',
    [CartItemCompareCartItemType.Contract]:
      'cart_compare.cart_item_type.contract',
    [CartItemCompareCartItemType.Package]:
      'cart_compare.cart_item_type.package',
    [CartItemCompareCartItemType.MCC]: 'cart_compare.cart_item_type.mcc',
    [CartItemCompareCartItemType.ConfigurationOption]:
      'cart_compare.cart_item_type.option',
  };

  transform(state: any /* CartItemCompareCartItemType*/): string {
    return this.cartItemTranslationKeyMapping[state];
  }
}
