<tr
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  [gaClickListener]="{
    category: 'Cart',
    action: 'ProductItemListClick',
    label: 'Result'
  }"
  class="mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  (delete)="deleteItem($event)"
  (toOptional)="onToOptional($event)"
  (configure)="onConfigure($event)"
  (quantityChange)="onQuantityChange($event)"
  (showConfiguration)="onShowConfiguration($event, isPricingVisible)"
  [ngClass]="{
    'success-ribbon': item.is_configurable && item.is_valid === true,
    'error-ribbon': item.is_configurable && item.is_valid === false
  }"
></tr>
