import { CartItemsService } from '@sales-libs/project/data-access';
import { catchError, map } from 'rxjs';

import { SlProjectDataCreationSolutionItem } from '../types';
import { SlProjectCartItemCreationRow } from './cart-item-creation-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectSolutionItemCreationRow extends SlProjectCartItemCreationRow {
  constructor(
    private _solutionCartItem: SlProjectDataCreationSolutionItem,
    private _cartItemsService: CartItemsService,
  ) {
    super(
      _solutionCartItem.name ??
        _solutionCartItem.short_description ??
        _solutionCartItem.long_description ??
        '',
    );
  }

  protected _creationFunction = () =>
    this._cartItemsService
      .addSolutionItemAsync({
        ...this._solutionCartItem,
        cart_id: this.cartId,
        cart_item_group_id: this.cartItemGroupId,
      })
      .pipe(
        map((data) => ({
          result: {
            data,
            calculationItemReferenceId:
              this._solutionCartItem.calculationItemReferenceId,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
