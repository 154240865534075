import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SlCartItem } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sl-project-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  standalone: false,
})
export class SlProjectQuantityInputComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() item: SlCartItem;

  @Output() quantityChange = new EventEmitter<SlCartItem>();

  control: FormControl<number | null>;
  private readonly _destroy$ = new Subject<void>();

  ngOnInit() {
    this.control = new FormControl<number | null>({
      value: this.item?.quantity,
      disabled: this.disabled,
      /* TODO check if this is not needed anymore ||
        !(
          this.item?.actions &&
          this.item.actions.includes(CartItemActions.ChangeQuantity)
        ),*/
    });

    this.control.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filterTruthy(),
        takeUntil(this._destroy$),
      )
      .subscribe((quantity) =>
        this.quantityChange.emit({ ...this.item, quantity }),
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
