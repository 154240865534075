import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsImage, DsImagesModule } from '@design-system/components/images';
import { OfferScopeItem } from '@sales-libs/project/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';

@Component({
  selector: 'sl-project-digital-offer-specifications-section',
  imports: [
    CommonModule,
    GoogleAnalytics4DirectiveModule,
    DsImagesModule,
    DsSpacingModule,
    MatCardModule,
    FlexLayoutModule,
  ],
  templateUrl: './digital-offer-specifications-section.component.html',
  styleUrls: ['./digital-offer-specifications-section.component.scss'],
})
export class SlProjectDigitalOfferSpecificationsSectionComponent {
  @Input() headline: string;
  // we use the id on the element at the start of the component
  // so that scrolling will allways end at the top of the list
  @Input() scrollId: string;
  @Input() set items(value: OfferScopeItem[]) {
    this.itemWithImages = value.map((item) => ({
      ...item,
      image: item.picture_url
        ? {
            src: item.picture_url.replace('_md', '_xl'),
            thumbnail: item.picture_url.replace('_md', '_sm'),
            text: item.name ?? '',
            alt: item.name,
          }
        : undefined,
    }));
  }
  itemWithImages: (OfferScopeItem & { image: DsImage | undefined })[] = [];
}
