import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ContractItem,
  CustomArticleItem,
  MccItem,
  ProductItem,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

type CustomPriceItem =
  | ContractItem
  | CustomArticleItem
  | MccItem
  | SalesOptionCartItem
  | TuningCenterItem
  | WarrantyExtensionItem;

@Component({
  selector: 'sl-project-optional-actions-column',
  templateUrl: './optional-actions-column.component.html',
  styleUrls: ['./optional-actions-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectOptionalActionsColumnComponent {
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsProductItem = value as ProductItem;
    this.itemAsCustomPriceItem = value as CustomPriceItem;
  }
  @Input() isReadOnly: boolean;

  @Output() deleteClick = new EventEmitter<SlCartItem>();
  @Output() configureClick = new EventEmitter<SlCartItem>();
  @Output() backToCartClick = new EventEmitter<SlCartItem>();
  @Output() editTuningClick = new EventEmitter();
  @Output() editMccClick = new EventEmitter();
  @Output() editArticleClick = new EventEmitter<SlCartItem>();
  @Output() priceDifferenceToClick = new EventEmitter<SlCartItem>();
  @Output() showConfigurationClick = new EventEmitter<SlCartItem>();
  @Output() restoreOriginalPriceClick = new EventEmitter<SlCartItem>();
  @Output() changeGroupClick = new EventEmitter<SlCartItem>();
  @Output() removeFromGroupClick = new EventEmitter<SlCartItem>();

  cartItem: SlCartItem;
  itemAsProductItem: ProductItem;
  itemAsCustomPriceItem: CustomPriceItem;
}
