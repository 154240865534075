<ng-container matColumnDef="code" sticky>
  <th
    mat-header-cell
    *matHeaderCellDef
    id="code"
    data-test="lost-order-code-header"
  >
    {{ 'table_columns.code' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'CodeClick'
    }"
    data-test="lost-order-code"
  >
    {{ lostOrder.code }}
  </td>
</ng-container>
