<ng-container
  [matColumnDef]="perspective + 'PurchasePrice'"
  gaCategoryGroup="Cart"
  gaLabelGroup="CalculationPurchasePriceColumn"
>
  <th mat-header-cell *matHeaderCellDef id="purchase_price">
    {{ 'calculation.price' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let item"
    class="text-right {{ perspective }}-purchase-price-cell"
    data-test="purchase-price"
    [gaClickListener]="perspective + 'PurchasePricelick'"
  >
    <ng-container
      *ngIf="
        item.item_type === itemTypes.Item || item.item_type === itemTypes.Group
      "
    >
      {{
        item[perspective].purchase_price
          | slMoneyAmount$
            : {
                display: 'symbol',
                roundingDigits: (item | isUserPriceInputItem) ? 2 : undefined,
                quantity: item.quantity
              }
          | async
      }}
    </ng-container>
    <small
      *ngIf="
        item.item_type === itemTypes.Option ||
        item.item_type === itemTypes.GroupItem
      "
    >
      {{
        item[perspective].purchase_price
          | slMoneyAmount$
            : {
                display: 'symbol',
                roundingDigits: (item | isUserPriceInputItem) ? 2 : undefined,
                quantity: item.quantity
              }
          | async
      }}
    </small>
  </td>
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right"
    [gaClickListener]="perspective + 'PurchasePricelick'"
  >
    {{
      subTotal
        | slMoneyAmount$: { display: 'symbol', ratio: 1, roundingDigits: 2 }
        | async
    }}
  </td>
</ng-container>
