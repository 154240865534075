import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace DocumentsSelectors {
  const documentsState = createSelector(
    moduleState,
    (state) => state.documents,
  );

  export const filterState = createSelector(
    documentsState,
    (state) => state.filter,
  );
  export const loading = createSelector(
    documentsState,
    (state) => state.loading,
  );
  export const projectId = createSelector(
    documentsState,
    (state) => state.projectId,
  );
  export const list = createSelector(
    documentsState,
    filterState,
    (state, filter) =>
      state.list &&
      state.list.filter(
        (item) => item.file_name && item.file_name.includes(filter.term),
      ),
  );
  export const count = createSelector(
    documentsState,
    (state) => (state.list && state.list.length) || 0,
  );
  export const authorization = createSelector(
    documentsState,
    (state) => state.authorization,
  );
}
