<ng-container [matColumnDef]="perspective + 'Discount'">
  <th mat-header-cell *matHeaderCellDef id="discount_column">
    {{ 'calculation.discount' | translate }}
  </th>
  <td mat-cell *matCellDef="let item" class="text-right">
    <sl-project-discount-column-input
      *ngIf="
        item.item_type === itemTypes.Item ||
        item.item_type === itemTypes.GroupItem
      "
      [parentForm]="parentForm"
      [item]="item"
      [perspective]="perspective"
      [percentSymbol]="percentSymbol"
      [locale]="locale"
      [isAlternativeView]="isAlternativeView"
      [calculation]="calculation"
      (calculationChange)="calculationChange.emit($event)"
    ></sl-project-discount-column-input>
    <span *ngIf="item.item_type === itemTypes.Group">
      {{ item[perspective].discount | percent: '1.2-2' : locale }}
    </span>
    <small *ngIf="item.item_type === itemTypes.Option">
      {{ item[perspective].discount | percent: '1.2-2' : locale }}
    </small>
  </td>
  <td mat-footer-cell *matFooterCellDef></td>
</ng-container>
