<mat-card gaLabelGroup="BestInvest" gaCategoryGroup="BestInvestFeaturesData">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <mat-card-title>{{
      'benefits.soft_facts.title' | translate
    }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="features-container">
    <ds-table>
      <table class="pd-table" data-test="features-table">
        <thead>
          <tr>
            <th>
              {{ 'benefits.soft_facts.name' | translate }}
            </th>
            <th>
              {{ 'benefits.soft_facts.value' | translate }}
            </th>
            <th class="text-center">
              {{ 'benefits.soft_facts.more_info' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let feature of features">
            <td>{{ feature.name }}</td>
            <td>{{ feature.value | currency: currencyCode : 'symbol' }}</td>
            <td>
              <div class="text-center">
                <button
                  mat-icon-button
                  *ngIf="feature.description || feature.assumptions"
                  class="more-info-icon"
                  (click)="openMoreDetailsDialog(feature.id)"
                  data-test="more-info-btn"
                  gaClickListener="OpenMoreDetailsDialogInfoIconClick"
                >
                  <mat-icon>info</mat-icon>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ds-table>
  </mat-card-content>
</mat-card>
