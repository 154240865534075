import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project, ProjectState } from '@sales-libs/project/data-access';

@Component({
  selector: 'sl-project-checkout-without-order',
  templateUrl: './checkout-without-order.component.html',
  styleUrls: ['./checkout-without-order.component.scss'],
  standalone: false,
})
export class SlProjectCheckoutWithoutOrderComponent {
  @Input() project: Project;
  @Output() setProjectToDone = new EventEmitter();
  projectStates = ProjectState;
}
