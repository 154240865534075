import { Injectable, inject } from '@angular/core';
import { RootActions } from '@features/root-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DocumentsActionTypes, DocumentsActions } from './documents.actions';
import { DocumentsSelectors } from './documents.selectors';

@Injectable()
export class DocumentsEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store<any> = inject(Store);

  triggerRefreshDocuments$ = createEffect(() =>
    this._actions.pipe(
      ofType(
        DocumentsActionTypes.LoadDocuments,
        DocumentsActionTypes.UploadDocumentSuccess,
        DocumentsActionTypes.DeleteDocumentSuccess,
      ),
      map(() => DocumentsActions.RefreshDocuments()),
    ),
  );

  refreshDocuments$ = createEffect(() =>
    this._actions.pipe(
      ofType(DocumentsActions.RefreshDocuments),
      withLatestFrom(
        this._store
          .select(DocumentsSelectors.projectId)
          .pipe(
            filter((projectId): projectId is number => projectId !== undefined),
          ),
      ),
      switchMap(([, projectId]) =>
        this._projectService.getProjectDocumentsByIdAsync(projectId).pipe(
          map((data) =>
            DocumentsActions.LoadDocumentsSuccess({ payload: data }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.documents.not_loaded',
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  uploadDocument$ = createEffect(() =>
    this._actions.pipe(
      ofType(DocumentsActions.UploadDocument),
      map((action) => action),
      tap(() => this._store.dispatch(RootActions.ShowLoadingSpinner())),
      withLatestFrom(
        this._store
          .select(DocumentsSelectors.projectId)
          .pipe(
            filter((projectId): projectId is number => projectId !== undefined),
          ),
      ),
      mergeMap(([action, projectId]) =>
        this._projectService
          .uploadProjectDocumentAsync(projectId, action.payload)
          .pipe(
            map(() => DocumentsActions.UploadDocumentSuccess()),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.documents.not_created',
                ),
              }),
            ]),
          ),
      ),
      tap(() => this._store.dispatch(RootActions.HideLoadingSpinner())),
    ),
  );

  deleteDocument$ = createEffect(() =>
    this._actions.pipe(
      ofType(DocumentsActions.DeleteDocument),
      map((action) => action),
      tap(() => this._store.dispatch(RootActions.ShowLoadingSpinner())),
      mergeMap((action) =>
        this._projectService.deleteProjectDocumentAsync(action.payload).pipe(
          map(() => DocumentsActions.DeleteDocumentSuccess()),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.documents.not_deleted',
              ),
            }),
          ]),
        ),
      ),
      tap(() => this._store.dispatch(RootActions.HideLoadingSpinner())),
    ),
  );

  constructor(
    private readonly _projectService: ProjectsService,
    private readonly _translateService: TranslateService,
  ) {}
}
