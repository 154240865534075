// tslint:disable:no-console
import { Injectable, inject } from '@angular/core';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CalculationsService,
  OffersService,
} from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import {
  catchError,
  combineLatestWith,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { CartSelectors } from '../../store';
import { CalculationMapping } from '../shared/calculation-mapping';
import { CalculationUtils } from '../shared/calculation-utils';
import { CalculationActions, CalculationSelectors } from '../store';

@Injectable()
export class CalculationEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store<any> = inject(Store);

  loadCalculation$ = createEffect(() =>
    this._actions.pipe(
      ofType(CalculationActions.LoadCalculation),
      switchMap((action) =>
        this._calculationsService.getCalculation(action.payload).pipe(
          map((data) =>
            CalculationActions.LoadCalculationSuccess({ payload: data }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.calculation.not_loaded',
              ),
            }),
            CalculationActions.LoadCalculationError(),
          ]),
        ),
      ),
    ),
  );

  updateCalculation$ = createEffect(() =>
    this._actions.pipe(
      ofType(CalculationActions.UpdateCalculation),
      withLatestFrom(this._store.select(CartSelectors.id).pipe(filterTruthy())),
      mergeMap(([action, cartId]) => {
        const updated = action.payload;
        return this._calculationsService
          .updateCalculation(cartId, updated)
          .pipe(
            map(() => CalculationActions.UpdateCalculationSuccess()),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.calculation.not_updated',
                ),
              }),
              CalculationActions.UpdateCalculationError(),
            ]),
          );
      }),
    ),
  );

  getCalculationExport$ = createEffect(() =>
    this._actions.pipe(
      ofType(CalculationActions.GetExportUrl),
      combineLatestWith(
        this._store.select(CartSelectors.current).pipe(filterTruthy()),
        this._store
          .select(CartSelectors.optionalCartItemsGrouped)
          .pipe(filterTruthy()),
        this._store
          .select(CalculationSelectors.calculation)
          .pipe(filterTruthy()),
        this._store.select(CalculationSelectors.perspectives),
        this._store.select(CartSelectors.currency).pipe(filterTruthy()),
      ),
      filter(([, cartId]) => cartId !== null),
      mergeMap(
        ([
          ,
          cart,
          optionalCartItemsGrouped,
          calculation,
          perspectives,
          currency,
        ]) => {
          const clonedCalculation = structuredClone(calculation);
          const transformedCalculation = CalculationMapping.transform(
            clonedCalculation,
            cart,
            currency,
            optionalCartItemsGrouped,
            this._userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_PURCHASINGP),
          );
          const calculated = CalculationUtils.calculate(
            transformedCalculation,
            currency.ratio,
            currency.rounding_digits,
          );
          // TODO improve calculation with ratio
          ['customer', 'partner', 'general_agent'].forEach((perspective) => {
            if (calculated[perspective]) {
              calculated[perspective] = {
                ...calculated[perspective],
                absolute_discount:
                  calculated[perspective].absolute_discount * currency.ratio,
              };
              calculated[perspective] = {
                ...calculated[perspective],
                transport_costs:
                  calculated[perspective].transport_costs * currency.ratio,
              };
            }
          });

          return this._calculationsService
            .getCalculationExcel(
              cart.id,
              calculated,
              perspectives.indexOf('partner') > -1,
              perspectives.indexOf('general_agent') > -1,
              'body',
              false,
            )
            .pipe(
              map((data) =>
                CalculationActions.GetExportUrlSuccess({ payload: data }),
              ),
              catchError((err) => [
                SnackbarActions.ShowError({
                  error: err,
                  message: this._translateService.instant(
                    'error_messages.calculation.not_exported',
                  ),
                }),
                CalculationActions.GetExportUrlError(),
              ]),
            );
        },
      ),
    ),
  );

  getOfferUrl$ = createEffect(() =>
    this._actions.pipe(
      ofType(CalculationActions.GetOfferUrl),
      withLatestFrom(this._store.select(CartSelectors.id)),
      filter(([, cartId]) => cartId !== null),
      mergeMap(([, cartId]) =>
        this._offerService.getOfferDocumentLink(cartId || 0).pipe(
          map((url) =>
            CalculationActions.GetOfferUrlSuccess({
              payload: url,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'common.document_download.error',
              ),
            }),
            CalculationActions.GetOfferUrlError(),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly _calculationsService: CalculationsService,
    private readonly _offerService: OffersService,
    private readonly _translateService: TranslateService,
    private readonly _userService: UserService,
  ) {}
}
