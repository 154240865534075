<ng-container gaLabelGroup="EditCustomCartItem" gaCategoryGroup="Projects">
  <h3 matDialogTitle data-test="title" gaClickListener="TitleClick">
    {{ 'edit_custom_articles.article' | translate }}
  </h3>
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit(ratio)"
    *ngIf="ratio$ | async as ratio"
  >
    <mat-dialog-content *ngIf="item" fxLayout="column" fxLayoutGap="0.5rem">
      <mat-form-field class="full-width" gaClickListener="CodeClick">
        <mat-label>{{ 'edit_custom_articles.code' | translate }}</mat-label>
        <input
          data-test="custom-article-item-name-mat-input"
          matInput
          type="text"
          [formControl]="form.controls.name"
          required
          autocomplete="off"
        />
        <mat-error
          data-test="name-error-message"
          *ngIf="(form.controls.name.value?.length ?? 0) > 100"
          >{{
            'form_char_restriction'
              | translate
                : {
                    limit: 100,
                    count: (form.controls.name.value?.length ?? 0) - 100
                  }
          }}</mat-error
        >
        <mat-error
          data-test="name-not-empty-error-message"
          *ngIf="
            form.controls.name.invalid &&
            (form.controls.name.value?.length ?? 0) < 101
          "
        >
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="full-width"
        gaClickListener="ShortDescriptionClick"
      >
        <mat-label>{{
          'edit_custom_articles.short_description' | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="form.controls.short_description"
          data-test="custom-article-item-short-description-mat-input"
          required
          autocomplete="off"
        />
        <mat-error
          data-test="short-description-error-message"
          *ngIf="(form.controls.short_description.value?.length ?? 0) > 100"
          >{{
            'form_char_restriction'
              | translate
                : {
                    limit: 100,
                    count:
                      (form.controls.short_description.value?.length ?? 0) - 100
                  }
          }}</mat-error
        >
        <mat-error
          data-test="short-description-not-empty-error-message"
          *ngIf="
            form.controls.short_description.invalid &&
            (form.controls.short_description.value?.length ?? 0) < 101
          "
        >
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
        <mat-hint align="end"
          >{{ form.controls.short_description.value?.length ?? 0 }} /
          100</mat-hint
        >
      </mat-form-field>
      <div fxLayout fxLayoutGap="1rem" fxLayout.xs="column">
        <mat-form-field
          class="full-width"
          gaClickListener="RetailPriceClick"
          *pdLet="form.controls.sales_price as c"
        >
          <mat-label>{{
            'edit_custom_articles.retail_price' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="c"
            required
            autocomplete="off"
            data-test="retail-price"
            [max]="maxPrice"
          />
          <mat-error *ngIf="c.hasError('required')">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
          <mat-error *ngIf="c.hasError('max')">
            {{ 'common.max' | translate: { max: maxPrice } }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="PurchasingPriceClick"
          *pdLet="form.controls.purchase_price as c"
        >
          <mat-label>{{
            'edit_custom_articles.purchasing_price' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            [formControl]="c"
            required
            autocomplete="off"
            data-test="purchasing-price"
            [max]="maxPrice"
          />
          <mat-error *ngIf="c.hasError('required')">
            {{ 'common.not_empty_hint' | translate }}
          </mat-error>
          <mat-error *ngIf="c.hasError('max')">
            {{ 'common.max' | translate: { max: maxPrice } }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field
        fxFlex="50%"
        fxFlex.xs="100%"
        gaClickListener="QuantityClick"
      >
        <mat-label>{{ 'project.quantity' | translate }}</mat-label>
        <input
          matInput
          type="number"
          [min]="1"
          [formControl]="form.controls.quantity"
          required
          data-test="quantity"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls.quantity.invalid">
          {{ 'common.not_empty_hint' | translate }}
        </mat-error>
      </mat-form-field>
      <div
        fxLayout
        fxLayout.xs="column"
        [fxLayoutGap]="isFeatureFlagPpiSet ? '1rem' : '0'"
      >
        <div [fxFlex]="isFeatureFlagPpiSet ? '50%' : '100%'" fxFlex.xs="100%">
          <mat-checkbox
            [formControl]="form.controls.is_discountable"
            gaClickListener="DiscountableClick"
            data-test="discountable-checkbox"
            >{{ 'edit_custom_articles.discountable' | translate }}</mat-checkbox
          >
        </div>
        <div *ngIf="isFeatureFlagPpiSet" fxFlex="50%" fxFlex.xs="100%">
          <mat-checkbox
            [formControl]="form.controls.is_ppi_relevant"
            gaClickListener="PPIRelevantClick"
            data-test="ppi-checkbox"
            >{{
              'edit_custom_articles.is_ppi_relevant' | translate
            }}</mat-checkbox
          >
        </div>
      </div>
      <br />
      <h6>{{ 'edit_custom_articles.long_description' | translate }}</h6>
      <editor
        [init]="tinymceConfig"
        [(ngModel)]="longDescription.editorValue"
        (onInit)="setInitalEditorValue()"
        [ngModelOptions]="{ standalone: true }"
        gaClickListener="LongDescriptionClick"
      ></editor>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        matDialogClose
        gaClickListener="CancelClick"
        data-test="cancel-button"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        data-test="save-button"
        [disabled]="!form.valid"
        [gaClickListener]="item.id ? 'SaveClick' : 'AddToCartClick'"
      >
        <ng-container *ngIf="!item.id">
          {{ 'project.add_to_cart' | translate }}
        </ng-container>
        <ng-container *ngIf="item.id">
          {{ 'general.save' | translate }}
        </ng-container>
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
