import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Cart, ProductItem } from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable } from 'rxjs';
import { SlProjectCartCompareComponent } from '../cart-compare/cart-compare.component';

@Component({
  selector: 'sl-project-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss'],
  standalone: false,
})
export class SlProjectProductPreviewComponent {
  @Input()
  cart: Cart;
  @Input()
  showPrice$: Observable<boolean>;
  @Input()
  comment: FormControl;
  @Input() set firstItem(value: SlCartItem | null) {
    this._firstItem = value;
    this.firstItemAsProductItem = value as ProductItem;
  }
  get firstItem(): SlCartItem | null {
    return this._firstItem;
  }

  @Output()
  readonly toggleShowPrice = new EventEmitter<never>();

  firstItemAsProductItem: ProductItem | null;
  private _firstItem: SlCartItem | null;

  constructor(private _dialog: MatDialog) {}

  togglePrice() {
    this.toggleShowPrice.emit();
  }

  openCartCompare() {
    /* TODO rework comparison
    this._store.dispatch(
      CartActions.LoadCartCompare({ payload: this.cart.id }),
    );*/
    this._dialog.open(SlProjectCartCompareComponent, {
      data: this.cart.id,
    });
  }
}
