<ng-container matColumnDef="actions" stickyEnd>
  <th mat-header-cell *matHeaderCellDef id="actions">
    <div class="spotlight-helper" [spotlight]="projectSettingsSpotlight">
      <ds-table-settings
        [defaultSettings]="displayColumns"
        [settingsKey]="'CPQ.projects.table_settings'"
        (userSettings)="setUserSettings.emit($event)"
      >
      </ds-table-settings>
    </div>

    <ds-spotlight
      id="sl-project-spotlight-table-custom-settings"
      [tags]="['table-settings', '2021.07']"
      #projectSettingsSpotlight
    >
      {{ 'spotlight.release_2021_07.custom_tables' | translate }}
    </ds-spotlight>
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    class="mat-action-cell"
    gaLabelGroup="ProjectListActions"
    gaCategoryGroup="ProjectOverview"
  >
    <button
      mat-button
      (click)="selectClick.emit(project)"
      [spotlight]="projectSelectSpotlight"
      data-test="select-btn"
      gaClickListener="SelectClick"
    >
      {{ 'general.select' | translate }}
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="appMenu"
      data-test="more-btn"
      gaClickListener="MoreClick"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu
      #appMenu="matMenu"
      data-test="more-menu"
      gaCategoryGroup="ProjectOverview"
      gaLabelGroup="ProjectListActionsMatMenu"
    >
      <div
        [matTooltip]="
          !project.actions.includes(projectActions.Delete)
            ? ('project.disable_delete_reason' | translate)
            : null
        "
      >
        <button
          mat-menu-item
          (click)="deleteClick.emit(project)"
          [disabled]="
            project.state !== projectState.InProgress || project.has_offer
          "
          data-test="delete-btn"
          gaClickListener="DeleteClick"
        >
          <mat-icon>delete</mat-icon>
          <span>{{ 'common.delete' | translate }}</span>
        </button>
      </div>
      <div>
        <button
          mat-menu-item
          [disabled]="
            project.state !== projectState.InProgress &&
            project.state !== projectState.Lost
          "
          (click)="editClick.emit(project)"
          data-test="edit-btn"
          gaClickListener="EditClick"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'common.edit' | translate }}</span>
        </button>
      </div>
    </mat-menu>
  </td>
</ng-container>

<ds-spotlight
  id="sl-project-spotlight-project-overview-select"
  #projectSelectSpotlight
  position="left"
>
  {{ 'spotlight.release_2020_05.project_overview.select' | translate }}
</ds-spotlight>
