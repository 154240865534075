import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { matFormFieldAnimations } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { CrmAccountResponse } from '@data-access/bpm-generated';
import { dsConfig } from '@design-system/cdk/config';
import { Store } from '@ngrx/store';
import {
  LostOrder,
  Project,
  ProjectInput,
  ProjectInputState,
  ProjectState,
} from '@sales-libs/project/data-access';

import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  SlProjectEditLostOrderComponent,
  SlProjectEditLostOrderDialogData,
} from '../edit-lost-order/edit-lost-order.component';
import { LostOrderActions, LostOrderSelectors } from '../lost-order/store';

export const SL_PROJECT_EDIT_DIALOG_DEFAULT_WIDTH =
  35 * dsConfig.spacing + 'px';

export interface SlProjectEditDialogData {
  project?: Project; // only on edit
}

@Component({
  selector: 'sl-project-edit',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  standalone: false,
})
export class SlProjectEditComponent {
  customer: CrmAccountResponse;
  project: Project | undefined;

  readonly form: FormGroup<EditProjectForm>;
  readonly states = ProjectState;
  readonly maxNameLength = 150;
  readonly maxRequirementLength = 5000;

  constructor(
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _selfRef: MatDialogRef<SlProjectEditComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: SlProjectEditDialogData | undefined,
  ) {
    this.project = this._data?.project;

    this.form = new FormGroup<EditProjectForm>({
      name: new FormControl<string | null>(null, Validators.required),
      comment: new FormControl<string | null | undefined>(null),
      chance: new FormControl<number>(0, { nonNullable: true }),
      closed_date: new FormControl<Date | null | undefined>(null),
      is_whole_sale_order: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
      customer: new FormControl(undefined, { nonNullable: true }),
    });

    if (this.project) {
      this.form.patchValue(this.project);

      if (
        this.project.customer_number ||
        this.project.external_customer_number
      ) {
        this.form.controls.customer.setValue({
          account_id: this.project.external_customer_number,
          customer_number: this.project.customer_number,
          company_name: this.project.customer_name,
        } as CrmAccountResponse);
      }
    }
  }

  confirmChange() {
    const customer = this.form.controls.customer.value as CrmAccountResponse;
    const project: ProjectInput = {
      ...(this.project || {}),
      ...this.form.getRawValue(),
      name: this.form.controls.name.value as string,
      state: this.project?.state
        ? ProjectInputState[this.project.state]
        : undefined,
      customer_number: this.form.controls.is_whole_sale_order
        ? undefined
        : customer?.customer_number || '',
      external_customer_number: this.form.controls.is_whole_sale_order
        ? undefined
        : customer?.account_id || '',
      customer_name: this.form.controls.is_whole_sale_order
        ? undefined
        : customer?.company_name || '',
    };
    if (!project.is_whole_sale_order) {
      project.customer_number = customer?.customer_number || '';
      project.external_customer_number = customer?.account_id || '';
      project.customer_name = customer?.company_name || '';
    }

    // we map the form customer object to the project customer_number, external_customer_number and customer_name
    // the customer object is not part of the project object
    delete project['customer'];

    this._selfRef.close(project as ProjectInput);
  }

  createLostOrder(project: Project) {
    this.openLostOrder(project, of({} as LostOrder));
  }

  openLostOrder(project: Project, lostOrder$: Observable<LostOrder | null>) {
    this._store.dispatch(LostOrderActions.LoadReasons());
    this._store.dispatch(LostOrderActions.LoadCompetitors());
    if (project.state === this.states.Lost) {
      this._store.dispatch(
        LostOrderActions.LoadLostOrder({ projectId: project.id }),
      );
    }

    const config = new MatDialogConfig<SlProjectEditLostOrderDialogData>();
    config.data = {
      lostOrder$: lostOrder$,
      project: project,
      competitors$: this._store.select(LostOrderSelectors.competitors),
      reasons$: this._store.select(LostOrderSelectors.reasons),
    };
    const dialogRef = this._dialog.open(
      SlProjectEditLostOrderComponent,
      config,
    );
    dialogRef.componentInstance.confirm
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe((data) => {
        if (data.id && data.id !== 0) {
          this._store.dispatch(
            LostOrderActions.UpdateLostOrder({ payload: data }),
          );
        } else {
          this._store.dispatch(
            LostOrderActions.AddLostOrder({ payload: data }),
          );
        }
        dialogRef.close();
        this._selfRef.close();
      });
  }

  deleteLostOrder(project: Project) {
    this._store.dispatch(
      LostOrderActions.DeleteLostOrder({ projectId: project.id }),
    );
  }

  editOrder(project: Project) {
    if (project.state === this.states.Lost) {
      this._store.dispatch(
        LostOrderActions.LoadLostOrder({ projectId: project.id }),
      );
      this.openLostOrder(
        project,
        this._store.select(LostOrderSelectors.lostOrder),
      );
    } else if (project.state === this.states.Won) {
      this._selfRef.close();
      this._router.navigate(['orders', 'byproject', project.id]);
    }
  }
}

interface EditProjectForm {
  name: FormControl<string | null>;
  comment: FormControl<string | null | undefined>;
  chance: FormControl<number>;
  closed_date: FormControl<Date | null | undefined>;
  is_whole_sale_order: FormControl<boolean>;
  customer: FormControl<CrmAccountResponse | undefined>;
}
