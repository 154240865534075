import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ContractItem,
  CustomArticleItem,
  MccItem,
  ProductItem,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';

type EditableItem =
  | ContractItem
  | CustomArticleItem
  | MccItem
  | SalesOptionCartItem
  | TuningCenterItem
  | WarrantyExtensionItem;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectCartItemListRow]',
  templateUrl: './cart-item-row.component.html',
  styleUrls: ['./cart-item-row.component.scss'],
  standalone: false,
})
export class SlProjectCartItemRowComponent {
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsProductItem = value as ProductItem;
    this.editableItem = value as EditableItem;
  }
  @Input() isPricingVisible = true;
  @Input() isSelectOnly: boolean;
  @Input() isAddSupported = false;
  @Input() isTypeVisible = false;
  @Input() isReadOnly = false;
  @Input() isSortingSupported = false;
  @Input() isItemWithCustomerPriceInput = false;

  @Output() selectItem = new EventEmitter<SlCartItem>();
  @Output() delete = new EventEmitter<SlCartItem>();
  @Output() configure = new EventEmitter<SlCartItem>();
  @Output() toOptional = new EventEmitter<SlCartItem>();
  @Output() edit = new EventEmitter<SlCartItem>();
  @Output() showConfiguration = new EventEmitter<SlCartItem>();
  @Output() quantityChange = new EventEmitter<SlCartItem>();
  @Output() backToCart = new EventEmitter<SlCartItem>();
  @Output() editTuning = new EventEmitter();
  @Output() editMcc = new EventEmitter();
  @Output() editArticle = new EventEmitter<SlCartItem>();
  @Output() priceDifferenceTo = new EventEmitter<SlCartItem>();
  @Output() restoreOriginalPrice = new EventEmitter<SlCartItem>();
  @Output() changeGroup = new EventEmitter<SlCartItem>();
  @Output() removeFromGroup = new EventEmitter<SlCartItem>();

  cartItem: SlCartItem;
  editableItem: EditableItem;
  itemAsProductItem: ProductItem;
}
