<ng-container matColumnDef="created_date">
  <th mat-header-cell mat-sort-header *matHeaderCellDef id="created_date">
    {{ 'table_columns.created_date' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let project"
    [gaClickListener]="{
      category: 'ProjectOverview',
      label: 'ProjectList',
      action: 'CreatedOnClick'
    }"
    data-test="project-created-date-cell"
  >
    {{ project.created_on | date: 'shortDate' }}
  </td>
</ng-container>
