import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BASE_PATH, ProjectDocument } from '@sales-libs/project/data-access';
import { PALFINGER_API_MANAGEMENT_SUBS_KEY } from '@sales-libs/shared/util';
import { ConfirmDeleteDialogComponent } from '@ui-kit/modals';
import { nameofFactory } from '@utils/name-of';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ProjectSelectors } from '../store';
import { DocumentsActions, DocumentsSelectors } from './store';

const nameofDocument = nameofFactory<ProjectDocument>();

@Component({
  selector: 'sl-project-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  standalone: false,
})
export class SlProjectDocumentListComponent implements OnInit, OnDestroy {
  readonly isLoaded$: Observable<boolean>;
  readonly documents$: Observable<ProjectDocument[] | undefined>;
  readonly filterTerm$: Observable<string>;
  readonly displayedColumns = [
    nameofDocument('file_name'),
    nameofDocument('created_by'),
    nameofDocument('created_on'),
    nameofDocument('file_size'),
    'actions',
  ];
  readonly documentsFilterForm: FormGroup;

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _store: Store<any>,
    private readonly _dialog: MatDialog,
    @Inject(BASE_PATH) private _basePath: string,
    @Inject(PALFINGER_API_MANAGEMENT_SUBS_KEY) private _subscriptionKey: string,
  ) {
    this.isLoaded$ = this._store
      .select(DocumentsSelectors.loading)
      .pipe(map((loading) => !loading));
    this.documents$ = this._store.select(DocumentsSelectors.list);
    this.documentsFilterForm = new FormGroup({
      term: new FormControl<string>('', { nonNullable: true }),
    });
    this.filterTerm$ = this._store
      .select(DocumentsSelectors.filterState)
      .pipe(map((value) => value.term));
  }

  ngOnInit() {
    this._store
      .select(ProjectSelectors.id)
      .pipe(
        filter((id): id is number => id != null),
        takeUntil(this._destroy$),
      )
      .subscribe((projectId) =>
        this._store.dispatch(
          DocumentsActions.LoadDocuments({ payload: projectId }),
        ),
      );

    this._store
      .select(DocumentsSelectors.filterState)
      .pipe(takeUntil(this._destroy$))
      .subscribe((newValue) =>
        this.documentsFilterForm.patchValue(newValue, { emitEvent: false }),
      );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onFilterChange(event: string): void {
    this._store.dispatch(
      DocumentsActions.ChangeFilter({ payload: { term: event } }),
    );
  }

  onFileSelect(event: File): void {
    this._store.dispatch(DocumentsActions.UploadDocument({ payload: event }));
  }

  deleteDocument(document: ProjectDocument): void {
    const config = new MatDialogConfig();

    const dialogRef = this._dialog.open(ConfirmDeleteDialogComponent, config);
    dialogRef.componentInstance.key = '' + document.file_name;
    dialogRef.componentInstance.confirm
      .pipe(
        tap(() => dialogRef.close()),
        filter((isConfirmed) => isConfirmed),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe(() =>
        this._store.dispatch(
          DocumentsActions.DeleteDocument({ payload: document.id }),
        ),
      );
  }
}
