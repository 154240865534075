<form
  [formGroup]="extendedBestInvestForm"
  fxFlex
  fxLayout="column wrap"
  class="input-data-form"
  gaLabelGroup="ExtendedBestInvestForm"
  gaCategoryGroup="ExtendedBestInvestData"
>
  <h3 class="input-data-form__title">
    {{ 'benefits.extended_data_input.title' | translate }}
  </h3>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-button
      class="input-data-form__refresh-btn"
      (click)="resetToGlobalValues()"
      data-test="reset-to-global-values"
      gaClickListener="ResetToGlobalSettingsClick"
      [disabled]="isReadOnly"
    >
      <mat-icon inline title="">refresh_outline</mat-icon>
      {{ 'benefits.extended_data_input.reset_values' | translate }}
    </button>
  </div>
  <sl-settings-currency-dependend-page (currencyLoad)="loadCurrency($event)">
    <mat-card class="input-data-card">
      <mat-card-content>
        <h4 class="no-margin-top">
          {{ 'benefits.extended_data_input.costs_and_prices' | translate }}
        </h4>
        <mat-form-field class="full-width" gaClickListener="DieselPriceClick">
          <mat-label>{{
            'benefits.extended_data_input.price_for_diesel' | translate
          }}</mat-label>
          <input
            matInput
            readonly
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            formControlName="price_diesel"
            data-test="price_diesel"
            autocomplete="off"
          />
          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Liter }}</span>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="HydraulicOilPriceClick"
        >
          <mat-label>{{
            'benefits.extended_data_input.price_for_hydraulic_oil' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            formControlName="price_hydraulic_oil"
            data-test="price_hydraulic_oil"
            autocomplete="off"
          />
          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Liter }}</span>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          gaClickListener="LubricantPriceClick"
        >
          <mat-label>{{
            'benefits.extended_data_input.price_for_lubricant' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            formControlName="price_lubricant"
            data-test="price_lubricant"
            autocomplete="off"
          />
          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Kg }}</span>
        </mat-form-field>
        <mat-form-field class="full-width" gaClickListener="CO2PriceClick">
          <mat-label>{{
            'benefits.extended_data_input.price_per_ton_of_co2' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            formControlName="price_co2_per_ton"
            data-test="price_co2_per_ton"
            autocomplete="off"
          />
          <span matTextSuffix>{{ currency?.symbol }}/{{ Units.CO2Ton }} </span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </sl-settings-currency-dependend-page>
</form>
