<ng-container gaCategoryGroup="Cart" gaLabelGroup="ProductPreview">
  <ng-container *ngIf="cart; else loading">
    <!-- todo make properties type secure -->
    <img
      loading="lazy"
      [src]="
        firstItem?.['picture_name']
          ? (firstItem?.['picture_name'] | slSharedImageSize: 'md')
          : '/assets/img/product-image-placeholder.png'
      "
      [alt]="firstItem?.name"
      data-test="image"
      gaClickListener="ImageClick"
      class="full-width"
    />

    <div class="padding" *ngIf="firstItem || cart?.original_cart_id">
      <h4 class="no-margin" data-test="headline" *ngIf="firstItem">
        <a
          *ngIf="firstItemAsProductItem?.model_id; else noLinkTemplate"
          [routerLink]="['../', firstItemAsProductItem?.model_id]"
          [queryParams]="{
            objectType: firstItemAsProductItem?.object_type,
            productType: firstItemAsProductItem?.product_type
          }"
          gaClickListener="NameClick"
          >{{ firstItem?.name }}</a
        >
        <ng-template #noLinkTemplate>
          <span gaClickListener="NameWihtoutLinkClick">
            {{ firstItem?.name }}
          </span>
        </ng-template>
      </h4>
      <ng-container *ngIf="cart?.original_cart_id">
        <small
          data-test="duplication-hint"
          [spotlight]="duplicationHintSidebar"
        >
          {{ 'project_detail.duplicate_hint' | translate }}
          <a (click)="openCartCompare()">
            {{ 'project_detail.open_comparison' | translate }}
          </a>
        </small>
        <ds-spotlight
          id="cpq-spotlight-duplication-hint"
          #duplicationHintSidebar
          position="below"
        >
          {{ 'spotlight.release_2021_11.open_comparison' | translate }}
        </ds-spotlight>
      </ng-container>
    </div>
    <sl-project-product-preview-prices
      *ngIf="showPrice$ | async as showPrice"
      [cart]="cart"
    ></sl-project-product-preview-prices>
    <div class="bottom-container">
      <mat-slide-toggle
        [checked]="showPrice$ | async"
        (change)="togglePrice()"
        data-test="show-price"
        [gaClickListener]="
          (showPrice$ | async) ? 'HidePriceClick' : 'ShowPriceClick'
        "
      >
        {{ 'project_detail.show_price' | translate }}
      </mat-slide-toggle>
      <br />
      <br />
      <mat-form-field class="full-width" gaClickListener="CommentClick">
        <mat-label>{{ 'common.comment' | translate }}</mat-label>
        <textarea
          rows="3"
          matInput
          [formControl]="comment"
          data-test="comment"
        ></textarea>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-template #loading>
    <div gaClickListener="LoadingClick">
      <div class="image-container full-width" data-test="loading">
        <ds-loading-placeholder
          mat-card-image
          height="100%"
        ></ds-loading-placeholder>
      </div>
      <div class="padding">
        <ds-loading-placeholder
          height="100%"
          [width]="1.5 | dsSpacing"
        ></ds-loading-placeholder>
        <br />
        <div>
          <table list role="presentation">
            <tr>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
            </tr>
            <tr>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
            </tr>
            <tr>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
            </tr>
            <tr>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
              <td>
                <ds-loading-placeholder
                  height="1em"
                  [width]="3 | dsSpacing"
                ></ds-loading-placeholder>
              </td>
            </tr>
          </table>
        </div>
        <br />
        <table list role="presentation">
          <tr>
            <td>
              <ds-loading-placeholder
                height="1em"
                [width]="3 | dsSpacing"
              ></ds-loading-placeholder>
            </td>
            <td></td>
          </tr>
        </table>
        <br /><br />
        <div class="image-container">
          <ds-loading-placeholder height="100%"></ds-loading-placeholder>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
