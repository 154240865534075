import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  SolutionItem,
  SolutionItemUpdate,
} from '@sales-libs/project/data-access';
import { SlCartItem } from '@sales-libs/shared/util';
import { Observable } from 'rxjs';
import { CartActions, CartSelectors } from '../../store';
import {
  SlProjectSolutionOptionsDialogComponent,
  SlProjectSolutionOptionsDialogData,
} from './solution-cart-item-options-dialog/solution-cart-item-options-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slProjectSolutionItems]',
  templateUrl: './solution-cart-items.component.html',
  standalone: false,
})
export class SlProjectSolutionItemsComponent {
  @Input() isReadOnly: boolean;
  @Input() isPricingVisible: boolean;
  @Output() configureItem = new EventEmitter<SolutionItem>();

  readonly items$: Observable<SolutionItem[] | null | undefined>;

  constructor(
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
  ) {
    this.items$ = this._store.select(CartSelectors.solutionItems);
  }

  onToOptional(item: SlCartItem) {
    this._store.dispatch(
      CartActions.UpdateSolutionItem({
        update: { ...item, is_optional: true } as SolutionItemUpdate,
      }),
    );
  }

  onConfigure(item: SlCartItem) {
    this.configureItem.emit(item as SolutionItem);
  }

  onShowConfiguration(item: SlCartItem, isPricingVisible: boolean): void {
    this._dialog.open<
      SlProjectSolutionOptionsDialogComponent,
      SlProjectSolutionOptionsDialogData
    >(SlProjectSolutionOptionsDialogComponent, {
      data: {
        name: item.name ?? '',
        options: (item as SolutionItem).options ?? [],
        isPricingVisible: isPricingVisible,
      },
    });
  }

  deleteItem(itemToDelete: SlCartItem): void {
    this._store.dispatch(
      CartActions.DeleteSolutionItem({
        itemId: itemToDelete.id as string,
        cartId: itemToDelete.cart_id,
      }),
    );
  }
}
