import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { TableSettings } from '@design-system/components/table-settings';
import { Store } from '@ngrx/store';
import { Project } from '@sales-libs/project/data-access';
import { slAnimations } from '@sales-libs/shared/util';
import { Observable } from 'rxjs';
import { ProjectSelectors } from '../../store';

@Component({
  selector: 'sl-project-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  animations: [slAnimations.table],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectListComponent {
  @Input()
  pagination: PageEvent;
  @Input()
  projects: Project[];
  @Input() displayColumns: TableSettings[];

  @Output()
  readonly getPage = new EventEmitter<PageEvent>();
  @Output()
  readonly selected = new EventEmitter<Project>();
  @Output()
  readonly deleteProject = new EventEmitter<Project>();
  @Output()
  readonly editProject = new EventEmitter<Project>();
  @Output()
  readonly showCustomer = new EventEmitter<Project>();
  @Output()
  readonly addProject = new EventEmitter();
  @Output()
  readonly setUserSettings = new EventEmitter<TableSettings[]>();

  @Output()
  readonly sortChange = new EventEmitter<Sort>();

  sort$: Observable<Sort | undefined>;

  constructor(private store: Store) {
    this.sort$ = this.store.select(ProjectSelectors.sort);
  }
}
