<ng-container [matColumnDef]="perspective + 'RelativeDiscountInput'">
  <td mat-footer-cell *matFooterCellDef class="text-right">
    <mat-form-field
      class="percentage-input {{ perspective }}-discount-input print-hidden"
      [gaClickListener]="{
        action: perspective + 'SpecialDiscountClick',
        category: 'Cart',
        label: 'RelativeDiscountInputColumn'
      }"
    >
      <input
        (keyup.enter)="triggerBlur($event)"
        matInput
        [formControl]="formControl"
        type="number"
        max="100"
        data-test="discount-input"
        step="0.01"
        autocomplete="off"
      />
      <span matTextSuffix>{{ percentSymbol }}</span>
    </mat-form-field>
    <span class="print-only">
      {{ formControl.value / 100 | percent: '1.2-2' : locale }}
    </span>
  </td>
</ng-container>
