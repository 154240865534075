import { createAction, props } from '@ngrx/store';
import {
  MountingCategoriesContainer,
  TrucksContainer,
} from '@sales-libs/project/data-access';

export namespace MccActions {
  export const GetMountingOptions = createAction('[MCC] GET_MOUNTING_OPTIONS');
  export const GetMountingOptionsSuccess = createAction(
    '[MCC] GET_MOUNTING_OPTIONS_SUCCESS',
    props<{ payload: MountingCategoriesContainer }>(),
  );

  export const GetTruckOptions = createAction('[MCC] GET_TRUCK_OPTIONS');
  export const GetTruckOptionsSuccess = createAction(
    '[MCC] GET_TRUCK_OPTIONS_SUCCESS',
    props<{ payload: TrucksContainer }>(),
  );
}
