<div
  class="padding"
  [gaClickListener]="{
    category: 'ProjectDetail',
    action: 'DetailsClick',
    label: 'Project'
  }"
>
  <table list role="presentation">
    <tr data-test="project-details-state">
      <td gaClickListener="StateClick">{{ 'project.state' | translate }}:</td>
      <td>
        <a
          *ngIf="project.state === states.Lost"
          (click)="lostStateClick.emit(project)"
          data-test="lost-state"
          class="error-text"
          gaClickListener="LostOrderClick"
          >{{ 'project_filters.lost' | translate }}</a
        >
        <a
          *ngIf="project.state === states.Won"
          (click)="wonStateClick.emit(project)"
          data-test="won-state"
          class="success-text"
          gaClickListener="EditOrderClick"
          >{{ 'project_filters.won' | translate }}</a
        >
        <p
          *ngIf="project.state === states.Closed"
          class="success-text"
          gaClickListener="ClosedWonClick"
        >
          {{ 'project_filters.won' | translate }}
        </p>
        <span
          *ngIf="
            project?.state !== states.Won &&
            project?.state !== states.Closed &&
            project?.state !== states.Lost
          "
          gaClickListener="StateClick"
          >{{ 'project_filters.in_progress' | translate }}</span
        >
      </td>
    </tr>
    <tr
      *ngIf="priceListDate"
      gaClickListener="PriceListDateClick"
      data-test="project-details-price-date"
    >
      <td>{{ 'project.price_date' | translate }}:</td>
      <td>
        {{ priceListDate | date: 'shortDate' }}
      </td>
    </tr>
    <tr gaClickListener="VolumeClick" data-test="project-details-volume">
      <td>{{ 'project.volume' | translate }}:</td>
      <td
        data-test="project-volume"
        *pdLet="primaryCartCurrency$ | async as primaryCartCurrency"
      >
        {{
          projectVolume
            | slMoneyAmount$: { currency: primaryCartCurrency }
            | async
        }}
      </td>
    </tr>
    <tr gaClickListener="ChanceClick" data-test="project-detais-chance">
      <td>{{ 'project.chance' | translate }}:</td>
      <td>
        {{ (project?.chance || 0) / 100 | percent }}
      </td>
    </tr>
  </table>

  <ng-container [formGroup]="form">
    <mat-form-field
      class="full-width"
      gaClickListener="CommentClick"
      data-test="project-detail-comment"
    >
      <mat-label>{{ 'project.comment' | translate }}</mat-label>
      <textarea
        matInput
        [formControl]="form.controls.comment"
        data-test="comment-input"
      ></textarea>
    </mat-form-field>
  </ng-container>
</div>
