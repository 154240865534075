<ng-container gaCategoryGroup="BestInvest" gaLabelGroup="MoreDetailsData">
  <h1 mat-dialog-title data-test="title">
    {{ data.feature.name }}
  </h1>
  <div mat-dialog-content>
    <p data-test="description">{{ data.feature.description }}</p>
    <mat-list *ngIf="data.feature.assumptions">
      <ul *ngFor="let assumption of data.feature.assumptions">
        <li data-test="assumptions">{{ assumption }}</li>
      </ul>
    </mat-list>
  </div>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close gaClickListener="CloseDialog">
      {{ 'general.close' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
