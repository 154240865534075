import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LostOrdersService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';
import { LostOrderListActions } from './lost-order-list.action';
import { LostOrderListSelectors } from './lost-order-list.selectors';

@Injectable()
export class LostOrderListEffects {
  private _actions: Actions = inject(Actions);
  private _store: Store = inject(Store);

  loadLostOrders$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderListActions.LoadLostOrders),
      map((action) => action.payload),
      withLatestFrom(this._store.select(LostOrderListSelectors.pagination)),
      switchMap(([payload, _pagination]) =>
        this._lostOrderService
          .searchLostOrders(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            _pagination.pageSize,
            payload || _pagination.pageIndex,
          )
          .pipe(
            map((data) =>
              LostOrderListActions.LoadLostOrdersSuccess({ payload: data }),
            ),
            catchError((err) =>
              this._errorActions(err, 'error_messages.lost_order.not_loaded'),
            ),
          ),
      ),
    ),
  );

  searchLostOrderPage$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderListActions.SearchLostOrders),
      withLatestFrom(
        this._store.select(LostOrderListSelectors.lostOrderFilters),
        this._store.select(LostOrderListSelectors.pagination),
        this._store.select(LostOrderListSelectors.sort),
      ),
      switchMap(([, filters, pagination, sort]) =>
        this._lostOrderService
          .searchLostOrders(
            filters.reason,
            filters.competitor,
            filters.term,
            // parse date adapter type to native date so that query parsing works
            filters.createdDate ? new Date(filters.createdDate) : undefined,
            filters.endDate ? new Date(filters.endDate) : undefined,
            sort?.active,
            sort?.direction,
            pagination.pageSize,
            pagination.pageIndex,
          )
          .pipe(
            map((data) =>
              LostOrderListActions.LoadLostOrdersSuccess({ payload: data }),
            ),
            catchError((err) =>
              this._errorActions(err, 'error_messages.lost_order.not_loaded'),
            ),
          ),
      ),
    ),
  );

  getExport$ = createEffect(() =>
    this._actions.pipe(
      ofType(LostOrderListActions.GetExport),
      withLatestFrom(
        this._store.select(LostOrderListSelectors.lostOrderFilters),
        this._store.select(LostOrderListSelectors.sort),
      ),
      switchMap(([, filters, sort]) =>
        this._lostOrderService
          .exportLostOrdersTable(
            filters.reason,
            filters.competitor,
            filters.term,
            // parse date adapter type to native date so that query parsing works
            filters.createdDate ? new Date(filters.createdDate) : undefined,
            filters.endDate ? new Date(filters.endDate) : undefined,
            sort?.active,
            sort?.direction,
          )
          .pipe(
            map((data) =>
              LostOrderListActions.GetExportSuccess({ payload: data }),
            ),
            catchError(() => [LostOrderListActions.GetExportError()]),
          ),
      ),
    ),
  );

  constructor(
    private readonly _lostOrderService: LostOrdersService,
    private readonly _translateService: TranslateService,
  ) {}

  private _errorActions = (err: any, message: string) => [
    SnackbarActions.ShowError({
      error: err,
      message: this._translateService.instant(message),
    }),
    LostOrderListActions.Error(),
  ];
}
