<tr
  [gaClickListener]="{
    category: 'Cart',
    action: 'MccItemListClick',
    label: 'MccCartItemList'
  }"
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  class="mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  [isItemWithCustomerPriceInput]="true"
  (edit)="onEdit()"
  (delete)="onDelete($event)"
  (toOptional)="onToOptional($event)"
></tr>
